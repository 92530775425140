import React from 'react';
var _ = require('lodash');

import { do_post, ScrollToHereWithRouter, tracking_event } from '../utils.jsx';

import { FormInput, MyForm } from '../components/Forms.jsx';
import { Popup } from '../components/Popup.jsx';

export class RecommendAFriendForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	
	componentDidMount() {
		//if(window.ga) ga('send', 'event', 'Popups', 'show', 'Recommend A Friend');
		tracking_event('recommend_friend_popup_show');
	}
	
	render() {
		var props = {'errors':this.state.errors||{}, 'values':this.state.data||{}, 'context':{'focused':false}};
		
		var inner = <React.Fragment>
			<h2>Recommend a Friend</h2>
			
			{ this.state.success ? <React.Fragment>
				<ScrollToHereWithRouter  offset={-120} />
				<h3>Thank you. Your submission has been received.</h3>
				<div className="form-row"><button type="button" className="right button blue" onClick={ this.handleClose.bind(this)}>Close</button></div>
			</React.Fragment> :

			<MyForm ref="recommend_form" onSubmit={this.handleSubmit.bind(this)}>
			<h3>Your details</h3>
			<FormInput label="First name" name="first_name" required={true} {...props} />
			<FormInput label="Surname" name="surname" required={true} {...props} />
			<FormInput label="Email" name="email" validate="email" required={true} {...props} />
			<FormInput label="Phone" name="phone" required={false} validate="phone" {...props} />
			
			<h3>Your friend's details</h3>
			<FormInput label="First name" name="friend_first_name" required={true} {...props} />
			<FormInput label="Surname" name="friend_surname" required={true} {...props} />
			<FormInput label="Address line 1" name="friend_address_1" required={true} {...props} />
			<FormInput label="Address line 2" name="friend_address_2" required={false} {...props} />
			<FormInput label="Town" name="friend_town" required={true} {...props} />
			<FormInput label="Postcode" name="friend_postcode" required={true} {...props} />
			
			<div className="form-row"><button type="submit" className="right">Submit</button></div>
			</MyForm> }
			
		</React.Fragment>;

		if(this.props.embedStyle=="inline") return inner;
		else return <Popup onClose={this.handleClose.bind(this)}>{ inner }</Popup>;

	}
	
	handleSubmit(fields, errors) {
		var hasErrors = _.find(errors, (v)=>(v!=null));

		this.setState({'data':fields, 'errors':errors});
		if(hasErrors) return;
		
		tracking_event('recommend_friend_popup_submit');
		//if(window.ga) ga('send', 'event', 'Popups', 'submit', 'Recommend A Friend');
		
		return new Promise((resolve)=>{
			do_post('/api/recommend-a-friend/',
				fields, 
			(ret)=>{
				if(ret.errorText) {
					alert(ret.errorText);
				} else {
					this.setState({'success':true, 'data':{}});
				}
			}).finally(resolve);
		});
	}
	
	handleClose() {
		if(this.props.onClose) this.props.onClose();
	}
}
