import React from 'react';

import { BookAppointmentForm } from '../components/BookAppointmentForm.jsx';
import { Popup } from '../components/Popup.jsx';

export class AppointmentQuery extends React.Component {
	constructor(props) {
		super(props);
		this.state = {'booking_appointment':false};
	}
	
	render() {
		return <React.Fragment>
			{ this.state.booking_appointment
				? <BookAppointmentForm onClose={this.handleClose.bind(this)} />
				: <Popup onClose={this.handleClose.bind(this)}>
				<h2>Book an appointment</h2>
				
				<p>Would you like to book an appointment?</p>

				<p>We offer complimentary face-to-face appointments in our London Showroom and Virtual Appointments via Zoom.</p>

				<p>Both appointment types are a brilliant way to start your wedding list.</p>

				<p>You will meet your dedicated Wedding List Advisor who will offer you a tailored experience and unrivalled expertise when it comes to choosing presents for your wedding list.</p>
				
				<p className="actions"><a className="button blue" href="#" onClick={ this.handleYes.bind(this) }>Yes please</a><a className="button blue" href="/my-list/">No thank you</a></p>
				
			</Popup> }
		</React.Fragment>;
	}
	
	handleYes(ev) {
		ev.preventDefault();
		this.setState({'booking_appointment':true});
	}
	
	handleClose() {
		window.location.href = "/my-list/";
	}
}
