import React from 'react';

export class Button extends React.Component {
    constructor(props) {
        super(props);
        this.state = {submitting:false};
    }

	render() {
        var props = this.props;
        
        return <button 
          {...props} 
          className={(props.className||'') + (this.state.submitting?' submitting':'')} 
          onClick={ this.handleClick.bind(this) }>
            { props.children }
        </button>;
    }
    
    handleClick(ev) {
        if(this.state.submitting) {
            ev.preventDefault();
            return;
        }
        if(this.props.onClick) {
			var promise = this.props.onClick(ev);
			if(promise) {
				this.setState({submitting:true});
				promise.finally(()=>{
                    var delay = this.props.delay || 0;
                    if(delay) {
                        setTimeout(()=>{
                            this.setState({submitting: false});
                        }, delay);
                    } else {
    					this.setState({submitting: false});
                    }
				});
			}
		}
    }
}
