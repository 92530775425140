import React from 'react';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { Account } from '../pages/Account.jsx';
import { AccountAdmin } from '../pages/AccountAdmin.jsx';
import { AccountCustomProduct } from '../pages/AccountCustomProduct.jsx';
import { AccountFinances } from '../pages/AccountFinances.jsx';
import { AccountHelp } from '../pages/AccountHelp.jsx';
import { AccountOrders } from '../pages/AccountOrders.jsx';
import { AccountPlaceOrder } from '../pages/AccountPlaceOrder.jsx';
import { Browse } from '../pages/Browse.jsx';
import { BrowseBrands } from '../pages/BrowseBrands.jsx';
import { Catalog } from '../pages/Catalog.jsx';
import { MyList } from '../pages/MyList.jsx';
import { ShowroomScanner } from '../pages/ShowroomScanner.jsx';
import { ThankYouList } from '../pages/ThankYouList.jsx';


export class RootRouter extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	
	
	render() {
		return <Router>
			<Switch>
			<Route exact path="/my-list/" component={MyList} />
			<Route exact path="/my-list/account/" component={Account} />
			<Route exact path="/my-list/account/financial/" component={AccountFinances} />
			
			<Route exact path="/my-list/account/admin/" component={AccountAdmin} />
			<Route exact path="/my-list/thank-you-list/" component={ThankYouList} />
			<Route exact path="/my-list/my-orders/:invoice_id?" component={AccountOrders} />
			<Route exact path="/my-list/help/" component={AccountHelp} />
			<Route exact path="/my-list/place-order/" component={AccountPlaceOrder} />
			<Route exact path="/my-list/custom-product/:step?" component={AccountCustomProduct} />
			<Route exact path="/my-list/scanner/" component={ShowroomScanner} />

			<Route path="/browse/" exact={true} component={Browse} />
			<Route path="/search/" exact={true} component={Catalog} />
			<Route path="/search/:category_id+" component={Catalog} />
			<Route path="/browse/by-brand/" exact={true} component={BrowseBrands} />
			<Route path="/browse/:match_by/" exact={true} component={Browse} />
			<Route path="/browse/:match_by/:category_id+" component={Catalog} />
			
			</Switch>
		</Router>;
	}
}
