if (window.NodeList && !NodeList.prototype.forEach) {
	//polyfill
	NodeList.prototype.forEach = Array.prototype.forEach;
}

(function (arr) {
	//element.remove polyfill
	arr.forEach(function (item) {
	  if (item.hasOwnProperty('remove')) {
		return;
	  }
	  Object.defineProperty(item, 'remove', {
		configurable: true,
		enumerable: true,
		writable: true,
		value: function remove() {
		  this.parentNode && this.parentNode.removeChild(this);
		}
	  });
	});
  })([Element.prototype, CharacterData.prototype, DocumentType.prototype].filter(Boolean));

(function() {
	window.rmon_data = [];
	window.rmon_history = [];
	
	function send_rmon_data() {
		if(window.rmon_data.length==0) return;
		
		if(!window.XMLHttpRequest) return false;
		var req = new XMLHttpRequest();
		req.open('POST','/rmon',true);
		req.setRequestHeader('Content-type','application/json');
		if (req.readyState == 4) return false;
		if(!window.JSON || !window.JSON.stringify) return false;
		req.send(JSON.stringify({'data':window.rmon_data, 'ua':"" + navigator.userAgent, 'url':"" + window.location.href}));
		window.rmon_data = [];
	}
	
	function rmon_report(item) {
		if(!JSON || !JSON.stringify) return false;
		var j = JSON.stringify(item);
		for(var i=0;i < window.rmon_history.length;i++) {
			if(j==JSON.stringify(window.rmon_history[i])) return false;
		}
		window.rmon_history.push(item);
		window.rmon_data.push(item);
	}
	window.rmon_report = rmon_report;
	
	function rmon_tick() {
		/*if(document.body.scrollWidth > (window.innerWidth+10) ) { //10 pixel leeway
			rmon_report({'type':'misalign', 'target':'hscroll', 'error':'Horizontal scrolling', 'scroll_width':"" + document.body.scrollWidth, 'window_width':"" + window.innerWidth});
		}*/
		
		if(window.rmon_data.length) send_rmon_data();
		setTimeout(rmon_tick, 5000);
	}
	setTimeout(rmon_tick, 1000);

	window.onerror = function(msg, url, line, col, error) {
		if(window.location.hostname=="localhost") return false;

		rmon_report({'type':'jserror', 'url':"" + url, 'msg':"" + msg, 'line':"" + line, 'col':"" + col, 'error':"" +error, 'stack':(error&&error.stack)?error.stack:''});
		
		//send_rmon_data();
	
		var extra = !col ? '' : '\ncolumn: ' + col;
		extra += !error ? '' : '\nerror: ' + error;
		console.log("Error: " + msg + "\nurl: " + url + "\nline: " + line + extra);

		return true; //suppress
	};
})();


import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Redirect, Link, NavLink, Prompt, Switch, withRouter } from 'react-router-dom';
import QueryString from 'query-string';
import toastr from 'toastr';
//import { ScrollBox } from 'react-scroll-box';
var $ = require('jquery');
var _ = require('lodash');
var pace = require('pace-js');
window.$ = $;
require('slick-carousel');
import ReactCrop, {makeAspectCrop} from 'react-image-crop';
import Player from '@vimeo/player';

import { cookies, decodeID, do_get, do_post, ScrollToHere, tracking_event } from './utils.jsx';








window.toastr = toastr;
toastr.options = {
	"closeButton": true,
	"closeHtml": '<button class="toast-close-button">&nbsp;</button>',
	"debug": false,
	"newestOnTop": false,
	"progressBar": false,
	"positionClass": "toast-bottom-right",
	"preventDuplicates": false,
	"onclick": null,
	"showDuration": "300",
	"hideDuration": "1000",
	"timeOut": "5000",
	"extendedTimeOut": "1000",
	"showEasing": "swing",
	"hideEasing": "linear",
	"showMethod": "fadeIn",
	"hideMethod": "fadeOut"
};





var toTop = ()=>{
	window.scrollTo(0, 0);
	//$("html, body").animate({ scrollTop: 0 }, 200);
};

var toTopSmooth = ()=>{
	$("html, body").animate({ scrollTop: 0 }, 200);
};








var clearAndGetById = (id)=>{
	var el = document.getElementById(id);
	if(!$(el).hasClass('emptied')) {
		$(el).addClass('emptied');
		//$(el).empty();
	}
	return el;	
};












var create_alert = (msg, extra)=>{
/*	var abc = $('#alert-bar-container');
	abc.empty();
	var ab = $('<div class="alert-bar error"></div>');
	var con = $('<div class="container"></div>').appendTo(ab);
	var col = $('<div class="twelve columns"></div>').appendTo(con);
	col.append(msg);
	var a = $('<a href="#" class="close"><picture><source type="image/svg+xml" srcSet="/assets/images/alert-close.svg" /><img src="/assets/images/alert-close.png" /></picture></a>').appendTo(con);
	a.on('click', (ev)=>{
		ev.preventDefault();
		ab.removeClass('in');
	});
	ab.appendTo(abc);
	setTimeout(()=>{ab.addClass('in');}, 50);*/
	var body = "";
	body += "<h3>Sorry, there was a problem</h3>";
	body += "<p>" + msg + "</p>";
	toastr.options.timeOut = 10000;
	toastr.error(body);
	if(window.onerror) {
		window.onerror(msg, window.location.href, 1, 1, JSON.stringify(extra));
	}
};
window.create_alert = create_alert;

















import { Menu } from './components/Menu.jsx';










/* this is all a bit complicated, what is the sequence of calls?
 * 
 * Catalog is normally fed a top-level-category ID, as the first bit of the path. It then put this in the 'requestedCategoryId' state variable. This only changes if the user moves to a different top level category.
 * 
 * There is also a state variable called 'selectedCategoryId', which is the category ID for the products being shown. This is set to the the rightmost path element (ie, the leaf category).
 * 
 * Some queries are then made:
 *   - get-categories is called for requestedCategoryId, which gets the child category tree, list of brands, and product counts. Used for breadcrumb trail and sidebar filters.
 *   - get-categories is called to get all categories, but excluding children, and all brands. This is used for the populating the browse drop-down menu.
 *   - if requestedCategoryId!=selectedCategoryId, get-categories is called on the selectedCategoryId to get available brands in that category for the filter.
 *   - get-products is called for the selectedCategoryId to populate the RHS
 * 
 * 
 * Ranges work with a minor hack which sets selectedCategoryId
 * 
 */

/* so, for the initial page-load fetch:
 * 
 * - get-categories for requestedCategoryId with includeBrands=true, includeProductCounts=true to populate the category filter and brand filter.
 * - get-products for requestedCategoryId
 * 
 * what about if it is a by-brand search? hmm...
 * 
 * when a subcategory is clicked:
 * 
 * - get-categories on the subcategory with includeBrands=true, to get the available brands
 * - get-products for selectedCategoryId with selectedBrands (do we actually need to prefilter the selectedBrands before this call?)
 * 
 * when a range is clicked:
 * 
 * - get-products on selectedCategoryId (no need for get-categories at all?)
 * 
 */



import { ListFinder } from './pages/ListFinder.jsx';
import { MyList } from './pages/MyList.jsx';
import { MyListPrint } from './pages/MyListPrint.jsx';
import { RegisterForm } from './pages/RegisterForm.jsx';
import { RootRouter } from './pages/RootRouter.jsx';
import { SearchResults } from './pages/SearchResults.jsx';
import { ThankYouList } from './pages/ThankYouList.jsx';
import { ViewList } from './pages/ViewList.jsx';

import { BookAppointmentForm } from './components/BookAppointmentForm.jsx';
import { CashHandlingFeeNotice } from './components/CashHandlingFeeNotice.jsx';
import { ForgotPasswordForm } from './components/ForgotPasswordForm.jsx';
import { ImageUploader } from './components/ImageUploader.jsx';
import { LoginForm } from './components/LoginForm.jsx';
import { PasswordResetForm } from './components/PasswordResetForm.jsx';
import { RecommendAFriendForm } from './components/RecommendAFriendForm.jsx';
import { RequestBrochureForm } from './components/RequestBrochureForm.jsx';



var components = {
	'BookAppointmentForm':BookAppointmentForm,
	'CashHandlingFeeNotice':CashHandlingFeeNotice,
	'ForgotPasswordForm':ForgotPasswordForm, 
	'ImageUploader':ImageUploader,
	'ListFinder':ListFinder, 
	'LoginForm':LoginForm, 
	'MyList':MyList, 
	'MyListPrint':MyListPrint,
	'PasswordResetForm':PasswordResetForm,
	'RecommendAFriendForm':RecommendAFriendForm, 
	'RegisterForm':RegisterForm, 
	'RequestBrochureForm':RequestBrochureForm, 
	'RootRouter': RootRouter,
	'SearchResults':SearchResults,
	'ViewList':ViewList, 
};

window.initialise_catalog = function(basename, cid) {
	ReactDOM.render(
		<Router basename={basename}>
			<RootRouter />
		</Router>,
		document.getElementById('catalog')
	);
};

window.initialise_component = function(target, component_type, props) {
	window.wpco_api_prefix = window.wpco_api_prefix;
	ReactDOM.render(
		<Router>
			{ React.createElement(withRouter(components[component_type]), props) }
		</Router>,
		target
	);
};

// reveals
$(function() {
	// var transitionables = $('body.page-home .outer-how_it_works');
	// transitionables.addClass('transition');
	var transition_cache = {
		'last_updated':0,
		'data':[]
	};

	var getTransitionPoints=()=>{
		var now = (new Date()).getTime();
		if((now-transition_cache.last_updated)<2000) return transition_cache.data;

		var ret = [];
		[].forEach.call(document.querySelectorAll('.transition'), (d)=>{
			if(d.classList.contains('in')) return;
			var rect = d.getBoundingClientRect();
			var top = rect.top+window.scrollY;
			var bottom = top + rect.height;
			ret.push({
				'element':d,
				'top':top,
				'bottom':bottom
			});
		});
		transition_cache.last_updated = now;
		transition_cache.data = ret;
		return ret;
	};

	var onScroll = (ev)=>{
		var top = window.scrollY;
		var bottom = top + window.innerHeight*0.75;
		getTransitionPoints().forEach(d=>{
			if(bottom > d.top) d.element.classList.add('in');
		});
	};

	window.addEventListener('scroll', onScroll);
	onScroll();
});

//parallax
$(function() {
	return;
	if($(window).innerWidth()<720) return;
	var overlays = [];
	$('.image-feature .overlay, .brands_row .overlay').each(function(k, v) {
		var v = $(v);
		var parent = v.parent();
		
		var parent_top = v.parent().offset().top;
		var parent_height = v.parent().height();
		var anchor = parent_top + (parent_height/2);
		
		var type = '';
		if(parent.hasClass('position-bottom_right')) type = "bottom";
		else if(parent.hasClass('position-top_left') || parent.hasClass('position-top_right')) { 
			type = "top";
			anchor = parent_top;
			var img = parent.find('.image');
			if(img.length) anchor = img.offset().top;
		} else if(parent.hasClass('brands_row')) {
			type = "top"
			anchor = parent_top;
		} else if(parent.hasClass('position-mid_right') || parent.hasClass('position-mid_left')) type = 'mid';
		else {
			type = "bottom";
			var img = parent.find('.image');
			if(img.length) anchor = img.offset().top + img.height();
		}
		
		overlays.push([v, anchor, type]);
	});
	
	var onscroll = function() {
		var wh = $(window).height();
		var y = $(window).scrollTop();// - wh*0.5;

		var sf = 600/wh;
		
		overlays.forEach(function(v,k) {
			var diff = 0;
			if(v[2]=="bottom") diff = Math.min( (v[1]-(y+(wh*0.5)))*sf*0.15, 0);
			else if(v[2]=="top") diff = Math.max( (v[1]-(y+wh*0.25))*sf*0.15, 0);
			else if(v[2]=="mid") diff = ((v[1]-y)-(wh*0.2))*sf*0.15;
			
			v[0].css('top', diff);
		});
	};
		
	window.addEventListener('scroll', onscroll, {passive: true});
	onscroll();
});

//reflow blog
$(function() {
	var single = null;
	var onresize = function() {
		var w = window.innerWidth || $(document.body).width();
		if((w<720 && single) || (w>=720 && single===false)) return;
		single = (w<720);
		
		var cols = $('.post-column');
		var posts = {};
		
		$('.post-link').each(function(k,v) {
			posts[parseInt($(v).attr('data-index'))] = v;
		});
		
		var ncols = single ? 2 : 3;
		
		var indices = _.sortBy(_.keys(posts), (i)=>(parseInt(i)));
		_.each(indices, (i, k)=>{
			var post = $(posts[i]);
			$(cols[k%ncols]).append(post);
		});
		
//		console.log(posts);
	};
	
	$(window).on('resize', onresize);
	onresize();
});

$(function() {

	$('.container-person').each(function(k, v) {
		var controls = $('<div class="container scroll-control"><div class="left"></div><div class="right"></div></div>');
		controls.insertBefore(v);
		
		$(v).slick({
			responsive: [{
				breakpoint: 719,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
				}
			}],
			slidesToShow: 4,
			slidesToScroll: 4,
			dots:true,
			prevArrow:controls.find('.left'),
			nextArrow:controls.find('.right'),
		});
	});

	/*$('body.page-oldhome .container-testimonial_row .testimonials, .container-testimonial_row_v1 .testimonials').each(function(k, v) {
		var controls = $('<div class="container scroll-control"><div class="left"></div><div class="right"></div></div>');
		controls.insertBefore(v);
		
		$(v).slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			adaptiveHeight: true,
			autoplay:true,
			autoplaySpeed:6500,
			dots:true,
			fade: true,
			prevArrow:controls.find('.left'),
			nextArrow:controls.find('.right'),
		});
	});*/

	$('.container-testimonial_row .testimonials').each(function(k, v) {
		var controls = $('<div class="container scroll-control"><div class="left"></div><div class="right"></div></div>');
		controls.insertBefore(v);
		
		$(v).slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			adaptiveHeight: true,
			autoplay:true,
			autoplaySpeed:6500,
			dots: false,
			fade: true,
			prevArrow:controls.find('.left'),
			nextArrow:controls.find('.right'),
		});

		$(v).find('a[data-prev]').on('click', function(ev) {
			ev.preventDefault();
			$(v).slick('slickPrev');
		});
		$(v).find('a[data-next]').on('click', function(ev) {
			ev.preventDefault();
			$(v).slick('slickNext');
		});
	});

	$('.container-product_carousel .product-carousel').each(function(k, v) {
		var controls = $('<div class="container scroll-control"><div class="left"><svg width="17" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.905 30.726"><path d="M16.56 30.362L.727 15.47 16.56.362" fill="none" stroke="#47513a" stroke-miterlimit="10"/></svg></div><div class="right"><svg width="17" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.905 30.726"><path d="M.345.364l15.833 14.892L.345 30.364" fill="none" stroke="#47513a" stroke-miterlimit="10"/></svg></div></div>');
		controls.insertBefore(v);

		$(v).slick({
			responsive: [{
				breakpoint: 719,
				settings: {
					slidesToScroll: 1,
				}
			}],
			slidesToShow: 3,
			slidesToScroll: 3,
			adaptiveHeight: true,
			autoplay:true,
			autoplaySpeed:650000, // TODO reduce to 6500
			dots: false,
			prevArrow:controls.find('.left'),
			nextArrow:controls.find('.right'),
		});
	});
	
	$('.brand-slider').each(function(k, v) {
		if($(document.body).hasClass('page-newhome')) return;
		if($(window).innerWidth()>=720) return;
		
		$(v).slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			dots:true,
			arrows:false,
		});
	});
	
	$('body.page-newhome .brand-slider').each(function(k, v) {
		if($(window).innerWidth()>=720) return;
		
		$(v).slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			dots:false,
			arrows:false,
		});

		$(v).find('a[data-prev]').on('click', function(ev) {
			ev.preventDefault();
			$(v).slick('slickPrev');
		});
		$(v).find('a[data-next]').on('click', function(ev) {
			ev.preventDefault();
			$(v).slick('slickNext');
		});
	});
	
	
	return false;
	
	
	window.scroll_direction = null;
	$('.container-person').each(function(k, v) {
		var v = $(v);
		var controls = $('<div class="container scroll-control" />');
		
		var advance = function(dir) {
			if(window.scroll_direction===null) window.scroll_direction = dir;
			dir *= window.scroll_direction;
			
			var targ = v.scrollLeft()+(dir*v.width());
			targ = Math.max(0, targ);
			if(v[0].scrollWidth) targ = Math.min(v[0].scrollWidth-v.width(), targ);
			v.animate({scrollLeft:targ});
		};
		
		var left = $('<div class="left" />');
		left.on('click', function(ev) {
			advance(1);
		});
		left.appendTo(controls);
		var right = $('<div class="right" />');
		right.on('click', function(ev) {
			advance(-1);
		});
		right.appendTo(controls);
		
		controls.insertBefore(v);
	});
	
	window.scroll_direction = null;
	$('.container-testimonial_row .testimonials').each(function(k, v) {
		var timer = 0;
		var v = $(v);
		var spots = $(v).parent().find('.spots');
		var controls = $('<div class="container scroll-control" />');
				
		var advance = function(dir, reset) {
			if(timer) clearTimeout(timer);
			timer = 0;
			if(window.scroll_direction===null) window.scroll_direction = dir;
			dir *= window.scroll_direction;
			
			var limit = parseInt(Math.round(v[0].scrollWidth/v.width()));
			var ind = parseInt(Math.round(v.scrollLeft()/v.width())) + dir;
			ind = Math.max(ind, 0);
			if(ind >= limit && reset) ind = 0;
			ind = Math.min(ind, limit-1);

			goto(ind);
		};
		
		var goto = function(i) {
			var targ = (i*v.width());
			targ = Math.max(0, targ);
			console.log(v.width(), v[0].scrollWidth);
			if(v[0].scrollWidth) {
				spots.find('.spot.active').removeClass('active');
				$(spots.find('.spot')[i]).addClass('active');
			}
			
			v.animate({scrollLeft:targ});
		};
		
		spots.on('click', function(ev) {
			if(timer) clearTimeout(timer);
			timer = 0;
			var i = $(ev.target).attr('data-index');
			if(i!==undefined) goto(i);
		});
		
		var left = $('<div class="left" />');
		left.on('click', function(ev) {
			advance(1);
		});
		left.appendTo(controls);
		var right = $('<div class="right" />');
		right.on('click', function(ev) {
			advance(-1);
		});
		right.appendTo(controls);
		
		controls.insertBefore(v);
		
		var tick = function() {
			advance(1, true);
			timer = setTimeout(tick, 5000);
		};
		timer = setTimeout(tick, 5000);
	});
});

$(function() {
	//open first homepage faq expander by default
	$('body.page-home .faq .inner .section.expander:first-child').addClass('open');

	$('.expander').each((k,v)=>{
		var context = {};
		var v = $(v);
		
		var close = ()=>{
			var h = parseInt(v.attr('data-initial-height'));
			v.css('max-height', v.prop('scrollHeight'));
			setTimeout(function() {
				v.css('max-height', h);
				v.addClass('closing');
			}, 10);
			if(context.timeout) clearTimeout(context.timeout);
			context.timeout = setTimeout(function() {
// 					v.css('max-height', '');
				v.removeClass('open');
				v.removeClass('closing');
			}, 300);
		};
		
		v.find('.head').on('click', ()=>{
			if(v.hasClass('inactive')) return;
			//var h = v.find('.head').outerHeight();
			
			if(v.hasClass('open')) {
				close();
			} else {
				var h = parseInt(v.attr('data-initial-height'));
				v.css('max-height', h);
				v.addClass('open');
				v.css('max-height', v.prop('scrollHeight'));
				
				if(context.timeout) clearTimeout(context.timeout);
				context.timeout = setTimeout(function() {
					v.css('max-height', null);
				}, 600);
				var yoff = 50;
			}
		});
		
		
		v.find('*[data-action=close]').on('click', ()=>{
			close();
		});

		var head = v.find('.head');
// 		console.log(head, head.length, head.outerHeight());
// 		console.log(parseInt(head.css("border-top-width")) + parseInt(head.css("border-bottom-width")));
		
		var h = (head.length ? (head.height()+parseInt(head.css("border-top-width")) + parseInt(head.css("border-bottom-width"))) : v.outerHeight());
		v.attr('data-initial-height', h);
		//var h = v.find('.head').outerHeight();
		if(!v.hasClass('open')) v.css('max-height', h)
		else v.css('max-height', v.prop('scrollHeight'));
	});

});

$(function() {
	$('a[data-action=show-search]').each(function(k,v) {
		$(v).on('click', function(ev) {
			ev.preventDefault();
			if($('body').hasClass('search-open')) {
				$('body').removeClass('search-open');
			} else {
				$('body').addClass('search-open');
				$('body').addClass('menu-open');
				$('.header input.search').focus();
			}
			
		});
	});

	var del = $('.header input.search + .clear');
	if(!del.length) {
		var del = $('<div class="clear"></div>');
		del.insertAfter($('.header input.search'));
		var form = del.closest('form');
		del.on('click', (ev)=>{
			$('.header input.search').val('').focus();
			form.removeClass('populated');
		});
		
		
		var on_change = ()=>{
			if($('.header input.search').val().length > 0) {
				form.addClass('populated');
			} else {
				form.removeClass('populated');
			}
		};
		
		$('.header input.search').on('keyup', on_change);
		$('.header input.search').on('change', on_change);
		on_change();
	}
	
	$('a[data-action=toggle-search]').on('click', function(ev) {
		ev.preventDefault();
		if($(document.body).hasClass('search-open')) {
			$(document.body).removeClass('search-open');
			$('.frontend .header .search-bar').css('display', '');
		} else {
			$('.frontend .header .search-bar').css('display', 'block');
			setTimeout(()=>{
				$(document.body).addClass('search-open');
				$('.header input.search').focus();
			}, 50);
		}
	});
	if($(document.body).hasClass('search-open')) $('.frontend .header .search-bar').css('display', 'block');
	
});

$(function() {
	_.each([
		['#tell-your-friends', 'RecommendAFriendForm'],
		['#request-a-brochure', 'RequestBrochureForm'],
		['#book-appointment', 'BookAppointmentForm'],
	], ([frag, com])=>{
		var onclick = null;
		$('a[href="' + frag + '"]').each(function(k,v) {
			var con = $('<div></div>');
			con.appendTo($(v).parent());
			onclick = function(ev) {
				window.initialise_component(con[0], com, {'onClose':()=>{
					ReactDOM.unmountComponentAtNode(con[0]);
				}});
			};
			$(v).on('click', onclick);
		});
		if(window.location.hash==frag && onclick) onclick();
	});

	$('.recommend-a-friend-form').each(function(k,v) {
		window.initialise_component(v, "RecommendAFriendForm", {embedStyle:'inline'});
	});
});


$(function() {
	$('a[data-action=show-redeem]').each(function(k,v) {
		$(v).on('click', function(ev) {
			ev.preventDefault();
			$('body').toggleClass('show-redeem');
			$('input[name=voucher_code]').focus();
		});
	});
});

$(function() {
	$('.filter-bar').each(function(k,v) {
		$(v).on('click', function(ev) {
			console.log(ev.target);
//			ev.preventDefault();
			$(v).toggleClass('open');
		});
	});
});

$(function() {
	$('.alert-bar a.close').each(function(k,v) {
		$(v).on('click', function(ev) {
			ev.preventDefault();
			var ab = $(v).parents('.alert-bar');
			ab.removeClass('in');
			if($(v).attr('data-action')=='cookies_agree') {
				ab.css('bottom', -(ab.height()+5));
				cookies.set('cookies_agree', '1', {path:'/', maxAge:365*86400, secure:true});
			}
			if($(v).attr('data-action')=='seen_welcome') {
				ab.css('margin-top', -ab.height());
				cookies.set('seen_welcome', '1', {path:'/', maxAge:365*86400, secure:true});
			}
			if($(v).attr('data-key')) {
				cookies.set('seen_' + $(v).attr('data-key'), '1', {path:'/', maxAge:2*3600, secure:true});
			}
		});
		if($(v).attr('data-key')) {
			$(v).closest('.alert-bar').find('p a').on('click', function(ev) {
				cookies.set('seen_' + $(v).attr('data-key'), '1', {path:'/', maxAge:2*3600, secure:true});
			});
		}
	});
});


$(function() {
	$('.video-feature').each(function(k,v) {
		$(v).find('.image').on('click', function(ev) {
			//ev.preventDefault();
			var iframe = $(v).find('iframe')[0];
			if(iframe) {
				var player = new Player(iframe);
				
				$(v).find('.overlay').on('click', function(ev) {
					if($(v).hasClass('hide-overlay')) {
						player.pause();
					}
				});
				
				player.on('play', function() {
					$(v).addClass('show-player');
					$(v).addClass('hide-overlay');
				});
				player.on('pause', function() {
					$(v).removeClass('hide-overlay');
					$(v).removeClass('show-player');
//					player.pause();
// 					if($(window).innerWidth()<720) {
// 					}
					tracking_event('video_pause');
					//if(window.ga) ga('send', 'event', 'Videos', 'pause', iframe.src);
				});
				player.on('stop', function() {
					$(v).removeClass('hide-overlay');
				});
				player.on('ended', function() {
					$(v).removeClass('hide-overlay');
					$(v).removeClass('show-player');
					tracking_event('video_end');
					//if(window.ga) ga('send', 'event', 'Videos', 'end', iframe.src);
				});

				tracking_event('video_play');
//				if(window.ga) ga('send', 'event', 'Videos', 'play', iframe.src);
				
				player.setCurrentTime(0);
				player.play();
			}
		});
	});
});

$(function() {
	$('#chk_weekdays_am,#chk_weekends_am').on('click', (ev)=>{
		var targ = $(ev.target);
		if(targ.is(':checked')) targ.prev().prev().prop('checked', true);
	});
	$('#chk_weekdays_pm,#chk_weekends_pm').on('click', (ev)=>{
		var targ = $(ev.target);
		if(targ.is(':checked')) targ.prev().prev().prev().prev().prop('checked', true);
	});
});


$(function() {
	$('#menu-open-link').on('click', function(ev) {
		ev.preventDefault();
		$(document.body).toggleClass('menu-open');
	});

	$('.tablet-menu-open-link').on('click', function(ev) {
		ev.preventDefault();
		$(document.body).toggleClass('tablet-menu-open');
	});

	
//	window.addEventListener("click", function(ev) {
		//console.log('got click');
// 		var node = $(ev.target);
// 		if(node.hasClass('internal')) {
// 			console.log('eating click');
// 			ev.preventDefault();
// 			return;
// 		}
//	});
	
	window.addEventListener("touchstart", function(ev) {
		$(document.body).removeClass('no-touch').addClass('has-touch');
		var node = $(ev.target);

 		var no_hover = node.hasClass('no-hover'); //disable hovering
			
// 			console.log('eating click');
// 			var event = document.createEvent("HTMLEvents");
// 			event.initEvent("click", true, true);
// 			node[0].dispatchEvent(event);
// 			ev.preventDefault();
// 			return;
//		}
//		console.log('touchstart on',node);
		
		//if(!node.getParent) return;
		if(!node.hasClass('hoverable')) node = $(ev.target).parents('.hoverable');
		var was_hovering = node.length ? node.hasClass('hovering') : false;

		console.log('touchstart', ev.target, node, was_hovering);

		$(document).find('.hovering').each(function(k, v) {//.removeClass('hovering');
			if($.contains(v, ev.target)) {
			} else {
				$(v).removeClass('hovering');
			}
		});
//		$(document).find('.dehover').removeClass('dehover');
		if(node.length) {
			if(!was_hovering && !no_hover) node.addClass('hovering');
			
			//console.log(was_hovering, $(ev.target).prop('tagName')=='A', 
			
			if(!was_hovering && ($(ev.target).prop('tagName')=='A' || $(ev.target).parents('a').length)) {
				console.log('preventing');
				ev.preventDefault();
			}
			if(was_hovering && node.hasClass('hover-toggle')) node.removeClass('hovering');
			//setTimeout(function() { node.addClass('hovered'); }, 1000);
		}
	}, {passive: false});
		
	$(document).find('.clickable').each(function(k, el) {
		$(el).click(function(ev) {
			var btn = el.getElement('a.button');
			if(btn && (!el.hasClass('hovering') || el.hasClass('hovered'))) window.location.href = btn.get('href');
		});
	});
	
	var win = $(window);
	var body = $(document.body);
	window.addEventListener('scroll', function() {
		//ev.preventDefault();
		//$(document.body).toggleClass('menu-open');
		if(win.scrollTop()>($(win).height()*0.5)) {
			if(!body.hasClass('scrolled')) body.addClass('scrolled');
		} else {
			if(body.hasClass('scrolled')) body.removeClass('scrolled');
		}
	}, {passive: true});
	
	$('#back-to-top').on('click', (ev)=>{
		ev.preventDefault();
		toTopSmooth();
	});
	
	$('#id_country').each(function(k, v) {
		var el = $(v);
		var onchange = function() {
			el.parents('form').attr('data-country', el.val());
		};
		el.on('change', onchange);
		onchange();
	});
});

if(window._domready) _.each(window._domready, (f)=>($(f)));
window._domready = {push:(f)=>($(f))};
pace.start({
	// ajax: {
	// 	ignoreURLs: [/.*facebook\.com\/tr\/.*/]
	// }
})

