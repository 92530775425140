import React from 'react';

import { 
    MoneyInput,
	myParseFloat,
    myParseInt
} from '../utils.jsx';

import { 
    isGiftCard,
    isMoneyQuantity,
    shouldShowRequiredRemaining
} from '../logic.jsx';

export class AddToBasket extends React.Component {
	constructor(props) {
		super(props);
        this.state = {'quantity':undefined};
	}
	
	render() {
        var maxqty = this.getMaxQuantity(this.props.giftListItem);

		var genQuantityInput = (prod)=>{
			maxqty = Math.min(maxqty, 180); //arbitrary maximum
			if (maxqty > 0 || isGiftCard(prod)) {
				if(isMoneyQuantity(prod)) {
					return <MoneyInput className="quantity" value={ this.state.quantity?this.state.quantity:"0"} onChange={ this.handleQuantityChange.bind(this) } placeholder="0.00" />;
				} else {
					return <select className="quantity" value={ this.state.quantity||"1"} onChange={ this.handleQuantityChange.bind(this) }>{ _.map(_.range(1, maxqty+1), (i)=>(<option key={i} value={i}>{i}</option>)) }</select>;
				}
			}
			return "";
		};

		var genAdd = (prod, ci)=>{
			var maxqty = this.getMaxQuantity(prod) - (ci?ci.quantity:0);
			if(ci) {
				if(maxqty>0 || isGiftCard(prod)) {
					return <a href="#" onClick={ this.handleAdd.bind(this) }>Add more</a>;
				}
				return "Added";
			} else if(maxqty>0 || isGiftCard(prod)) {
				return <a href="#" onClick={ this.handleAdd.bind(this) }>{ prod.isGroupGift ? "Contribute" : "Add" }</a>;
			}
		};

        if (!(this.props.cartItem || maxqty > 0 || isGiftCard(this.props.giftListItem))) {
            if(this.props.style=="td") {
                return <td colSpan="2" className="center already">Already purchased</td>;
            } else {
                return <p>Already purchased</p>;
            }
        }

        var showRequiredRemaining = shouldShowRequiredRemaining(this.props.giftListItem);
        var qtyInput = genQuantityInput(this.props.giftListItem);
        var addButton = genAdd(this.props.giftListItem, this.props.cartItem);

        if(this.props.style=="td") {
            return <React.Fragment>
				<td className="center qty-required">{ showRequiredRemaining ? this.props.giftListItem.required : "" }</td>
				<td className="center qty-remaining">{ showRequiredRemaining ? this.props.giftListItem.remaining : "" }</td>
                <td className="center quantity">
					{ qtyInput }
                </td>
                <td className="center add">
                    { addButton }
                </td>
            </React.Fragment>;
        } else {
            return <React.Fragment>
            	{ showRequiredRemaining && <React.Fragment> 
					<dt>Required</dt>
					<dd>{ this.props.giftListItem.required }</dd>
					<dt>Remaining</dt>
					<dd>{ this.props.giftListItem.remaining }</dd>
				</React.Fragment> }
                <dt className="quantity">
                    { qtyInput }
                </dt>
                <dd className="add">
                    { addButton }
                </dd>
            </React.Fragment>;
        }
	}
	
    handleAdd(ev) {
        ev.preventDefault();
        this.props.onAdd(this.state.quantity);
    }

	getMaxQuantity(prod) {
		if(prod.isUnlimited || prod.required>50000) return 99999;
		return myParseInt(prod.remaining);
	}

    handleQuantityChange(ev) {
		var quan = this.state.quantity;
		var max = this.getMaxQuantity(this.props.giftListItem);

		if(this.props.giftListItem.isGroupGift) max = myParseFloat(this.props.giftListItem.remaining);

		if(ev.target.value=="") {
			quan = '';
		} else if(this.props.giftListItem.isUnlimited || isGiftCard(this.props.giftListItem)) {
			quan = parseInt(ev.target.value);
		} else {
			quan = Math.min(Math.max((parseInt(ev.target.value) || 0), 0), max);
		}
		this.setState({'quantity':quan});
	}

}
