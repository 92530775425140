import React from 'react';

import { Popup } from '../components/Popup.jsx';

export class CashHandlingFeeNotice extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            'popupOpen':props.popupOnly || false
        };
    }

	render() {
        var popup = null;
        if(this.state.popupOpen) {
            popup = <Popup className="lighter" onClose={this.handleClose.bind(this)}>
				<h2>Handling fee for funds</h2>
				{ this.props.isGuest ?
					<p>We charge a small handling fee of { this.props.cashChargePercentage }% to any guest that contributes to a cash fund, to simply cover our costs.</p>
                    : 
                    <p>Thank you for choosing to add a Honeymoon, Furniture or Art Fund to your list. We will not charge you for including these on your list or for withdrawing funds, however there is a { this.props.cashChargePercentage }% handling fee to guests who decide to contribute to these funds, just to cover our costs. <br />See our <a href="/terms/terms-couples/" target="_blank">T&amp;Cs</a> for more information.</p>
                }
			</Popup>;
        }

        if(!this.props.cashChargePercentage) {
            return <React.Fragment></React.Fragment>;
        }

        if(this.props.popupOnly) {
            return <React.Fragment>{ popup }</React.Fragment>;
        }

		return <div className="handling-fee-notice">{ popup }<a href="#" onClick={ this.handleHelpClick.bind(this) }>{ this.props.cashChargePercentage }% handling fee <span className="help-tooltip">?</span></a></div>;
	}

    handleHelpClick(ev) {
        ev.preventDefault();
        this.setState({popupOpen:true});
    }

    handleClose() {
        this.setState({popupOpen:false});
    }
}
