import React from 'react';
var $ = require('jquery');
var _ = require('lodash');

import { cookies, do_get, do_get_cached, do_post, ScrollToHereWithRouter, tracking_event } from '../utils.jsx';
import { countries } from '../countries.js';

import { AppointmentQuery } from '../components/AppointmentQuery.jsx';
import { FormInput, FormStep } from '../components/Forms.jsx';

export class RegisterForm extends React.Component {
	constructor(props) {
		super(props);
		
		var data = {};
		try {
			data = JSON.parse(window.localStorage.create_list_data);
			delete data['OtherText']; //fix case-sensitivity issues
			delete data['WeddingType'];
			delete data['referrerText'];
		} catch(err) { data = {}; }
		
		this.state = {
			'open':'login', 
			'active':{'login':true}, 
			'data':data || {}, 
			'changes':data || {}, 
			'emailStatus':{}, 
			'errors':{},
			'message':null,
			'referrerFriendId':null,
			'referrerOtherId':null,
			'forceReferrer':null,
			'referrers':[],
		};
	}
	
	componentDidMount() {
		do_get_cached(window.wpco_api_prefix + 'Util/GetReferrers', {}, (r)=>{
			var state = {};
			
			var ref = _.find(r.referrers, ref=>ref.name=="Personal Recommendation");
			state.referrerFriendId = ref ? ref.value : null;
			
			var ref = _.find(r.referrers, ref=>ref.name=="Other");
			state.referrerOtherId = ref ? ref.value : null;
			
			state.referrers = _.filter(r.referrers, ref=>( ref.value!=state.referrerFriendId && ref.value!=state.referrerOtherId && ref.show && ref.showOnWeb) );
			state.referrers = _.sortBy(state.referrers, r=>r.sortOrder);

			var wpsource = cookies.get('wpsource');
			if(wpsource) {
				var matches = _.filter(r.referrers, ref=>ref.name && ref.name.toLowerCase()==wpsource.toLowerCase());
				if(matches.length) state.forceReferrer = matches[0];
			}

			this.setState(state);
		});
	}
	
	doStep(title, key, contents, options) {
		return <FormStep title={title} name={key} open={key==this.state.open} active={this.state.active[key]} onRequestOpen={this.handleRequestOpen.bind(this, key)} onNext={this.handleNext.bind(this, key)} options={options || {}}>
			{ contents }
		</FormStep>;
	}
	
	doHearAboutOption(opt, options) {
		// var options = options || {};
		// var [opt, detail] = _.split(v, "[");
		// if(detail) detail = _.split(detail, "]")[0];
		var val = this.state.data['referrerId'] || [];
		
		var checked = this.state.changes['referrerId']!==undefined ? (this.state.changes['referrerId']==opt.id) : val==opt.id;

		var extra = null;
		if(opt.argument) {
		// 	var dkey = "hear_about__" + opt + "__detail";
			var dkey = "referrerText_" + opt.id;
		 	extra = <input className={"if-checked form-input " + (this.state.errors[dkey]?'error':'')} type="text" name={ dkey } placeholder={ opt.argument } defaultValue={ this.state.data[dkey]||"" } data-required={checked?"true":"false"} />;
		}

		
		// return <div key={opt} className={"form-row checkbox hear-about" + (checked?' checked':'')}><label><input type="checkbox" name="hear_about[]" className="form-input" value={opt} defaultChecked={ val.indexOf(opt)>-1 ? true : undefined  } onChange={this.handleHearAboutToggle.bind(this)} /> { opt }{ options.help_text ? <div className="help-small">{ options.help_text }</div> : "" }</label>{ extra }</div>;
		return <div key={opt.id} className={"form-row checkbox hear-about" + (checked?' checked':'')}><label><input type="radio" name="referrerId" className="form-input" value={opt.id} defaultChecked={ val==opt.id } onChange={this.handleHearAboutToggle.bind(this)} /> { opt.label }{ opt.help_text ? <div className="help-small">{ opt.help_text }</div> : "" }</label>{ extra }</div>;
	}
	
	render() {
		/*
		var hear_about_options = [
			{'id':1, 'label':'Wedding show', 'argument':'Name of wedding show'},
			{'id':2, 'label':'Magazine', 'argument':'Name of magazine'},
			{'id':3, 'label':'Google Search'},
			{'id':4, 'label':'Social Media'},
			{'id':5, 'label':'Bridebook'},
		];*/
		
		var hear_about_options = _.map(this.state.referrers, (r)=>({
			'id':r.value,
			'label':r.name,
			'argument':'Please give more information'
		}));
		
		if(this.state.referrerOtherId) {
			hear_about_options.push({
				'id':this.state.referrerOtherId, 
				'label':'Other', 
				'argument':'Please give more information'
			});
		}

		//var hear_about_options = ['Wedding show[Name of wedding show]', 'Magazine[Name of magazine]', 'Google Search', 'Social Media', 'Bridebook', 'Other[Please give more information]'];
		
		var hear_about_fields = _.map(hear_about_options, this.doHearAboutOption.bind(this));

		var country_choices = _.map(countries, (c)=>[c.Name, c.Name]); //no longer c.ISO2
		var rem = _.remove(country_choices, (c)=>(c[0]=='United Kingdom'));
		country_choices = _.concat(rem, country_choices);

		var props = {
			'errors':_.assign({}, this.state.errors), 
			'values':_.assign({}, this.state.data), 
			'context':{'focused':false}, 
			'onChange':(ch)=>{this.setState({'changes':_.assign(this.state.changes, ch)})}
		};
		
		//<FormInput label="Email" name="groomEmail" required={true} validate="email" help_text="By entering an email address here you will be able to log in to your online account using both email addresses." {...props} />
		


		//<FormInput label="Telephone Number" name="groomHomeTel" required={true} validate="phone" {...props} />

//				<h4>Would you like to receive email notifications when guests purchase&nbsp;presents?</h4>
//				<FormInput name="notifyEmail" type="yesno" {...props} />

		return <div className="register">
			{ this.doStep('Login details', 'login', <React.Fragment>
				{ this.state.message && <div className="alert">{ this.state.message }</div> }
				<FormInput label="Email" name="loginEmail" required={true} validate="email" {...props} />
				<FormInput label="Confirm Email" name="loginEmail_confirm" required={true} same="loginEmail" {...props} />
				<FormInput label="Password" name="loginPassword" required={true} type="password" validate="password" placeholder="min. 8 characters, including one number" {...props} />
				<FormInput label="Confirm Password" name="loginPasswordConfirm" required={true} type="password" same="loginPassword" {...props} />
				<FormInput label={<React.Fragment>I agree to the the <a href="/terms/terms-couples/" target="_blank">Terms and Conditions</a></React.Fragment>} name="agree_terms" type="checkbox" required={true} {...props} />
			</React.Fragment>) }
			{ this.doStep('Your details', 'your_details', <React.Fragment>
				<FormInput label="Title" name="title" required={true} {...props} />
				<FormInput label="First Name" name="name" required={true} {...props} />
				<FormInput label="Surname" name="surname" required={true} {...props} />
				<FormInput label="Telephone Number" name="contactNumber" required={true} validate="phone" {...props} />
			</React.Fragment>) }
			{ this.doStep('Your partner\'s details', 'partner_details', <React.Fragment>
				<FormInput label="Title" name="partnerTitle" required={true} {...props} />
				<FormInput label="First Name" name="partnerName" required={true} {...props} />
				<FormInput label="Surname" name="partnerSurname" required={true} {...props} />
				<FormInput label="Telephone Number" name="partnerContactNumber" required={true} validate="phone" {...props} />
				<FormInput label="Email" name="partnerLoginEmail" required={false} validate="email" help_text="By entering an email address here you will be able to log in to your online account using both email addresses." {...props} />
			</React.Fragment>) }
			{ this.doStep('Preferences', 'preferences', <React.Fragment>
				<h4>Wedding Details</h4>
				<FormInput label="Number of Guests" name="numberOfGuests" required={true} className="bold" {...props} />
				<FormInput label="Wedding Date" name="occasionDate" type="date" required={true} className="bold" validate="future" {...props} />
				<h4>Correspondence Address</h4>
				<FormInput label="Street address" name="address1" required={true} {...props} />
				<FormInput label="" name="address2" required={false} {...props} />
				<FormInput label="Town" name="town" required={true} {...props} />
				<FormInput label="Postcode" name="postcode" required={true} {...props} />
				<FormInput label="Country" name="country" type="select" choices={country_choices} required={true} {...props} />

				<FormInput label="My delivery address differs from my correspondence address" name="delivery_address_differs" type="checkbox" {...props} />
				{ this.state.changes.delivery_address_differs ? <React.Fragment>
					<h4>Delivery Address</h4>
					<FormInput label="Street address" name="delivery_address1" required={true} {...props} />
					<FormInput label="" name="delivery_address2" required={false} {...props} />
					<FormInput label="Town" name="delivery_town" required={true} {...props} />
					<FormInput label="Postcode" name="delivery_postcode" required={true} {...props} />
					<FormInput label="Country" name="delivery_country" type="select" choices={country_choices} required={true} {...props} />
					</React.Fragment> : "" }

				<h4>Would you like to receive email notifications when guests purchase&nbsp;presents?</h4>
				<FormInput name="receivePledgeNotifications" type="yesno" {...props} />

				{ !this.state.forceReferrer && <React.Fragment>
					<h4>How did you hear about us?</h4>
					{ this.state.referrerFriendId && this.doHearAboutOption({'id':this.state.referrerFriendId, 'label':"Friend", 'argument':"Enter friend's name", 'help_text':'We\'ll send your friend a £50 referral voucher once you register with us based on their recommendation'}) }
					{ hear_about_fields }
				</React.Fragment> }

				<p>The Wedding Present Co is secure and your personal details are protected. View our <a href="/privacy-policy/" target="_blank">data protection policy</a>.</p>
				
			</React.Fragment>) }
			{ this.doStep('Voucher code', 'voucher_code', <React.Fragment>
				<FormInput label="" name="voucherCode" required={false} placeholder="If you have a voucher code please enter it here" {...props} />
			</React.Fragment>, {'button_text':'Complete Registration'}) }
			
			{ this.state.open=='appointment_query' && <AppointmentQuery /> }
		</div>;
	}

	handleRequestOpen(key, open) {
		if(!this.state.active[key]) return;
		//var op = this.state.open;
		//op[key] = open;
		this.setState({'open':key});
	}
	
	handleHearAboutToggle(ev) {
		var changes = this.state.changes;
		changes['referrerId'] = ev.target.value;
		this.setState({'changes':changes});
	}
	
	handleNext(key, field_data, field_errors) {//key, fields) {
		var hasErrors = _.find(field_errors, (v)=>(v!=null));
		var data = this.state.data;
		var errors = this.state.errors;
		var active = this.state.active;
		var next = null;
		
		this.setState({'errors':_.assign(errors, field_errors), 'message':null});
		//console.log('hasErrors?', hasErrors);
		if(hasErrors) return;
		
		data = _.assign({}, data, field_data);
		
		var validate_errors = this.validateData(key, data);
		//console.log('validate errors?', validate_errors);
		if(validate_errors!==null) {
			this.setState({'errors':_.assign(errors, validate_errors)});
			return;
		}
		
		tracking_event('register_stage_' + key);
		//if(window.ga) ga('send', 'event', 'Register', 'submit_stage', key);
		
		var ret = null;
		if(key=="login") {
			next = "your_details";
		} else if(key=="your_details") {
			next = "partner_details";
		} else if(key=="partner_details") {
			next = "preferences";
		} else if(key=="preferences") {
			next = "voucher_code";
		} else if(key=="voucher_code") {
			ret = this.send(this.preprocessData(data));
		}
		if(next) {
			active[next] = true;
		}
		window.localStorage.create_list_data = JSON.stringify(data);
		this.setState({'data':data, 'active':active, 'open':next?next:this.state.open});
		return ret;
	}
	
	validateData(step, data) {
		var ret = {};
		//console.log('calling validateData with step',step,'and data',data,'and emailStatus',this.state.emailStatus);
		if(step=="login") {
			if(this.state.emailStatus[data.loginEmail]===undefined && data.loginEmail) {
				// TODO - dupe email check
				// do_get(window.wpco_api_prefix + '/api/get-bg-login-check.a4d', 
				// 	{'loginEmail':data.loginEmail}, 
				// 	(ret)=>{
				// 		var es = this.state.emailStatus;
				// 		es[data.loginEmail] = ret.available;
				// 		this.setState({'emailStatus':es}, ()=>{ this.handleNext('login', data); });
				// 	}
				// );
				//return ({});
			} else if(this.state.emailStatus[data.loginEmail]===false) {
				ret['loginEmail'] = 'There is already an account with this email address.';
				console.log('ret now',ret);
			}
		}
		//console.log('ret is',ret);
		return _.isEmpty(ret) ? null : ret;
	}
	
	preprocessData(data) {
		var ret = {};


		console.log('pp data is', data);

		//TODO - referral keys
		// referrerId - 

			// Friend - 
			// Wedding show
	 		// Magazine
			// Google Search
			// Social Media
			// Bridebook
			// Other
			
		
		//ret['referrerId'] = 67;
		// referrerText - free text field
		
		_.forOwn(data, (v,k)=>{
			if(_.startsWith(k, "referrerText_")) {
				if(k.substring(13)==data.referrerId) {
					ret.referrerOptional = v;
				}
			} else if(k=="weddingDate") {
				ret[k] = v.substring(8) + "/" + v.substring(5,7) + "/" + v.substring(0, 4);
			} else if(k.indexOf(":")>-1) {
				//pass
			} else if(_.startsWith(k, "delivery_")) {
				//pass
			} else if(['loginEmail_confirm', 'address1', 'address2', 'town', 'county', 'postcode', 'country'].indexOf(k)>-1) {
				//pass
			} else if(k=='partnerLoginEmail' && v=='') {
				// if partnerLoginEmail is blank, use loginEmail instead
				ret[k] = data.loginEmail;
			} else {
				ret[k] = v;
			}
		});

		if(this.state.forceReferrer) {
			ret.referrerId = this.state.forceReferrer.value;
			delete ret['referrerOptional'];
		}
	
		var corr = {'type':'correspondence'};
		_.each(['address1', 'address2', 'town', 'county', 'postcode', 'country', 'phoneNumber'], k=>{
			corr[k] = data[k];
		});
		
		var del = {'type': 'delivery'};
		if(!data['delivery_address_differs']) {
			del = _.assign({}, corr, del);
		} else {
			_.each(['address1', 'address2', 'town', 'county', 'postcode', 'country', 'phoneNumber'], k=>{
				del[k] = data['delivery_' + k];
			});
		}
		
		ret['addresses'] = [corr, del];
		ret['receivePledgeNotifications'] = (ret['receivePledgeNotifications']=='1');

		//ret['sourceObject'] = JSON.stringify(ret['sourceObject']);
 	// 	ret['GroomWorkTel'] = '';
 	// 	ret['GroomMobileTel'] = '';
 	// 	ret['BrideWorkTel'] = '';
 	// 	ret['BrideMobileTel'] = '';
 	// 	ret['correCounty'] = '';
 	// 	ret['delCounty'] = '';
 	// 	ret['delAddressCounty'] = '';
 	// 	ret['BrideMobileTel'] = '';
 	// 	ret['marriedAddressLine1'] = '';
 	// 	ret['marriedTown'] = '';
 	// 	ret['marriedCounty'] = '';
 	// 	ret['marriedPCode'] = '';
		// ret['weddingType'] = '';
		// ret['notifyPost'] = ret['notifyPost']?'true':null;
		// ret['notifyEmail'] = ret['notifyEmail']?'true':null;
		//ret['otherText'] = ret['otherText'] || '';
 		
 		//  if(!data['delivery_address_differs']) {
 		// 	ret['delAddressLine1'] = ret['correAddressLine1'];
			// ret['delTown'] = ret['correTown'];
			// ret['delPCode'] = ret['correPCode'];
 		// }
 		
 		/*
 		var ref = cookies.get('referrer');
		if(ref) {
			if(ref.indexOf(":")) {
				var bits = ref.split(":");
				ret['referrer'] = bits[0];
				ret['referrerID'] = bits[1];
			} else {
				ret['referrer'] = ref;
			}
		}
		*/
		
		return ret;
	}
	
	send(data) {
		return new Promise((resolve)=>{
			//console.log("registering",data,'to',window.wpco_api_prefix + 'Account/RegisterWpc');
			do_post(window.wpco_api_prefix + 'Account/RegisterWpc',
				data, 
				(ret)=> {
					//console.log('Register ret is', JSON.stringify(ret));
					//if(ret.responseType==2 && _.startsWith(ret.message, "This email is already registered")) {
					//	this.setState({'errors':{'loginEmail':'This email address is already registered.'}, 'open':'login'});
					if(ret.responseType!=1) {
						this.setState({'message':ret.message || "Unknown error.", 'open':'login'});
					} else {
						if(ret.authToken) {
							cookies.set('wpaT', ret.authToken, {path:'/', maxAge:14*86400, secure:true});
							cookies.set('wpgLS', JSON.stringify(ret.giftListSLA), {path:'/', maxAge:14*86400, secure:true});
						}
						tracking_event('register_complete', {
							guestNumbers: data['numberOfGuests']
						});
						//if(window.ga) ga('send', 'event', 'Register', 'complete');
						if(window.fbq) fbq('track', 'CompleteRegistration');					
						window.localStorage.removeItem('create_list_data');
						
						if(window.location.search=="?virtual=1") {
							window.location.href = '/virtual-appointment-questionnaire/';
						} else {
							this.setState({'open':'appointment_query'});
						}
					}
				}, (error)=>{
					console.log('error is', error);
					var state = this.state;
					if(error && error.LoginPassword) {
						state.open = 'login';
						state.errors.loginPassword = error.LoginPassword;
					} else if(error && error.ReferrerId) {
						state.open = 'preferences';
					} else {
						create_alert(error || "Unknown error.");
						state.open = 'login';
					}
					this.setState(state);
				}
			).finally(resolve);
		});
	}
}
