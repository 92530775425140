import React from 'react';
import Cookies from 'universal-cookie';
import { Link, withRouter } from 'react-router-dom';
var jwt = require('jwt-simple');
var $ = require('jquery');
var _ = require('lodash');
var _slugify = require('slugify');
var uuidv4 = require('uuid/v4');
var uuidv5 = require('uuid/v5');

export var cookies = new Cookies();

export function encodeID(s) {
	return (s+'').replace(/\//g, '+');
};

export function decodeID(s) {
	return s.replace(/\+/g, '/');
};

export function encodeBrand(s) {
	return s.toLowerCase().replace(/ /g, '-');
}

export function encodeCategoryFilterText(s) {
	return _slugify(s.toLowerCase());
}

var post_cache = {};

export function do_post_cached(url, data, success) {
	// crudely jettison some cached entries if post_cache gets too big
	if(Object.keys(post_cache).length>100) {
		_.each(Object.keys(post_cache).slice(0, 50), k=>{
			delete post_cache[k];
		});
	}

	var k = url + '|' + JSON.stringify(data);
	if(!post_cache[k]) {
		post_cache[k] = do_post(url, data);
	}

	if(success) {
		post_cache[k].then(v=>{
			success(v);
		});
	}
	
	return post_cache[k];
}

export function do_post(url, data, success, onerror) {
	var headers = {'Channel':'wpc', 'client-name': 'WPC-Classic'};
	
	if(cookies.get('wpaT')) headers.Authorization = 'Bearer ' + cookies.get('wpaT');
	if(cookies.get('wpoBSK')) headers.cgcs_ok = cookies.get('wpoBSK');
	if(cookies.get('pledgeBasketSessionKey')) headers.cgcs_pk = cookies.get('pledgeBasketSessionKey');

	var cbextra = {}; //pledgeBasketToken:headers.PledgeBasketReferenceToken};

	return new Promise((resolve, reject)=>{
		$.ajax({
			url: url,
			headers: headers,
			data: JSON.stringify(data),
			processData: false,
			//dataType: 'json',
	        contentType: 'application/json',
			type: 'POST',
			success: (ret)=>{if(success) success(ret, cbextra);},
			error: (xhr, status, error)=>{
				if(xhr.status==401) {
					if(success) success({
						'loginRequired':true
					});
					resolve({
						'loginRequired':true
					});
				} else if(onerror && xhr.status<500) {
					onerror(xhr.responseJSON);
					reject(status);
				} else if(xhr.status==0) {
					// might abort on navigation away
					console.log('Request aborted.');
					reject(status);
				} else {
					create_alert('Error: ' + (error.message || "Cannot connect to server."), xhr.status + " " + xhr.responseText);
					reject(status + error.message);
				}
			}
		}).done((json) => resolve(json));
	});
};

var get_cache = {};

export function do_get_cached(url, data, success) {
	// crudely jettison some cached entries if get_cache gets too big
	if(Object.keys(get_cache).length>100) {
		_.each(Object.keys(get_cache).slice(0, 50), k=>{
			delete get_cache[k];
		});
	}

	var k = url + '|' + JSON.stringify(data);
	if(get_cache[k]) {
		if(success) success(get_cache[k]);
		return;
	}
	
	do_get(url, data, (ret)=>{
		get_cache[k] = ret;
		if(success) success(get_cache[k]);
	});

}

export function do_get(url, data, success, onerror) {
	var headers = {'Channel':'wpc', 'client-name': 'WPC-Classic'};
	headers.PledgeBasketReferenceToken = cookies.get('wppBRT');
	if(cookies.get('wpaT')) headers.Authorization = 'Bearer ' + cookies.get('wpaT');
	if(cookies.get('wpoBSK')) headers.cgcs_ok = cookies.get('wpoBSK');

	$.ajax({
		url: url,
		headers: headers,
		data: data,
		dataType: 'json',
		type: 'GET',
		success: (ret)=>{if(success) success(ret);},
		error: (xhr, status, error)=>{
			if(xhr.status==401) {
				success({
					'loginRequired':true
				});
			} else if(onerror && xhr.status<500) {
				onerror(xhr.responseJSON);
			} else {
				create_alert('Error: Cannot connect to server.');
			}
		}
	});
};

export function do_put(url, data, success) {
	var headers = {'Channel':'wpc', 'client-name': 'WPC-Classic'};
	headers.PledgeBasketReferenceToken = cookies.get('wppBRT');
	if(cookies.get('wpaT')) headers.Authorization = 'Bearer ' + cookies.get('wpaT');

	return new Promise((resolve, reject)=>{
		$.ajax({
			url: url,
			headers: headers,
			data: JSON.stringify(data),
			processData: false,
			dataType: 'json',
	        contentType: 'application/json',
			type: 'PUT',
			success: (ret)=>{if(success) success(ret);},
			error: (xhr, status, error)=>{
				if(xhr.status==401) {
					success({
						'loginRequired':true
					});
				} else {
					create_alert('Error: Cannot connect to server.');
					reject(status + error.message);
				}
			}
		}).done((json) => resolve(json));
	});
};

export function do_delete_with_body(url, data, success) {
	var headers = {'Channel':'wpc', 'client-name': 'WPC-Classic', 'Content-Type': 'application/json'};
	if(cookies.get('wppBRT')) headers.PledgeBasketReferenceToken = cookies.get('wppBRT');
	if(cookies.get('wpaT')) headers.Authorization = 'Bearer ' + cookies.get('wpaT');

	//url = url + '?' + (new URLSearchParams(data)).toString();

	return fetch(url, {
		method: 'DELETE',
		headers: headers,
		body: JSON.stringify(data),
	}).then(r=>{
		if(r.status==401) {
			success({
				'loginRequired':true
			});
		} else if(r.status>=400) {
			create_alert('Error: Cannot connect to server.');
			return Promise.reject(r.status + " " + r.statusText);
		} else {
			return r.json()
		}
	}).then(r=>{
		success(r);
		return r;
	});
};

export function do_pa_get(url, token) {
	return fetch(url, {
		headers: { 'x-functions-key': token, 'client-name': 'WPC-Classic' }
	}).then(r=>r.json());
}

export function get_similar_products_link(pid, success) {
	if(window.wpco_pa_api_prefix) {
		// direct method
		return do_pa_get(
			window.wpco_pa_api_prefix + 'product/alternative-link/3/' + pid,
			window.wpco_pa_api_token,
		).then(r=>{
			if(r?.Value?.FilterPath && success) {
				return success('/search/' + r.Value.FilterPath);
			}
		});
	}

	// indirect method via webserver
	do_get_cached(
		'/api/alternative-link/' + pid + '/', 
		{},
		r=>{
			if(r?.Value?.FilterPath && success) {
				return success('/search/' + r.Value.FilterPath);
			}
		}
	);
}
window.get_similar_products_link = get_similar_products_link;

export function rewrite_image_url(url) {
	return url.replace('https://www.weddingpresentimages.co.uk/', '/i/');
}

export function resolveLink(url, to) {
	if(_.startsWith(to, '/')) {
		return to;
	} else {
		return url + to;
	}
}

export function nl2br(s) {
	var s = (s || "").replace(/\r\n/g, '\n').replace(/\r/g, '\n');
	
	var ret = [];
	return _.map(s.split("\n"), (i,k)=><React.Fragment key={k}>{ i }<br /></React.Fragment>);
}

export function formatHtmlParagraphs(s) {
	if(!s) return '';
	if(s[0]=="<") return <div dangerouslySetInnerHTML={ {__html: s} } />;
	return <p>{ nl2br(s) }</p>;
}

export function slugify(s) {
	return _slugify(s, {lower: true})
};

// wpsessid keeps changing for some reason?
export function set_wpsessid(wpsessid) {
	var update = (wpsessid!=cookies.get('wpsessid'));
	cookies.set('wpsessid', wpsessid, {path:'/', maxAge:14*86400, secure:true});
	//if(update) do_get('/log/', {});
}

export function set_wpcartid(wpcartid, bglistid) {
	var update = (wpcartid!=cookies.get('wpcartid'));
	cookies.set('wpcartid', wpcartid, {path:'/', maxAge:14*86400, secure:true});
	if(update) do_get('/log/', {'wpcartid':wpcartid, 'bglistid':bglistid});
}

export function redirect_to_login(next) {
	cookies.set('wpaT', '', {path:'/', maxAge:14*86400, secure:true});
	window.location.href = '/login/?next=' + encodeURI(next);
}

export function myParseInt(s) {
	if(s && s.replace) s = s.replace(/,/g, '');
	return parseInt(s);
}

export function myParseFloat(s) {
	if(s && s.replace) s = s.replace(/,/g, '');
	return parseFloat(s);
}

export function formatPrice(s) {
	if(parseFloat(parseInt(s))==parseFloat(s)) return parseInt(s);
	return parseFloat(s).toFixed(2);
}

export function get_url_param(name) {
	var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
	if (results==null) {
		return null;
	} else {
		return decodeURIComponent(results[1]) || 0;
	}
}


export function parse_hash(hash) {
	var i = hash.indexOf("#");
	if(i!=-1) hash = hash.substring(i+1);
	//if(hash.indexOf("#")===0) hash = hash.substring(1);
	var bits = hash.split(/&/g).filter(i=>i);

	var ret = {};
	bits.forEach(bit=>{
		var i = bit.indexOf("=");
		if(i<1) return;

		ret[bit.substring(0, i)] = bit.substring(i+1).split(/\|/g).map(s=>decodeURIComponent(s).replace(/\+/g, ' ')).filter(s=>s);
	});
	return ret;
}

export function build_hash(d) {
	var ret = Object.entries(d).map(([k,v])=>{
		if(!v || !v.length) return null;
		return k + "=" + v.map(vv=>encodeURIComponent(v)).join("|");
	}).filter(i=>i).join("&");

	return ret ? ("#" + ret) : "";
}

export function getGiftListSLA() {
	// Get giftListSLA of currently logged in user from the cookie
	try{
		//freakily, the cookies library is decoding and JSON.parsing it already ?!
		var giftListSLA = cookies.get('wpgLS');
		if(giftListSLA.cashCharge===undefined) return {};
		return giftListSLA;
	} catch(e) {}
	
	return {};
}


/**
 * @param {File} image - Image File Object
 * @param {Object} pixelCrop - pixelCrop Object provided by react-image-crop
 * @param {String} fileName - Name of the returned file in Promise
 */
export function getCroppedImg(image, pixelCrop, fileName) {
	var canvas = document.createElement('canvas');
	var w = 400;
	var h = (pixelCrop.height*w)/pixelCrop.width;

	canvas.width = w;//pixelCrop.width;
	canvas.height = h;//pixelCrop.height;
	var ctx = canvas.getContext('2d');

	ctx.drawImage(
		image,
		pixelCrop.x,
		pixelCrop.y,
		pixelCrop.width,
		pixelCrop.height,
		0,
		0,
		w,
		h
	);

	// As Base64 string
	// const base64Image = canvas.toDataURL('image/jpeg');

	// As a blob
	return new Promise((resolve, reject) => {
		canvas.toBlob(file => {
			file.name = fileName;
			resolve(file);
		}, 'image/jpeg');
	});
}

export function getResizedImg(image, width, fileName) {
	var canvas = document.createElement('canvas');
	var w = Math.min(image.width, width);
	var h = (w/image.width) * image.height;

	canvas.width = w;
	canvas.height = h;
	var ctx = canvas.getContext('2d');

	ctx.drawImage(
		image,
		0,
		0,
		image.width,
		image.height,
		0,
		0,
		w,
		h
	);

	// As Base64 string
	// const base64Image = canvas.toDataURL('image/jpeg');
	return canvas.toDataURL('image/jpeg');

	// As a blob
	return new Promise((resolve, reject) => {
		canvas.toBlob(file => {
		file.name = fileName;
		resolve(file);
		}, 'image/jpeg');
	});
}

export function bothNamesToSummary(s) {
	var name = _.split(s, " and ");
	return _.split(name[0], " ")[0] + " and " + _.split(name[1], " ")[0] + "’s";
}

export function validate_email(e) {
	return (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(e));
}

export function validate_password(p) {
	return p.length>=8 && p.match(/[0-9]/);
}

export function validate_phone(s) {
	s = s.replace(/[ \-\(\)\+]/g, '');
	return s.length>=9 && _.isFinite(parseInt(s));
}

export function decode_access_token() {
	var ret = {};
	var wpaT = cookies.get('wpaT');
	if(wpaT) {
		var tok = jwt.decode(wpaT, 'asdfasdf', true) || {};
		if(tok['UserId']) ret.userId = tok['UserId'];
		if(tok['GiftListId']) ret.giftListId = tok['GiftListId'];
		var date_bits = (tok['OccasionDate'] || '').split(/\//g);
		if(date_bits.length==3) {
			ret.weddingDate = date_bits[2] + '-' + date_bits[1] + '-' + date_bits[0];
		}
	}
	return ret;
}

export function is_logged_in() {
	return !!cookies.get('wpaT');
}

export function tracking_event(name, data) {
	if((typeof ga)==='function' && name.indexOf("register_")===0) {
		var path = name.substring(9);
		ga('set', 'page', window.location.pathname + path + '/' + window.location.search);
        ga('send', 'pageview');
	}

	if(window.dataLayer) {
		var data = _.assign(
			{}, 
			data||{}, 
			{event: name}, 
			decode_access_token()
		);
		// var wpaT = cookies.get('wpaT');
		// if(wpaT) {
		// 	var tok = jwt.decode(wpaT, 'asdfasdf', true) || {};
		// 	if(tok['UserId']) data.userId = tok['UserId'];
		// 	if(tok['GiftListId']) data.giftListId = tok['GiftListId'];
		// 	var date_bits = (tok['OccasionDate'] || '').split(/\//g);
		// 	if(date_bits.length==3) {
		// 		data.weddingDate = date_bits[2] + '-' + date_bits[1] + '-' + date_bits[0];
		// 	}
		// }

		dataLayer.push(data);
	}
}

export function track_add_to_list(data) {
	//console.log('track_add_to_list:', data);
	if(window.dataLayer) {
		var qty = parseInt(data.productQuantity);
		if(qty>99) qty = 1;

		var data = _.assign({}, data||{}, {
			event: 'dataLayerEvent',
			eventCategory: 'product actions',
			eventAction: 'add to list',
			eventLabel: data.productId + ' - ' + data.productName,
			productPrice: parseFloat(data.productPrice) ? parseFloat(data.productPrice).toFixed(2) : '',
			productQuantity: qty,
			eventValue: (qty * parseFloat(data.productPrice)) || 0,
			nonInteraction: 0
		});
		
		dataLayer.push(data);
	}
}

export function factfinder_event(key, event, wpaT) {
	// eg: factfinder_event("Click", {...})

	event = event || {};
	
	wpaT = wpaT || cookies.get('wpaT');
	if(!wpaT) {
		console.log("FF: no wpaT");
		return Promise.resolve();
	}

	var tok = jwt.decode(wpaT, 'asdfasdf', true) || {};
	//if(tok['UserId']) data.userId = tok['UserId'];
	if(!tok['GiftListId']) {
		console.log("FF: no GiftListId");
		return Promise.resolve();
	}
	event.userId = tok['GiftListId'];

	var sid = cookies.get('ffsid');
	if(!sid) {
		sid = uuidv4();
		cookies.set('ffsid', sid, {path:'/', maxAge:14*86400, secure:true});
	}

	sid = uuidv5(new Date().toDateString(), sid);

	event.sid = sid;

	var data = {
		"ClickEvents": [],
		"CartEvents": [],
		"CheckoutEvents": [],
		"RecommendationEvents": [],
		"LoginEvents": []		  
	};
	data[key + "Events"].push(event);

	return do_post(window.wpco_api_prefix + 'track/factfinderevents',
		data, ()=>{}, ()=>{}
	);
}

class ScrollToTop extends React.Component {
	constructor(props) {
		super(props);
		this.componentDidUpdate({});
	}
	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			window.scrollTo(0, 0)
		}
	}

  render() {
    return this.props.children || []; 
  }
}
ScrollToTop = withRouter(ScrollToTop);
export { ScrollToTop };

class ScrollToHere extends React.Component {
	constructor(props) {
		super(props);
		this.divRef = React.createRef();
	}
	
	componentDidMount() {
		this.componentDidUpdate({});
	}
	
	componentDidUpdate(prevProps) {
		if(!_.isEqual(this.props, prevProps)) {
//		if (this.props.location !== prevProps.location) {
			var target = $(this.divRef.current).offset().top + (this.props.offset||0);
			var top = $(window).scrollTop();
			if(this.props.toTop || (target < top || target > (top+$(window).innerHeight()))) {
				if(this.props.smooth) {
					$("html, body").animate({ scrollTop: target }, 200);
				} else {
					window.scrollTo(0, target);
				}
			}
		}
	}

  render() {
    return <div ref={ this.divRef }></div>;
  }
}
var ScrollToHereWithRouter = withRouter(ScrollToHere);
export {ScrollToHere, ScrollToHereWithRouter};

class MyLink extends React.Component {
	render() {
		var to = resolveLink(this.props.match.url, this.props.to);
		return <Link to={to} className={this.props.className} draggable={this.props.draggable}>
			{ this.props.children }
		</Link>;
	}
}
MyLink = withRouter(MyLink);
export {MyLink};

class BackLink extends React.Component {
	render() {
		var match_url = this.props.match.url;
		if(match_url=='/') match_url = window.location.pathname;
		var to = resolveLink(match_url, this.props.to);
		return <Link to={to} className={this.props.className} onClick={this.handleClick.bind(this)}>
			{ this.props.children }
		</Link>;
	}
	
	handleClick(ev) {
		if(this.props.history.length>2) {
			ev.preventDefault();
			this.props.history.goBack();
		}
	}
}
BackLink = withRouter(BackLink);
export {BackLink};

export class MoneyInput extends React.Component {
	constructor(props) {
		super(props);
		var v = props.value;
		if(!isNaN(parseFloat(v)) && Math.abs(parseInt(v)-v)>0) v = parseFloat(v).toFixed(2);
		if(v==0 || v=='0') v = '';
		this.state = {'value':v};
	}

	render() {
		var v = this.state.value;
		if(v===null) v = '';
		return <div className="money-input"><input className={this.props.className || "quantity"} type="text" value={ v } step=".01" onChange={ this.handleChange.bind(this) } placeholder={ this.props.placeholder } onBlur={ this.handleBlur.bind(this) } /></div>;
	}

	clean(v) {
		var cleaned = null;
		var v = parseFloat(v);
		if(!isNaN(v)) {
			if(Math.abs(parseInt(v)-v)>0) cleaned = v.toFixed(2);
			else cleaned = parseInt(v);
		}
		return cleaned;
	}

	handleBlur(ev) {
		var cleaned = this.clean(ev.target.value);
		if(ev.target.value=='') {
			this.setState({'value':''});
		//} else if(cleaned!==null) {
		//	this.setState({'value':cleaned});
		} else {
			//reset back to original value
			this.setState({'value':this.clean(this.props.value)});
		}
	}

	handleChange(ev) {
		this.setState({'value':ev.target.value});

		var cleaned = this.clean(ev.target.value);
		if(cleaned!==null || ev.target.value=='') {
			console.log('cleaned is', cleaned);
			if(this.props.onChange) this.props.onChange(ev, cleaned);
		}
	}
}


export function mergeOrderedItems(productsOnList, orderItems) {
	var ret = [];
	orderItems = [].concat(orderItems || []);

	_.each(productsOnList, (prod)=>{
		let first = true;
		// find any orderItems for this product (there may be several)
		orderItems = orderItems.filter(oi=>{
			if(oi.product.id==prod.id
				|| oi.product.id==prod.originalProductId) {
				// this OI is for this product

				if(first) {
					first = false;
					// overwrite product fields
					prod.qtyOrdered = oi.qtyOrdered;
					prod.qtyInWarehouse = oi.qtyInWarehouse;
					prod.qtyDelivered = oi.qtyDelivered;
				} else {
					// add to product fields
					prod.qtyOrdered += oi.qtyOrdered;
					prod.qtyInWarehouse += oi.qtyInWarehouse;
					prod.qtyDelivered += oi.qtyDelivered;
				}
				prod.expectedDate = oi.expectedDate;
				// remove this orderItem from the list
				return false;
			}
			// keep this item in the list
			return true;
		});

		ret.push(prod);
	});

	// add any remaining orderItems on to the end
	_.each(orderItems, (oi)=>{
		ret.push(_.assign(
			{},
			oi.product,
			oi
		));
	});

	return ret;
}

