import React from 'react';
var _ = require('lodash');

import { ExpandingDiv } from '../components/ExpandingDiv.jsx';

export class SectionExpander extends React.Component {
	constructor(props) {
		super(props);
		this.state = {'open':false};
	}
	
	render() {
		var body = null;
		if(this.props.text!==undefined) {
			body = <div dangerouslySetInnerHTML={{__html: this.props.text }} />
		} else {
			body = this.props.children;
		}
		
		return <div ref="section" className={"section expander" + (this.state.open?' open':'')}>
			<div ref="head" className="head" onClick={this.handleHeadClick.bind(this)}><h4>{ this.props.title }</h4></div>
			<ExpandingDiv className="body" open={this.state.open} delay={300}>
				{ body }
			</ExpandingDiv>
		</div>;
	}
	
	handleHeadClick(ev) {
		ev.preventDefault();
		this.setState({'open':!this.state.open});
	}
}
