import React from 'react';

export class ListFilterByText extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	
	render() {
		return <React.Fragment>
			<input type="text" placeholder="Search products..." 
                value={ this.props.currentFilter || "" } 
                onChange={ this.handleChange.bind(this) }
                />			
		</React.Fragment>;
	}

    handleChange(ev) {
        if(this.props.onFilter) this.props.onFilter(ev.target.value);
    }
	
}
