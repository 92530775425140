import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
var $ = require('jquery');
var _ = require('lodash');

import { Menu } from '../components/Menu.jsx';
import { do_get, is_logged_in, ScrollToHereWithRouter } from '../utils.jsx';

export class Browse extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};

		this.catalogTopMenu = document.getElementById('catalog-top-menu');
		if(!$(this.catalogTopMenu).hasClass('cleared')) {
			$(this.catalogTopMenu).empty();
			$(this.catalogTopMenu).addClass('cleared');
		}

		this.state.menuData = JSON.parse($(this.catalogTopMenu).attr('data-menu-data') || '{}');
		
		this.loadData();
	}
	
	loadData() {
		do_get(window.wpco_api_prefix + 'Brands/GetAllGrouped', {},
			(ret) => {
				this.setState({'brands':ret.brands});
				//console.log('brands', ret);
			}
		);
	}
	
	render() {
		//if(!this.state.rooms || !this.state.brands || !this.state.categories || !this.state.funds) return <div className="loading-spinner"></div>;
		if(!this.state.brands) return <div key="loading-spinner" className="loading-spinner"></div>;

		//var categories = this.state.data.categories;
		
		//var funds_cat = _.find(categories, (cat)=>(cat.parentID==0 && (cat.name=="Funds" || cat.name=="TEST Funds")));
		
		//console.log('menuData is', this.state.menuData);
		
		var rooms = this.state.menuData.rooms;
		var inspiration = this.state.menuData.inspirations;
		//var brands = _.filter(this.state.brands, (f)=>!f.isExclusive);
		var main_brands = this.state.menuData.brands;//brands.slice(0, 4);
		//var featured_brands = _.filter(brands, (f)=>f.isFeatured);
		var categories = this.state.menuData.categories;
		var funds = this.state.menuData.funds;
		//_.each(this.state.funds, (f)=>{
		//	funds.push(...f.fundCategories);
		//});

		var cslugify = (cslug)=>(
			cslug.replace(/ /g, "-")
		);

		/*
		var map_rooms = (rooms)=>_.map(rooms, (cat)=>({
			'title':cat.title,
			'imagePath':cat.image,
			'url':cat.url
		}));
		*/

		var map_brands = (brands)=>_.map(brands, (brand)=>({
			'name':brand.name,
			'url':'/browse/by-brand/' + brand.seoName + '/'
		}));

		/*
		
		var map_categories = (categories)=>_.map(categories, (cat)=>({
			'name':cat.title,
			'imagePath':cat.image,
			'url':'/browse/by-category/' + cat.seoName + '/'
		}));

		var map_inspirations = (inspirations)=>_.map(inspirations, (cat)=>({
			'name':cat.description,
			'imagePath':cat.image.url,
			'url':'/browse/inspiration/' + cat.seoName + '/'		
		}));
		

		console.log(funds);
		var map_funds = (funds)=>_.map(funds, (fund)=>({
			'name':fund.title,
			'imagePath':getProductImage(fund),
			'url':'/browse/funds/' + fund.productSeoName + '/'
		}));
		*/

		var blocks = [];
		if(this.props.match.params.match_by=='by-room') {
			blocks = [{'name':'room', 'prefix':'/browse/by-room/', 'cats':rooms}]
		} else if(this.props.match.params.match_by=='by-brand') {
			//blocks = [{'name':'brand', 'prefix':'/browse/by-brand/', 'cats':map_brands(brands)}]
		} else if(this.props.match.params.match_by=='by-category') {
			blocks = [{'name':'category', 'prefix':'/browse/by-category/', 'cats':categories}]
		} else if(this.props.match.params.match_by=='inspiration') {
			blocks = [{'name':'inspiration', 'prefix':'/browse/inspiration/', 'cats':inspiration}]
		} else if(this.props.match.params.match_by=='funds') {
			blocks = [{'name':'fund', 'prefix':'/browse/funds/', 'cats':funds}]
		} else {
			blocks = [
			{'name':'category', 'prefix':'/browse/by-category/', 'cats':categories.slice(0,4), 'more':(categories.length>4 && 'See all Categories')},
			{'name':'inspiration', 'prefix':'/browse/inspiration/', 'cats':inspiration.slice(0,4), 'more':(inspiration.length>4 && 'See all Inspiration'), 'verb':'See our'},
			{'name':'brand', 'prefix':'/browse/by-brand/', 'cats':main_brands.slice(0,4), 'more':'See all Brands'},
			{'name':'fund', 'prefix':'/browse/funds/', 'cats':funds.slice(0,4), 'verb': 'Create a', 'more':'See all Funds', 'add_from_another_website':is_logged_in()}
			];
		}
		
		if(this.props.match.params.match_by=='by-brand') {
			
			var groupings = [
				['0-9', ['0-9']],
				['A-C', ['A', 'B', 'C']],
				['D-K', ['D', 'E', 'F', 'G', 'H', 'I', 'J', 'K']],
				['L-S', ['L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S']],
				['T-Z', ['T', 'U', 'V', 'W', 'X', 'Y', 'Z']]];
			/*
			_.each(_.sortBy(brands, (b)=>b.name.toUpperCase()), (b)=>{
				var cc = b.name.toUpperCase().charCodeAt(0);
				for(var i=4;i >= 0;i--) {
					if(cc>=groupings[i][0].charCodeAt(0)) {
						groupings[i][1].push(b);
						break;
					}
				}
			});*/
			var groupings = groupings.map(([ok, iks])=>(
				[ok, iks.map(k=>(this.state.brands[k] || [])).flat()]
			));
			
			console.log(groupings);
			
			// {ReactDOM.createPortal(<Menu categories={this.state.data.categories}  brands={this.state.data.brands} />, this.catalogTopMenu)}

			return <div>
				<ScrollToHereWithRouter />
				
				{ ReactDOM.createPortal(<Menu { ...this.state.menuData } />, this.catalogTopMenu) }

				<div className="three columns">&nbsp;</div>
				<div className="nine columns"><h2><div>All of our <span>brands</span></div></h2></div>
				
				{ _.map(groupings, ([title, items], i)=>( items.length ? 
					<div className="row" key={i}>
						<div className="three columns"><h2>{ title }</h2></div>
						<div className="nine columns container brand-links">
							{ _.map(items, (item, j)=>(
								<div className="three columns brand-link" key={j}>
									<Link to={ '/browse/by-brand/' + item.seoName + "/" }>{ item.name }</Link>
								</div>
							)) }
						</div>
					</div>
				: '')) }
				
				
			</div>;
		}
		
		//{ReactDOM.createPortal(<Menu categories={this.state.data.categories}  brands={this.state.data.brands} />, this.catalogTopMenu)}

		return <div className="browse-by">
			<ScrollToHereWithRouter />
			
			{ ReactDOM.createPortal(<Menu { ...this.state.menuData } />, this.catalogTopMenu) }

			{ _.map(blocks, (bl,k)=>(<div key={k}>
				<h2 className="lined"><div>{ bl.verb || "Browse by" } <span>{ bl.name }</span></div></h2>
				<div className="category-grid row">
				{ _.map(bl.cats, (cat, kk)=>(
					<div className="three columns category" key={kk}>
						<Link to={ cat.url } >
						<img src={ cat.image } />
						<h3>{ cat.title }</h3>
						</Link>
					</div>
				))}
				{ bl.more && <div className="twelve columns center-button-bar">
					<Link to={ bl.prefix } className="button blue">{ bl.more }</Link>
					{ !!bl.add_from_another_website && <Link to="/my-list/custom-product/" className="button blue">Add from another website</Link> }
					</div> }
				</div>
				
			</div> ))}
			
			
		</div>;
	}
}

