import React from 'react';

import { FormInput, MyForm } from '../components/Forms.jsx';
import { Popup } from '../components/Popup.jsx';
import { do_get, do_post, ScrollToHere, ScrollToHereWithRouter } from '../utils.jsx';

export class ListNotOpenPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
		var nprops = {'errors':this.state.popupErrors || {}, 'values':this.state.popupData || {}, 'context':{'focused':false}};

        return <Popup onClose={this.handleClose.bind(this)}>
                <h2>Sorry, this list is not yet open.</h2>
                <h3>{ this.props.listTitle }<br />{ this.props.weddingDate }</h3>
                <p>Please submit your email address to be notified when this list is ready to be viewed.</p>
                { this.state.notifySuccess ? <React.Fragment>
                    <ScrollToHereWithRouter offset={-200} />
                    <h3>{ this.state.notifyResponse.message }</h3>
                    <div className="form-row"><button type="button" className="right button blue" onClick={ this.handleClose.bind(this) }>Close</button></div>
                </React.Fragment> : <MyForm onSubmit={this.handlePopupSubmit.bind(this)}>
                    <FormInput label="Email" name="email" required={true} validate="email" {...nprops} />
                    <div className="form-row"><button type="submit" className="right">Submit</button></div>
                </MyForm> }
            </Popup>;
    }

    handleClose(ev) {
        if(this.props.onClose) this.props.onClose();
    }

    handlePopupSubmit(fields, errors) {
		var hasErrors = _.find(errors, (v)=>(v!=null));
		this.setState({'popupData':fields, 'popupErrors':errors});
		if(hasErrors) {
			return;
		}

		do_post(window.wpco_api_prefix + 'Pledge/AddToGiftListLiveNotification', {
            'giftListId':this.props.giftListId, 
            'name':'', 
            'email':fields['email']
        }, (ret)=>{
			if(ret.responseType!=1) {
				alert(ret.message || "Unknown error.");
			} else {
				this.setState({'notifySuccess':true, 'notifyResponse':ret});
			}
		});
	}
}