import React from 'react';
import { NavLink } from 'react-router-dom';

export class HeaderBasket extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return <NavLink className="header-basket" to={ "/find-list/" + this.props.listId + "/basket/" }>
            { this.props.quantity>0 && <span>{ this.props.quantity }</span> }
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 119.9" height="18"><path d="M149.5 27.8c-.5-.6-1.1-.9-1.8-.9H77.3V2.3a2.3 2.3 0 1 0-4.6 0V27H2.3c-.7 0-1.4.3-1.8.9-.4.6-.6 1.3-.4 2L20 118c.3 1 1.2 1.8 2.3 1.8h105.2c1 0 2-.8 2.2-1.8L150 29.8c.2-.7 0-1.5-.4-2zm-43.9 3.8v24.7H77.3V31.6Zm26.8 54.2h-22.1V61H138zm-26.8 0H77.3V61h28.3ZM12 61h27.7v24.8H17.6Zm32.4 0h28.3v24.8H44.4Zm28.3 29.4v24.8H44.4V90.4zm4.6 0h28.3v24.8H77.3Zm-4.6-34H44.4V31.5h28.3v24.7Zm-33-24.8v24.7H11L5.2 31.6Zm-21 58.8h21v24.8H24.3Zm107 24.8h-15.4V90.4h21zm13.4-58.9h-28.8V31.6h34.4z" fill="#979797"/></svg>
        </NavLink>;
	}
}
