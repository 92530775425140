import React from 'react';
var _ = require('lodash');

import { FormInput, MyForm } from '../components/Forms.jsx';

import { do_get, do_post } from '../utils.jsx';

export class PasswordResetForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {'success':false, 'ready':false, 'failed':false};
	}

	componentDidMount() {
		var token = window.location.pathname.substring(7);

		if(token.indexOf('/')>-1 || token.toLowerCase().indexOf("%2")>-1) {
			//the API doesn't like funny characters, so assume OK
			this.setState({'ready':true});
			return;
		}

		do_get(window.wpco_api_prefix + 'Account/ResetPassword/' + token, {},
			(ret)=> {
				if(ret.responseType!=1) {
					this.setState({'error':ret.message, 'failed':true});
				} else {
					this.setState({'ready':true});
				}
			}
		);
	}
	
	render() {
		var vprops = {'errors':this.state.errors || {}, 'values':this.state.data || {}, 'context':{'focused':false}};

		return <MyForm ref="recommend_form" onSubmit={this.handleSubmit.bind(this)}>
			{ this.state.error ? <div className="alert alert-error">{ this.state.error }</div> : '' }
			
			{ this.state.message && <div className="alert alert-success">{ this.state.message }</div> }

			{ this.state.failed &&
				<div className="form-row"><a href="/forgot/" className="button right">Request a new password reset</a></div>
			}
			
			{ this.state.success ?
				<div className="form-row"><a href="/login/" className="button right">Continue to login</a></div>
			: ( this.state.ready && <React.Fragment>
					<FormInput label="New Password" name="newPassword" required={true} type="password" validate="password" {...vprops} />
					<FormInput label="Confirm New Password" name="confirmPassword" required={true} type="password" same="newPassword" {...vprops} />

					<div className="form-row"><button type="submit" className="right">Update your password</button></div>
				</React.Fragment> )
			}
		</MyForm>
	}	
	
	handleSubmit(fields, errors) {
		var hasErrors = _.find(errors, (v)=>(v!=null));

		this.setState({'data':fields, 'errors':errors});

		if(hasErrors) return;
		
		var token = window.location.pathname.substring(7);
		return new Promise((resolve)=>{
			do_post(window.wpco_api_prefix + 'Account/ResetPassword',
				{
					'token':token,
					'newPassword':fields.newPassword,
					'confirmPassword':fields.confirmPassword
				}, (ret)=> {
					if(ret.responseType!=1) {
						this.setState({'error':ret.message, 'failed':true});
					} else {
						this.setState({'message':ret.message, 'success':true});
					}
				}
			).finally(resolve);
		});
		
	}
}
