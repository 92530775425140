import React from 'react';
var _ = require('lodash');

import { cookies, do_get, do_post, myParseInt } from '../utils.jsx';

import { LoginForm } from '../components/LoginForm.jsx';
import { Popup } from '../components/Popup.jsx';
import { Product } from '../components/Product.jsx';

export class ProductGrid extends React.Component {
	constructor(props) {
		super(props);
		this.state = {'added':{}};
		
		var tempBasket = JSON.parse(window.localStorage.tempBasket || "[]");
		_.each(tempBasket, (d)=>{
			this.state.added[d.presentID] = d.quantity;
		});
		
		this.loadList();
	}
	
	loadList() {
		if(!cookies.get('wpsessid')) return;
		do_get(window.wpco_api_prefix + '/api/get-bg-list.a4d', 
			{ rangeTo:10000 },
			(ret)=>{
				if(ret.products) {
					var productBGIDs = {};
					var added = this.state.added;
					_.each(ret.products, (prod)=>{
						added[prod.presentID] = myParseInt(prod.qtyRequired);
						productBGIDs[prod.presentID] = prod.bgPresentID;
					});
					this.setState({'productBGIDs':productBGIDs, 'added':added});
				}
			}
		);
	}
	
	handleStartAddToList(prod, qty) {
		var checkForParams = ()=>{
			//if isn't already added, and has colours or is a fund
			if(!(this.state.productBGIDs||{})[prod.presentID] && (this.state.productInfo.colourCount || this.state.productInfo.isFund)) {
				this.setState({'showAddParamsPopup':true, 'adding':prod, 'addingQuantity':qty, 'addOptions':{}});
			} else {
				this.setState({'addingQuantity':qty, 'addOptions':{}}, ()=>{
					this.handleAddToList(prod);
 				});
			}
		};
		if(this.state.productInfoID!=prod.presentID) {
			this.fetchProductInfo(prod, checkForParams);
		} else {
			checkForParams();
		}
	}
	
	handleAddToList(prod, ev) {
		if(ev) ev.preventDefault();
		
		var initialAddOpts = {};
		if(this.state.productInfo.colourCount) {
			initialAddOpts.colour = this.state.productInfo.colours[0];
		}
		
		var qty = this.state.addingQuantity; //this.state.quantities[prod.presentID]||1;
		var toAdd = _.assign({
				'presentID':prod.presentID, 
				'quantity':qty
			}, initialAddOpts, this.state.addOptions);
		
		var endpoint = '/api/post-add-to-list.a4d';
 		if(this.state.productBGIDs && this.state.productBGIDs[prod.presentID]) {
 			qty += this.state.added[prod.presentID];
			toAdd = {
				bgPresentID: this.state.productBGIDs[prod.presentID],
				quantity: qty
			};
			endpoint = '/api/post-bg-list-update.a4d';
 		}
		
		do_post(window.wpco_api_prefix + endpoint,
			toAdd, 
			(ret)=>{
				if(!ret.loggedIn) {
					//window.localStorage.tempBasket = JSON.stringify(_.concat(JSON.parse(window.localStorage.tempBasket || "[]"), [toAdd]));
					window.localStorage.tempBasket = JSON.stringify([toAdd]);
					var showLogin = true;//JSON.parse(window.localStorage.tempBasket).length>2;
					this.setState({'added':_.assign({}, this.state.added, {[prod.presentID]:qty}), 'showAddParamsPopup':false, 'showAddLoginPopup':showLogin});
					console.log('setting added to',_.assign({}, this.state.added, {[prod.presentID]:qty}));
				} else if(ret.responseType!=1) {
					this.setState({'error':ret.message || "Unknown error."});
				} else {
					this.setState({'added':_.assign({}, this.state.added, {[prod.presentID]:qty}), 'showAddParamsPopup':false});
					this.loadList(); //refresh bg list to get bgPresentIDs
				}
			}
		);
	}
	
	render() {
		var products = this.props.products;
		var adding_qty = _.keys(JSON.parse(window.localStorage.tempBasket||"{}")).length;
		
		return <React.Fragment>
			
			{ this.state.showAddLoginPopup ? <Popup onClose={null}>
				<h2>You&rsquo;re adding {adding_qty} item{adding_qty!=1?'s':''} to your list.</h2>
				<p>To save it please log in or register with us.</p>

				<div className="three columns">
					<p>Follow our 4 easy steps to register and continue creating your dream wedding list.</p>
					<p className="actions"><a href="/create-a-list/" className="button blue">Register</a></p>
				</div>
				<div className="three columns">
					<LoginForm />
				</div>
				</Popup>: '' }
			{ (this.state.showAddParamsPopup && this.state.productInfoID==this.state.adding.presentID) ? <Popup onClose={()=>{this.setState({'showAddParamsPopup':false})}}>
				<h2>Item options</h2>

				<form>
				{ this.state.productInfo.colourCount ? <div className="form-row">
					<label>Colour:</label>
					<select value={ this.state.addOptions.colour } onChange={ this.handleChangeAddOption.bind(this, 'colour') }>
						{ _.map(this.state.productInfo.colours, (opt, k)=>(<option key={k}>{opt}</option>))}
					</select>
				</div> : '' }
				
				{ this.state.productInfo.isFund && <div className="form-row">
					<label>Enter a message about this fund to your guests (optional):</label>
					<textarea value={ this.state.addOptions.description } onChange={ this.handleChangeAddOption.bind(this, 'description') } />
				</div> }
				
				<div className="form-row"><button type="submit" className="right" onClick={this.handleAddToList.bind(this, this.state.adding)}>Add to list</button></div>
				</form>
				
				</Popup>: '' }
			
			
			{ (products.length>0 || this.props.suppressEmptyMessage) ? ( products.length > 0 ? _.map(products, (p, k)=>(
				<Product 
					product={p}
					added={this.state.added[p.presentID]}
					key={k}
					onAddToList={ this.handleStartAddToList.bind(this, p) } 
					urlPrefix={ p.url_prefix || this.props.urlPrefix } />
			)) : <div key="loading-spinner" className="loading-spinner"></div> ) :
				( this.state.loading ? <div key="loading-spinner" className="loading-spinner"></div> : <div className="empty">
					<p>No matching products found.</p>
				</div> )
			}
			{ (products && products.length>0) && _.map(_.range(2-((products.length-1)%3)), (i)=>(
				<div className="product placeholder" key={i}></div>
			)) }

		</React.Fragment>;
	}
	
	handleChangeAddOption(name, ev) {
		var opts = this.state.addOptions;
		opts[name] = ev.target.value;
		this.setState({'addOptions':opts});
	}
	
	getCategoryUrl() {
		return "/asd/";
		var bits = _.split(_.trim(this.props.match.url, "/"), "/");
		if(bits[0]=="by-brand") {
			if(bits.length>2 && !isFinite(bits[bits.length-1])) bits.pop();
		} else {
			if(!isFinite(bits[bits.length-1])) bits.pop();
		}
		return "/" + _.join(bits, "/") + "/";
	}
	
	fetchProductInfo(prod, callback) {
		do_get(window.wpco_api_prefix + '/api/get-product.a4d', {'presentID':prod.presentID}, (ret)=>{
			this.setState({'productInfo':ret, 'productInfoID':prod.presentID}, callback);
		});
	}
}
