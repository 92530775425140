import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
var $ = require('jquery');

import { Menu } from '../components/Menu.jsx';
import { do_get, ScrollToHere, ScrollToHereWithRouter } from '../utils.jsx';

function columnise(items) {
    var number_per_column = Math.ceil((items||[]).length/3);
    number_per_column = Math.max(number_per_column, 8);
    
    var ret = [];
    for(var i=0;i<3;i++) {
        ret.push(items.slice(i*number_per_column, (i+1)*number_per_column));
    }
    return ret;
}

class Reveal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.divRef = React.createRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // console.log(this.props.in);
    }

    render() {
        return <div ref={this.divRef} className={"reveal" + (this.props.in ? ' in':'')}>
            { this.props.children }
        </div>;
    }
}

export class BrowseBrands extends React.Component {
    constructor(props) {
        super(props);
        this.alphabetMenuRef = React.createRef();
        this.brandSearchInputRef = React.createRef();
        this.state = {
            'letter':null,
            'search_open':false,
            'search_query':''
        };

        this.catalogTopMenu = document.getElementById('catalog-top-menu');
		if(!$(this.catalogTopMenu).hasClass('cleared')) {
			$(this.catalogTopMenu).empty();
			$(this.catalogTopMenu).addClass('cleared');
		}

		this.state.menuData = JSON.parse($(this.catalogTopMenu).attr('data-menu-data') || '{}');

        this.loadData();
    }

    loadData() {
		do_get(window.wpco_api_prefix + 'Brands/GetAllGrouped', {},
			(ret) => {
                var by_id = {};
                Object.values(ret.brands).forEach(arr=>{
                    arr.forEach(b=>{
                        by_id[b.id] = b;
                    })
                });

				this.setState({
                    'brands':ret.brands,
                    'by_id':by_id,
                });
			}
		);

        do_get('/api/brand-highlights/', {}, 
            (ret) => {

                var by_letter = {};
                ((ret.sections || {})['A-Z'] || []).forEach(b=>{
                    by_letter[b.title.substring(0, 1).toUpperCase()] = b;
                });

                this.setState({
                    'sections':ret.sections,
                    'by_letter':by_letter
                });
            }
        );
    }

    componentDidMount() {
        window.addEventListener('scroll', this.onScroll.bind(this), {passive:true});
        window.addEventListener('resize', this.onScroll.bind(this), {passive:true});
    }

    onScroll(ev) {
        if(window.innerWidth>=720) return;

        var frame = this.alphabetMenuRef.current.getBoundingClientRect();
        if(frame.top>0) {
            this.alphabetMenuRef.current.style.marginTop = 0;
            return;
        }

        //var FOOTER_HEIGHT = document.querySelector('.footer').scrollHeight //cache this?
        var prop = window.scrollY / Math.max(document.body.scrollHeight - window.innerHeight, 1);

        var overflow = Math.max((frame.height) - window.innerHeight, 0);

        this.alphabetMenuRef.current.style.marginTop = -(prop * overflow).toFixed(0) + 'px';

        return;

//        console.log('ref:', this.alphabetMenuRef.current.getBoundingClientRect());

        var frame = this.alphabetMenuRef.current.parentNode.getBoundingClientRect();
//        console.log(frame);

//        console.log(window.scrollY);
        this.alphabetMenuRef.current.style.top = Math.max(frame.y, 10).toFixed(0) + 'px';
    }

    render() {
        var brands = this.state.brands || {};
        var filtered_brands = this.state.filtered_brands || brands;

        var section = (this.props.location.hash || '').substring(1) || 'A-Z'; 

        var other_sections = Object.keys(this.state.sections || {})
                                .filter(i=>i!='A-Z')
                                .map(i=>([i, this.state.sections[i]]));

        return <div>
            <ScrollToHereWithRouter />

            { ReactDOM.createPortal(<Menu { ...this.state.menuData } />, this.catalogTopMenu) }
            
            <div className="twelve columns"><h2 className="center"><div>Our Brands</div></h2></div>

            <div className="twelve columns">
                <ul className="page-horiz-menu">
                    <li className={section=='A-Z' ? 'active' : ''}><Link to="#">A-Z</Link></li>
                    { this.getBrandSections().map(s=>(
                        <li key={s} className={s==section ? 'active' : ''}><Link to={"#" + s }>{ s }</Link></li>
                    )) }
                </ul>
            </div>
                
            <Reveal in={ section=='A-Z' }>
                <div className="twelve columns brand-exclusive-legend mobile-only">
                    <picture><source type="image/svg+xml" srcSet="/assets/images/dec19/monogram-large.svg" /><img src="/assets/images/dec19/monogram-large.png" /></picture> Exclusive to The Wedding Present Company
                </div>

                <div className="twelve columns brand-search-mobile mobile-only">
                    <input type="text" placeholder="Search brands..." onChange={ this.handleSearch.bind(this) } />
                </div>

                <div className="twelve columns alphabet-menu-container">
                    <ul className="page-horiz-alphabet-menu" ref={ this.alphabetMenuRef }>
                        { Object.keys(brands).map(k=>(
                            <li key={k}><a href="#" onClick={ this.handleAlphabet.bind(this, k) }>{ k }</a></li>
                        )) }
                        { !!Object.keys(brands).length && <li className="no-mobile"><a href="#" onClick={ this.handleSearchOpen.bind(this) }><picture><source type="image/svg+xml" srcSet="/assets/images/dec19/icon-search-big.svg" /><img src="/assets/images/dec19/icon-search-big.png" /></picture></a></li> }
                    </ul>
                </div>

                <div className={"twelve columns brand-search no-mobile " + (this.state.search_open?' in':'')}>
                    <input type="text" className="borderless" ref={ this.brandSearchInputRef } placeholder="Search brands..." onChange={ this.handleSearch.bind(this) } />
                </div>

                <div className="twelve columns brand-exclusive-legend no-mobile">
                    <picture><source type="image/svg+xml" srcSet="/assets/images/dec19/monogram-large.svg" /><img src="/assets/images/dec19/monogram-large.png" /></picture> Exclusive to The Wedding Present Company
                </div>

                { Object.keys(filtered_brands).map(k=>(
                    <div className="twelve columns container brand-letter-listing" key={k}>

                    { this.state.letter==k && <ScrollToHere smooth={true} offset={-30} toTop={true} /> }

                    <h2 className="lined"><div>{ k }</div></h2>

                    { this.getAlphabetBrandImage(k) }

                    { columnise(filtered_brands[k]).map((col, i)=>(
                        <div className="three columns brand-link" key={i}>
                            { col.map(b=>(
                                <div key={b.id} className={b.isExclusive ? 'exclusive':''}>
                                    <Link to={ '/browse/by-brand/' + b.seoName + "/" }>{ b.name }</Link>
                                </div>
                            )) }
                        </div>
                    ))}


                </div>))}

                { brands && Object.keys(brands).length>0 && filtered_brands && Object.keys(filtered_brands).length==0 && 
                    <div>
                        <p>No matching brands found.</p>
                    </div>
                }
            </Reveal>
            
            { other_sections.map(([name, brands])=>(
                <Reveal in={ section==name }>
                    <div className="twelve columns container brand-thumbnail-listing" key={name}>
                        <h2 className="lined"><div>{ name }</div></h2>

                        { brands.map((brand, i)=>(
                            this.getBrandImage(brand)
                        ))}

                    </div>


                </Reveal>
            )) }
            

        </div>;
    }

    handleAlphabet(k, ev) {
        ev.preventDefault();
        this.setState({letter:k, 'search_open':false, 'search_query':'', 'filtered_brands':null});
    }

    handleSearchOpen(ev) {
        ev.preventDefault();
        this.setState({'search_open':true, 'letter':null});
        this.brandSearchInputRef.current.focus();
    }

    handleSearch(ev) {
        var q = (ev.target.value||'').trim().toLowerCase();

        var filtered_brands = null;
        if(q) {
            var brands = this.state.brands || {};
            filtered_brands = {};
            Object.keys(brands).forEach(k=>{
                var within = brands[k].filter(b=>b.name && b.name.toLowerCase().indexOf(q)>-1);
                if(within.length) filtered_brands[k] = within;
            });
        }

        this.setState({'search_open':true, 'search_query':q, 'filtered_brands':filtered_brands});
    }

    getAlphabetBrandImage(letter) {
        var brand = this.state.by_letter[letter];
        if(!brand) return '';

        return this.getBrandImage(brand);
    }

    getBrandImage(brand) {
        if(!this.state.by_id) return '';
        var neo_brand = this.state.by_id[brand.brand_id];
        if(!neo_brand) return '';

        return <div className="three columns" key={brand.brand_id}>
            <div className="brand-image">
                <a href={"/browse/by-brand/" + neo_brand.seoName + '/'}>
                    <img src={ brand.image } />
                    { brand.title }
                </a>
            </div>
        </div>;
    }

    getBrandSections() {
        return Object.keys(this.state.sections || {}).filter(i=>i!='A-Z');
    }


}