import React from 'react';
var _ = require('lodash');

import { ProductGrid } from '../components/ProductGrid.jsx';

export class SearchResults extends React.Component {
	constructor(props) {
		super(props);
	}
	
	render() {
		return <div className="nine columns container center">
			{ window.search_categories.length ? <div className="nine columns">
				<h2>Categories matching <strong>&lsquo;{ window.search_query }&rsquo;</strong></h2>
				<ul className="category-results">
					{ window.search_categories.length ? 
						_.map(window.search_categories, (cat, k)=>(
						<li key={k}><a href={cat[0]} dangerouslySetInnerHTML={{__html:cat[1]}} /></li>
					)) : <p>No matches found.</p> }
				</ul>
			</div> : '' }
		
			<div className="nine columns">
				<h2>Products matching <strong>&lsquo;{ window.search_query }&rsquo;</strong></h2>
			</div>
		
			<div className="nine columns product-grid">
				<ProductGrid products={ window.search_products } />
			</div>
		</div>;
	}
};
