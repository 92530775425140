import React from 'react';
var _ = require('lodash');
import { Link } from 'react-router-dom';

import { do_get, formatPrice, ScrollToTop } from '../utils.jsx';

import { DashboardMenu } from '../components/DashboardMenu.jsx';
//import { ExpandingDiv } from '../components/ExpandingDiv.jsx';
import { FinanceExpander } from '../components/FinanceExpander.jsx';
import { HelpTooltip } from '../components/HelpTooltip.jsx';


export class AccountFinances extends React.Component {
	constructor(props) {
		super(props);
		console.log(props);
		this.state = {'summary':{}, 'loaded':false};
		
	}
	
	componentWillMount() {
		this.fetchFinances();
	}
	
	fetchFinances() {
		do_get(window.wpco_api_prefix + 'manage/AccountBalance', {}, 
			(ret)=> {
				if(ret.loginRequired) {
					window.location.href = '/login/?next=' + encodeURI('/my-list/account/financial/');
				} else {
					this.setState({'balance':ret, 'loaded':true});
				}
			}
		);
	}
	
	render() {
		if(!this.state.loaded) return <div key="loading-spinner" className="loading-spinner"></div>;
		
		var balance = this.state.balance;
		/*
		balance = {
			"accountBalance": 225.00,
			"totalPledged": 555.00,
			"totalSpent": -350.00,
			"transferRequests": [],
			"balanceBreakdowns": [
				{
					"description": "Branded Products",
					"balance": 75.00,
					"pledged": 155.00,
					"spent": -150.00
				},
				{
					"description": "Cash Funds & Custom Products",
					"balance": 50.00,
					"pledged": 100.00,
					"spent": 0.0
				},
				{
					"description": "Voucher And Experiences",
					"balance": 100.00,
					"pledged": 300.00,
					"spent": -200.00
				}
			]
		}
		*/
		
		return <div className="container">
			<ScrollToTop />
			<DashboardMenu />
			<div className="six columns financial-summary">
				<h2 className="underlined">Financial Summary</h2>
				<React.Fragment>
					<p>Here you will find a breakdown of your wedding gift list account. See what's been pledged by guests, what you've spent and what you've spent it on, and any vouchers or credits you may have.</p>
				
					<div className="balance-hero">
						&pound;{ formatPrice(balance.accountBalance) }
						<div className="caption">Your balance <HelpTooltip iconLabel="?">Any balance on account can be used toward WPC products. The WPC product balance can not be used to pay for custom products or a cash fund.</HelpTooltip></div>
					</div>
					
					<h4>Balance breakdown</h4>
					
					<table className="balance-breakdown">
						<tbody>
							{ balance.balanceBreakdowns.length ?
								_.map(balance.balanceBreakdowns, (b, k)=>(
									<tr key={k}><td>{ b.description }</td><td align="right">&pound;{ formatPrice(b.balance) }</td></tr>
								))
							: <tr><td>No purchases yet</td><td align="right"></td></tr> }
						</tbody>
					</table>

					<p>To view a full statement of your account, or to settle your account, please contact us at <a href="mailto:office@weddingpresentco.com">office@weddingpresentco.com</a> or call <a href="tel:+441488662100">01488 662 100</a>.</p>
					
					<FinanceExpander title="Guest Purchases" subtitle={"£" + formatPrice(balance.totalPledged)} help="How your guests have contributed towards your wedding list.">
						<h4>Balance breakdown</h4>
						<table className="balance-breakdown">
							<tbody>
								{ balance.balanceBreakdowns.length ?
									balance.balanceBreakdowns.filter(b=>b.pledged!=0).map((b, k)=>(
										<tr key={k}><td>{ b.description }</td><td align="right">&pound;{ formatPrice(b.pledged) }</td></tr>
									))
								: <tr><td>No guest purchases yet</td><td align="right"></td></tr> }
							</tbody>
						</table>
					</FinanceExpander>
					
					<FinanceExpander title="Your Orders" subtitle={"£" + formatPrice(-balance.totalSpent)} help="What you have ordered from your wedding list.">
						<h4>Balance breakdown</h4>
						<table className="balance-breakdown">
							<tbody>
								{ balance.balanceBreakdowns.length ?
									balance.balanceBreakdowns.filter(b=>b.spent!=0).map((b, k)=>(
										<tr key={k}><td>{ b.description }</td><td align="right">&pound;{ formatPrice(-b.spent) }</td></tr>
									))
								: <tr><td>No orders yet</td><td align="right"></td></tr> }
							</tbody>
						</table>
					</FinanceExpander>
					
					<p>Once your list is finalised, we will let you know the final donation value and get in 
					touch for the details of your chosen charity. If you don't have a particular charity 
					in mind we support two brilliant small, local charities who would be delighted to
					receive the donation. Find out more about them <a href="/generosity/">here</a>.</p>
					
				</React.Fragment>
			</div>
		</div>;
	}
}



