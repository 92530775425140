import React from 'react';
var _ = require('lodash');

import { cookies, do_get, do_post, ScrollToHereWithRouter, tracking_event } from '../utils.jsx';

import { FormInput, MyForm } from '../components/Forms.jsx';
import { Popup } from '../components/Popup.jsx';
import { SectionExpander } from '../components/SectionExpander.jsx';

export class BookAppointmentForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.sections = {
			showroom: React.createRef(),
			virtual: React.createRef()
		};
	}
	
	componentDidMount() {
		//if(window.ga) ga('send', 'event', 'Popups', 'show', 'Book Appointment');
		tracking_event('book_appointment_popup_show');

		do_get('/api/snippet/', {'name':'showroom_appointment_text'}, (ret)=>{
			this.setState({'showroom_appointment_text':ret.value || ''});
		});
	}
	
	render() {
		var props = {'errors':this.state.errors||{}, 'values':this.state.data||{}, 'context':{'focused':false}};
		
		return <Popup onClose={this.handleClose.bind(this)}>
			<h2>Book an appointment</h2>
			
			<p>Choose your appointment type:</p>
			
			{ this.state.success ? <React.Fragment>
				<ScrollToHereWithRouter offset={-120} />
				<h3>Thank You. Our Showroom will be in touch shortly.</h3>
				<div className="form-row"><button type="button" className="right button blue" onClick={ this.handleClose.bind(this)}>Close</button></div>
			</React.Fragment> :

			<SectionExpander title="London showroom appointment" ref={this.sections.showroom}>
			
			<section dangerouslySetInnerHTML={ {__html:this.state.showroom_appointment_text } } />

			<MyForm ref="appointment_form" onSubmit={this.handleSubmit.bind(this)}>
			<div className="half row">
				<FormInput label="First Name" name="firstName" required={true} {...props} />
				<FormInput label="Surname" name="surname" required={true} {...props} />
			</div>
			<div className="half row">
				<FormInput label="Phone" name="phone" required={true} validate="phone" {...props} />
				<FormInput label="Email" name="email" validate="email" required={true} {...props} />
			</div>
			<div className="half row">
				<FormInput label="Wedding Date" name="weddingDate" type="date" required={false} validate="future" {...props} />
				<FormInput label="Number of Guests" name="numberOfGuests" required={false} {...props} />
			</div>
			<FormInput label="Ideal Appointment Date and Time" name="preferredTimeAndDate" required={false} {...props} />
			
			<div className="form-row"><button type="submit" className="right">Send</button></div>
			</MyForm>
			</SectionExpander> }

			<SectionExpander title="Virtual appointment" ref={this.sections.virtual}>
				
			<p>Our virtual appointments are a brilliant alternative to our London Showroom Appointments.<br />Meet your dedicated Wedding List Advisor via a video call and receive the same tailored experience and product knowledge via Zoom.</p>

			{ cookies.get('wpaT') ? <React.Fragment>
				<p className="actions"><a className="button blue" href="/virtual-appointment-questionnaire/">Book</a></p>
			</React.Fragment> : <React.Fragment>
				<p>Please either register or login to your account so we can prepare a bespoke experience for your Virtual Appointment.</p>
				<p className="actions"><a className="button blue" href="/create-a-list/?virtual=1">Register</a><a className="button blue" href="/login/?next=/virtual-appointment-questionnaire/">Login</a></p>
			</React.Fragment> }
				
			</SectionExpander>
			
			
		</Popup>;
	}
	
	handleSection(name, ev) {
		ev.preventDefault();
		// yick
		this.sections.showroom.current.setState({'open':false});
		this.sections.virtual.current.setState({'open':true});
	}
	
	handleSubmit(fields, errors) {
		var hasErrors = _.find(errors, (v)=>(v!=null));

		if(!hasErrors && !fields.phone && !fields.email) {
			errors.phone = "Please enter phone or email.";
			hasErrors = true;
		}
		
		this.setState({'data':fields, 'errors':errors});
		if(hasErrors) return;
		
		fields.comment = 'Wedding date (YYYY-MM-DD): ' + fields.weddingDate + '\nNumber of guests: ' + fields.noGuests + '\nIdeal appointment date/time: ' + fields.appointmentDateTime + '\n';
		console.log(fields);

		tracking_event('book_appointment_popup_submit');
		//if(window.ga) ga('send', 'event', 'Popups', 'submit', 'Book Appointment');
		if(window.fbq) fbq('track', 'Lead');
		
 		do_post('/api/appointment/', {
 			first_name: fields.firstName,
 			surname: fields.surname,
 			phone: fields.phone,
 			email: fields.email,
 			wedding_date: fields.weddingDate,
 			number_of_guests: fields.numberOfGuests,
 			ideal_appointment_date_time: fields.preferredTimeAndDate
 		}, (ret)=>{
 			if(ret.errorText) {
 				alert(ret.errorText);
 			}
 		});

		return new Promise((resolve)=>{
			do_post(window.wpco_api_prefix + 'Util/RequestAppointmentWpc',
				fields,
			(ret)=>{
				if(ret.responseType!=1) {
					alert(ret.message || "Unknown error.");
				} else {
					this.setState({'success':true, 'data':{}});
				}
			}).finally(resolve);
		});
			
	}
	
	handleClose() {
		if(this.props.onClose) this.props.onClose();
	}
}
