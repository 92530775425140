import React from 'react';
var _ = require('lodash');
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { addDays, addBusinessDays, addMonths, addYears, format, getDay, isBefore, isEqual, isSunday, parseISO, setDate, startOfDay } from 'date-fns'

import { isInternalDeliveryAddress } from '../logic.jsx';
import { decode_access_token, do_get, do_post, do_post_cached, ScrollToTop } from '../utils.jsx';

import { AgeRestrictionNotice } from '../components/AgeRestrictionNotice.jsx';
import { Availability } from '../components/Availability.jsx';
import { Button } from '../components/Button.jsx';
import { DashboardMenu } from '../components/DashboardMenu.jsx';
import { EditDeliveryAddressForm } from '../components/EditDeliveryAddressForm.jsx';
import { MyForm } from '../components/Forms.jsx';
import { Popup } from '../components/Popup.jsx';
import { SimilarProducts } from '../components/SimilarProducts.jsx';

import { 
	canMakeGroupGift,
	canRevertGroupGift,
	getItemCategory, 
	getListStatusText,
	getListValue, 
	getProductBrand,
	getProductImage,
	getPurchaseValue, 
    isAgeVerificationRequired,
	isGiftCard,
	shouldAllowEdit,
	shouldShowBrand,
	shouldShowPrice,
	shouldShowRequiredRemaining,
	sortedFilteredListItems 
} from '../logic.jsx';

var TESTING = false;

function getExpectedDate(item) {
    //return format(parseISO("2023-11-02T10:45:37.437"), "dd/MM/yyyy");

    if(item.customerEdd) {
        return format(parseISO(item.customerEdd), "dd/MM/yyyy");
    }

    if(item.outOfStock) {
        // Status will also say 'Out of Stock' so leave this blank
        return '-';
    }

    return "-";
}

function getItemStatus(item) {
    if(item.outOfStock) return "Out of Stock";
    return item.status;
}

export class AccountOrders extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
            'loaded':false, 
            'introduction':null, 
            'changingDeliveryDate':false,
            'changedDeliveryDate': false,
            'submitting':false,
            'cancelledItems': {},

            'canRequestDelivery': false,
            'remainingDeliveries': null,
            'totalFreeDeliveries': TESTING ? 200 : 2,
            'bookDeliveryStage': null,//'choose_date',
            'isAgeVerificationRequired': false,
        };
	}
	
	componentDidMount() {
        this.fetchList();
		this.fetchOrders();
        this.fetchRemainingDeliveries();
        do_get('/api/snippet/', {'name':'account_my_orders_introduction'}, (ret)=>{
			this.setState({'introduction':ret.value || ''});
		});
	}
	
	fetchOrders() {
        do_get(window.wpco_api_prefix + 'Manage/TrackYourOrdersv2',
            {},
            (ret)=>{
                var orders = {};

                if(window.location.hash=="#test-derange") {
                    (ret.orderItems||[]).forEach(i=>{
                        i.deRange = true;
                        i.orderByDate = "2024-01-12T00:00:00";
                    });
                } else if(window.location.hash=="#test-outofstock") {
                    (ret.orderItems||[]).forEach(i=>{
                        i.outOfStock = true;
                        i.expectedBackInStockDate = "2024-01-12T00:00:00";
                    });
                }

                (ret.orderItems||[]).forEach(oi=>{
                    if(!orders[oi.orderHeaderId]) orders[oi.orderHeaderId] = [];
                    orders[oi.orderHeaderId].push(oi);
                });

                var canRequestDelivery = (ret.orderItems || []).filter(oi=>oi.status=="Arrived In Warehouse").length>0 && !ret.hasFutureValidDeliveryDate;
                if(TESTING) canRequestDelivery = true;

                var verif = (ret.orderItems || []).filter(oi=>
                    oi.status=="Arrived In Warehouse" 
                    && isAgeVerificationRequired(oi)
                ).length>0;

                this.setState({
                    'loaded':true,
                    'orders':orders,
                    'orderItems':ret.orderItems,
                    'canRequestDelivery': canRequestDelivery,
                    'isAgeVerificationRequired': verif,
                });
            }
        );
	}

    fetchList() {
        do_post(window.wpco_api_prefix + 'Manage/YourList',
            {}, 
            (ret)=> {
                if(ret.loginRequired) {
                    window.location.href = '/login/?next=' + encodeURI('/my-list/');
                } else if(ret.responseType!=1) { //TODO
                    alert(ret.message || "Unknown error");
                } else {
                    if(TESTING) {
                        ret.giftList.hasItemsAllocated = true;
                        ret.giftList.hasFutureDeliveryDate = false;
                    }

                    this.setState({'giftList':ret.giftList});
                }
            }
        );
    }

    fetchRemainingDeliveries() {
        return new Promise((resolve, reject)=>{
            do_get(window.wpco_api_prefix + 'Delivery/GetDeliveryCount', {},
                (ret)=> {
                    this.setState({'remainingDeliveries': Math.max(this.state.totalFreeDeliveries - ret.deliveryCount, 0)});
                }
            );
        });
    }
	
	render() {
		if(!this.state.loaded) return <div key="loading-spinner" className="loading-spinner"></div>;

        if(this.props.match && this.state.orders[this.props.match.params.invoice_id]) {
            var items = this.state.orders[this.props.match.params.invoice_id].map(oi=>{
                return _.assign({},
                    oi.product,
                    oi
                );
            });

            // remove cancelled items
            items = items.filter(i=>!this.state.cancelledItems[i.id]);
            if(items.length==0) {
                // crudely redirect if no items anymore
                this.props.history.push("/my-list/my-orders/");
                return <div></div>;
            }

            return <div>
                <div className="container">
                    <div className="sidebar three columns">
                        <Link className="back" to="/my-list/my-orders/"><span>Back</span></Link>
                        <br /><br />
                    </div>

                    <div className="twelve columns my-orders">
                        <h2>Order { items[0].orderHeaderId }</h2>
                        <p>Date: { new Date(items[0].orderDate).toLocaleDateString() }</p>

                        <table className="result-list view-list my-list">
                            <thead>
                                <tr>
                                    <th width="17.2%">Item</th>
                                    <th>Name</th>
                                    <th width="13%">Ordered</th>
                                    <th width="17%">Date Expected <div className="help-hover">The date your item is due into our warehouse.</div></th>
                                    <th width="16%">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                { _.map(items, (prod, k) => (
                                    <React.Fragment key={k}>
                                    <tr className={"first-row"}>
                                        <td className="thumbnail" rowSpan="2">
                                            <img src={ getProductImage(prod, 150) } width="100%" />
                                        </td>
                                        <td className="description">
                                            <h3>{ prod.brand } { prod.title }</h3>
                                            { isAgeVerificationRequired(prod) && <AgeRestrictionNotice /> }
                                        </td>
                                        { /* <td className="qty-bought">
                                            { (prod.isGroupGift || prod.isFundProductType || isGiftCard(prod)) ? 
                                                '£'+((prod.purchased||0)*(prod.salePrice||0)).toFixed(2)
                                            : 
                                                (prod.purchased || 0 )
                                            }</td> */ }
                                        { (isGiftCard(prod) || prod.isFundProductType) ?
                                            <td colSpan="4"></td>
                                        : <React.Fragment>
                                            <td className="qty-ordered">{ prod.quantity || 0 }</td>
                                            <td className="date-expected">{ getExpectedDate(prod) }</td>
                                            <td className="status">{ getItemStatus(prod) }</td>
                                        </React.Fragment> }
                                    </tr>
                                    <tr className={ "second-row "}>
                                        <td colSpan="6">
                                            <Availability 
                                                product={prod} 
                                                productId={prod.productId}
                                                showExtraOrderFeatures={true} 
                                                ignoreDeRange={true} 
                                                onCancel={ ()=>{
                                                    var cancelledItems = this.state.cancelledItems;
                                                    this.setState({cancelledItems: Object.assign(
                                                        {},
                                                        this.cancelledItems,
                                                        {
                                                            [prod.id]: true
                                                        }
                                                    )});
                                                    //this.setState({'loaded': false});
                                                    this.fetchOrders();
                                                    this.fetchList();
                                                } }
                                                />
                                        </td>
                                    </tr>
                                    </React.Fragment>
                                )) }
                                { items.length==0 && <tr>
                                    <td colSpan="6">You have no orders yet.</td>
                                    </tr> }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>;
        }

        var popup = null;
        if(this.state.changingDeliveryDate) {
            var valid_dates = [];
            var day = addDays(addBusinessDays(new Date(), 3), 1);
            for(var i=0;i<100;i++) {
                if(isSunday(day)) day = addDays(day, 1);
                valid_dates.push(day);
                day = addDays(day, 1);
            }

            if(this.state.changedDeliveryDate) {
                popup = <Popup onClose={ ()=>{ this.setState({changingDeliveryDate:false}); }}>
                    <h2>Preferred dates for delivery</h2>
                    <p>Thank you, your request has been received. A member of our team will be in touch shortly.</p>
                    <div className="form-row"><button type="button" className="blue right" onClick={ ()=>{ this.setState({changingDeliveryDate:false}); }}>Close</button></div>
                </Popup>;
            } else if (
                this.state.giftList && !this.state.giftList.hasItemsAllocated
            ) {
                popup = <Popup onClose={ ()=>{ this.setState({changingDeliveryDate:false}); }}>
                    <h2>Preferred dates for delivery</h2>
                    <p>Sorry, you cannot book a delivery until we have received your presents into our warehouse. You can track the expected arrival dates of your presents in your Online Dashboard and book a delivery as soon as we have received them into our warehouse. <br/><br />For more information please contact our <a href="/contact-us/" target="_blank">Customer Care team</a>.</p>
                    <div className="form-row"><button type="button" className="blue right" onClick={ ()=>{ this.setState({changingDeliveryDate:false}); }}>Close</button></div>
                </Popup>;
            } else if (
                this.state.giftList && this.state.giftList.hasFutureDeliveryDate
            ) {
                popup = <Popup onClose={ ()=>{ this.setState({changingDeliveryDate:false}); }}>
                    <h2>Preferred dates for delivery</h2>
                    <p>Your delivery has already been booked. For any changes, please contact our <a href="/contact-us/" target="_blank">Customer Care team</a>. (Please note we are unable to change a delivery 48 hours prior to your delivery date)</p>
                    <div className="form-row"><button type="button" className="blue right" onClick={ ()=>{ this.setState({changingDeliveryDate:false}); }}>Close</button></div>
                </Popup>;
            } else {
                popup = <Popup onClose={ ()=>{ this.setState({changingDeliveryDate:false}); }}>
                    <form method="post" className={ this.state.submitting ? 'submitting' : '' } onSubmit={ this.handleChangeDeliveryDateSubmit.bind(this) }>
                    <h2>Preferred dates for delivery</h2>
                    <p>To request a delivery, simply enter three preferred dates below.</p>

                    <div className="form-row">
                        <label>First preference</label>
                        <DatePicker
                            includeDates={valid_dates}
                            selected={ this.state.deliveryDate1 }
                            onChange={ date => this.setState({'deliveryDate1': date}) }
                            customInput={<input required type="text" />}
                            dateFormat="dd/MM/yyyy"
                            />
                    </div>
                    <div className="form-row">
                        <label>Second preference</label>
                        <DatePicker
                            includeDates={valid_dates}
                            selected={ this.state.deliveryDate2 }
                            onChange={ date => this.setState({'deliveryDate2': date}) }
                            dateFormat="dd/MM/yyyy"
                            />
                    </div>
                    <div className="form-row">
                        <label>Third preference</label>
                        <DatePicker
                            includeDates={valid_dates}
                            selected={ this.state.deliveryDate3 }
                            onChange={ date => this.setState({'deliveryDate3': date}) }
                            dateFormat="dd/MM/yyyy"
                            />
                    </div>
                    <p>We will be in touch shortly to confirm your delivery date. Please note that your delivery is not confirmed until you have received email confirmation from one of our team. For any changes to your delivery preferences, please contact our <a href="/contact-us/" target="_blank">Customer Care team</a> (please note we are unable to change a delivery 48 hours prior to your confirmed date).</p>
                    <div className="form-row"><button type="submit" className="blue right">Submit</button></div>
                    </form>
                </Popup>;
            }
        } else if(this.state.bookDeliveryStage == "confirm_address") {
            popup = <ConfirmDeliveryAddressPopup 
                        isAgeVerificationRequired={ this.state.isAgeVerificationRequired }
                        onClose={ ()=>{ this.setState({'bookDeliveryStage': null}); } }
                        onNext={ (stage, d) => { this.setState({...d, 'bookDeliveryStage': stage}); } }
                        />;
        } else if(this.state.bookDeliveryStage == "choose_date") {
            popup = <ChooseDatePopup
                        onClose={ ()=>{ this.setState({'bookDeliveryStage': null}); } }
                        onNext={ this.handleChosenDeliveryDate.bind(this) }
                        />;
        } else if(this.state.bookDeliveryStage == "choose_preferred_dates") {
            // mechanism for international orders
            popup = <ChoosePreferredDatesPopup
                        onClose={ ()=>{ this.setState({'bookDeliveryStage': null}); } }
                        onNext={ this.handleChosenPreferredDates.bind(this) }
                        />;
        } else if(this.state.bookDeliveryStage == "complete") {
            popup = <ConfirmationPopup 
                        onClose={ ()=>{ this.setState({'bookDeliveryStage': null}); } }
                        deliveryDay={ this.state.deliveryDay }
                        deliveryAddress={ this.state.deliveryAddress }
                        />;
        } else if(this.state.bookDeliveryStage == "complete_preferred") {
            popup = <ConfirmationPopup 
                        onClose={ ()=>{ this.setState({'bookDeliveryStage': null}); } }
                        deliveryDay={ null }
                        deliveryAddress={ this.state.deliveryAddress }
                        />;
        }

        return <div>
            <ScrollToTop />
            <div className="container">
                { popup }

                <DashboardMenu />
                <div className="six columns">
                    <h2 className="underlined">My Orders</h2>

                    { false && this.state.remainingDeliveries > 0 && 
                        <div className="book-del__badge">Free Deliveries Remaining: <strong>{ this.state.remainingDeliveries } of { this.state.totalFreeDeliveries }</strong></div>
                    }
                    { false && this.state.remainingDeliveries === 0 &&
                        <div className="book-del__badge">Free Deliveries Remaining: <strong>0 of { this.state.totalFreeDeliveries }</strong></div>
                    }

                    <div dangerouslySetInnerHTML={ {__html:this.state.introduction } } />

                    { (this.state.remainingDeliveries > 0 && !this.state.giftList?.hasFutureDeliveryDate && this.state.canRequestDelivery) && <p>
                        <Button className="button blue" onClick={ this.startBookDelivery.bind(this) } delay={ 0 }>Book a delivery</Button>
                    </p> }
                    { (this.state.remainingDeliveries === 0 || this.state.giftList?.hasFutureDeliveryDate) && <p>
                        <Button className="button blue" onClick={ this.handleChangeDeliveryDate.bind(this) } delay={ 0 }>Book a delivery</Button>
                    </p> }

                    <p>Please do not hesitate to get in touch with us if you have any questions.</p>
                    <p><a href="/contact-us/">Contact our team</a></p>

                    { /* <p><a href="#" className="button blue" onClick={ this.handleChangeDeliveryDate.bind(this) }>Request to book a delivery (old)</a></p> */ }
                    { /* <p><Button className="button blue" onClick={ this.startBookDelivery.bind(this) } delay={ 0 } disabled={ !this.state.canRequestDelivery }>Book a delivery</Button></p> */ }
                    
                    <table className="result-list view-list my-list">
                        <thead>
                            <tr>
                                <th>Order</th>
                                <th>Date</th>
                                <th width="50"></th>
                            </tr>
                        </thead>
                        <tbody>
                            { _.map(Object.values(this.state.orders), (items, k)=>(
                                <tr key={k}>
                                    <td>{ items[0].orderHeaderId }</td>
                                    <td>{ new Date(items[0].orderDate).toLocaleDateString() }</td>
                                    <td><Link to={"/my-list/my-orders/" + items[0].orderHeaderId + "/"}>View</Link></td>
                                </tr>
                            )) }

                        </tbody>
                    </table>

                </div>
            </div>
        </div>;
	}

    /* Old delivery date request system */

    handleChangeDeliveryDate(ev) {
        ev.preventDefault();

        this.setState({'changingDeliveryDate': true});
    }

    handleChangeDeliveryDateSubmit(ev) {
        // The old mechanism submit, which currently just sends an email.
        
        ev.preventDefault();
        this.setState({'submitting': true});

        var dates = [];
        if(this.state.deliveryDate1) dates.push(format(this.state.deliveryDate1, "yyyy-MM-dd"));
        if(this.state.deliveryDate2) dates.push(format(this.state.deliveryDate2, "yyyy-MM-dd"));
        if(this.state.deliveryDate3) dates.push(format(this.state.deliveryDate3, "yyyy-MM-dd"));

        do_post('/api/change-delivery-dates/',
			_.assign({}, decode_access_token(), {
                'salutation':this.state.giftList?.salutation,
                'delivery_dates':dates
            }), 
			(ret)=>{
				if(ret.errorText) {
					alert(ret.errorText);
				} else {
                    this.setState({'changedDeliveryDate': true});
				}
        }).finally(r=>{
            this.setState({'submitting':false});
        });
    }

    /* New delivery booking system (2023) */

    startBookDelivery(ev) {
        ev.preventDefault();

        this.setState({
            'bookDeliveryStage': 'confirm_address',
        });
    }

    

    handleChosenDeliveryDate(next_stage, data) {
        //where we actually do the request

        return new Promise((resolve, reject)=>{
            
            // 42 = internal delivery needing extra processing, 116 = auto 'external' courier booking 
            var deliveryTypeId = isInternalDeliveryAddress(this.state.deliveryAddress.postcode) ? 42 : 116;

            do_post(window.wpco_api_prefix + 'Delivery/Request',
                {
                    deliveryTypeId: deliveryTypeId,
                    preferredDates: [
                        format(data.deliveryDay, "yyyy-MM-dd"),
                        "",
                        "",
                    ]
                },
                (ret)=>{
                    if(ret.responseType!=1) {
                        create_alert(ret.message || "Unknown error.");
                        reject();
                    } else {
                        resolve();

                        this.fetchRemainingDeliveries();

                        this.setState({
                            ...data,
                            'bookDeliveryStage': next_stage,
                        });
                    }
                }
            );
        });
    }

    handleChosenPreferredDates(next_stage, data) {
        // the fallback for awkward/international deliveries, new mechanism

        return new Promise((resolve, reject)=>{

            // 42 = internal delivery needing extra processing, 116 = auto 'external' courier booking 
            var deliveryTypeId = isInternalDeliveryAddress(this.state.deliveryAddress.postcode) ? 42 : 116;

            do_post(window.wpco_api_prefix + 'Delivery/Request',
                {
                    deliveryTypeId: deliveryTypeId,
                    preferredDates: [
                        format(data.deliveryDate1, "yyyy-MM-dd"),
                        format(data.deliveryDate2, "yyyy-MM-dd"),
                        format(data.deliveryDate3, "yyyy-MM-dd"),
                    ]
                },
                (ret)=>{
                    if(ret.responseType!=1) {
                        create_alert(ret.message || "Unknown error.");
                        reject();
                    } else {
                        resolve();

                        this.fetchRemainingDeliveries();

                        this.setState({
                            ...data,
                            'bookDeliveryStage': next_stage,
                        });
                    }
                }
            );
        });
    }
}

class ConfirmDeliveryAddressPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            addresses: null,
            selected: null,
            editing: null,
            errors: {},
        }
    }

    componentDidMount() {
        this.fetchDeliveryAddresses();
    }

    render() {
        if(this.state.addresses===null) {
            return <Popup onClose={ this.props.onClose } className="book-del__popup">
                <div key="loading-spinner" className="loading-spinner"></div>
            </Popup>;
        }

        var addrs = this.state.addresses?.map(addr=>{
            var name = [
                addr.firstName,
                addr.surname,
            ].filter(i=>i).join(" ");
            
            var addr = [
                name,
                addr.address1,
                addr.address2,
                addr.address3,
                [addr.town, addr.postcode].filter(i=>i).join(", "),
                addr.country,
                addr.phoneNumber,
            ].filter(i=>i);

            return addr;
        });
        var addr_titles = this.state.addresses?.map(addr=>{
            return addr.address1;
        });

        return <Popup onClose={ this.props.onClose } className="book-del__popup">
            <MyForm onSubmit={this.handleSubmit.bind(this)}>
            <h2>Book a delivery</h2>
            <h3>Add or select an address to book your delivery</h3>
            { /* this.state.deliveryCount !== null && <div className="book-del__badge">You have <strong>{ Math.max(2 - this.state.deliveryCount, 0) }</strong> free deliveries remaining.</div> */ }
            { this.state.editing !== null ? 
                <EditDeliveryAddressForm 
                    showAddress3={ true }
                    showPhone={ true }
                    deliveryAddress={ this.state.editing === "new" ? {} : this.state.addresses[this.state.editing] }
                    errors={ this.state.errors } 
                    onSave={ this.handleSave.bind(this) } 
                    onCancel={ ()=>{ this.setState({"editing": null}) } } />
            : <React.Fragment>

                { addrs.map((addr, i)=>(
                    <div key={i} className="book-del__address">
                        <label><input type="radio" name="address" checked={ this.state.selected === i } onChange={ (ev)=> { this.setState({'selected': i}); } } /><span></span> { addr_titles[i] }</label>
                        <p>{ addr.map((s, i)=><span key={i}>{ s }<br /></span>) }
                            <a href="#" onClick={ this.handleEdit.bind(this, i) }>Edit</a>
                        </p>
                    </div>
                )) }

                <p className="book-del__add-address"><a href="#" className="book-del__plus" onClick={ this.handleAdd.bind(this) }>Add another address</a></p>

                { /*
                <div className="book-del__check-row">
                    <label><input 
                        type="checkbox" 
                        className="green-checkbox"
                        checked={this.state.confirmed} 
                        onChange={ (ev)=>{ this.setState({'confirmed':ev.target.checked}) } } 
                        /> Confirm delivery address</label>
                </div>
                */ }
            </React.Fragment> }

            { !!this.props.isAgeVerificationRequired && <div className="book-del__age-verif">
                <strong>NOTICE:</strong> This order contains age restricted products only available to persons aged 18 years or over. A valid ID will be required for delivery.
            </div> }

            <div className="book-del__actions">
                <button type="submit" disabled={this.state.selected===null || this.state.editing!==null }>Choose a date</button>
            </div>
            </MyForm>
        </Popup>;
    }

    handleAdd(ev) {
        ev.preventDefault();
        this.setState({'editing': 'new', 'selected':null});
    }

    handleEdit(ind, ev) {
        ev.preventDefault();
        this.setState({'editing': ind});
    }

    handleSave(data) {
        var errors = {};
        if(!data.address1) errors.address1 = "Please enter the address.";
        if(!data.town) errors.town = "Please enter the town/city.";
        if(!data.country) errors.country = "Please select the country.";
        if(!data.phoneNumber) errors.phoneNumber = "Please enter a phone number.";
        this.setState({"errors": errors});
        if(Object.keys(errors).length) return;

        var id = 0;
        if(this.state.editing !== "new") {
            id = this.state.addresses[this.state.editing].id;
        }
        return new Promise((resolve, reject)=>{
            do_post(window.wpco_api_prefix + 'Manage/UpdateGiftListAddress',
                {address: Object.assign(
                    {},
                    data,
                    {
                        // update existing address if there is one
                        "id": id,
                        "type": "delivery",
                        //"isPrimaryAddress": false,
                    }
                )},
                (ret)=> {
                    if(ret.responseType!=1) {
                        create_alert(ret.message || 'There was an error updating your delivery address.');
                        reject();
                    } else {
                        this.fetchDeliveryAddresses().then(()=>{
                            this.setState({'editing': null});
                            resolve();
                        });
                    }
                }
            );
        });
    }

    handleSubmit(data, errors) {
        if(this.state.selected !== null) {
            var addr = this.state.addresses[this.state.selected];
            return new Promise((resolve, reject)=>{
                do_post(window.wpco_api_prefix + 'Manage/UpdateGiftListAddress',
                    {address: Object.assign(
                        addr,
                        {
                            // update existing address if there is one
                            "id": addr.id,
                            "type": "delivery",
                            "isPrimaryAddress": true,
                        }
                    )},
                    (ret)=> {
                        if(ret.responseType!=1) {
                            create_alert(ret.message || 'There was an error updating your delivery address.');
                            reject();
                        } else {
                            resolve();

                            if(addr.country=="United Kingdom" && !isInternalDeliveryAddress(addr.postcode)) {
                                this.props.onNext('choose_date', {
                                    'deliveryAddress': addr,
                                });
                            } else {
                                this.props.onNext('choose_preferred_dates', {
                                    'deliveryAddress': addr,
                                });
                            }
                        }
                    }
                );
            });
        }
	}

    fetchDeliveryAddresses() {
        return new Promise((resolve, reject)=>{
            do_get(window.wpco_api_prefix + 'Manage/GiftListSettingsWpc', {}, 
                (ret)=> {
                    if(ret.loginRequired) {
                        window.location.href = '/login/?next=' + encodeURIComponent('/my-list/my-orders/');
                    } else if(ret.responseType!=1) {
                        alert(ret.message || "Unknown error.");
                    } else {
                        //this.setState({'listSettings':ret.giftListSettings, 'editingDetails':true});
                        var addresses = ret.giftListSettings.addresses.filter(a=>a.type=="delivery" && a.address1 && a.town && a.country);
                        addresses.sort((a,b)=> 
                            ( a.isPrimaryAddress > b.isPrimaryAddress) ?
                                -1
                                : ( ( a.isPrimaryAddress < b.isPrimaryAddress) ?
                                    1
                                    : 0
                                )
                        );
                        this.setState({"addresses":addresses});
                        resolve();
                    }
                }
            );
        });
	}
}

class ChooseDatePopup extends React.Component {
    constructor(props) {
        super(props);

        var earliest = addDays(startOfDay(new Date()), 2);
        // if sunday, nudge on one
        if(earliest.getDay()==0) earliest = addDays(earliest, 1); 

        this.state = {
            earliest: earliest,
            month: startOfDay(new Date()),
            blockedDates: null,
            day: null,
        };
    }

    componentDidMount() {
        do_get(window.wpco_api_prefix + 'Delivery/BlockedDeliveryDates',
            {},
            (ret)=>{
                var blockedDates = {};
                ret.blockedDates.forEach(d=>{
                    // TODO - handle the blockedDeliveryTypes?
                    blockedDates[d.date.substring(0, 10)] = true;
                });

                var earliest = this.state.earliest;

                for(var i=0;i<30;i++) {
                    if(
                        // if is a sunday
                        (earliest.getDay()==0)
                        // or is blocked
                        || blockedDates[format(earliest, "yyyy-MM-dd")]
                      ) {
                        // then nudge on by one
                        earliest = addDays(earliest, 1); 
                    } else {
                        break;
                    }
                }

                this.setState({
                    'blockedDates': blockedDates,
                    'earliest': earliest,
                    'month': startOfDay(earliest),
                    'cutoff': startOfDay(addMonths(earliest, -1)),
                });
            }
        );

	}

    render() {
        if(this.state.blockedDates===null) {
            return <Popup onClose={ this.props.onClose } className="book-del__popup">
                <div key="loading-spinner" className="loading-spinner"></div>
            </Popup>;
        }

        var go = (f)=>((ev)=>{ 
            ev.preventDefault(); 
            var v = f(this.state.month);
            if(v && this.state.cutoff && isBefore(v, this.state.cutoff)) return;
            this.setState({'month':v});
        });

        var isAllowed = d=>{
            if(getDay(d)==0) return false; // no sundays

            var dt = format(d, "yyyy-MM-dd");
            if(this.state.blockedDates!==null && this.state.blockedDates[dt]) return false;

            return !isBefore(d, this.state.earliest);
        };

        return <Popup onClose={ this.props.onClose } className="book-del__popup">
            <h2>Book a delivery</h2>
            <p>Choose your delivery date.</p>
            <div className="book-del__calendar">
                <div className="book-del__prev">
                    <a href="#" onClick={ go((v)=>addMonths(v, -1)) }><svg xmlns="http://www.w3.org/2000/svg" fill="none" width="6" viewBox="0 0 6 10"><path stroke="#C9A184" d="M5 9 1 5l4-4"/></svg></a>
                    <a href="#" onClick={ go((v)=>addYears(v, -1)) }><svg xmlns="http://www.w3.org/2000/svg" fill="none" width="9" viewBox="0 0 9 10"><path stroke="#C9A184" d="M5 9 1 5l4-4"/><path stroke="#C9A184" d="M8 9 4 5l4-4"/></svg></a>
                </div>
                <div className="book-del__next">
                    <a href="#" onClick={ go((v)=>addYears(v, 1)) }><svg xmlns="http://www.w3.org/2000/svg" fill="none" width="9" viewBox="0 0 9 10"><path stroke="#C9A184" d="m4 1 4 4-4 4"/><path stroke="#C9A184" d="m1 1 4 4-4 4"/></svg></a>
                    <a href="#" onClick={ go((v)=>addMonths(v, 1)) }><svg xmlns="http://www.w3.org/2000/svg" fill="none" width="6" viewBox="0 0 6 10"><path stroke="#C9A184" d="m1 1 4 4-4 4"/></svg></a>
                </div>
                <span className="book-del__month">{ format(this.state.month, "MMMM yyyy") }</span>
                <span className="book-del__day">Mo</span>
                <span className="book-del__day">Tu</span>
                <span className="book-del__day">We</span>
                <span className="book-del__day">Th</span>
                <span className="book-del__day">Fr</span>
                <span className="book-del__day">Sa</span>
                <span className="book-del__day">Su</span>
                { _.range(0, (getDay(setDate(this.state.month, 1))+6)%7).map(i=>
                    <span key={i}></span>
                ) }
                { _.range(1, 31).map(i=>{
                    var d = setDate(this.state.month, i);
                    if(isAllowed(d)) {
                        return <span className="book-del__date" onClick={ (ev)=>{
                            ev.preventDefault();
                            this.setState({'day': d });
                        } } data-selected={ isEqual(this.state.day, d) || undefined } key={10+i} >{ i }</span>
                    } else {
                        return <span className="book-del__date" data-unavailable={true} key={100+i}>{ i }</span>
                    }
                }) }
            </div>

            <div className="book-del__actions">
                <Button onClick={ this.handleSubmit.bind(this) } delay={ 2000 } disabled={this.state.day==null}>Confirm delivery</Button>
            </div>
        </Popup>;
    }

    handleSubmit(ev) {
        ev.preventDefault();

        if(this.state.day) {
            return this.props.onNext('complete', {
                'deliveryDay': this.state.day,
            });
        }
    }
}

class ChoosePreferredDatesPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        var valid_dates = [];
        var day = addDays(addBusinessDays(new Date(), 3), 1);
        for(var i=0;i<100;i++) {
            if(isSunday(day)) day = addDays(day, 1);
            valid_dates.push(day);
            day = addDays(day, 1);
        }

        return <Popup onClose={ this.props.onClose } className="book-del__popup">
            <form method="post" className={ this.state.submitting ? 'submitting' : '' } onSubmit={ this.handleSubmit.bind(this) }>
                <h2>Book a delivery</h2>
                <p>To request a delivery, simply enter three preferred dates below.</p>

                <div className="book-del__popup-padding">
                    <div className="form-row">
                        <label>First preference</label>
                        <DatePicker
                            includeDates={valid_dates}
                            selected={ this.state.deliveryDate1 }
                            onChange={ date => this.setState({'deliveryDate1': date}) }
                            customInput={<input required type="text" />}
                            dateFormat="dd/MM/yyyy"
                            />
                    </div>
                    <div className="form-row">
                        <label>Second preference</label>
                        <DatePicker
                            includeDates={valid_dates}
                            selected={ this.state.deliveryDate2 }
                            onChange={ date => this.setState({'deliveryDate2': date}) }
                            dateFormat="dd/MM/yyyy"
                            />
                    </div>
                    <div className="form-row">
                        <label>Third preference</label>
                        <DatePicker
                            includeDates={valid_dates}
                            selected={ this.state.deliveryDate3 }
                            onChange={ date => this.setState({'deliveryDate3': date}) }
                            dateFormat="dd/MM/yyyy"
                            />
                    </div>
                </div>
                <p>We will be in touch shortly to confirm your delivery date. Please note that your delivery is not confirmed until you have received email confirmation from one of our team. For any changes to your delivery preferences, please contact our <a href="/contact-us/" target="_blank">Customer Care team</a> (please note we are unable to change a delivery 48 hours prior to your confirmed date).</p>
                <div className="book-del__actions">
                    <button type="submit" disabled={!this.state.deliveryDate1 || !this.state.deliveryDate2 || !this.state.deliveryDate3 }>Submit</button>
                </div>
            </form>


        </Popup>;
    }

    handleSubmit(ev) {
        ev.preventDefault();

        if(!!this.state.deliveryDate1 && !!this.state.deliveryDate2 && !!this.state.deliveryDate3) {
            return this.props.onNext('complete_preferred', {
                'deliveryDate1': this.state.deliveryDate1,
                'deliveryDate2': this.state.deliveryDate2,
                'deliveryDate3': this.state.deliveryDate3,
            });
        }
    }
}

class ConfirmationPopup extends React.Component {
    render() {
        var daddr = this.props.deliveryAddress;

        var name = [
            daddr.firstName,
            daddr.surname,
        ].filter(i=>i).join(" ");
        
        var addr = [
            name,
            daddr.address1,
            daddr.address2,
            daddr.address3,
            daddr.town,
            daddr.postcode,
            daddr.country,
            daddr.phoneNumber,
        ].filter(i=>i);

        return <Popup onClose={ this.props.onClose } className="book-del__popup">
            { !!this.props.deliveryDay ? <React.Fragment> 
                <h3 className="book-del__dark">Your delivery has been booked { format(this.props.deliveryDay, "do MMMM yyyy") }</h3>
                <p>We have sent you an email with all your delivery information. On the day of your delivery, our couriers will be in touch to confirm a delivery time slot and tracking information.</p>
            </React.Fragment> : <React.Fragment> 
                <h3 className="book-del__dark">Your delivery has been requested</h3>
                <p>Thank you, your request has been received. A member of our team will be in touch shortly.</p>
            </React.Fragment> }
               
            <h3>Your delivery address</h3>
            <p>{ addr.map((s, i)=><span key={i}>{ s }<br /></span>) }</p>
            <p className="small">To make any changes to your delivery please <a href="/contact-us/" target="_blank">contact our customer care team</a>. Please note that we are unable to change a delivery 48 hours prior to the selected&nbsp;date.)</p>            
        </Popup>;
    }
}