import React from 'react';

import { Popup } from '../components/Popup.jsx';
import { cookies, do_post } from '../utils.jsx';

export class ChooseListTypePopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            changeToFixed: null
        };
    }

    render() {
		var nprops = {'errors':this.state.popupErrors || {}, 'values':this.state.popupData || {}, 'context':{'focused':false}};

        return <Popup onClose={this.handleClose.bind(this)} className="list-type-popup">
                <h2>Choose your list type</h2>
                <h4>Fixed List</h4>
                
                <p>A Fixed list is an excellent choice for couples who know exactly what they want, and want it delivered as soon as possible after their wedding. When a present is bought by a guest, it will automatically be sent through to our purchasing team, who will then place your order (unless the item is part of an incomplete set). You can keep track of your orders from your online dashboard and arrange delivery when you’re ready. This option does mean that once a guest has purchased a present, you cannot exchange it.</p>

                <h4>Flexible List</h4>
                
                <p>A Flexible list gives you complete flexibility to change your mind, even after a guest has bought you a present and your list is closed. Think of it like a bank account – your guests contribute towards presents on your list, and after your wedding you can review your list and choose which items you would like to order, using your pot of money. It does mean that your presents take slightly longer to arrive with you, as we won’t order anything until you choose to finalise and place your order.</p>

                <p>By default, when you register your list, it will be Flexible.</p>

                <div className="list-type-popup__checkboxes">
                    <label>Change to Fixed <input type="checkbox" className="green-checkbox" checked={ this.state.changeToFixed===true } onChange={ ()=>this.handleChange(true) } /></label>
                    <label>Stay Flexible <input type="checkbox" className="green-checkbox" checked={ this.state.changeToFixed===false } onChange={ ()=>this.handleChange(false) } /></label>
                </div>
                <div className="list-type-popup__actions">
                    <button type="button" disabled={ this.state.changeToFixed===null } onClick={ this.handlePopupSubmit.bind(this) }>Confirm</button>
                </div>
            </Popup>;
    }

    handleClose(ev) {
        if(this.props.onClose) this.props.onClose();
    }

    handleChange(v) {
        if(this.state.changeToFixed===v) {
            v = null;
        }
        this.setState({
            changeToFixed: v
        });
    }

    handlePopupSubmit(ev) {
        if(this.state.changeToFixed===null) {
            return;
        }
        
		do_post(window.wpco_api_prefix + 'GiftList/ToggleListFixedStatus', {
            setFixed: this.state.changeToFixed
        }, (ret)=>{
            console.log(ret);
			if(ret.responseType!=1) {
				create_alert(ret.message || "Unknown error.");
			} else {
				window.location.reload();
                return;
			}
		});
	}
}