import React from 'react';
var _ = require('lodash');

import { countries } from '../countries.js';

import { Button } from '../components/Button.jsx';
import { FormInput } from '../components/Forms.jsx';

export class EditDeliveryAddressForm extends React.Component {
	constructor(props) {
		super(props);
		this.div = React.createRef();
	}

	render() {
		var props = {
			values: this.props.deliveryAddress,
			errors: this.props.errors,
			context: {'focused':false}, 
		};

		var country_choices = _.map(countries, (c)=>[c.Name, c.Name]); //no longer c.ISO2
		var rem = _.remove(country_choices, (c)=>(c[0]=='United Kingdom'));
		country_choices = _.concat(rem, country_choices);
		
		return <div className="pototals__address-form" ref={ this.div }>
			<FormInput label="Address 1" name="address1" required={true} {...props} />
			<FormInput label="Address 2" name="address2" required={false} {...props} />
			{ !!this.props.showAddress3 && <FormInput label="Address 3" name="address3" required={false} {...props} /> }
			<FormInput label="Town" name="town" required={true} {...props} />
			<FormInput label="Post Code" name="postcode" required={true} {...props} />
			<FormInput label="Country" name="country" type="select" choices={country_choices} required={true} {...props} />
			{ !!this.props.showPhone && <FormInput label="Phone No" name="phoneNumber" required={true} {...props} /> }
			{ this.props.onSave && <div className="pototals__address-form-actions">
				{ this.props.onCancel && <Button type="button" onClick={ (ev)=>{ return this.props.onCancel(); } }>Cancel</Button> }
                <Button type="button" onClick={ (ev)=>{ return this.props.onSave({
					"address1": this.div.current.querySelector("[name=address1]")?.value,
					"address2": this.div.current.querySelector("[name=address2]")?.value,
					"address3": this.div.current.querySelector("[name=address3]")?.value || undefined,
					"town": this.div.current.querySelector("[name=town]")?.value,
					"postcode": this.div.current.querySelector("[name=postcode]")?.value,
					"country": this.div.current.querySelector("[name=country]")?.value,
					"phoneNumber":  this.div.current.querySelector("[name=phoneNumber]")?.value || undefined,
				}); } }>Save</Button>
				
            </div> }
		</div>;
	}
}
