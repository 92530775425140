import React from 'react';

import { do_get_cached } from '../utils.jsx';

import { ListItemLink } from '../components/ListItemLink.jsx';

export class DashboardMenu extends React.Component {
	constructor(props) {
		super(props);
		this.state = {'open':false, 'hasOrders':!!window.localStorage.show_account_orders};

		do_get_cached(window.wpco_api_prefix + 'Manage/TrackYourOrdersv2',
			{},
			(ret)=>{
				window.localStorage.show_account_orders = (ret.orderItems||[]).length>0;
				this.setState({
					'hasOrders': (ret.orderItems||[]).length>0
				});
			}
        );
	}
	
	render() {
		return <div className="three columns">
			<ul className={"side-menu" + (this.state.open?' open':'')} onClick={ this.handleToggleOpen.bind(this) }>
				<ListItemLink to="/my-list/" label="My List" />
				<ListItemLink to="/my-list/account/" exact={false} label="Account" link={false}>
					<ul>
						<ListItemLink to="/my-list/account/financial/" exact={false} label="Financial summary" />
						<ListItemLink to="/my-list/account/admin/" label="Admin" />
					</ul>
				</ListItemLink>
				<ListItemLink to="/my-list/thank-you-list/" label="Thank you List"></ListItemLink>
				<ListItemLink to="/my-list/place-order/" label="Place an order"></ListItemLink>
				{ this.state.hasOrders && <ListItemLink to="/my-list/my-orders/" label="My orders"></ListItemLink> }
				<ListItemLink to="/my-list/help/" label="Help &amp; Support"></ListItemLink>
			</ul>
		</div>;
//						<ListItemLink to="/my-list/account/messages/" exact={false} label="Messages" />
	}
	
	handleToggleOpen(ev) {
		if(!this.state.open) ev.preventDefault();
		this.setState({'open':!this.state.open});
	}
}
