import React from 'react';
import ReactDOM from 'react-dom';
import { Link, NavLink } from 'react-router-dom';
var $ = require('jquery');
var _ = require('lodash');

import { bothNamesToSummary, cookies, decodeID, do_get, do_get_cached, do_post, formatPrice, get_url_param, MoneyInput, myParseFloat, myParseInt, nl2br, set_wpsessid, rewrite_image_url, set_wpcartid } from '../utils.jsx';
import { 
	isGiftCard,
	isMoneyQuantity,
	getItemCategory, 
	getPledgeBasketQuantity,
	getProductBrand,
	getProductImage,
	shouldShowBrand,
	shouldShowCashChargeWarning,
	shouldShowPrice,
	sortedFilteredListItems 
} from '../logic.jsx';

import { AddToBasket } from '../components/AddToBasket.jsx'; 
import { CashHandlingFeeNotice } from '../components/CashHandlingFeeNotice.jsx'; 
import { MyForm, FormInput } from '../components/Forms.jsx';
import { HeaderBasket } from '../components/HeaderBasket.jsx';
import { ListFilterByDropdown } from '../components/ListFilterByDropdown.jsx';
import { ListFilterByText } from '../components/ListFilterByText.jsx';
import { ListNotOpenPopup } from '../components/ListNotOpenPopup.jsx';
import { Popup } from '../components/Popup.jsx';
import { PriorityWidget } from '../components/PriorityWidget.jsx';
import { ProductInfo } from '../components/ProductInfo.jsx';
import { ViewBasket } from '../components/ViewBasket.jsx';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat);
var jwt = require('jwt-simple');

export class ViewList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			'list':null, 
			'sortOrder':'', 
			'search':null,
			'quantities':{}, 
			changedQuantities: {},
			'viewingBasket':false, 
			'view':'image',//window.innerWidth <= 1200 ? 'image' : 'list', 
			'ref':get_url_param('ref')||undefined
		};
		
		this.fetchList();
//		this.fetchCart(); TODO
		
		this.componentWillReceiveProps(props, true);
	}
	
	componentWillReceiveProps(nextProps, forceUpdate) {
		var oldpresid = this.props ? this.props.location.pathname.split("/")[3] : null;
		var presid = nextProps.location.pathname.split("/")[3];
		if(presid && presid!="basket" && (presid!=oldpresid || forceUpdate)) {
			do_get_cached(window.wpco_api_prefix + 'Product/Get/' + presid, {},
				(ret)=>{
					this.setState({'product': ret.product, 'productId':presid});
				}
			);
		}
	}

	componentDidMount() {
		this.headerBasketPortalContainers = [];
		this.headerBasketPortalContainersCleared = false;

		var el = document.querySelector('.header .menu-top li.basket');
		if(el) {
			this.headerBasketPortalContainers.push(el);
		} else {
			var menu = document.querySelector('.header .menu-top');
			if(menu) {
				var el = document.createElement('li');
				menu.appendChild(el);
				this.headerBasketPortalContainers.push(el);
			}
		}

		var el = document.getElementById('menu-basket-link');
		if(el) {
			this.headerBasketPortalContainers.push(el);
		} else {
			var mol = document.getElementById('menu-open-link');
			if(mol) {
				var el = document.createElement('a');
				mol.after(el);
				this.headerBasketPortalContainers.push(el);
			}
		}


	}
	
	fetchList() {
		do_get('/api/list/' + this.props.listId + '/', {}, (ml)=>{
			var st = {'customOrder':ml.custom_order};
			if(!this.state.sortOrder) st.sortOrder = 'custom';
			this.setState(st);
		});

		do_post(window.wpco_api_prefix + 'Pledge/View',
			{
				'id':this.props.listId,
				'password':this.state.password
			},
			(ret)=> {
				if(ret.loginRequired) {
					this.setState({'needsPassword':true});
				} else if(ret.responseType!=1) {
					this.setState({'error':ret.message || "Unknown error.", 'needsPassword':false});
				} else {
					if(ret.pledgeBasketSessionKey) cookies.set('pledgeBasketSessionKey', ret.pledgeBasketSessionKey, {path:'/', maxAge:14*86400, secure:true});

					ret.bgID = this.props.listId; //transfer across
					this.setState({'list':ret, 'error':null, 'needsPassword':false}, ()=>{
						if(ret.pledgeBasketToken) {
							this.updateBasketToken(ret.pledgeBasketToken);
						}
					});
				}
			}
		);
	}
	handleLogin(password) {
		this.setState({'password':password, 'needsPassword':false, 'loginAttempt':(this.state.loginAttempt||0)+1}, ()=>{
			this.fetchList();
		});
	}
	
	hasCart() {
		return this.state.cart || cookies.get('wpcartid');
	}
		
	getMaxQuantity(prod) {
		if(prod.isUnlimited || prod.required>50000) return 99999;
		return myParseInt(prod.remaining);
	}

	render() {
		var bits = this.props.location.pathname.split("/");
		var viewingBasket = bits[3]=="basket";
		var viewingProduct = (bits[3] && bits[3]!="basket") ? decodeID(bits[3]) : null;

		if(this.state.needsPassword) return <ViewListLogin onLogin={ this.handleLogin.bind(this) } showError={ this.state.loginAttempt } />;

		var header_basket = [];
		if((this.headerBasketPortalContainers || []).length && this.state.pledgeBasketToken) {
			var quantity = getPledgeBasketQuantity(this.state.pledgeBasketToken, this.state.list.giftList);
			if(!this.headerBasketPortalContainersCleared) {
				// on first render, clear portals of existing basket
				_.map(
					this.headerBasketPortalContainers,
					el=>el.innerHTML=''
				);
				this.headerBasketPortalContainersCleared = true;
			}
			header_basket = _.map(
				this.headerBasketPortalContainers,
				el=>ReactDOM.createPortal(
					<HeaderBasket listId={ this.props.listId } quantity={ quantity }/>
				, el)
			);
		}

		if(this.state.error) return <div className="loading-error">{ header_basket }Error: { this.state.error }</div>;
		if(!this.state.list) return <div key="loading-spinner" className="loading-spinner">{ header_basket }</div>;
		if(this.state.list.giftList.stateId<3) return <ListNotOpenPopup 
			onClose={()=>{window.location.href='/find-list/';}}
			listTitle={this.state.list.giftList.salutation}
			weddingDate={this.state.list.giftList.occasionDate}
			giftListId={this.state.list.giftList.giftListId}		
			/>

		if(viewingBasket) {
			return <React.Fragment>
				{ header_basket }
				<ViewBasket 
					list={this.state.list} 
					password={this.state.password}
					pledgeBasketToken={this.state.pledgeBasketToken}
					onRemove={ this.handleRemove.bind(this) }
					onBack={ ()=>{this.setState({'viewingBasket':false});}}
					onUpdateQuantities={ this.handleUpdateQuantities.bind(this) } 
					onUpdateBasketToken={ (t)=>this.updateBasketToken(t) }
					/>
				</React.Fragment>;
		}

		var getCartItem = (itemId)=>{
			if(!this.state.pledgeBasketToken) return null
			return _.find(this.state.pledgeBasketToken.BasketItems, (ci)=>(ci.PledgeItem.ItemId==itemId));
		};
		
		var genQuantityInput = (prod)=>{
			var maxqty = this.getMaxQuantity(prod);
			maxqty = Math.min(maxqty, 180); //arbitrary maximum
			if (maxqty > 0 || isGiftCard(prod)) {
				if(isMoneyQuantity(prod)) {
					return <MoneyInput className="quantity" value={ this.state.quantities[prod.itemId]!==undefined?this.state.quantities[prod.itemId]:"0"} onChange={ this.handleQuantityChange.bind(this, prod) } placeholder="0.00" />;
				} else {
					return <select className="quantity" value={ this.state.quantities[prod.itemId]||"1"} onChange={ this.handleQuantityChange.bind(this, prod) }>{ _.map(_.range(1, maxqty+1), (i)=>(<option key={i} value={i}>{i}</option>)) }</select>;
				}
			}
			return "";
		};

		var genAdd = (prod, ci)=>{
			var maxqty = this.getMaxQuantity(prod) - (ci?ci.quantity:0);
			if(ci) {
				if(maxqty>0 || isGiftCard(prod)) {
					return <a href="#" onClick={ this.handleAdd.bind(this, prod) }>Add more</a>;
				}
				return "Added";
			} else if(maxqty>0 || isGiftCard(prod)) {
				return <a href="#" onClick={ this.handleAdd.bind(this, prod) }>{ prod.isGroupGift ? "Contribute" : "Add" }</a>;
			}
		};

		if(viewingProduct) {
			var giftListItem = _.find(
				this.state.list.giftList.items,
				(i)=>i.id == viewingProduct
			);
			var ci = getCartItem(giftListItem.itemId);
			//addControls={ genAdd(giftListItem, ci) } 
			console.log(giftListItem, ci); 
			return <div className="container">
				{ header_basket }
				{ viewingProduct==this.state.productId && <ProductInfo 
					breadcrumb={(<div className="breadcrumb"><a href="/">Home</a> / <Link to="../">{ this.state.list.giftList.salutation }</Link> / <strong>{ this.state.product.description }</strong></div> )}
					disableBrandLink={ true }
					//presentID={this.state.productInfoID} 
					product={this.state.product}

					giftListItem={ giftListItem } 
					cartItem={ ci }
					onAddToBasket={ this.handleAdd.bind(this, giftListItem) }
					/> }
				</div>;
		}
		
		var genSort = (n, label)=>{
			if(this.state.sortOrder==n) {
				return label;
			} else {
				return <a href="#" onClick={ (ev)=>{ev.preventDefault();this.setState({'sortOrder':n})} }>{ label }</a>
			}
		};
		
		var genView = (n, label)=>{
			if(this.state.view==n) {
				return label;
			} else {
				return <a href="#" onClick={ (ev)=>{ev.preventDefault();this.setState({'view':n})} }>{ label }</a>
			}
		};
		
		var cart_value = 0;
		var cart_qty = 0;
		
		if(this.state.pledgeBasketToken) {
			cart_value = this.state.pledgeBasketToken.pledgeValue;
			cart_qty = getPledgeBasketQuantity(this.state.pledgeBasketToken, this.state.list.giftList);
		}
		
		var date = dayjs(this.state.list.giftList.occasionDate, 'DD/MM/YYYY');
		var days_until = Math.max(date.diff(dayjs(), 'days'), 0);
		date = date.format("D MMM YYYY");

		var vprops = null;
		if(this.state.addingVoucher) {
			vprops = {'errors':this.state.voucherErrors || {}, 'values':this.state.voucherData || {}, 'context':{'focused':false}};
		}
		
		
		
		/*
									<dt className="remove"><a href="#" onClick={ this.handleStartRemove.bind(this, prod) }>Remove</a></dt>
									<dd className="edit"><a href="#" onClick={ this.handleStartEdit.bind(this, prod) }>Edit</a></dd>

		 * */
		
		var pic_url = this.state.list.giftList.listImage || "/assets/images/profile.jpg";// ? ("/media/render/profile/r/" + this.state.list.imagePath) : "/assets/images/profile.jpg";
		
		//<strong>Sort by:</strong> { genSort('Category', 'Category') } | { genSort('PriceLtoH', 'Price: Low to High') } | { genSort('PriceHtoL', 'Price: High to Low') } | { genSort('BGChoice', 'Couple’s Choice') } | { genSort('AtoZ', 'A-Z') } | { genSort('ZtoA', 'Z-A') }</React.Fragment>;
		var filters = <React.Fragment>
			<strong>View:</strong> { genView('image', 'Image View') } | { genView('list', 'List View') }<br />
			<div className="filter-by-bar">
				<ListFilterByText currentFilter={this.state.search} onFilter={ (n)=>{ this.setState({'search':n, 'sortFrozen':false}); } } />
				<ListFilterByDropdown 
					filters={[
						['purchased', 'Purchased'],
						['unpurchased', 'Not purchased'],
					]}
					currentFilter={this.state.filter}
					onFilter={ (n)=>{this.setState({'filter':n}) } }
					sorts={[].concat(
						(this.state.customOrder||[]).length ? [['custom', 'Default order']] : [],
						[['category', 'Category'],
						['', 'Couple\'s Choice'],
						['price asc', 'Price: Low to High'],
						['price desc', 'Price: High to Low'],
						['name', 'A-Z'],
					])}
					currentSort={this.state.sortOrder}
					onSort={ (n)=>{this.setState({'sortOrder':n}) } }
					/>
			</div>
			{ (this.state.filter || this.state.search) && 
				<a href="#" className="filter-by-clear" onClick={ (ev)=>{ ev.preventDefault(); this.setState({'filter':null, 'search':null}) } }>Clear Selection</a> }
		</React.Fragment>;
			
			
		
		if(this.state.customOrder) {
			this.state.list.giftList.items.forEach(p=>{
				var ind = this.state.customOrder.indexOf(""+p.itemId);
				p.customOrder = ind > -1 ? ind : 99999;
			});
		}

		var items = sortedFilteredListItems(
			this.state.list.giftList.items, 
			this.state.sortOrder, 
			this.state.filter,
			this.state.search
		);
		
		var productCount = items.length;// + (this.state.list.giftVoucherDisabled?0:1);

		var ret = <React.Fragment>
			{ header_basket }
			{ this.state.addingVoucher ? <Popup onClose={()=>{this.setState({'addingVoucher':false})}}>
				<h2>Add a Gift Voucher</h2>

				<MyForm ref="voucher_form" onSubmit={this.handleAddVoucherSubmit.bind(this)}>
				<FormInput label="Amount" name="value" required={true} validate="number" {...vprops} />
				<FormInput label="Your name" name="from" required={true} {...vprops} />
				<FormInput label="Your email (optional)" name="email" required={false} validate="email" {...vprops} />
				<FormInput label="Enter a message to accompany this voucher (optional):" name="message" type="textarea" required={false} {...vprops} />
				
				<div className="form-row"><button type="submit" className="right">Add to basket</button></div>
				</MyForm>
				
				</Popup>: '' }

			<div className="list-header-outer">
				<div className="container">
					<div className="list-header twelve columns">
						<img src={ pic_url } />
						<div className="title">
							<h2>{ this.state.list.giftList.salutation }</h2>
							<div className="date">{ date }</div>
						</div>
						<div className="nine columns container">
							<div className="nine columns bio"><p>{ nl2br(_.trim(this.state.list.giftList.listMessage)) }</p></div>
							<div className="three columns">
								<NavLink to="basket/" className="button">View Basket &amp; Checkout</NavLink>
							</div>
							<div className="four columns right">
								<p><strong>Need help?</strong> Call <a href="tel:+441488662100">01488 662100</a><br /> or email <a href="mailto:office@weddingpresentco.com">office@weddingpresentco.com</a></p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="dashline twelve columns">
					<div className="four columns container"><strong>{ days_until }</strong> days to wedding</div>
					<div className="four columns container"><strong>{ cart_qty }</strong> item{ cart_qty!=1 ? 's' : ''}</div>
					<div className="four columns container"><strong>£{ cart_value.toFixed(2) }</strong> my basket</div>
				</div>
			</div>
			
			<div className="container">
				<div className="twelve columns">
					{ this.state.view=="image" ?
					<div className="image-list">
						<div className="filters">{ filters }</div>
						<div className="products">
						{ _.map(items, (prod, k) => {
							var ci = getCartItem(prod.itemId);
							var maxqty = this.getMaxQuantity(prod);
							return <div className="product" key={k}>
								<Link to={ prod.id + '/' }><div className="thumbnail" style={ {backgroundImage:"url('" + getProductImage(prod, 150) + "')"} }></div></Link>
								{ prod.priority ? <PriorityWidget active={true} view="guest" /> : '' }
								<h3><Link to={ prod.id + "/" }>{ prod.description }</Link></h3>
								{ isGiftCard(prod) ?
									<p>&nbsp;<br />&nbsp;</p>
								:
									<p>{ shouldShowBrand(prod) && <a href={"/browse/by-brand/" + prod.brandSeoName + "/"}>{ getProductBrand(prod) }</a> }{ shouldShowBrand(prod) && <br /> }{ shouldShowPrice(prod) && <span className="price">{ prod.formattedPrice }</span> }</p> 
								}
								
								<dl>
									{ getItemCategory(prod) && <dt className="category">Category</dt> }
									{ getItemCategory(prod) && <dd className="category">{ getItemCategory(prod) }</dd> }
									{ shouldShowCashChargeWarning(prod) && <CashHandlingFeeNotice isGuest={ true } cashChargePercentage={ prod.cashChargePercentage } /> }
									<AddToBasket 
										giftListItem={ prod } 
										cartItem={ ci }
										onAdd={ this.handleAdd.bind(this, prod) }
										/>
								</dl>
								{ prod.isGroupGift && <div className="group-gift">
									Group Gift <div className="help hoverable">Contribute towards the cost of this present</div><br />
										{ prod.pledgeSummary }
								</div> }
							</div>;
						}) }
						{ false && !this.state.list.giftVoucherDisabled && <div className="product">
								<div className="thumbnail" style={{backgroundImage:'url("/assets/images/thumb-gift.jpg")'}}></div>
								<h3>Gift Voucher</h3>
								<p>If you would like to buy a gift voucher, <a href="#" onClick={ this.handleAddVoucher.bind(this) }>click here</a>.</p>
						</div> }
						{ (productCount>0) && _.map(_.range(3-((productCount-1)%4)), (i)=>(
							<div className={"product placeholder placeholder-"+(3-((productCount-1)%4)-i)} key={i}></div>
							)) }
						</div>
						{ productCount==0 && <p>No matching items found.</p> }
					</div>
						:
					<table className="result-list view-list found-list">
						<thead>
							<tr><td colSpan="6">{ filters }</td><td colSpan="3" className="right showing">Showing { this.state.filter ? 'matching' : 'all' } { items.length }</td></tr>
							<tr><th width="17.2%">Item</th><th width="3%"></th><th width="22%">Name</th><th width="12.75%">Category</th><th width="12.75%">Price</th><th width="8.5%">Required&nbsp;</th><th width="8.5%">Remaining&nbsp;</th><th width="8.5%">Quantity</th><th>Add</th></tr>
						</thead>
						<tbody>
							{ _.map(items, (prod, k) => {
								var ci = getCartItem(prod.itemId);
								var maxqty = this.getMaxQuantity(prod);

								return <React.Fragment key={k}>
									<tr className={ prod.isGroupGift ? "first-row" : "" }>
									<td className="thumbnail" rowSpan={ prod.isGroupGift ? 2 : 1 }>
										<Link to={ prod.id + "/" }><img src={ getProductImage(prod, 150) } width="100%" /></Link>
									</td>
									<td className="priority" rowSpan={ prod.isGroupGift ? 2 : 1 }>{ prod.priority ? <PriorityWidget active={true} view="guest" /> : '' }</td>
									<td className="description">
										<Link to={ prod.id + "/" }><h3>{ prod.coupleDescription || prod.description }</h3></Link>
										{ shouldShowBrand(prod) && <a href={"/browse/by-brand/" + prod.brandSeoName + "/"}>{ getProductBrand(prod) }</a> }
										{ shouldShowCashChargeWarning(prod) && <CashHandlingFeeNotice isGuest={ true } cashChargePercentage={ prod.cashChargePercentage } /> }
									</td>
									<td className="category">{ getItemCategory(prod) }</td>
									
									{ prod.isGroupGift ?
										<td className="price">Group&nbsp;Gift</td>
										:
										( !shouldShowPrice(prod) ? 
											<td className="center price"></td>
											:
											<td className="center price">{ prod.formattedPrice }</td>
										) 
									}

									<AddToBasket 
										giftListItem={ prod } 
										cartItem={ ci }
										onAdd={ this.handleAdd.bind(this, prod) }
										style="td"
										/>									
								</tr>
								{ prod.isGroupGift && <tr className="second-row is-group-gift">
									<td colSpan="2">Group Gift <div className="help hoverable">Contribute towards the cost of this present</div></td>
									<td colSpan="6">{ prod.pledgeSummary }</td>
								</tr> }</React.Fragment>;
							}) }
							{ false && !this.state.list.giftVoucherDisabled && <tr>
								<td className="thumbnail"><img src="/assets/images/thumb-gift.jpg" /></td>
								<td></td>
								<td colSpan="7"><h3>Gift Voucher</h3>If you would like to buy a gift voucher, <a href="#" onClick={ this.handleAddVoucher.bind(this) }>click here</a>.</td>
							</tr> }
							{ productCount==0 && <tr><td colSpan="9">No matching items found.</td></tr> }
						</tbody>
					</table>
					}
					<div className="twelve columns container right actions">
						<NavLink to="basket/" className="button blue wide">View Basket &amp; Checkout</NavLink>
					</div>
				</div>
			</div>
		</React.Fragment>;

		if(this.state.list && this.state.list.giftList && this.state.list.giftList.stateId > 3) {
			ret = <div className="list-overlay-container">
				<div className="overlay"><div className="popup"><p>{ this.state.list.giftList.salutation } have now closed their list. If you would like to purchase a gift please contact us at <a href="mailto:office@weddingpresentco.com">office@weddingpresentco.com</a> or <a href="tel:+441488622100">01488 622100</a>.</p></div></div>
				<div className="inner">
					{ ret }
				</div>
			</div>;
		}

		return ret;
	}
	
	handleQuantityChange(prod, ev) {
		var quan = this.state.quantities;
		var max = this.getMaxQuantity(prod);

		//if(prod.isFundProductType) max = max/myParseInt(prod.salePrice);

		if(prod.isGroupGift) max = myParseFloat(prod.remaining);

		if(ev.target.value=="") {
			quan[prod.itemId] = '';
		} else if(prod.isUnlimited || isGiftCard(prod)) {
			quan[prod.itemId] = parseInt(ev.target.value);
		} else {
			quan[prod.itemId] = Math.min(Math.max((parseInt(ev.target.value) || 0), 0), max);
		}
		this.setState({'quantities':quan});
	//	console.log(prod.presentID, ev.target.value);
	}
	
	handleRemove(itemId, ev) {
		this.updateQuantity(itemId, 0);
		// var quan = this.state.changedQuantities;
		// quan[prod.itemId] = 0;
		// console.log('handleRemove:',prod.itemId, quan);
		// this.setState({'changedQuantities':quan}, ()=>{
		// 	this.handleAdd(prod, null);
		// });
	}
	
	// quantities is {<itemId>: <qty>} dict
	updateQuantities(quantities) {
		var pairs = _.toPairs(quantities);
		
		var removeds = [];
		var addeds = [];
		
		var doStep = ()=>{
			if(pairs.length==0) {
				//done!
				//this.fetchList(); //to update qtyRemainings

				if(removeds.length>1) {
					var msg = "";
					msg += "<h3>Items removed</h3>";
					msg += "<p>The items have been removed from your basket.</p>";
					toastr.options.timeOut = 5000;
					toastr.info(msg);			
				} else if(removeds.length==1) {
					var msg = "";
					msg += "<h3>Item removed</h3>";
					msg += "<p>This item has been removed from your basket.</p>";
					toastr.options.timeOut = 5000;
					toastr.info(msg);			
				}
				
				if(addeds.length>0) {
					var msg = "<h3>Added to basket</h3>";
					
					_.each(addeds, pair=>{
						var item = _.find(this.state.list.giftList.items, i=>(i.itemId==pair[0]));
						if(!item) {
							// not in giftlist, eg. upsell? ignore
						} else if(item.isGroupGift) {
							msg += "<p>" + (item.description||"") + "</p>";
						} else if(isMoneyQuantity(item)) {
							msg += "<p>&pound;" + pair[1] + ' ' + (item.description||"") + " in basket</p>";
						} else {
							msg += "<p>" + pair[1] + 'x ' + (item.description||"") + " in basket</p>";
						}
					});
					/*
					if(prod.isGroupGift) {
						msg += "<p>" + (prod.description||"") + "</p>";
					} else {
						msg += "<p>" + qty + "x " + (prod.description||"") + "</p>";
					}
					if(prod.price) {
						msg += "<p>&pound;" + formatPrice(prod.price) + " each</p>";
					}

					msg += '<p class="cart-summary">' + cart.cartItemCount + ' item' + (cart.cartItemCount>1?'s':'') + ' in Basket</p>';
					* */

					msg += '<p><a href="basket/" class="button">View Basket &amp; Checkout</a></p>';

					toastr.options.timeOut = 5000;
					toastr.info(msg);
					
				}

				return;
			}
			var pair = pairs.shift();
			
			
			do_post(window.wpco_api_prefix + 'Pledge/UpdatePledgeBasket', {
				'giftListId':this.state.list.giftList.giftListId,
				'itemId':pair[0],
				'quantity':pair[1],
				'remove':pair[1]==0
			}, (ret)=>{
				if(ret.responseType!=1) {
					create_alert(ret.message || 'The item could not be added to your basket.');
					return;
				}

				var existing_bi = _.find(this.state.pledgeBasketToken.pledgeItems, (i)=>(i.giftListItemId==pair[0]));

				// TODO: limit the amount added to stay within required?
				
				if(existing_bi && pair[1]==0) {
					removeds.push([pair[0], pair[1]]);
				} else if(existing_bi==null && pair[1]>0) {
					addeds.push([pair[0], pair[1]]);
				} else if(existing_bi && pair[1]>existing_bi.quantity) {
					//addeds.push([pair[0], pair[1] - existing_bi.BasketQuantity]);
					addeds.push([pair[0], pair[1]]);
				}
				
				if(ret.pledgeBasketReferenceToken) cookies.set('wppBRT', ret.pledgeBasketReferenceToken, {path:'/', maxAge:14*86400, secure:true});

				if(ret.pledgeBasketToken) {
					this.updateBasketToken(ret.pledgeBasketToken);
				}

				doStep();
			});
		};
		doStep();
		
	}
	
	updateQuantity(itemId, qty) {
		var quantities = {};
		quantities[itemId] = qty;
		this.updateQuantities(quantities);
	}
	
	handleAdd(prod, quantity) {
		if((prod.isGroupGift || isGiftCard(prod)) && !quantity) {
			toastr.options.timeOut = 3000;
			toastr.error("Please enter an amount to contribute.");
			return;
		}

		var getCartItem = (itemId)=>{
			if(!this.state.pledgeBasketToken) return null
			return _.find(this.state.pledgeBasketToken.pledgeItems, (ci)=>(ci.giftListItemId==itemId));
		};

		var ci = getCartItem(prod.itemId);
		var qty = quantity!==undefined ? quantity : 1;

		if(qty>0 && ci && ci.quantity==prod.remaining) {
			var msg = "";
			msg += "<h3>Already added</h3>";
			msg += "<p>You already have the maximum amount in your basket.</p>";
			toastr.options.timeOut = 5000;
			toastr.info(msg);	
			return;
		}

		if(ci) {
			qty += ci.quantity;
			if(!prod.isUnlimited && qty > prod.remaining) qty = prod.remaining;
		}

		this.updateQuantity(prod.itemId, qty);
	}

	updateBasketToken(token) {
		var tok = jwt.decode(token, 'asdfasdf', true);
		var pbt = JSON.parse(tok.pledgeBasketToken);

		//store basket summary in cookie for use in site menu
		var wplastbasket = pbt.giftListId + "_" + getPledgeBasketQuantity(pbt, this.state.list.giftList);
		if(cookies.get('wplastbasket')!=wplastbasket) {
			cookies.set('wplastbasket', wplastbasket, {path:'/', maxAge:14*86400});
		}

		this.setState({'pledgeBasketToken':pbt});
	}
	
	handleAddVoucher(ev) {
		ev.preventDefault();
		this.setState({'addingVoucher':true});
// 		do_post(window.wpco_api_prefix + '/api/post-add-voucher.a4d', 
// 			{
// 				'cartID':cartID,
// 				'bgid':this.props.listId,
// 				'bgPresentID':prod.bgPresentID,
// 				'quantity':this.state.quantities[prod.bgPresentID],
// 				'update':'true',
// 			}, (ret)=>{
// 			}
// 		);
				
	}
	
	handleAddVoucherSubmit(data, errors) {
		var hasErrors = _.find(errors, (v)=>(v!=null));

		if(errors.value==null && parseFloat(data.value) < 5) {
			errors.value = "Please enter an amount of £5.00 or greater.";
			hasErrors = true;
		}
		
		if(hasErrors) {
			this.setState({'voucherData':data, 'voucherErrors':errors});
			return;
		}
		
		var cartID = cookies.get('wpcartid');
		do_post(window.wpco_api_prefix + '/api/post-add-voucher.a4d', 
 			{
 				'cartID':cartID,
 				'bgid':this.props.listId,
				'value':data.value,
 				'email':data.email?data.email:null,
 				'from':data.from?data.from:null,
 				'message':data.message?data.message:null,
 				//'update':'true',
 			}, (ret)=>{
				if(ret.cartID) set_wpcartid(ret.cartID, this.props.listId);
				//if(ret.cartID) cookies.set('wpcartid', ret.cartID, {path:'/', maxAge:14*86400});
				if(ret.sessionID) set_wpsessid(ret.sessionID);
				//if(ret.sessionID) cookies.set('wpsessid', ret.sessionID, {path:'/', maxAge:14*86400});
				if(ret.errorText) {
					alert(ret.errorText);
				} else if(ret.warningText) {
				} else {
					this.setState({'voucherData':null, 'voucherErrors':null, 'addingVoucher':false});
				}
				this.fetchCart(ret.cartID)
 			}
 		);
		
	}
		
	handleViewBasket(ev) {
		ev.preventDefault();
		this.setState({'viewingBasket':true});
	}
	
	handleUpdateQuantities(quantities) {
		this.updateQuantities(quantities);
	}
}

class ViewListLogin extends React.Component {
	render() {
		console.log('showError is',this.props.showError);
		
		return <div className="container">
			<div className="eight columns container dialog center">
				<div className="nine columns">
					<h2 className="underlined">Welcome to <span>our</span> list</h2>
				</div>

				<div className="six columns">
					<form>
						<p>Please enter the password found on your invitation insert.</p>
						<div className={"form-row" + (this.props.showError?" error":"")}><input type="password" ref="password" autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" defaultValue="" />{ this.props.showError && <div className="error-detail">Password incorrect.</div>}</div>
						<div className="form-row"><button className="right" onClick={ this.handleLogin.bind(this) }>Next</button></div>
					</form>
				</div>
			</div>
		</div>;
	}
	
	handleLogin(ev) {
		ev.preventDefault();
		var p = this.refs.password.value;
		if(p) {
			if(this.props.onLogin) this.props.onLogin(p);
		}
	}
}
