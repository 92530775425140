import React from 'react';
import dayjs from 'dayjs';
import { NavLink } from 'react-router-dom';

import { Button } from '../components/Button.jsx';
import { HelpTooltip } from '../components/HelpTooltip.jsx';
import { Popup } from '../components/Popup.jsx';
import { SimilarProducts } from '../components/SimilarProducts.jsx';

import { do_get, get_similar_products_link } from '../utils.jsx';

export class Availability extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            'popupOpen': false,
            'showingSimilarProducts': false,
            'similarProductsLink': null,
            'cancelling': false,
            'cancelled': false,
        };
        this.queryDependencies();
    }

    queryDependencies() {
        var prod = this.props.product;
        if(!this.state.similarProductsLink && (prod.outOfStock || prod.deRange || prod.isDiscontinued)) {
            get_similar_products_link(this.props.productId, u=>{
                this.setState({similarProductsLink: u});
            });
        }
    }

    componentDidUpdate() {
        this.queryDependencies();
    }

    render() {
        var popup = null;
        var bar = null;
        var showButton = <a href="#" onClick={ this.handleShowInfo.bind(this) }>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" width="13" viewBox="0 0 13 13"><circle cx="6.5" cy="6.5" r="6.5" fill="#F1EEEB"/><path fill="#41414C" d="M5.61 3.39c0-.35.28-.63.63-.63s.63.28.63.63-.28.63-.63.63a.63.63 0 0 1-.63-.63ZM5.79 10V5.08h.9V10h-.9Z"/></svg>
        </a>;

        var isInFuture = !!this.props.isInFuture;
        var prod = this.props.product;
        // named differently in different APIs...
        var ordered = prod.qtyOrdered || prod.qtyOnOrder;
        var backInStockDate = prod.backInStockDate || prod.expectedBackInStockDate;

        if(prod.outOfStock===undefined) {
            console.log("Availability: outOfStock undefined");
        } else if(isInFuture) {
            // wedding is in the future, don't show scary OoS messages
        } else if(prod.outOfStock) {
            if(this.state.popupOpen) {
                popup = <Popup className="lighter centered" onClose={this.handleClose.bind(this)}>
                    <h2>Out of stock</h2>
                    { ordered > 0 ? 
                        <p>Sadly, this item is currently unavailable to order. You can <NavLink to="/my-list/my-orders/">cancel your order</NavLink> at any time before it arrives in our warehouse, and we'll credit your account.<br />
                        If you'd prefer to wait, we'll monitor the item's availability and update its status on your list.</p>
                        
                        : <p>Sadly, this item is currently unavailable to order. If you'd prefer to wait, we'll monitor the item's availability and update its status on your list.</p> 
                    }
                    { !!backInStockDate && <p>This item is expected to be back in stock on <strong>{ dayjs(backInStockDate).format("DD.MM.YY") }</strong>.</p> }
                    { !!this.state.similarProductsLink && <a href="#" className="button blue" onClick={ (ev)=>{ ev.preventDefault(); this.setState({'showingSimilarProducts': true}); } }>See similar products</a> }
                </Popup>;
            }

            if(this.props.showExtraOrderFeatures) {
                if(this.state.cancelling) {
                    popup = <Popup className="centered" onClose={this.handleCloseCancel.bind(this)}>
                        <h2>Cancel order</h2>
                        <p>Are you sure you would like to cancel this order?<br />Please kindly note, your balance may take a few minutes to update after cancelling.</p>
                        <Button className="button blue" onClick={ this.handleCancel.bind(this) }>Confirm</Button>
                    </Popup>;
                }

                bar = <React.Fragment>
                    { !!backInStockDate && <div className="availability-bar__pre-bar">
                        Estimated to be back in stock with our supplier { dayjs(backInStockDate).format("DD.MM.YY") } <HelpTooltip iconLabel="?">Please note, once this item is back in stock, it may take a few weeks to arrive at our warehouse.</HelpTooltip>
                    </div> }
                    <div className="availability-bar">
                        <div className="availability-bar__cols"><div>You can <a href="#" onClick={this.handleStartCancel.bind(this)}>cancel your order</a> for this item anytime and your account will be credited.</div>{ !!this.state.similarProductsLink && <a href="#" onClick={ (ev)=>{ ev.preventDefault(); this.setState({'showingSimilarProducts': true}); } }>See similar products</a> }</div>
                    </div>
                </React.Fragment>;
            } else {
                bar = <div className="availability-bar">
                    <div>Out of stock</div>
                    { showButton }
                </div>;
            }
        }

        if(!!this.props.ignoreDeRange) {
            // pass
        } else if(prod.deRange===undefined) {
            console.log("Availability: deRange undefined");
        } else if(prod.deRange) {
            if(this.state.popupOpen) {
                popup = <Popup className="lighter centered" onClose={this.handleClose.bind(this)}>
                    <h2>Limited availablilty</h2>
                    <p>This item has limited availability, so we would advise to secure your order before the stated date. After this date, the item will be discontinued from our offering, and we will be unable to place any further orders. Place your order now or explore similar alternative items.</p>
                    { !!this.state.similarProductsLink && <a href="#" className="button blue" onClick={ (ev)=>{ ev.preventDefault(); this.setState({'showingSimilarProducts': true}); } }>See similar products</a> }
                </Popup>;
            }
                
            bar = <div className="availability-bar">
                <div>
                    Limited availability{ !!prod.orderByDate && "." } <br />{ !!prod.orderByDate && <React.Fragment>Order by { dayjs(prod.orderByDate).format("DD.MM.YY") }</React.Fragment>}
                </div>
                { showButton }
            </div>;
        }

        if(prod.isDiscontinued===undefined) {
            console.log("Availability: isDiscontinued undefined");
        } else if(prod.isDiscontinued) {
            if(this.state.popupOpen) {
                console.log('discontinued prod is', prod);
                popup = <Popup className="lighter centered" onClose={this.handleClose.bind(this)}>
                    <h2>Discontinued</h2>
                    { ordered > 0 ? 
                        <p>Sadly, this item has been discontinued by our supplier. Please accept our sincere apologies for this, we know how much effort goes into choosing presents for your gift list.<br /><br />We have cancelled your order and credited your account. You can use this credit towards any gift from our brand partners.</p>

                        : <p>Sadly, this item has been discontinued by our supplier. We know how much effort goes into choosing presents for your gift list, so we selected similar alternative items for you.</p> 
                    }
                    { !!this.state.similarProductsLink && <a href="#" className="button blue" onClick={ (ev)=>{ ev.preventDefault(); this.setState({'showingSimilarProducts': true}); } }>See similar products</a> }
                </Popup>;
            }

            bar = <div className="availability-bar">
                <div>Discontinued</div>
                { showButton }
            </div>;
        }

        /*
        if(prod.knownLongLeadTime===undefined) {
            console.log("Availability: knownLongLeadTime undefined");
        } else if(prod.knownLongLeadTime) {
            bar = <div className="availability-bar">
                <div>Long lead time</div>
            </div>;
        }
        */
       
        if(this.state.showingSimilarProducts) {
            popup = <SimilarProducts 
                link={ this.state.similarProductsLink }
                onClose={ ()=>{ this.setState({'showingSimilarProducts': false, 'popupOpen':false}); } }
                />
        }

        return <React.Fragment>
            { popup }
            { bar }
        </React.Fragment>;
    }

    handleShowInfo(ev) {
        ev.preventDefault();
        this.setState({popupOpen:true});
    }

    handleClose() {
        this.setState({popupOpen:false});
    }

    handleStartCancel(ev) {
        ev.preventDefault();

        // already cancelled?
        if(this.state.cancelled) return;

        this.setState({cancelling: true});
    }

    handleCloseCancel() {
        this.setState({cancelling: false});
    }

    handleCancel(ev) {
        ev.preventDefault();

        return new Promise((resolve, reject)=>{
            do_get(window.wpco_api_prefix + 'Manage/CreditSalesOrder/' + this.props.product.id + '/' + this.props.product.quantity, {}, 
                (ret)=>{
                    this.setState({cancelling: false, cancelled: true});

                    var msg = "";
                    msg += "<h3>Order cancelled</h3>";
                    msg += "<p>This order has been cancelled.</p>";
                    toastr.options.timeOut = 5000;
                    toastr.info(msg);	
    
                    if(this.props.onCancel) this.props.onCancel();

                    resolve();
                }
            );
        });
    }
}