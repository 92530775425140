import React from 'react';
var _ = require('lodash');

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat);

import ReactCrop, {makeAspectCrop} from 'react-image-crop';

import { cookies, do_delete_with_body, do_get, do_post, do_post_cached, do_put, formatPrice, getCroppedImg, getGiftListSLA, mergeOrderedItems, myParseFloat, myParseInt, nl2br, redirect_to_login, rewrite_image_url } from '../utils.jsx';
import { 
	canMakeGroupGift,
	canRevertGroupGift,
	getExpectedDate,
	getItemCategory, 
	getListStatusText,
	getListTypeText,
	getListValue, 
	getProductBrand,
	getProductImage,
	getProductPageUrl,
	getPurchased,
	isAgeVerificationRequired,
	isGiftCard,
	isListTypeChangeable,
	isPendingCustomProduct,
	shouldAllowEdit,
	shouldShowBrand,
	shouldShowCashChargeWarning,
	shouldShowPrice,
	shouldShowRequiredRemaining,
	sortedFilteredListItems 
} from '../logic.jsx';


import { AgeRestrictionNotice } from '../components/AgeRestrictionNotice.jsx';
import { Availability } from '../components/Availability.jsx';
import { Button } from '../components/Button.jsx';
import { CashHandlingFeeNotice } from '../components/CashHandlingFeeNotice.jsx'; 
import { CheckBox } from '../components/CheckBox.jsx';
import { ChooseListTypePopup } from '../components/ChooseListTypePopup.jsx';
import { DashboardMenu } from '../components/DashboardMenu.jsx';
import { Discontinued } from '../components/Discontinued.jsx';
import { HelpTooltip } from '../components/HelpTooltip.jsx';
import { ListFilterByDropdown } from '../components/ListFilterByDropdown.jsx';
import { ListFilterByText } from '../components/ListFilterByText.jsx';
import { PendingCustomProductNotice } from '../components/PendingCustomProductNotice.jsx';
import { Popup } from '../components/Popup.jsx';
import { PriorityWidget } from '../components/PriorityWidget.jsx';


//<div className={"product" + (this.state.invalidatedItems[prod.itemId]?' loading':'') + (prod.isDiscontinued?' discontinued':'')} key={k}>

class SortableItem extends React.Component {
	render() {
		return <div className="product">{ this.props.children }</div>;
	}
}

var storeTableCellSizes = (el)=>{
	el.querySelectorAll('th,td').forEach(cell=>{
		cell.setAttribute('data-width', cell.getBoundingClientRect().width);
	});
	return el;
};

var applyTableCellSizes = (el)=>{
	el.querySelectorAll('th,td').forEach(cell=>{
		cell.setAttribute('width', cell.getAttribute('data-width'));
	});
	return el;
};

class SortableContainer extends React.Component {
	constructor(props) {
		super(props);
		this.containerRef = React.createRef();
	}

	render() {
		return React.createElement(
			this.props.tag || 'div',
			{
				className: this.props.className,
				ref: this.containerRef,
			},
			this.props.children
		);
		//return <div className={ this.props.className } ref={this.containerRef}>{ this.props.children }</div>;
	}

	componentDidMount() {
		this.mouseDownListener = (ev)=>{
			var target = (ev.touches && ev.touches.length) ? ev.touches[0].target : ev.target;

			if(!target.closest('.drag-handle')) return;

			var p = target.closest('.draggable');
			if(!p) {
				p = target.closest('.drag-with');
				if(p) p = p.previousElementSibling;
			}

			if(p) {
				ev.preventDefault();
				ev.stopPropagation();
				this.originals = [p];//, p.nextElementSibling];
				while(true) {
					var next = this.originals[this.originals.length-1].nextElementSibling;
					if(next && next.classList && next.classList.contains('drag-with')) {
						this.originals.push(next);
					} else {
						break;
					}
				}
				this.containerRef.current.addEventListener('mousemove', this.mouseMoveListener);
				this.containerRef.current.addEventListener('touchmove', this.mouseMoveListener);
			}
		};
		this.containerRef.current.addEventListener('mousedown', this.mouseDownListener);
		this.containerRef.current.addEventListener('touchstart', this.mouseDownListener);

		this.mouseUpListener = (ev)=>{
			if(this.containerRef.current) {
				this.containerRef.current.removeEventListener('mousemove', this.mouseMoveListener);
				this.containerRef.current.removeEventListener('touchmove', this.mouseMoveListener);
			}

			if(this.scroller) {
				clearTimeout(this.scroller);
				this.scroller = null;
			}

			if(this.clone) {
				this.clone.remove();
				this.clone = null;
			}

			if(this.originals) {
				this.originals.forEach(el=>{
					el.classList.remove('fade');
				});
				this.originals = null;
			}

			if(this.currentOrdering) {
				if(JSON.stringify(this.currentOrdering) != JSON.stringify(this.initialOrdering)) {
					if(this.props.onChange) this.props.onChange(this.currentOrdering);
				}
				this.currentOrdering = null;
			}
		};
		window.addEventListener('mouseup', this.mouseUpListener);
		window.addEventListener('touchend', this.mouseUpListener);

		this.mouseMoveListener = (ev)=>{
			ev = (ev.touches && ev.touches.length) ? ev.touches[0] : ev;

			if(!this.clone) {
				var rect = this.originals[0].getBoundingClientRect();

				this.clone = document.createElement('div');

				//var totalWidth = 0;
				var totalHeight = 0;
				this.originals.forEach(el=>{
					//totalWidth += el.getBoundingClientRect().width;
					totalHeight += el.getBoundingClientRect().height;
					this.clone.appendChild(applyTableCellSizes(storeTableCellSizes(el).cloneNode(true)));
					el.classList.add('fade');
				});

				this.clone.style.position = 'absolute';
				this.clone.style.left = 0;
				this.clone.style.top = 0;
				this.clone.style.width = (rect.width+2) + 'px';
				this.clone.style.height = totalHeight + 'px';
				this.clone.style.zIndex = 100;
				this.clone.classList.add('dragging');
				this.containerRef.current.appendChild(this.clone);

				var cr = this.containerRef.current.getBoundingClientRect();
				this.containerRect = {
					left: cr.left + window.scrollX,
					top: cr.top + window.scrollY,
					bottom: cr.top + cr.height + window.scrollY,
				};

				this.offsetX = ev.pageX - (rect.left + window.scrollX);
				this.offsetY = ev.pageY - (rect.top + window.scrollY);

				this.calculateDropTargets();
				this.initialOrdering = this.dropTargets.map(el=>el.element.getAttribute('data-id'));
			}

			if(this.clone) {
				var tx = (ev.pageX - this.containerRect.left - this.offsetX);
				var ty = (ev.pageY - this.containerRect.top - this.offsetY);

				var translate = ()=>{
					this.clone.style.transform = 'translate3d(' + tx + 'px, ' + ty + 'px, 0)';
				};
				translate();

				var scrollBy = (x, y)=>{
					x = parseInt(x); y = parseInt(y);
					if(x==0 && y==0) return;
					window.scrollBy(x, y);
					tx += x;
					ty += y;
					translate();
				};

				var scrollEase = (v)=>{
					//v = 100 / (175-Math.min(v, 172));
					v = ((v/15)**1.7);
					return Math.max(1, v);
				};

				var isAtTop = ()=>
					(window.scrollY < this.containerRect.top - 50);

				var isAtBottom = ()=>
					(window.scrollY > (this.containerRect.bottom-window.innerHeight) + 40);

				if((ev.pageY-window.scrollY) < 175 && (ev.movementY<0 || ev.movementY===undefined || this.scroller) && !isAtTop()) {
					//scroll up
					if(this.scroller) clearTimeout(this.scroller);
					var step = -scrollEase(175-(ev.pageY-window.scrollY));
					var scroll = ()=>{
						if(isAtTop()) return;
						scrollBy(0, step);
						//step -= 2;
						this.scroller = setTimeout(scroll, 30);
					};
					scroll();
					return;
				} else if(window.innerHeight - (ev.pageY-window.scrollY) < 175 && (ev.movementY>0 || ev.movementY===undefined || this.scroller) && !isAtBottom()) {
					// scroll down
					if(this.scroller) clearTimeout(this.scroller);
					var step = scrollEase(175 - (window.innerHeight - (ev.pageY-window.scrollY)));
					var scroll = ()=>{
						if(isAtBottom()) return;
						scrollBy(0, step);
						//step += 2;
						this.scroller = setTimeout(scroll, 30);
					};
					scroll();
					return;
				} else if(this.scroller) {
					clearTimeout(this.scroller);
					this.scroller = null;
				}
				
				var SNAP_THRESHOLD = 150*150; //is squared

				var x = ev.pageX - this.offsetX;
				var y = ev.pageY - this.offsetY;
				var bestDist = null;
				var best = null;
				this.dropTargets.forEach(dt=>{
					let dist = (dt.x-x)**2 + (dt.y-y)**2;
					if(dist < SNAP_THRESHOLD && (bestDist===null || dist<bestDist)) {
						bestDist = dist;
						best = dt;
					}
				});
				if(best) {
					this.moveOriginalTo(best.k);
				}
			}
		};
	}

	componentWillUnmount() {
		this.containerRef.current.removeEventListener('mousedown', this.mouseDownListener);
		this.containerRef.current.removeEventListener('touchstart', this.mouseDownListener);
		window.removeEventListener('mouseup', this.mouseUpListener);
		window.removeEventListener('touchend', this.mouseUpListener);
	}

	moveOriginalTo(index) {
		var parentNode = this.originals[0].parentNode;
		if(parentNode.childNodes[index]==this.originals[0]) {
			return;
		}
		this.originals.forEach(i=>parentNode.removeChild(i));
		if(index>=parentNode.childNodes.length) {
			this.originals.forEach(i=>parentNode.appendChild(i));
		} else {
			var before = parentNode.childNodes[index];
			this.originals.forEach(i=>parentNode.insertBefore(i, before));
		}
		this.calculateDropTargets();
		this.currentOrdering = this.dropTargets.map(el=>el.element.getAttribute('data-id'));
	}

	calculateDropTargets() {
		this.dropTargets = Array.from(this.originals[0].parentNode.childNodes).map((p, k)=>{
			if(p==this.clone
				|| !p.classList
				|| !p.classList.contains('draggable')
				) return;
			let rect = p.getBoundingClientRect();
			return {
				'k':k,
				'element':p,
				'x':rect.left + window.scrollX,// + rect.width*0.5,
				'y':rect.top +  + window.scrollY,// + rect.height*0.5,
			};
		}).filter(p=>p);
	}
}


export class MyList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			'list':null,
			'sortOrder':null, 
			'show':null, 
			'view':'image', //window.innerWidth <= 1200 ? 'image' : 'list', 
			'invalidatedItems': {},
			'lastPositions':null,
			'sortFrozen':false,
			'filter':null,
			'search':null,
			'fetchListRequestNumber':0,
			'urlCopied': false,
			'choosingListType': false,
		};
	}
	
	componentDidMount() { 
		this.fetchList(); //should this happen earlier?
	}
	
	
	fetchList() {
		do_get('/api/mylist/', {}, (ml)=>{
			var st = {'customOrder':ml.custom_order};
			if(this.state.sortOrder==null) st.sortOrder = 'custom';

			if(ml.site_member_message) {
				st.siteMemberMessage = ml.site_member_message;
			}

			this.setState(st);
		});

		var req = this.state.fetchListRequestNumber+1;
		this.setState({'fetchListRequestNumber':req}, ()=>{
			do_post(window.wpco_api_prefix + 'Manage/YourList',
				{}, 
				(ret)=> {
					//ignore update if another has already started
					if(this.state.fetchListRequestNumber>req) return;

					if(ret.loginRequired) {
						redirect_to_login('/my-list/');
					} else if(ret.responseType!=1) { //TODO
						alert(ret.message || "Unknown error");
					} else {
						ret.giftList.productsOnList = mergeOrderedItems(
							ret.giftList.productsOnList,
							ret.giftList.orderItems
						);



						this.setState({
							'list':ret, 
							'invalidatedItems':{},
							'fetchListRequestNumber':req
						});

						// do_post('/api/mylist/', {
						// 	'names':ret.giftList.salutation,
						// 	'weddingDate':ret.giftList.occasionDate
						// });
						
						/* add any temp basket items */
						var tb = JSON.parse(window.localStorage.tempBasket || "[]");
						if(!_.isEmpty(tb)) {

							var existing = {};
							_.each(ret.giftList.productsOnList, (prod)=>{
								existing[prod.id] = prod.required;
							});

							var reqs = _.map(_.filter(tb, (v)=>(v.id && v.quantity)), (v)=>(
								do_post(window.wpco_api_prefix + 'GiftListItem/AddUpdateGiftListItem', {
										'productId':v.id,
										'quantity':v.quantity + (existing[v.id]||0)
									})));
							Promise.all(reqs).then(()=>{
								window.localStorage.removeItem('tempBasket');
								this.fetchList();
							});
						}
						
						
					}
				}
			);
		});
	}
	
	/*
	mergeInOrderItems(items, orderItems) {
		var extra_items = [];
		_.each(orderItems, (oi)=>{
			var matching_items = _.filter(items, (i)=>(i.title==oi.product.title));
			var qty_fields = _.pick(oi, ['qtyOrdered', 'qtyInWarehouse', 'qtyDelivered', 'expectedDate']);
			if(matching_items.length==1) {
				//transfer orderItem data over to list item
				_.assign(matching_items[0], qty_fields);
			} else {
				//add a new item to the bottom
				extra_items.push(_.assign({}, oi.product, qty_fields));
			}
		});
		return [].concat(items, extra_items);
	}
	*/

	render() {
		if(!this.state.list) return <div key="loading-spinner" className="loading-spinner"></div>;
		
		var genSort = (n, label)=>{
			if(this.state.sortOrder==n) {
				return label;
			} else {
				return <a href="#" onClick={ (ev)=>{ev.preventDefault();this.setState({'sortOrder':n, 'sortFrozen':false}, ()=>{this.fetchList();})} }>{ label }</a>
			}
		};
		
		var genShow = (n, label)=>{
			if(this.state.show==n) {
				return label;
			} else {
				return <a href="#" onClick={ (ev)=>{ev.preventDefault();this.setState({'show':n}, ()=>{this.fetchList();})} }>{ label }</a>
			}
		};

		
		var genView = (n, label)=>{
			if(this.state.view==n) {
				return label;
			} else {
				return <a href="#" onClick={ (ev)=>{ev.preventDefault();this.setState({'view':n})} }>{ label }</a>
			}
		};
		
		//var filters = <React.Fragment><strong>View:</strong> { genView('image', 'Image View') } | { genView('list', 'List View') }<br /></React.Fragment>;
		//var free_text_filter = <ListFilterByText />;
		var dropdown_filters = null;
		if(listClosed) {
			dropdown_filters = (
				<ListFilterByDropdown 
					filters={[
						['delivered', 'Delivered'],
						['undelivered', 'Undelievered'],
					]}
					currentFilter={this.state.show}
					onFilter={ (n)=>{this.setState({'show':n}, ()=>{this.fetchList();}) } }

					/>
			);
			
		} else {
			dropdown_filters = (
				<ListFilterByDropdown 
					filters={[
						['purchased', 'Purchased'],
						['unpurchased', 'Not purchased'],
					]}
					currentFilter={this.state.filter}
					onFilter={ (n)=>{this.setState({'filter':n, 'sortFrozen':false}, ()=>{this.fetchList();}) } }
					sorts={[].concat(
						(this.state.customOrder||[]).length ? [['custom', 'Customised order']] : [],
						[['category', 'Category'],
						['price asc', 'Price: Low to High'],
						['price desc', 'Price: High to Low'],
						['', 'Couple\'s Choice'],
						['name', 'A-Z'],
					])}
					currentSort={this.state.sortOrder}
					onSort={ (n)=>{this.setState({'sortOrder':n, 'sortFrozen':false}, ()=>{this.fetchList();}) } }
					/>
			);
		}

		var filters = <React.Fragment>
			<strong>View:</strong> { genView('image', 'Image View') } | { genView('list', 'List View') }<br />
			<div className="filter-by-bar">
				<ListFilterByText currentFilter={this.state.search} onFilter={ (n)=>{ this.setState({'search':n, 'sortFrozen':false}); } } />
				{ dropdown_filters }
			</div>
			{ (this.state.filter || this.state.search) && 
				<a href="#" className="filter-by-clear" onClick={ (ev)=>{ ev.preventDefault(); this.setState({'filter':null, 'search':null, 'sortFrozen':false}, ()=>{this.fetchList();}) } }>Clear Selection</a> }
		</React.Fragment>;
					
		var giftListSLA = getGiftListSLA()
		
		var date = dayjs(this.state.list.giftList.occasionDate);
		
		var list_value = getListValue(this.state.list.giftList.productsOnList);
		var purchase_value = this.state.list.giftList.totalPledgedValue; //getPurchaseValue(this.state.list.giftList.productsOnList);

		// if wedding is in future, don't show OoS warnings
		var isInFuture = date.diff(dayjs(), 'days') >= 0;
		
		var days_until = Math.max(date.diff(dayjs(), 'days'), 0);
		date = date.format("D MMM YYYY");

		var pic_url = this.state.list.giftList.image || "/assets/images/profile.jpg";

		var listClosed = this.state.list.giftList.stateId>3;
		var listOpen = !listClosed;
		
		var maxqty = 36;
		
		var get_quantity_options = (maxqty, allow_unlimited)=>{
			var ret = _.range(1, maxqty+1).map(i=>([i,i]));
			if(allow_unlimited) ret.push([99999, 'Unlimited']);
			return ret;
		};
		
		if(this.state.customOrder) {
			this.state.list.giftList.productsOnList.forEach(p=>{
				var ind = this.state.customOrder.indexOf(""+p.itemId);
				p.customOrder = ind > -1 ? ind : 99999;
			});
		}

		var items = sortedFilteredListItems(
			this.state.list.giftList.productsOnList, 
			this.state.sortOrder, 
			this.state.filter,
			this.state.search
		);
		
		//items = this.mergeInOrderItems(items, this.state.orderItems);
		
		if(this.state.sortFrozen) {
			var prodKey = (p)=>(p.longDescription);
			//console.log('freezing, was', this.state.lastPositions, _.map(items, i=>prodKey(i)));
			items = _.sortBy(items, (p)=>(this.state.lastPositions[prodKey(p)]));
			//console.log('now', _.map(items, i=>prodKey(i)));
			
		}

		console.log('item is', items[0]);
		if(window.location.hash=="#test-discontinued" && items.length) {
			items[0].isDiscontinued = true;
		} else if(window.location.hash=="#test-derange" && items.length) {
			items.forEach(i=>{
				i.deRange = true;
				i.orderByDate = "2024-01-12T00:00:00";
			});
		} else if(window.location.hash=="#test-outofstock" && items.length) {
			items[0].outOfStock = true;
			items[0].expectedBackInStockDate = "2024-01-12T00:00:00";
		}
		
		var drag_handle = <div className="drag-handle-outer"><div className="drag-handle hoverable"><div className="inner">
			<svg viewBox="0 0 5.556 5.556" xmlns="http://www.w3.org/2000/svg" width="21" height="21"><path d="M2.78 0l-.992.717h.553v1.268h.878V.717h.553zM.72 1.786l-.718.992.717.992v-.554h1.267V2.34H.72zm4.121 0v.554H3.574v.877H4.84v.553l.717-.992zm-2.5 1.786v1.267h-.553l.992.717.992-.717H3.22V3.572z" clipRule="evenodd" fill="#d9d9db"/></svg>
			<h4>LIST ORGANISATION</h4><p>Organise your presents with a drag and drop.</p>
		</div></div></div>;

		var estimated_delivery_date = (item)=>{
			if(item.expectedDate) {
				return <div className="est-delivery">
					Est. Delivery: { item.expectedDate }
					<div className="help hoverable">This is the date that your item is due into our warehouse</div><span></span>
				</div>;
			} else if(item.qtyOrdered>0) {
				return <div className="est-delivery">
					Est. Delivery: pending&nbsp;&nbsp;&nbsp;
				</div>;
			} else {
				return "";
			}
		};

		return 	<div>
			{ this.state.editing && <Popup onClose={()=>{this.setState({'editing':null})}}>
				<h2>Edit product</h2>

				<form>
				
					{ !this.state.editing.isFundProductType && <div className="form-row">
						<label>Quantity:</label>

						{ false ?
							<MoneyInput className="quantity" value={ this.state.edits.required!==undefined ? this.state.edits.required : this.state.editing.required } onChange={ this.handleEditChange.bind(this, 'required') } /> :
							<select className="quantity" value={ this.state.edits.required!==undefined ? this.state.edits.required : this.state.editing.required } onChange={ this.handleEditChange.bind(this, 'required') }>
								{ _.map(get_quantity_options(maxqty, this.state.editing.isFundProductType || this.state.editing.isCustomProductFund), ([k,v])=>(<option key={k} value={k}>{v}</option>)) }
							</select>
						}
					</div> }
				
					{ this.state.editing.isFundProductType && <div className="form-row">
						<label>Personalise the name of this fund (optional):</label>
						<input type="text" 
							value={ 
								this.state.edits.coupleDescription!==undefined
								  ?	this.state.edits.coupleDescription 
								  : (this.state.editing.coupleDescription || this.state.editing.description )
							} 
							onChange={ this.handleEditChange.bind(this, 'coupleDescription') } />
					</div> }
				
					<div className="form-row"><button type="submit" className="right" onClick={this.handleEdit.bind(this, this.state.editing)}>Update product</button></div>
				</form>
				
				</Popup> }
			{ this.state.removing && <Popup onClose={()=>{this.setState({'removing':null})}}>
				<h2>Remove product</h2>
				
				{ this.state.removing.isGroupGift && this.state.removing.purchased>0 ? <React.Fragment>
					<p>This item has already received contributions so it cannot be removed.</p>

					<form>
					<button type="button" className="right narrow" onClick={()=>{this.setState({'removing':null})}}>Close</button>
					</form>
				</React.Fragment> : <React.Fragment>
					<p>Are you sure you want to remove this product from your list?</p>
				
					<form>
					<button type="button" className="right narrow" onClick={()=>{this.setState({'removing':null})}}>Cancel</button> <button type="button" className="right narrow" onClick={()=>{this.handleDelete(this.state.removing)}}>Remove</button>
					</form>
				</React.Fragment> }
				
				</Popup> }
			{ this.state.editingDetails && <Popup onClose={()=>{this.setState({'editingDetails':null})}}>
				<h2>Edit your details</h2>
				
				<form>
				<div className="form-row profile-picture">
					{ this.state.profileImageSrc && 
						<ReactCrop 
							src={ this.state.profileImageSrc } 
							onChange={ this.handleCropImage.bind(this) }
							onImageLoaded={ this.handleLoadedImage.bind(this) }
							crop={ this.state.profileImageCrop || {aspect: 1} } /> 
					}
					<label>Update profile picture
					<input type="file" accept="image/*" ref="image" onChange={this.handleUpdateImage.bind(this)} /></label>
				</div>
				<div className="form-row">
					<label>Customise your bio below, and add a personal message to your list for your guests to see:</label>
					<textarea ref="bio" defaultValue={ this.state.listSettings.websiteMessage } />
				</div>
				<div className="form-row"><Button type="submit" className="right" onClick={this.handleUpdateDetails.bind(this)}>Update</Button></div>
				</form>
				
				</Popup> }
			{ this.state.choosingListType && <ChooseListTypePopup giftListId={ this.state.list.giftList.id } onClose={()=>{this.setState({'choosingListType':false})}} /> }
		
 			<div className="mobile dashboard-menu">
 				<DashboardMenu />
			</div>

			<div className="list-header-outer">
				<div className="container">
					<div className="list-header twelve columns">
						<img src={ pic_url } />
						<div className="title">
							<h2>{ this.state.list.giftList.salutation } <a className="edit" href="#" onClick={ this.handleEditDetails.bind(this) }>Edit details</a></h2>
						</div>
						<div className="nine columns container">
							<div className="five columns">
								<div className="date">{ date }</div>
								<div className="bio"><p>{ nl2br(_.trim(this.state.list.giftList.listMessage )) }</p></div>
								<a href="/browse/" className="button">Add products to list</a>
							</div>
							<div className="four columns right">
								<div className="list-sharer">
									<strong>Share your gift list</strong> | <a href="#" onClick={ this.handleCopyUrl.bind(this) }>{ this.state.urlCopied ? 'Copied!' : 'Copy URL' }</a><br />
									<span>{ this.getListUrl() }</span>
								</div>
								<p><strong>Need help?</strong> Call <a href="tel:+441488662100">01488 662100</a><br /> or email <a href="mailto:office@weddingpresentco.com">office@weddingpresentco.com</a></p>
							</div>
						</div>
					</div>
				</div>
			</div>

			{ this.state.siteMemberMessage && 
				<div className="container">
					<div className="twelve columns member-message">
						<div dangerouslySetInnerHTML={ {__html:this.state.siteMemberMessage } } />
					</div>
				</div> 
			}
				
		

			<div className="container my-list-dash">
				<DashboardMenu />
				<div className="nine columns">
					<div className="dashline-header">
						<strong>List status:</strong> { getListStatusText(this.state.list.giftList) }
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						<strong>List type:</strong> { getListTypeText(this.state.list.giftList) }
						&nbsp;
						{ isListTypeChangeable(this.state.list.giftList) && <React.Fragment>
								<a href="#" onClick={ this.handleChooseListType.bind(this) }>Change</a> 
								{ this.state.list.giftList.isFixed
									? <HelpTooltip iconLabel="i" className="list-type-popup__tooltip">You have a Fixed list.<br />This means that when a guest buys you a present, it will automatically be sent to our purchasing team to order (unless it’s part of an incomplete set). You cannot change your mind once a present has been pledged by a guest.</HelpTooltip>
									: <HelpTooltip iconLabel="i" className="list-type-popup__tooltip">You have a Flexible list.<br />This means that you choose what you order, and when you order it - allowing you the flexibility to change your mind even after a present has been bought by a guest, or your list is closed.</HelpTooltip>
								}
							</React.Fragment>
						}
						<a href="/my-list/print/" target="_blank" className="print">Print list</a>
					</div>
					<div className="dashline row">
						<div className="three columns container"><strong>{ days_until }</strong> days to wedding</div>
						{ !isNaN(list_value) && <div className="three columns container"><strong>£{ list_value.toFixed(2) }</strong> list value</div> }
						<div className="three columns container"><strong>£{ purchase_value.toFixed(2) }</strong> value of purchases</div>
					</div>
				</div>
			</div>
			
			<div className="container">
				<div className="twelve columns">
					{ this.state.view=="image" ?
					<div className="image-list">
						<div className="filters">{ filters }</div>
						<SortableContainer className="products" onChange={ this.handleReorder.bind(this) }>
						{ _.map(items, (prod, k) => (
							<div className={"product draggable" + (this.state.invalidatedItems[prod.itemId]?' loading':'') + (prod.isDiscontinued?' discontinued':'')} index={k} key={'item-' + k + '-' + prod.itemId} data-id={ prod.itemId }>
							
								{ isGiftCard(prod) ?
									<div className="thumbnail" style={ {backgroundImage:"url('" + getProductImage(prod, 150) + "')"} }></div>
								:
									<a href={getProductPageUrl(prod)}><div className="thumbnail" style={ {backgroundImage:"url('" + getProductImage(prod, 150) + "')"} }></div></a>
								}
								<div className="relative"><PriorityWidget active={prod.isPriority} onClick={ this.togglePriority.bind(this, prod) } /></div>
								<h3>
									<a href={getProductPageUrl(prod)}>{ prod.description }</a>
								</h3>
								{ ( shouldShowBrand(prod) || shouldShowPrice(prod) ) && <p>{ shouldShowBrand(prod) && <a href={"/browse/by-brand/" + prod.brandSeoName + "/"}>{ getProductBrand(prod) }</a> }{ shouldShowBrand(prod) && <br /> }{ shouldShowPrice(prod) && <span className="price">{ prod.formattedPrice }</span>}</p> }
								{ listClosed ?
								<dl>
									<dt>Bought</dt>
									<dd>{ getPurchased(prod) }</dd>
									{ isGiftCard(prod) || prod.isFundProductType ? '' : <React.Fragment>
										<dt>Ordered</dt>
										<dd>{ prod.qtyOrdered || 0 }</dd>
										<dt className="date-expected">Date Expected <div className="help-hover">The date your item is due into our warehouse.</div></dt>
										<dd className="date-expected">{ getExpectedDate(prod) }</dd>
										<dt>Received</dt>
										<dd>{ prod.qtyInWarehouse || 0 }</dd>
										<dt>Delivered</dt>
										<dd>{ prod.qtyDelivered || 0 }</dd>
									</React.Fragment> }
								</dl> : <dl>
									{ shouldShowCashChargeWarning(prod) && <CashHandlingFeeNotice isGuest={ false } cashChargePercentage={ giftListSLA.cashCharge } /> }
									{ isAgeVerificationRequired(prod) && <AgeRestrictionNotice /> }
									{ isPendingCustomProduct(prod) && <PendingCustomProductNotice /> }
									{ getItemCategory(prod) && <dt className="category">Category</dt> }
									{ getItemCategory(prod) && <dd className="category">{ getItemCategory(prod) }</dd> }
									{ shouldShowRequiredRemaining(prod) && !prod.isGroupGift && <React.Fragment>
										<dt className="required">Required</dt>
										<dd>{ prod.required }&nbsp;</dd>
										<dt>Remaining</dt>
										<dd>{ prod.remaining }&nbsp;</dd> 
									</React.Fragment> }
									{ prod.canRemove && <dt className="remove"><a href="#" onClick={ this.handleStartRemove.bind(this, prod) }>Remove</a></dt> }
									{ shouldAllowEdit(prod) && <dd className="edit"><a href="#" onClick={ this.handleStartEdit.bind(this, prod) }>Edit</a></dd> }
								</dl> }
								<div className="group-gift">
									{ prod.isGroupGift ? 
										<React.Fragment>
											{ !canRevertGroupGift(prod) ? "Group Gift" : <React.Fragment><CheckBox onClick={ this.handleRemoveGroupGift.bind(this, prod) } checked={true} /> Remove Group Gift</React.Fragment> }
											<div className="contributed">{ prod.pledgeSummary }</div> 
										</React.Fragment>
									: ( listOpen && canMakeGroupGift(prod) && <React.Fragment><CheckBox onClick={ this.handleMakeGroupGift.bind(this, prod) } checked={false} /> Make Group Gift <div className="help hoverable">Allow your guests to split the cost of this present</div><span></span></React.Fragment> ) }
								</div>
								{ estimated_delivery_date(prod) } 
								{ drag_handle }
								{ <Availability product={prod} productId={prod.id} isInFuture={isInFuture} /> }
							</div>
						)) }
							
						{ (items.length>0) && _.map(_.range(3-((items.length-1)%4)), (i)=>(
							<div className="product placeholder" key={i}></div>
							)) }
						{ items.length==0 && (
							(this.state.filter || this.state.search) ? 
								<p>No matching items found.</p>
							  : <p>You have no items in your list.</p>
						 ) }
						</SortableContainer>
					</div>
						:
					<table className="result-list view-list my-list">
						<thead>
							<tr><td colSpan="6">{ filters }</td><td colSpan="3" className="right showing">Showing { this.state.filter ? 'matching' : 'all' } { items.length>1 && items.length } rows</td></tr>
							<tr>
								<th width="17.2%">Item</th>
								<th width="3%"></th>
								<th width="22%">Name</th>
								{ listClosed ? <React.Fragment>
									<th width="8%">Bought</th>
									<th width="8%">Ordered</th>
									<th width="11%">Date Expected <div className="help-hover">The date your item is due into our warehouse.</div></th>
									<th width="8%">Received</th>
									<th width="8%">Delivered</th>
								</React.Fragment> : <React.Fragment>
									<th width="12.75%">Category</th>
									<th width="12.75%">Price</th>
									<th width="9.6%">Required&nbsp;</th>
									<th width="7.7%">Remaining&nbsp;</th>
									<th width="9.5%" className="center">Remove</th>
									<th className="center">Edit</th>
								</React.Fragment> }
							</tr>
						</thead>
						<SortableContainer tag="tbody" onChange={ this.handleReorder.bind(this) }>
							{ _.map(items, (prod, k) => (
								<React.Fragment key={'item-' + k + '-' + prod.itemId}>
								<tr className={"first-row draggable" + (this.state.invalidatedItems[prod.itemId]?' loading':'') + (prod.isDiscontinued?' discontinued':'')} data-id={ prod.itemId }>
									<td className="thumbnail" width="17.2%" rowSpan="2">
										{ isGiftCard(prod) ?
											<img src={ getProductImage(prod, 150) } width="100%" />
										:
											<a href={getProductPageUrl(prod)}><img src={ getProductImage(prod, 150) } width="100%" /></a>
										}
									</td>
									<td className="priority" rowSpan="2"><PriorityWidget active={prod.isPriority} onClick={ this.togglePriority.bind(this, prod) } /></td>
									<td className="description">
										{ isGiftCard(prod) ?
											<h3>{ prod.coupleDescription || prod.description }</h3>
										:
											<React.Fragment><h3><a href={getProductPageUrl(prod)}>{ prod.coupleDescription || prod.description }</a></h3>{ shouldShowBrand(prod) && <a href={"/browse/by-brand/" + prod.brandSeoName + "/"}>{ getProductBrand(prod) }</a> }</React.Fragment>
										}
										{ shouldShowCashChargeWarning(prod) && <CashHandlingFeeNotice isGuest={ false } cashChargePercentage={ giftListSLA.cashCharge } /> }
										{ isAgeVerificationRequired(prod) && <AgeRestrictionNotice /> }
										{ isPendingCustomProduct(prod) && <PendingCustomProductNotice /> }
									</td>
									{ listClosed ? <React.Fragment>
										<td className="qty-bought">{ getPurchased(prod) }</td>
										{ (isGiftCard(prod) || prod.isFundProductType) ?
											<td colSpan="4"></td>
										: <React.Fragment>
											<td className="qty-ordered">{ prod.qtyOrdered || 0 }</td>
											<td className="date-expected">{ getExpectedDate(prod) }</td>
											<td className="qty-received">{ prod.qtyInWarehouse || 0 }</td>
											<td className="qty-delivered">{ prod.qtyDelivered || 0 }</td>
										</React.Fragment> }
									</React.Fragment> : <React.Fragment>
									<td>{ getItemCategory(prod) }</td>

									{ prod.isGroupGift ?
										<td className="price" colSpan="3">Group Gift</td>
										:
										<React.Fragment>
											<td className="price">{ !isGiftCard(prod) &&
												( prod.isFundProductType
												  ? '£'+((prod.purchased||0)*(prod.salePrice||0)).toFixed(2)
												  : prod.formattedPrice )
											}</td>
											{ shouldShowRequiredRemaining(prod) ? 
												<React.Fragment>
													<td className="qty-required">{ prod.required }</td>
													<td className="qty-remaining">{ prod.remaining }</td>
												</React.Fragment> 
											:
												<React.Fragment>
													<td className="qty-required"></td>
													<td className="qty-remaining"></td>
												</React.Fragment>
											}
										</React.Fragment>
									}
									
									<td className="center remove">{ prod.canRemove && <a href="" onClick={ this.handleStartRemove.bind(this, prod) }><picture><source type="image/svg+xml" srcSet="/assets/images/icon-delete.svg" /><img src="/assets/images/icon-delete.png" /></picture><span>Remove</span></a> }</td>
									<td className="center edit">{ shouldAllowEdit(prod) && <a href="#" onClick={ this.handleStartEdit.bind(this, prod) }>Edit</a> }</td>
									</React.Fragment> }
								</tr>
								<tr className={ "second-row drag-with" + (this.state.invalidatedItems[prod.itemId]?' loading':'') + (prod.isGroupGift?' is-group-gift':'') + (prod.isDiscontinued?' discontinued':'')}>
									{ prod.isGroupGift ? 
										<React.Fragment>
											<td colSpan="2">
												{ !canRevertGroupGift(prod) ? "Group Gift" : <React.Fragment><CheckBox onClick={ this.handleRemoveGroupGift.bind(this, prod) } checked={true} /> Remove Group Gift</React.Fragment> }
												{ estimated_delivery_date(prod) }
											</td>
											<td colSpan="6">
												{ prod.pledgeSummary }{ drag_handle }
											</td> 
										</React.Fragment>
									: ( listOpen && canMakeGroupGift(prod) ? 
										<td colSpan="8">
											<CheckBox onClick={ this.handleMakeGroupGift.bind(this, prod) } checked={false} /> Make Group Gift <div className="help hoverable">Allow your guests to split the cost of this present</div><span></span>
											{ estimated_delivery_date(prod) }
											{ drag_handle }
											{ <Availability product={prod} productId={prod.id} isInFuture={isInFuture} /> }
										</td> 
										: <td colSpan="8">
											{ estimated_delivery_date(prod) }
											{ drag_handle }
											{ <Availability product={prod} productId={prod.id} isInFuture={isInFuture} /> }
										</td> 
									) }
								</tr>
								</React.Fragment>
							)) }
							{ items.length==0 && <tr>
								<td colSpan="9">
									{ (this.state.filter || this.state.search) ? 
									"No matching items found."
							  	  : "You have no items in your list." }</td>
							</tr> }
						</SortableContainer>
					</table> }
				</div>
			</div>
		</div>;
	}
	
	togglePriority(prod, ev) {
		ev.preventDefault();
		this.freezeSort();
		this._saveProduct(prod, {'isPriority':prod.isPriority?false:true});
	}
	
	handleReorder(ids) {
		// console.log('order now', ids);
		// console.log('saving order', JSON.stringify(ids));
		do_post('/api/mylist/', {
			'custom_order':ids,
		}, ()=>{
			this.setState({'customOrder':ids});
		});
	}

	freezeSort() {
		if(this.state.sortFrozen) return;
		
		var items = sortedFilteredListItems(
			this.state.list.giftList.productsOnList, 
			this.state.sortOrder, 
			this.state.filter
		);
		
		//hard to find a persistent key!
		var prodKey = (p)=>(p.longDescription);
		this.setState({
			'lastPositions':_.fromPairs(_.map(items, (p, k)=>([prodKey(p), k]))),
			'sortFrozen':true
		});
	}
	
	_saveProduct(prod, changes) {
		//var prod = _.assign({}, prod, changes);
		var prod = _.assign(prod, changes); //actually update prod
		do_put(window.wpco_api_prefix + 'GiftListItem/GiftListItem', {
			'giftListItemId': prod.itemId,
			'isGroupGift': prod.isGroupGift,
			'isPriority': prod.isPriority,
			'coupleDescription':prod.coupleDescription,
			'quantity':prod.required
			},
			(ret)=>{
				//console.log('hmm, ret is', ret);
				if(ret.loginRequired) {
			 		window.location.href = '/login/?next=' + encodeURI('/my-list/');
			 	} else if(ret.responseType!=1) {
					 create_alert(ret.message || "Unknown error.");
			 	} else {
					
			 		this.fetchList();
			 		this.setState({'editing':null});
			 	}

			}
		);
		
		// if GroupGift-ness is toggled, blank some fields out until the refresh
		if(changes.isGroupGift) prod.pledgeSummary = ''; 
		else if(changes.isGroupGift===false) {
			prod.formattedPrice = '';
			prod.required = '';
			prod.remaining = '';
			prod.pledgeSummary = '';
			console.log('prod now', prod);
		}
		if(changes.required=="99999") {
			// blank out required/remaining until refresh
			prod.required = '';
			prod.remaining = '';
		}
		
		this.setState({'list':this.state.list}); //force a redraw
		//this.setState({'invalidatedItems': _.assign(this.state.invalidatedItems, {[prod.itemId]:true})});
	}
	
	handleMakeGroupGift(prod, ev) {
		this.freezeSort();
		this._saveProduct(prod, {'isGroupGift':true});
	}
	
	handleRemoveGroupGift(prod, ev) {
		this.freezeSort();
		this._saveProduct(prod, {'isGroupGift':false});
	}
	
	
	handleStartRemove(prod, ev) {
		console.log(prod, ev);
		ev.preventDefault();
		this.setState({'removing':prod});
	}
	
	handleDelete(prod) {
		do_delete_with_body(window.wpco_api_prefix + 'Manage/RemoveProductsFromList',
		 	{
		 		'itemIds':[prod.itemId],
		 	},
		 	(ret)=>{
		 		if(ret.loginRequired) {
					window.location.href = '/login/?next=' + encodeURI('/my-list/');
				} else if(ret.responseType!=1) {
			 		alert(ret.message || "Unknown error!");
			 	} else {
					this.fetchList();
				}
		 	}
		);
		//update the local state instantly
		this.state.list.giftList.productsOnList = _.filter(
			this.state.list.giftList.productsOnList, 
			(p)=>(p.id!=prod.id)
		);
		this.setState({
			'list':this.state.list,
			'removing':null
		});
	}
	
	handleStartEdit(prod, ev) {
		ev.preventDefault();
		this.setState({'editing':prod, 'edits':{}});
	}
	
	handleEditChange(k, ev) {
		var ed = this.state.edits;
		ed[k] = ev.target.value;
		this.setState({'edits':ed});
	}
	
	handleEdit(prod, ev) {
		ev.preventDefault();
		//console.log('save?',prod);
		
		var edits = this.state.edits;
		
		/*
		do_post(window.wpco_api_prefix + 'GiftListItem/AddUpdateGiftListItem',
		 	{
		 		'productId':prod.id,
		 		'quantity':edits.required
		 	},
		 	(ret)=>{
		 		if(ret.loginRequired) {
					window.location.href = '/login/?next=' + encodeURI('/my-list/');
				} else if(ret.responseType!=1) {
					alert(ret.message);
				} else {
					//this.fetchList(); //avoid fetchList since it is slow, and we've already updated required/remaining?
					this.setState({'editing':null});
				}
		 	}
		);*/
		var sold = (prod.required-prod.remaining);
		prod.required = edits.required;
		prod.remaining = Math.max(prod.required - sold, 0);
		var coupleDescription = prod.coupleDescription;
		if(edits.coupleDescription!==undefined) {
			prod.coupleDescription = (edits.coupleDescription||'').slice(0, 1000);
		}
		this._saveProduct(prod, {'required':prod.required, 'coupleDescription':prod.coupleDescription});
		
		//this.setState({'invalidatedItems': _.assign(this.state.invalidatedItems, {[prod.itemId]:true})});
		//what about description etc?

		// do_post(window.wpco_api_prefix + '/api/post-bg-list-update.a4d', {'bgPresentID':prod.bgPresentID, 'quantity':edits.qtyRequired!==undefined ? edits.qtyRequired : prod.qtyRequired, 'coupleDescription':edits.coupleDescription!==undefined ? edits.coupleDescription : prod.description}, (ret)=>{
		// 	if(ret.loginRequired) {
		// 		window.location = '/login/?next=' + encodeURI('/my-list/');
		// 	} else if(ret.errorText) {
		// 		alert(ret.errorText);
		// 	} else {
		// 		this.fetchList();
		// 		this.setState({'editing':null});
		// 	}
		// });
	}

	handleEditDetails(ev) {
		ev.preventDefault();
		do_get(window.wpco_api_prefix + 'Manage/GiftListSettings', {}, 
			(ret)=> {
				this.setState({'listSettings':ret.giftListSettings, 'editingDetails':true});
			}
		);
		
	}
	
	handleUpdateImage(ev) {
		if(ev.target.files[0]) {
			this.setState({'profileImageSrc':URL.createObjectURL(ev.target.files[0])});
		}
	}
	
	handleCropImage(crop, pixelCrop) {
		// ignore too-small crops
		if(pixelCrop.width<270 || pixelCrop.height<270) {
			crop.width = (100*270)/this.state.profileImage.naturalWidth;
			crop.height = (100*270)/this.state.profileImage.naturalHeight;
		}
		this.setState({
			'profileImageCrop':crop,
			'profileImagePixelCrop':pixelCrop
		});
	}
	
	handleLoadedImage(image) {
		if(image.width<270 || image.height<270) {
			alert("This picture is too small. The minimum size is 270x270 pixels.");
			this.setState({'profileImageSrc': null});
			this.refs.image.value = null;
			return;
		}
		this.setState({
			'profileImageCrop': makeAspectCrop({
				x: 0,
				y: 0,
				aspect: 1,
				height: 100,
				}, image.width / image.height),
			'profileImage':image});
	}
	
	handleUpdateDetails(ev) {
		ev.preventDefault();
		
		return new Promise((resolve)=>{
			var doUpdate = (img)=>{
				var data = _.assign({}, this.state.listSettings, {
					websiteMessage:this.refs.bio.value,
				});

				var prom = do_post(window.wpco_api_prefix + 'Manage/UpdateGiftListSettings',
					data,
					(ret)=>{
						if(ret.loginRequired) {
							window.location.href = '/login/?next=' + encodeURI('/my-list/');
						} else if(ret.responseType!=1) {
							alert(ret.message || "Unknown error.");
						} else {
							this.fetchList();
						}
					}
				);

				if(img) {
					var reader = new FileReader();
					reader.readAsDataURL(img); 
					reader.onloadend = ()=>{
						var base64data = reader.result;
						base64data = base64data.substring(base64data.indexOf(",")+1);
						Promise.all([prom, do_post(window.wpco_api_prefix + 'Manage/UpdateGiftListImage',
							{
								'base64Image':base64data,
								'imageName':'image.jpg'
							},
							(ret) => {
								this.state.list.giftList.image = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQ4AAAEOAQMAAABCf2vmAAABg2lDQ1BJQ0MgcHJvZmlsZQAAKJF9kT1Iw0AcxV9TpVIqHdpBxCFDdbIgVsRRq1CECqFWaNXB5PoJTRqSFBdHwbXg4Mdi1cHFWVcHV0EQ/ABxcnRSdJES/5cUWsR4cNyPd/ced+8AoVVjqtk3AaiaZWRSSTGXXxUDrwgijAgSCMrM1OckKQ3P8XUPH1/v4jzL+9yfY7BQNBngE4lnmW5YxBvE05uWznmfOMoqcoH4nHjcoAsSP3JdcfmNc9lhgWdGjWxmnjhKLJZ7WOlhVjFU4iniWEHVKF/IuVzgvMVZrTVY5578haGitrLMdZojSGERS5AgQkEDVdRgIU6rRoqJDO0nPfzDjl8il0KuKhg5FlCHCtnxg//B727NUmLSTQolgf4X2/4YBQK7QLtp29/Htt0+AfzPwJXW9ddbwMwn6c2uFjsCwtvAxXVXU/aAyx1g6EmXDdmR/DSFUgl4P6NvygORWyC45vbW2cfpA5ClrtI3wMEhMFam7HWPdw/09vbvmU5/P3DAcqbx7/lKAAAACXBIWXMAAC4jAAAuIwF4pT92AAAAB3RJTUUH5AoPEzQWAkygTAAAABl0RVh0Q29tbWVudABDcmVhdGVkIHdpdGggR0lNUFeBDhcAAAADUExUReLe2nSh6k8AAAAgSURBVBgZ7cExAQAAAMIg+6deDQ9gAAAAAAAAAAAA9wIk6gABqFkg1gAAAABJRU5ErkJggg==';
								this.setState({'list':this.state.list, 'editingDetails':null, 'profileImageSrc':null, 'profileImage':null});
								this.fetchList();
							}
						)]).finally(resolve);
					}
				} else {
					this.setState({'editingDetails':null, 'profileImageSrc':null, 'profileImage':null});
					prom.finally(resolve);
				}
			};
			
			if(this.state.profileImage && this.state.profileImagePixelCrop) {
				getCroppedImg(this.state.profileImage, this.state.profileImagePixelCrop, "profile.jpg").then((img)=>{ doUpdate(img); });
			} else {
				doUpdate(this.refs.image.files[0]);
			}
		});
	}
	
	handlePublish(ev) {
		ev.preventDefault();
		do_get(window.wpco_api_prefix + 'Manage/PublishList', {
				'giftListId':this.state.list.giftList.id,
			}, 
			(ret)=> {
				console.log('ret is', ret);
			}
		);
	}

	getListUrl() {
		return "https://www.weddingpresentco.com/find-list/" + this.state.list.giftList.id + "/";
	}

	handleCopyUrl(ev) {
		ev.preventDefault();
		var input = document.createElement('input');
		input.style.position = 'absolute';
		input.style.zIndex = -10;
		input.value = this.getListUrl();
		document.body.appendChild(input);
		input.select();
		document.execCommand("copy");
		document.body.removeChild(input);
		this.setState({'urlCopied': true}, ()=>{
			setTimeout(()=>{
				this.setState({'urlCopied': false});
			}, 3000);
		});
	}

	handleChooseListType(ev) {
		ev.preventDefault();
		this.setState({choosingListType: true});
	}
}
