// same list as Neo, as Name needs to exactly match
export var countries = [
    {
      "ISO2": "AF",
      "ISO3": "AFG",
      "Name": "Afghanistan"
    },
    {
      "ISO2": "AX",
      "ISO3": "ALA",
      "Name": "Åland"
    },
    {
      "ISO2": "AL",
      "ISO3": "ALB",
      "Name": "Albania"
    },
    {
      "ISO2": "DZ",
      "ISO3": "DZA",
      "Name": "Algeria"
    },
    {
      "ISO2": "AS",
      "ISO3": "ASM",
      "Name": "American Samoa"
    },
    {
      "ISO2": "AD",
      "ISO3": "AND",
      "Name": "Andorra"
    },
    {
      "ISO2": "AO",
      "ISO3": "AGO",
      "Name": "Angola"
    },
    {
      "ISO2": "AI",
      "ISO3": "AIA",
      "Name": "Anguilla"
    },
    {
      "ISO2": "AQ",
      "ISO3": "ATA",
      "Name": "Antarctica"
    },
    {
      "ISO2": "AG",
      "ISO3": "ATG",
      "Name": "Antigua and Barbuda"
    },
    {
      "ISO2": "AR",
      "ISO3": "ARG",
      "Name": "Argentina"
    },
    {
      "ISO2": "AM",
      "ISO3": "ARM",
      "Name": "Armenia"
    },
    {
      "ISO2": "AW",
      "ISO3": "ABW",
      "Name": "Aruba"
    },
    {
      "ISO2": "AU",
      "ISO3": "AUS",
      "Name": "Australia"
    },
    {
      "ISO2": "AT",
      "ISO3": "AUT",
      "Name": "Austria"
    },
    {
      "ISO2": "AZ",
      "ISO3": "AZE",
      "Name": "Azerbaijan"
    },
    {
      "ISO2": "BS",
      "ISO3": "BHS",
      "Name": "Bahamas"
    },
    {
      "ISO2": "BH",
      "ISO3": "BHR",
      "Name": "Bahrain"
    },
    {
      "ISO2": "BD",
      "ISO3": "BGD",
      "Name": "Bangladesh"
    },
    {
      "ISO2": "BB",
      "ISO3": "BRB",
      "Name": "Barbados"
    },
    {
      "ISO2": "BY",
      "ISO3": "BLR",
      "Name": "Belarus"
    },
    {
      "ISO2": "BE",
      "ISO3": "BEL",
      "Name": "Belgium"
    },
    {
      "ISO2": "BZ",
      "ISO3": "BLZ",
      "Name": "Belize"
    },
    {
      "ISO2": "BJ",
      "ISO3": "BEN",
      "Name": "Benin"
    },
    {
      "ISO2": "BM",
      "ISO3": "BMU",
      "Name": "Bermuda"
    },
    {
      "ISO2": "BT",
      "ISO3": "BTN",
      "Name": "Bhutan"
    },
    {
      "ISO2": "BO",
      "ISO3": "BOL",
      "Name": "Bolivia"
    },
    {
      "ISO2": "BQ",
      "ISO3": "BES",
      "Name": "Bonaire, Sint Eustatius and Saba"
    },
    {
      "ISO2": "BA",
      "ISO3": "BIH",
      "Name": "Bosnia and Herzegovina"
    },
    {
      "ISO2": "BW",
      "ISO3": "BWA",
      "Name": "Botswana"
    },
    {
      "ISO2": "BV",
      "ISO3": "BVT",
      "Name": "Bouvet Island"
    },
    {
      "ISO2": "BR",
      "ISO3": "BRA",
      "Name": "Brazil"
    },
    {
      "ISO2": "IO",
      "ISO3": "IOT",
      "Name": "British Indian Ocean Territory"
    },
    {
      "ISO2": "VG",
      "ISO3": "VGB",
      "Name": "British Virgin Islands"
    },
    {
      "ISO2": "BN",
      "ISO3": "BRN",
      "Name": "Brunei"
    },
    {
      "ISO2": "BG",
      "ISO3": "BGR",
      "Name": "Bulgaria"
    },
    {
      "ISO2": "BF",
      "ISO3": "BFA",
      "Name": "Burkina Faso"
    },
    {
      "ISO2": "MM",
      "ISO3": "MMR",
      "Name": "Burma"
    },
    {
      "ISO2": "BI",
      "ISO3": "BDI",
      "Name": "Burundi"
    },
    {
      "ISO2": "KH",
      "ISO3": "KHM",
      "Name": "Cambodia"
    },
    {
      "ISO2": "CM",
      "ISO3": "CMR",
      "Name": "Cameroon"
    },
    {
      "ISO2": "CA",
      "ISO3": "CAN",
      "Name": "Canada"
    },
    {
      "ISO2": "CV",
      "ISO3": "CPV",
      "Name": "Cape Verde"
    },
    {
      "ISO2": "KY",
      "ISO3": "CYM",
      "Name": "Cayman Islands"
    },
    {
      "ISO2": "CF",
      "ISO3": "CAF",
      "Name": "Central African Republic"
    },
    {
      "ISO2": "TD",
      "ISO3": "TCD",
      "Name": "Chad"
    },
    {
      "ISO2": "CL",
      "ISO3": "CHL",
      "Name": "Chile"
    },
    {
      "ISO2": "CN",
      "ISO3": "CHN",
      "Name": "China"
    },
    {
      "ISO2": "CX",
      "ISO3": "CXR",
      "Name": "Christmas Island"
    },
    {
      "ISO2": "CC",
      "ISO3": "CCK",
      "Name": "Cocos (Keeling) Islands"
    },
    {
      "ISO2": "CO",
      "ISO3": "COL",
      "Name": "Colombia"
    },
    {
      "ISO2": "KM",
      "ISO3": "COM",
      "Name": "Comoros"
    },
    {
      "ISO2": "CG",
      "ISO3": "COG",
      "Name": "Congo"
    },
    {
      "ISO2": "CD",
      "ISO3": "COD",
      "Name": "Congo (Democratic Republic)"
    },
    {
      "ISO2": "CK",
      "ISO3": "COK",
      "Name": "Cook Islands"
    },
    {
      "ISO2": "CR",
      "ISO3": "CRI",
      "Name": "Costa Rica"
    },
    {
      "ISO2": "HR",
      "ISO3": "HRV",
      "Name": "Croatia"
    },
    {
      "ISO2": "CU",
      "ISO3": "CUB",
      "Name": "Cuba"
    },
    {
      "ISO2": "CW",
      "ISO3": "CUW",
      "Name": "Curaçao"
    },
    {
      "ISO2": "CY",
      "ISO3": "CYP",
      "Name": "Cyprus"
    },
    {
      "ISO2": "CZ",
      "ISO3": "CZE",
      "Name": "Czechia"
    },
    {
      "ISO2": "DK",
      "ISO3": "DNK",
      "Name": "Denmark"
    },
    {
      "ISO2": "DJ",
      "ISO3": "DJI",
      "Name": "Djibouti"
    },
    {
      "ISO2": "DM",
      "ISO3": "DMA",
      "Name": "Dominica"
    },
    {
      "ISO2": "DO",
      "ISO3": "DOM",
      "Name": "Dominican Republic"
    },
    {
      "ISO2": "TL",
      "ISO3": "TLS",
      "Name": "East Timor"
    },
    {
      "ISO2": "EC",
      "ISO3": "ECU",
      "Name": "Ecuador"
    },
    {
      "ISO2": "EG",
      "ISO3": "EGY",
      "Name": "Egypt"
    },
    {
      "ISO2": "SV",
      "ISO3": "SLV",
      "Name": "El Salvador"
    },
    {
      "ISO2": "GQ",
      "ISO3": "GNQ",
      "Name": "Equatorial Guinea"
    },
    {
      "ISO2": "ER",
      "ISO3": "ERI",
      "Name": "Eritrea"
    },
    {
      "ISO2": "EE",
      "ISO3": "EST",
      "Name": "Estonia"
    },
    {
      "ISO2": "SZ",
      "ISO3": "SWZ",
      "Name": "Eswatini"
    },
    {
      "ISO2": "ET",
      "ISO3": "ETH",
      "Name": "Ethiopia"
    },
    {
      "ISO2": "FK",
      "ISO3": "FLK",
      "Name": "Falkland Islands"
    },
    {
      "ISO2": "FO",
      "ISO3": "FRO",
      "Name": "Faroe Islands"
    },
    {
      "ISO2": "FJ",
      "ISO3": "FJI",
      "Name": "Fiji"
    },
    {
      "ISO2": "FI",
      "ISO3": "FIN",
      "Name": "Finland"
    },
    {
      "ISO2": "FR",
      "ISO3": "FRA",
      "Name": "France"
    },
    {
      "ISO2": "GF",
      "ISO3": "GUF",
      "Name": "French Guiana"
    },
    {
      "ISO2": "PF",
      "ISO3": "PYF",
      "Name": "French Polynesia"
    },
    {
      "ISO2": "TF",
      "ISO3": "ATF",
      "Name": "French Southern Territories"
    },
    {
      "ISO2": "GA",
      "ISO3": "GAB",
      "Name": "Gabon"
    },
    {
      "ISO2": "GM",
      "ISO3": "GMB",
      "Name": "Gambia"
    },
    {
      "ISO2": "GE",
      "ISO3": "GEO",
      "Name": "Georgia"
    },
    {
      "ISO2": "DE",
      "ISO3": "DEU",
      "Name": "Germany"
    },
    {
      "ISO2": "GH",
      "ISO3": "GHA",
      "Name": "Ghana"
    },
    {
      "ISO2": "GI",
      "ISO3": "GIB",
      "Name": "Gibraltar"
    },
    {
      "ISO2": "GR",
      "ISO3": "GRC",
      "Name": "Greece"
    },
    {
      "ISO2": "GL",
      "ISO3": "GRL",
      "Name": "Greenland"
    },
    {
      "ISO2": "GD",
      "ISO3": "GRD",
      "Name": "Grenada"
    },
    {
      "ISO2": "GP",
      "ISO3": "GLP",
      "Name": "Guadeloupe"
    },
    {
      "ISO2": "GU",
      "ISO3": "GUM",
      "Name": "Guam"
    },
    {
      "ISO2": "GT",
      "ISO3": "GTM",
      "Name": "Guatemala"
    },
    {
      "ISO2": "GG",
      "ISO3": "GGY",
      "Name": "Guernsey"
    },
    {
      "ISO2": "GN",
      "ISO3": "GIN",
      "Name": "Guinea"
    },
    {
      "ISO2": "GW",
      "ISO3": "GNB",
      "Name": "Guinea-Bissau"
    },
    {
      "ISO2": "GY",
      "ISO3": "GUY",
      "Name": "Guyana"
    },
    {
      "ISO2": "HT",
      "ISO3": "HTI",
      "Name": "Haiti"
    },
    {
      "ISO2": "HM",
      "ISO3": "HMD",
      "Name": "Heard Island and McDonald Islands"
    },
    {
      "ISO2": "HN",
      "ISO3": "HND",
      "Name": "Honduras"
    },
    {
      "ISO2": "HK",
      "ISO3": "HKG",
      "Name": "Hong Kong"
    },
    {
      "ISO2": "HU",
      "ISO3": "HUN",
      "Name": "Hungary"
    },
    {
      "ISO2": "IS",
      "ISO3": "ISL",
      "Name": "Iceland"
    },
    {
      "ISO2": "IN",
      "ISO3": "IND",
      "Name": "India"
    },
    {
      "ISO2": "ID",
      "ISO3": "IDN",
      "Name": "Indonesia"
    },
    {
      "ISO2": "IR",
      "ISO3": "IRN",
      "Name": "Iran"
    },
    {
      "ISO2": "IQ",
      "ISO3": "IRQ",
      "Name": "Iraq"
    },
    {
      "ISO2": "IE",
      "ISO3": "IRL",
      "Name": "Ireland"
    },
    {
      "ISO2": "IM",
      "ISO3": "IMN",
      "Name": "Isle of Man"
    },
    {
      "ISO2": "IL",
      "ISO3": "ISR",
      "Name": "Israel"
    },
    {
      "ISO2": "IT",
      "ISO3": "ITA",
      "Name": "Italy"
    },
    {
      "ISO2": "CI",
      "ISO3": "CIV",
      "Name": "Ivory Coast"
    },
    {
      "ISO2": "JM",
      "ISO3": "JAM",
      "Name": "Jamaica"
    },
    {
      "ISO2": "JP",
      "ISO3": "JPN",
      "Name": "Japan"
    },
    {
      "ISO2": "JE",
      "ISO3": "JEY",
      "Name": "Jersey"
    },
    {
      "ISO2": "JO",
      "ISO3": "JOR",
      "Name": "Jordan"
    },
    {
      "ISO2": "KZ",
      "ISO3": "KAZ",
      "Name": "Kazakhstan"
    },
    {
      "ISO2": "KE",
      "ISO3": "KEN",
      "Name": "Kenya"
    },
    {
      "ISO2": "KI",
      "ISO3": "KIR",
      "Name": "Kiribati"
    },
    {
      "ISO2": "KP",
      "ISO3": "PRK",
      "Name": "Korea (North)"
    },
    {
      "ISO2": "KR",
      "ISO3": "KOR",
      "Name": "Korea (South)"
    },
    {
      "ISO2": "KW",
      "ISO3": "KWT",
      "Name": "Kuwait"
    },
    {
      "ISO2": "KG",
      "ISO3": "KGZ",
      "Name": "Kyrgyzstan"
    },
    {
      "ISO2": "LA",
      "ISO3": "LAO",
      "Name": "Laos"
    },
    {
      "ISO2": "LV",
      "ISO3": "LVA",
      "Name": "Latvia"
    },
    {
      "ISO2": "LB",
      "ISO3": "LBN",
      "Name": "Lebanon"
    },
    {
      "ISO2": "LS",
      "ISO3": "LSO",
      "Name": "Lesotho"
    },
    {
      "ISO2": "LR",
      "ISO3": "LBR",
      "Name": "Liberia"
    },
    {
      "ISO2": "LY",
      "ISO3": "LBY",
      "Name": "Libya"
    },
    {
      "ISO2": "LI",
      "ISO3": "LIE",
      "Name": "Liechtenstein"
    },
    {
      "ISO2": "LT",
      "ISO3": "LTU",
      "Name": "Lithuania"
    },
    {
      "ISO2": "LU",
      "ISO3": "LUX",
      "Name": "Luxembourg"
    },
    {
      "ISO2": "MO",
      "ISO3": "MAC",
      "Name": "Macao"
    },
    {
      "ISO2": "MK",
      "ISO3": "MKD",
      "Name": "Macedonia"
    },
    {
      "ISO2": "MG",
      "ISO3": "MDG",
      "Name": "Madagascar"
    },
    {
      "ISO2": "MW",
      "ISO3": "MWI",
      "Name": "Malawi"
    },
    {
      "ISO2": "MY",
      "ISO3": "MYS",
      "Name": "Malaysia"
    },
    {
      "ISO2": "MV",
      "ISO3": "MDV",
      "Name": "Maldives"
    },
    {
      "ISO2": "ML",
      "ISO3": "MLI",
      "Name": "Mali"
    },
    {
      "ISO2": "MT",
      "ISO3": "MLT",
      "Name": "Malta"
    },
    {
      "ISO2": "MH",
      "ISO3": "MHL",
      "Name": "Marshall Islands"
    },
    {
      "ISO2": "MQ",
      "ISO3": "MTQ",
      "Name": "Martinique"
    },
    {
      "ISO2": "MR",
      "ISO3": "MRT",
      "Name": "Mauritania"
    },
    {
      "ISO2": "MU",
      "ISO3": "MUS",
      "Name": "Mauritius"
    },
    {
      "ISO2": "YT",
      "ISO3": "MYT",
      "Name": "Mayotte"
    },
    {
      "ISO2": "MX",
      "ISO3": "MEX",
      "Name": "Mexico"
    },
    {
      "ISO2": "FM",
      "ISO3": "FSM",
      "Name": "Micronesia"
    },
    {
      "ISO2": "MD",
      "ISO3": "MDA",
      "Name": "Moldova"
    },
    {
      "ISO2": "MC",
      "ISO3": "MCO",
      "Name": "Monaco"
    },
    {
      "ISO2": "MN",
      "ISO3": "MNG",
      "Name": "Mongolia"
    },
    {
      "ISO2": "ME",
      "ISO3": "MNE",
      "Name": "Montenegro"
    },
    {
      "ISO2": "MS",
      "ISO3": "MSR",
      "Name": "Montserrat"
    },
    {
      "ISO2": "MA",
      "ISO3": "MAR",
      "Name": "Morocco"
    },
    {
      "ISO2": "MZ",
      "ISO3": "MOZ",
      "Name": "Mozambique"
    },
    {
      "ISO2": "NA",
      "ISO3": "NAM",
      "Name": "Namibia"
    },
    {
      "ISO2": "NR",
      "ISO3": "NRU",
      "Name": "Nauru"
    },
    {
      "ISO2": "NP",
      "ISO3": "NPL",
      "Name": "Nepal"
    },
    {
      "ISO2": "NL",
      "ISO3": "NLD",
      "Name": "Netherlands"
    },
    {
      "ISO2": "NC",
      "ISO3": "NCL",
      "Name": "New Caledonia"
    },
    {
      "ISO2": "NZ",
      "ISO3": "NZL",
      "Name": "New Zealand"
    },
    {
      "ISO2": "NI",
      "ISO3": "NIC",
      "Name": "Nicaragua"
    },
    {
      "ISO2": "NE",
      "ISO3": "NER",
      "Name": "Niger"
    },
    {
      "ISO2": "NG",
      "ISO3": "NGA",
      "Name": "Nigeria"
    },
    {
      "ISO2": "NU",
      "ISO3": "NIU",
      "Name": "Niue"
    },
    {
      "ISO2": "NF",
      "ISO3": "NFK",
      "Name": "Norfolk Island"
    },
    {
      "ISO2": "MP",
      "ISO3": "MNP",
      "Name": "Northern Mariana Islands"
    },
    {
      "ISO2": "NO",
      "ISO3": "NOR",
      "Name": "Norway"
    },
    {
      "ISO2": "OM",
      "ISO3": "OMN",
      "Name": "Oman"
    },
    {
      "ISO2": "PK",
      "ISO3": "PAK",
      "Name": "Pakistan"
    },
    {
      "ISO2": "PW",
      "ISO3": "PLW",
      "Name": "Palau"
    },
    {
      "ISO2": "PS",
      "ISO3": "PSE",
      "Name": "Palestine"
    },
    {
      "ISO2": "PA",
      "ISO3": "PAN",
      "Name": "Panama"
    },
    {
      "ISO2": "PG",
      "ISO3": "PNG",
      "Name": "Papua New Guinea"
    },
    {
      "ISO2": "PY",
      "ISO3": "PRY",
      "Name": "Paraguay"
    },
    {
      "ISO2": "PE",
      "ISO3": "PER",
      "Name": "Peru"
    },
    {
      "ISO2": "PH",
      "ISO3": "PHL",
      "Name": "Philippines"
    },
    {
      "ISO2": "PN",
      "ISO3": "PCN",
      "Name": "Pitcairn"
    },
    {
      "ISO2": "PL",
      "ISO3": "POL",
      "Name": "Poland"
    },
    {
      "ISO2": "PT",
      "ISO3": "PRT",
      "Name": "Portugal"
    },
    {
      "ISO2": "PR",
      "ISO3": "PRI",
      "Name": "Puerto Rico"
    },
    {
      "ISO2": "QA",
      "ISO3": "QAT",
      "Name": "Qatar"
    },
    {
      "ISO2": "RE",
      "ISO3": "REU",
      "Name": "Réunion"
    },
    {
      "ISO2": "RO",
      "ISO3": "ROU",
      "Name": "Romania"
    },
    {
      "ISO2": "RU",
      "ISO3": "RUS",
      "Name": "Russia"
    },
    {
      "ISO2": "RW",
      "ISO3": "RWA",
      "Name": "Rwanda"
    },
    {
      "ISO2": "MF",
      "ISO3": "MAF",
      "Name": "Saint Martin (French)"
    },
    {
      "ISO2": "WS",
      "ISO3": "WSM",
      "Name": "Samoa"
    },
    {
      "ISO2": "SM",
      "ISO3": "SMR",
      "Name": "San Marino"
    },
    {
      "ISO2": "ST",
      "ISO3": "STP",
      "Name": "Sao Tome and Principe"
    },
    {
      "ISO2": "SA",
      "ISO3": "SAU",
      "Name": "Saudi Arabia"
    },
    {
      "ISO2": "SN",
      "ISO3": "SEN",
      "Name": "Senegal"
    },
    {
      "ISO2": "RS",
      "ISO3": "SRB",
      "Name": "Serbia"
    },
    {
      "ISO2": "SC",
      "ISO3": "SYC",
      "Name": "Seychelles"
    },
    {
      "ISO2": "SL",
      "ISO3": "SLE",
      "Name": "Sierra Leone"
    },
    {
      "ISO2": "SG",
      "ISO3": "SGP",
      "Name": "Singapore"
    },
    {
      "ISO2": "SX",
      "ISO3": "SXM",
      "Name": "Sint Maarten (Dutch)"
    },
    {
      "ISO2": "SK",
      "ISO3": "SVK",
      "Name": "Slovakia"
    },
    {
      "ISO2": "SI",
      "ISO3": "SVN",
      "Name": "Slovenia"
    },
    {
      "ISO2": "SB",
      "ISO3": "SLB",
      "Name": "Solomon Islands"
    },
    {
      "ISO2": "SO",
      "ISO3": "SOM",
      "Name": "Somalia"
    },
    {
      "ISO2": "ZA",
      "ISO3": "ZAF",
      "Name": "South Africa"
    },
    {
      "ISO2": "GS",
      "ISO3": "SGS",
      "Name": "South Georgia and the South Sandwich Islands"
    },
    {
      "ISO2": "SS",
      "ISO3": "SSD",
      "Name": "South Sudan"
    },
    {
      "ISO2": "ES",
      "ISO3": "ESP",
      "Name": "Spain"
    },
    {
      "ISO2": "LK",
      "ISO3": "LKA",
      "Name": "Sri Lanka"
    },
    {
      "ISO2": "BL",
      "ISO3": "BLM",
      "Name": "St Barthélemy"
    },
    {
      "ISO2": "SH",
      "ISO3": "SHN",
      "Name": "St Helena, Ascension and Tristan da Cunha"
    },
    {
      "ISO2": "KN",
      "ISO3": "KNA",
      "Name": "St Kitts and Nevis"
    },
    {
      "ISO2": "LC",
      "ISO3": "LCA",
      "Name": "St Lucia"
    },
    {
      "ISO2": "PM",
      "ISO3": "SPM",
      "Name": "St Pierre and Miquelon"
    },
    {
      "ISO2": "VC",
      "ISO3": "VCT",
      "Name": "St Vincent"
    },
    {
      "ISO2": "SD",
      "ISO3": "SDN",
      "Name": "Sudan"
    },
    {
      "ISO2": "SR",
      "ISO3": "SUR",
      "Name": "Suriname"
    },
    {
      "ISO2": "SJ",
      "ISO3": "SJM",
      "Name": "Svalbard and Jan Mayen"
    },
    {
      "ISO2": "SE",
      "ISO3": "SWE",
      "Name": "Sweden"
    },
    {
      "ISO2": "CH",
      "ISO3": "CHE",
      "Name": "Switzerland"
    },
    {
      "ISO2": "SY",
      "ISO3": "SYR",
      "Name": "Syria"
    },
    {
      "ISO2": "TW",
      "ISO3": "TWN",
      "Name": "Taiwan"
    },
    {
      "ISO2": "TJ",
      "ISO3": "TJK",
      "Name": "Tajikistan"
    },
    {
      "ISO2": "TZ",
      "ISO3": "TZA",
      "Name": "Tanzania"
    },
    {
      "ISO2": "TH",
      "ISO3": "THA",
      "Name": "Thailand"
    },
    {
      "ISO2": "TG",
      "ISO3": "TGO",
      "Name": "Togo"
    },
    {
      "ISO2": "TK",
      "ISO3": "TKL",
      "Name": "Tokelau"
    },
    {
      "ISO2": "TO",
      "ISO3": "TON",
      "Name": "Tonga"
    },
    {
      "ISO2": "TT",
      "ISO3": "TTO",
      "Name": "Trinidad and Tobago"
    },
    {
      "ISO2": "TN",
      "ISO3": "TUN",
      "Name": "Tunisia"
    },
    {
      "ISO2": "TR",
      "ISO3": "TUR",
      "Name": "Turkey"
    },
    {
      "ISO2": "TM",
      "ISO3": "TKM",
      "Name": "Turkmenistan"
    },
    {
      "ISO2": "TC",
      "ISO3": "TCA",
      "Name": "Turks and Caicos Islands"
    },
    {
      "ISO2": "TV",
      "ISO3": "TUV",
      "Name": "Tuvalu"
    },
    {
      "ISO2": "UG",
      "ISO3": "UGA",
      "Name": "Uganda"
    },
    {
      "ISO2": "UA",
      "ISO3": "UKR",
      "Name": "Ukraine"
    },
    {
      "ISO2": "AE",
      "ISO3": "ARE",
      "Name": "United Arab Emirates"
    },
    {
      "ISO2": "GB",
      "ISO3": "GBR",
      "Name": "United Kingdom"
    },
    {
      "ISO2": "US",
      "ISO3": "USA",
      "Name": "United States"
    },
    {
      "ISO2": "UM",
      "ISO3": "UMI",
      "Name": "United States Minor Outlying Islands"
    },
    {
      "ISO2": "VI",
      "ISO3": "VIR",
      "Name": "United States Virgin Islands"
    },
    {
      "ISO2": "UY",
      "ISO3": "URY",
      "Name": "Uruguay"
    },
    {
      "ISO2": "UZ",
      "ISO3": "UZB",
      "Name": "Uzbekistan"
    },
    {
      "ISO2": "VU",
      "ISO3": "VUT",
      "Name": "Vanuatu"
    },
    {
      "ISO2": "VA",
      "ISO3": "VAT",
      "Name": "Vatican City"
    },
    {
      "ISO2": "VE",
      "ISO3": "VEN",
      "Name": "Venezuela"
    },
    {
      "ISO2": "VN",
      "ISO3": "VNM",
      "Name": "Vietnam"
    },
    {
      "ISO2": "WF",
      "ISO3": "WLF",
      "Name": "Wallis and Futuna"
    },
    {
      "ISO2": "EH",
      "ISO3": "ESH",
      "Name": "Western Sahara"
    },
    {
      "ISO2": "YE",
      "ISO3": "YEM",
      "Name": "Yemen"
    },
    {
      "ISO2": "ZM",
      "ISO3": "ZMB",
      "Name": "Zambia"
    },
    {
      "ISO2": "ZW",
      "ISO3": "ZWE",
      "Name": "Zimbabwe"
    }
];
