import React from 'react';
var _ = require('lodash');

import { ExpandingDiv } from '../components/ExpandingDiv.jsx';
import { HelpTooltip } from '../components/HelpTooltip.jsx';

export class FinanceExpander extends React.Component {
	constructor(props) {
		super(props);
		this.state = {'open':false};
	}
	
	render() {
		var body = null;
		if(this.props.text!==undefined) {
			body = <div dangerouslySetInnerHTML={{__html: this.props.text }} />
		} else {
			body = this.props.children;
		}
		
		return <div ref="section" className={"section expander" + (this.state.open?' open':'')}>
			<div ref="head" className="head" onClick={this.handleHeadClick.bind(this)}><h3>{ this.props.title }&nbsp;{ this.props.help && <HelpTooltip iconLabel="?">{ this.props.help }</HelpTooltip> }</h3><div className="subtitle">{ this.props.subtitle }</div></div>
			<ExpandingDiv className="body" open={this.state.open} delay={300}>
				{ body }
			</ExpandingDiv>
		</div>;
	}
	
	handleHeadClick(ev) {
		ev.preventDefault();
		this.setState({'open':!this.state.open});
	}
}
