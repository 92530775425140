import React from 'react';

import { HelpTooltip } from '../components/HelpTooltip.jsx';

export class PendingCustomProductNotice extends React.Component {
    constructor(props) {
        super(props);
    }

	render() {
        return <div className="pending-custom-product-notice"><HelpTooltip label="Custom product pending" iconLabel="i" className="age-restriction-notice">Please allow three working days for us to confirm Custom Products on your list. Once confirmed, this Custom Product will no longer be pending and will be visible to guests.</HelpTooltip></div>;
	}
}
