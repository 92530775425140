import React from 'react';
var $ = require('jquery');
var _ = require('lodash');

import { cookies, do_get, do_post, formatPrice, nl2br, ScrollToTop } from '../utils.jsx';

import { DashboardMenu } from '../components/DashboardMenu.jsx';

export class ThankYouList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {'filter':''};
		this.fetchList();
	}
	
	render() {
		if(!this.state.pledges) return <div key="loading-spinner" className="loading-spinner"></div>;
		
		var genFilter = (n, label)=>{
			if(this.state.filter==n) {
				return label;
			} else {
				return <a href="#" onClick={ (ev)=>{ev.preventDefault();this.setState({'filter':n})} }>{ label }</a>
			}
		};
		
		var items = this.state.pledges;
		if(this.state.filter=="thanked") {
			items = _.filter(items, (i)=>(i.thankYouHasBeenSent));
		} else if(this.state.filter=="notthanked") {
			items = _.filter(items, (i)=>(!i.thankYouHasBeenSent));
		}
		
		console.log(items);
		
		var reminderShown = {};
		
		return <div className="container thank-you-list">
			<ScrollToTop />
			<DashboardMenu />
			<div className="nine columns">
				<h2 className="underlined">Thank you <span>List</span></h2>
				
				<table className="result-list thank-you-list">
					<thead>
						<tr><td colSpan="5"><strong>View:</strong> { genFilter('', 'All') } | { genFilter('thanked', 'Thanked') } | { genFilter('notthanked', 'Not Thanked') }</td></tr>
						<tr className="heading"><th width="21%">Guest Details</th><th width="25%">Present</th><th width="23%">Message</th><th width="3%" className="center">Thanked</th></tr>
					</thead>
					<tbody>
						{ _.map(items, (item, k) => {
							var showReminder = !reminderShown[item.pledgeId];
							reminderShown[item.pledgeId] = true;
							return <tr key={k}>
								<td><strong>{ item.guestFullName }<br /><span dangerouslySetInnerHTML={ {__html:item.guestAddressHtml} } /></strong></td>
								<td>{ item.productsBought.length && ( _.map(item.productsBought, (p, k)=>(<div className="present" key={k}>{ p.coupleDescription || p.description }<br />{ this.getDisplayedPrice(p) }</div>))) }</td>
								<td>{ nl2br(item.guestMessage) }</td>
								<td>{ showReminder && <input type="checkbox" checked={item.thankYouHasBeenSent} onChange={this.handleThank.bind(this, item)} /> }</td>
							</tr>;
						}) }
						{ items.length==0 && <tr>
							<td colSpan="5">No items to display.</td>
						</tr> }
						
					</tbody>
				</table>
				{ items.length>0 && <p className="table-actions"><a href="#" download onClick={ this.handleDownload.bind(this) }>Download list</a></p> }
			</div>
		</div>;

//			<th width="25%">Note</th>					<td className="textarea">{ showReminder && <textarea defaultValue={ item.reminderNote } placeholder="Write a reminder note" onBlur={this.handleReminder.bind(this, item, 'blur') } onChange={this.handleReminder.bind(this, item, 'change') }/> }</td>

	}
	
	getDisplayedPrice(prod) {
		if(prod.isGroupGift) {
			return "Group Gift contribution of £" + formatPrice(prod.lineTotal);
		}
		return "£" + formatPrice(prod.lineTotal);
	}
	
	fetchList() {
		do_get(window.wpco_api_prefix + 'Manage/ThankYouManager',
			{}, 
			(ret)=> {
				if(ret.loginRequired) {
					window.location.href = '/login/?next=' + encodeURI('/my-list/thank-you-list/');
				} else {
					this.setState({'pledges':ret.pledges});
				}
			}
		);
	}
	
	handleThank(item, ev) {
		ev.preventDefault();
		console.log(ev.target.value);
		
		var thanked = $(ev.target).is(':checked');
		
		do_get(window.wpco_api_prefix + 'Manage/MarkPledgeAsSent/' + item.pledgeId,
			{},
			(ret)=>{ 
				if(ret.responseType!=1) {
					alert(ret.message || "Unknown error.");
				} else {
					item.thankYouHasBeenSent = thanked;
					var pledges = _.map(this.state.pledges, (p)=>{
						if(p.pledgeId==item.pledgeId) p.thankYouHasBeenSent = thanked;
						return p;
					});
					this.setState({'pledges':pledges});
				}
			}
		);
		
		
	}
	
	handleReminder(item, method, ev) {
		if(method=="blur") {
			this._doReminder(item, ev.target.value);
		} else if(method=="change") {
			var value = ev.target.value;
			if(this.changeTimeout) clearTimeout(this.changeTimeout);
			this.changeTimeout = setTimeout(()=>{
				this._doReminder(item, value);
			}, 5000);
		}
	}
	
	_doReminder(item, value) {
		if(item.reminderNote==value) return;
		
		return;
		
		do_post(window.wpco_api_prefix + '/api/post-bg-thank-you-update.a4d',
 			{
 				'bgGuestID':item.bgGuestID,
				'reminderNote':value,
 			},
 			(ret)=> {
				if(ret.errorText) {
					alert(ret.errorText);
				} else {
					item.reminderNote = value;
					
					var list = this.state.list;
					list.listItems = _.map(list.listItems, (i)=>{
						if(i.bgGuestID==item.bgGuestID) i.reminderNote = value;
						return i;
					});
					this.setState({'list':list});
				}
 			}
 		);
	}
	
	handleDownload(ev) {
		ev.preventDefault();
		
		window.location.href = '/my-list/thank-you-list/export/';
	}
}
