import React from 'react';
var _ = require('lodash');

import { do_post, ScrollToHereWithRouter, tracking_event } from '../utils.jsx';

import { FormInput, MyForm } from '../components/Forms.jsx';
import { Popup } from '../components/Popup.jsx';

export class RequestBrochureForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	
	componentDidMount() {
		//if(window.ga) ga('send', 'event', 'Popups', 'show', 'Request Brochure');
		tracking_event('request_brochure_popup_show');
	}
	
	render() {
		var props = {'errors':this.state.errors||{}, 'values':this.state.data||{}, 'context':{'focused':false}};
		
		return <Popup onClose={this.handleClose.bind(this)}>
			<h2>Request a Brochure</h2>
			{ this.state.success ? <React.Fragment>
				<ScrollToHereWithRouter offset={-120} />
				<h3>Thank you. Your brochure will arrive in the post soon.</h3>
				<div className="form-row"><button type="button" className="right button blue" onClick={ this.handleClose.bind(this)}>Close</button></div>
			</React.Fragment> : <MyForm ref="recommend_form" onSubmit={this.handleSubmit.bind(this)}>
			<h3>Your Details</h3>
			<FormInput label="First Name" name="firstName" required={true} {...props} />
			<FormInput label="Surname" name="surname" required={true} {...props} />
			<FormInput label="Address Line 1" name="address1" required={true} {...props} />
			<FormInput label="Address Line 2" name="address2" required={false} {...props} />
			<FormInput label="Town" name="town" required={true} {...props} />
			<FormInput label="Postcode" name="postcode" required={true} {...props} />
			<FormInput label="Phone number" name="phone" required={false} validate="phone" {...props} />
			<FormInput label="Email" name="email" validate="email" required={true} {...props} />
			
			<div className="form-row"><button type="submit" className="right">Submit</button></div>
			</MyForm> }
			
		</Popup>;
	}
	
	handleSubmit(fields, errors) {
		var hasErrors = _.find(errors, (v)=>(v!=null));

		if(hasErrors) return;
		this.setState({'data':fields, 'errors':errors});

		tracking_event('request_brochure_popup_submit');
		//if(window.ga) ga('send', 'event', 'Popups', 'submit', 'Request Brochure');
		if(window.fbq) fbq('track', 'Lead');
		
		return new Promise((resolve)=>{
			do_post(window.wpco_api_prefix + 'Brochure/RequestBrochureWpc',
				fields, 
			(ret)=>{
				if(ret.responseType!=1) {
					alert(ret.message || "Unknown error.");
				} else {
					this.setState({'success':true});
				}
			}).finally(resolve);
		});
	}
	
	handleClose() {
		if(this.props.onClose) this.props.onClose();
	}
}
