import React from 'react';
import { NavLink } from 'react-router-dom';
var _ = require('lodash');
var queryString = require('query-string');

import { BackLink, do_post, formatHtmlParagraphs, getGiftListSLA, ScrollToHereWithRouter } from '../utils.jsx';

import { 
	getProductBrand,
	getProductImages,
	isAgeVerificationRequired,
	shouldAllowEdit,
	shouldShowBrand,
	shouldShowPrice
} from '../logic.jsx';

import { AddToBasket } from '../components/AddToBasket.jsx'; 
import { AgeRestrictionNotice } from '../components/AgeRestrictionNotice.jsx';
import { CashHandlingFeeNotice } from '../components/CashHandlingFeeNotice.jsx';

export class ProductInfo extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			'quantity':this.props.added !== undefined ? this.props.added : 1, 
			'prodTab':'description', 
			'submitting': false,
			'shouldShowCashChargePopup': false,
			'showingCashChargePopup': false
		};
	}

	componentDidMount() {
		if(window.location.hash=='#add') {
			if(this.props.onAddToList) this.props.onAddToList(1);
		}

		this.updateShouldShowCashChargePopup();
	}

	updateShouldShowCashChargePopup() {
		if(this.props.product && this.props.product.isChargeableCashType) {
			do_post(window.wpco_api_prefix + 'Manage/YourList',
				{}, 
				(ret)=> {
					if(_.filter(
						(((ret || {}).giftList || {}).productsOnList || []),
						item=>item.isChargeableCashType
					).length==0) {
						// has no chargeable-cash-type items on list yet, so able to show a popup
						this.setState({'shouldShowCashChargePopup': true})
					}
				}
			);
		}
	}

	render() {
		//var presentID = this.props.presentID;
		var product = this.props.product;
		var added = this.props.added;
		var submitting = this.props.submitting;

		var title = product.title;
		if(this.props.giftListItem) {
			//is this being shown on a list via ViewList? the title might be different
			title = this.props.giftListItem.description || product.description;
		}

		var backlink = <BackLink to="../" className="back"><span>Back</span></BackLink>;
		var qs = queryString.parse(window.location.search);
		if(qs.scan=="1") {
			backlink = <BackLink to="../" className="back-to-scanner"><span>Back to scanner</span></BackLink>;
		}

		var price = product.formattedPrice;
		if(this.props.giftListItem?.isGroupGift) {
			// show "£x.. contributed, £y... remaining" instead of price for group gifts
			price = this.props.giftListItem.pledgeSummary;
		}
		
		return <div className={"product-view" + ((!shouldAllowEdit(product) || added===true) ? " not-editable":"")}>
			<ScrollToHereWithRouter />
			<div className="mobile-breadcrumb">
				{ this.props.breadcrumb }
			</div>
			<div className="sidebar three columns">
				{ backlink }
			</div>
			<div className="product-list nine columns">
				{ this.props.breadcrumb }
			</div>

			<div className="six columns product-image">
				<ImageSlideshow images={ getProductImages(product, 400) } />
			</div>
			<div className="five columns product-details">
				<h1>{ title }</h1>
				{ shouldShowBrand(product) && <div className="brand">{
					this.props.disableBrandLink ? 
						getProductBrand(product)
						: <NavLink to={"/browse/by-brand/" + product.brandSeoName + "/"}>{ getProductBrand(product) }</NavLink>
				}</div> }
				{ shouldShowPrice(product) && 
					<div className="price">{ price }</div> 
				}
				<div className="code">Range Code: { product.groupId || product.id }</div>
				{ isAgeVerificationRequired(product) && <AgeRestrictionNotice /> }
				{ product.isInActiveOrDiscontinued && <div className="discontinued-widget">
					<p>This product has been discontinued</p>
				</div> }
				{ this.props.onAddToList && <div className={"add-to-list " + (product.isInActiveOrDiscontinued?" disabled":"")}>
					<div className="input">
						<input type="tel" onChange={ this.handleChangeQuantity.bind(this) } value={ this.state.quantity } />
					</div>
					<span className="up-down">
						<a href="#" onClick={ this.handleQuantityUp.bind(this) }>+</a><a href="#"  onClick={ this.handleQuantityDown.bind(this) }>&ndash;</a>
					</span>
					<a href="#" className={"button " + (submitting?" submitting":"") + (added?" added":"")} onClick={ this.handleAddToList.bind(this) }>
						{ added ?
							(added==this.state.quantity ?
								"Added to list"
								: "Update")
							: "Add to list" }
					</a>
				</div> }
				{ this.props.onAddToBasket && <dl>
					<AddToBasket 
						giftListItem={ this.props.giftListItem } 
						cartItem={ this.props.cartItem }
						onAdd={ this.props.onAddToBasket }
						/>
				</dl> }
				{ this.state.showingCashChargePopup && product.isChargeableCashType && <CashHandlingFeeNotice popupOnly={ true } cashChargePercentage={ getGiftListSLA().cashCharge } /> }

				<div className="tabber">
					<ul>
						<li className={ this.state.prodTab=="description" ?"active":""} onClick={()=>{this.setState({'prodTab':'description'});}}>Description</li>
						{ !!product.onlineSpecification && <li className={ this.state.prodTab=="specifications"?"active":""} onClick={()=>{this.setState({'prodTab':'specifications'});}} >Specifications</li> }
					</ul>
					<div className="product-description-text">
						{ this.state.prodTab=="description" && formatHtmlParagraphs(product.htmlDescription) }
						{ this.state.prodTab=="specifications" && formatHtmlParagraphs(product.onlineSpecification) }
					</div>
				</div>

			</div>
			
			{ (this.props.moreLikeThis||[]).length>0 && <div className="twelve columns container">
				<h2 className="lined"><div><span>more</span> like this</div></h2>
				
				<div className="twelve columns product-grid">
					{ this.props.moreLikeThis.slice(0, 8) }
				</div>
			</div> }
			
		</div>;
	}

	componentDidUpdate(prevProps) {
		if(this.props.added != prevProps.added) {
			this.setState({'quantity': this.props.added});

			if(this.props.product.isChargeableCashType && this.state.shouldShowCashChargePopup) {
				this.setState({'showingCashChargePopup':true});
			}
		}

		if(this.props.product != prevProps.product) {
			this.updateShouldShowCashChargePopup();
		}
	}
	
	handleChangeQuantity(ev) {
		this.setState({'quantity':ev.target.value});
	}

	handleQuantityUp(ev) {
		ev.preventDefault();
		var qty = parseInt(this.state.quantity) || 0;
		this.setState({'quantity':Math.min(99, qty+1)});
	}

	handleQuantityDown(ev) {
		ev.preventDefault();
		var qty = parseInt(this.state.quantity) || 0;
		this.setState({'quantity':Math.max(1, qty-1)});
	}
	
	handleAddToList(ev) {
		ev.preventDefault();
		var qty = parseInt(this.state.quantity);

		//can't add discontinued products
		if(this.props.product.isInActiveOrDiscontinued) return;
		
		if(this.props.product.isFundProductType) qty = 99999; //unlimited
		
		if(this.props.onAddToList && qty) {
			var promise = this.props.onAddToList(qty);
			if(promise) {
				this.setState({'submitting':true})
				promise.finally(()=>{
					this.setState({'submitting':false})
				});
			}
		}
	}
}

class ImageSlideshow extends React.Component {
	constructor(props) {
		super(props);
		this.state = {'current':0};
	}
	
	render() {
		var cur = this.state.current;

		var images = this.props.images;

		var lastIndex = images.length-1;
		
		var url = images[cur];

		return <div className={"image-slideshow" + (this.state.changing?" changing":"")}>
			<div className={"left" + (cur==0?' disabled':'')} onClick={ this.handleLeft.bind(this) }></div>
			<div className={"right" + (cur==lastIndex?' disabled':'')} onClick={ this.handleRight.bind(this) }></div>
			<div className="image" style={ {backgroundImage:"url('" + url + "')"} }></div>
		</div>;
	}
	
	handleRight() {
		var images = this.props.images; //_.filter(this.props.images, (img)=>img.width>=350);
		if(this.state.current==(images.length-1)) return;
		this.setState({'changing':true}, ()=>{
			setTimeout(()=>{
				this.setState({'current':Math.min(this.state.current+1, images.length-1), 'changing':false});
			}, 100);
		});
	}

	handleLeft() {
		if(this.state.current==0) return;
		this.setState({'changing':true}, ()=>{
			setTimeout(()=>{
				this.setState({'current':Math.max(this.state.current-1, 0), 'changing':false});
			}, 100);
		});
	}

}
