import React from 'react';
import { Link, NavLink } from 'react-router-dom';
var _ = require('lodash');

import { MyLink } from '../utils.jsx';

import {
	getProductBrand,
	getProductImage,
	getProductSeoName,
	shouldAllowEdit
} from '../logic.jsx';

export class Product extends React.Component {
	constructor(props) {
		super(props);
		this.state = {'quantity':props.added!==undefined ? props.added : 1, 'submitting':false};
	}
	
	render() {
		var listName = this.props.listName || "list";
		var added = this.props.added;
		var submitting = this.props.submitting || this.state.submitting;
		var p = this.props.product;
		if(p.rangeId && !p.range) {
			return <div className="product range" data-product-id={p.id} data-parent-id={this.props.parentId} data-position={this.props.position}>
				<Link to={ (this.props.urlPrefix||"") + 'range-' + p.rangeId + "/" + (this.props.urlSuffix||"") } className="product-link"><div className="image" style={ {backgroundImage:"url('" + getProductImage(p, 250) + "')"} }><div><span>View Range</span></div></div></Link>
				<div className="details">
					<div className="title">{ p.title }</div>
					<div className="brand"><NavLink to={"/browse/by-brand/" + p.brandSeoName + "/"}>{ getProductBrand(p) }</NavLink></div>
					<div className="price">From { p.formattedPrice }</div>
				</div>
				<p><Link className="button product-link" to={ (this.props.urlPrefix||"") + 'range-' + p.rangeId + "/" + (this.props.urlSuffix||"")}>View Range</Link></p>
			</div>;
		} else {
			return <div className={"product" + ((!shouldAllowEdit(p) || added===true) ? " not-editable":"")} data-product-id={p.id} data-parent-id={this.props.parentId} data-position={this.props.position}>
				{ this.props.disableLink ?
					<div className="image" style={ {backgroundImage:"url('" + getProductImage(p, 250) + "')"} }></div>
					: <MyLink to={ (this.props.urlPrefix||"") + getProductSeoName(p) + "/" + (this.props.urlSuffix||"") } className="product-link" draggable="false">
				<div className="image" style={ {backgroundImage:"url('" + getProductImage(p, 250) + "')"} }><div><span>View Details</span></div></div></MyLink> }
				<div className="details">
					<div className="title">{ p.title }</div>
					<div className="brand"><NavLink to={"/browse/by-brand/" + p.brandSeoName + "/"}>{ getProductBrand(p) }</NavLink></div>
					{!p.isFundProductType && <div className="price">{ p.formattedPrice }</div>}
				</div>
				<div className="add-to-list">
					<div className="input">
						<input type="tel" onChange={ this.handleChangeQuantity.bind(this) } value={ this.state.quantity || 1 }  />
					</div>
					<span className="up-down">
						<a href="#" onClick={ this.handleQuantityUp.bind(this) }>+</a><a href="#"  onClick={ this.handleQuantityDown.bind(this) }>&ndash;</a>
					</span>
					<a href="#" className={"button " + (submitting?" submitting":"") + (added?" added":"")} onClick={ this.handleAddToList.bind(this) }>
						{ added ?
							(added==this.state.quantity ?
								("Added to "+listName)
								: "Update")
							: ("Add to "+listName) }
					</a>
				</div>
			</div>;
		}
	}

	//						{ added ? "Added" : "Add" } to list{ added && <div className="quantity-badge">{ added }</div> }
	componentDidUpdate(prevProps) {
		if(this.props.added != prevProps.added) {
			this.setState({'quantity': this.props.added || 1});
		}	
	}

	handleChangeQuantity(ev) {
		this.setState({'quantity':ev.target.value});
	}

	handleQuantityUp(ev) {
		ev.preventDefault();
		var qty = parseInt(this.state.quantity) || 0;
		this.setState({'quantity':Math.min(99, qty+1)});
	}

	handleQuantityDown(ev) {
		ev.preventDefault();
		var qty = parseInt(this.state.quantity) || 0;
		this.setState({'quantity':Math.max(1, qty-1)});
	}
	
	handleAddToList(ev) {
		ev.preventDefault();
		var qty = parseInt(this.state.quantity);
		
		if(this.state.submitting) {
			return;
		}

		if(this.props.onAddToList && qty) {
			var promise = this.props.onAddToList(qty);
			if(promise) {
				this.setState({'submitting':true})
				promise.finally(()=>{
					this.setState({'submitting':false})
				});
			}
		}
	}
}
