import React from 'react';
var _ = require('lodash');

import { do_post, myParseInt } from '../utils.jsx';
import { 
	getItemCategory,
	getProductImage,
	isGiftCard,
	shouldShowRequiredRemaining
} from '../logic.jsx';

import { PriorityWidget } from '../components/PriorityWidget.jsx';

export class MyListPrint extends React.Component {
	constructor(props) {
		super(props);
		this.state = {'list':null, 'sortOrder':'AtoZ', 'view':'list'};
		
		this.fetchList();
	}
	
	fetchList() {
		do_post(window.wpco_api_prefix + 'Manage/YourList',
			{
			}, 
			(ret)=> {
				if(ret.loginRequired) {
					window.location.href = '/login/?next=' + encodeURI('/my-list/');
				} else if(ret.responseType!=1) { //TODO
					alert(ret.message || "Unknown error");
				} else {
					this.setState({'list':ret});
				}
			}
		);
	}
	
	render() {
		if(!this.state.list) return <div key="loading-spinner" className="loading-spinner"></div>;
		
		var items = this.state.list ? this.state.list.giftList.productsOnList : [];
		
		return <div className="container"><div className="twelve columns">
			<table className="result-list view-list my-list">
				<thead>
					<tr><th width="7%">Item</th><th width="5.5%"></th><th width="35%">Name</th>
					{ this.state.list.listClosed ? <React.Fragment>
						<th width="8%" className="center">Bought</th>
						<th width="8%" className="center">Ordered</th>
						<th width="11%" className="center">Date Expected</th>
						<th width="8%" className="center">Received</th>
						<th width="8%" className="center">Delivered</th>
					</React.Fragment> : <React.Fragment>
						<th width="10%">Category</th>
						<th width="7.5%" className="center">Price</th>
						<th width="9.6%" className="center">Required&nbsp;</th>
						<th width="7.7%" className="center">Remaining&nbsp;</th>
					</React.Fragment> }
					</tr>
				</thead>
				<tbody>
					{ _.map(items, (prod, k) => (
						<tr key={k}>
							<td className="thumbnail"><img src={ getProductImage(prod, 150) } width="100%" /></td>
							<td className="priority">{ prod.isPriority && <PriorityWidget active={prod.isPriority} /> }</td>
							<td className="description"><h3>{ prod.coupleDescription || prod.description }</h3>{ prod.brand }</td>
							{ this.state.list.listClosed ? <React.Fragment>
								<td className="center qty-bought">
									{ prod.isGroupGift ? 
										'£'+formatPrice(prod.purchased||0) 
									: 
										(prod.purchased || 0 )
									}</td>
								<td className="center qty-ordered">{ prod.qtyOrdered }</td>
								<td className="center date-expected">{ prod.expectedDate || "-" }</td>
								<td className="center qty-received">{ prod.qtyInWarehouse }</td>
								<td className="center qty-delivered">{ prod.qtyDelivered }</td>
							</React.Fragment> : <React.Fragment>
								<td>{ getItemCategory(prod) }</td>
								
								{ prod.isGroupGift ?
									<td className="center" colSpan="3">Group Gift</td>
									:
									<React.Fragment>
										<td className="price">{ !isGiftCard(prod) && prod.formattedPrice }</td>
										{ shouldShowRequiredRemaining(prod) ? 
											<React.Fragment>
												<td className="center qty-required">{ prod.required }</td>
												<td className="center qty-remaining">{ prod.remaining }</td>
											</React.Fragment> 
										:
											<React.Fragment>
												<td className="center qty-required"></td>
												<td className="center qty-remaining"></td>
											</React.Fragment>
										}
									</React.Fragment>
								}
								
							</React.Fragment> }
						</tr>
					)) }
					{ items.length==0 && <tr>
						<td colSpan="9">You have no items in your list.</td>
						</tr> }
				</tbody>
			</table>
		</div></div>;
	}
}
