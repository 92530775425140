import React from 'react';

import { Product } from '../components/Product.jsx';

import { cookies, do_get_cached, do_post } from '../utils.jsx';

export class BasketUpsells extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        if(!this.props.upsellProducts) return null;

        var addedByProductId = {};
        this.props.pledgeItems.forEach(pi=>{
            addedByProductId[pi.productId] = pi.quantity;
        });

        return <div className="basket-upsells">
            <h2 className="lined"><div>Add a little extra</div></h2>

            <div className="twelve columns product-grid">
            { this.props.upsellProducts.map((p, k)=>
                <Product 
                    product={p}
                    disableLink={true}
                    key={k}
                    position={ k+1 }
                    onAddToList={ this.handleAdd.bind(this, p) }                    
                    added={ addedByProductId[p.id] }
                    listName="basket"
                    urlPrefix="/browse/by-product/"
                    />
            )}
            </div>
        </div>;
    }

    handleAdd(prod, qty) {
        return do_post(window.wpco_api_prefix + "Pledge/AddUpsellItem", {
            "giftListId": this.props.list.giftList.giftListId,
            "productId": prod.id,
            "quantity": qty
        }, (ret)=>{
            if(ret.pledgeBasketReferenceToken) cookies.set('wppBRT', ret.pledgeBasketReferenceToken, {path:'/', maxAge:14*86400, secure:true});
            if(ret.pledgeBasketToken && this.props.onUpdateBasketToken) this.props.onUpdateBasketToken(ret.pledgeBasketToken);
        });
    }
}
