import React from 'react';
var _ = require('lodash');

import { FormInput, MyForm } from '../components/Forms.jsx';

import { do_post } from '../utils.jsx';

export class ForgotPasswordForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {'success':false};
	}
	
	render() {
		var vprops = {'errors':this.state.errors || {}, 'values':this.state.data || {}, 'context':{'focused':false}};
		
		return <MyForm ref="recommend_form" onSubmit={this.handleSubmit.bind(this)}>
			{ this.state.error ? <div className="alert alert-error">{ this.state.error }</div> : '' }
			
			{ this.state.message && <div className="alert alert-success">{ this.state.message }</div> }
			
			<FormInput label="Email address" name="email" required={true} validate="email" {...vprops} />
			
			<div className="form-row"><button type="submit" className="right">Request password reset</button></div>
		</MyForm>
	}	
	
	handleSubmit(fields, errors) {
		var hasErrors = _.find(errors, (v)=>(v!=null));

		if(hasErrors) {
			this.setState({'data':fields, 'errors':errors});
			return Promise.resolve(true);
		}
		
		this.setState({'data':fields, 'errors':errors});
		
		return new Promise((resolve)=>{
			do_post(window.wpco_api_prefix + 'Account/ForgotPassword',
				{
					'email':fields.email
				}, (ret)=> {
					this.setState({'message':ret.message});
				}
			).finally(resolve);
		});
	}
}
