import React from 'react';
import ReactDOM from 'react-dom';
var $ = require('jquery');

export class HelpTooltip extends React.Component {
	constructor(props) {
		super(props);
		this.spanRef = React.createRef();
		this.iconRef = React.createRef();
		this.bodyRef = React.createRef();
		this.leaveTimer = null;
		this.portal = document.createElement('div');
		if(props.className) this.portal.classList.add(props.className);
	}
	
	render() {
		return <React.Fragment>
			<span
				ref={this.spanRef}
				className={ this.props.className }
				onMouseEnter={ this.handleMouseEnter.bind(this) }
				onMouseLeave={ this.handleMouseLeave.bind(this) }
				onTouchStart={ this.handleTouchStart.bind(this) }
				>{ this.props.label || '' } { this.props.iconLabel && 
					<span className="help-tooltip" ref={this.iconRef}>{ this.props.iconLabel }</span> }</span>
			{ ReactDOM.createPortal(
				<div className="help-tooltip-body" ref={this.bodyRef}>{ this.props.children }</div>
			, this.portal) }
		</React.Fragment>;
	}
	
	handleMouseEnter() {
		var iconRect = (this.iconRef.current || this.spanRef.current).getBoundingClientRect();
		var x = iconRect.left+(iconRect.width/2)-2;
		var w = $(this.bodyRef.current).outerWidth();
		var h = $(this.bodyRef.current).outerHeight();

		if((x + (w/2)) > window.innerWidth) {
			this.bodyRef.current.style.left = '';
			this.bodyRef.current.style.right = '10px';
		} else {
			this.bodyRef.current.style.left = Math.max(10, x - (w/2)) + 'px';
			this.bodyRef.current.style.right = '';
		}

		if(this.leaveTimer) {
			clearTimeout(this.leaveTimer);
			this.leaveTimer = null;
		}
		
		this.bodyRef.current.style['top'] = (iconRect.top + window.scrollY - h - 20) + 'px';
		this.bodyRef.current.style.display = 'block';
		setTimeout(()=>{
			this.bodyRef.current.classList.add('in');
		}, 20);
	}
	
	handleMouseLeave() {
		this.bodyRef.current.classList.remove('in');
		this.leaveTimer = setTimeout(()=>{
			this.bodyRef.current.style.display = 'none';
		}, 300);
	}

	handleTouchStart() {
		this.handleMouseEnter();

		var handler = (ev)=>{
			this.handleMouseLeave();
			document.body.removeEventListener('touchstart', handler);
		};
		document.body.addEventListener('touchstart', handler);
	}

	componentDidMount() {
		document.body.querySelector('.frontend').appendChild(this.portal);
	}

	componentWillUnmount() {
		document.body.querySelector('.frontend').removeChild(this.portal);
	}
}
