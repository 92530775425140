import React from 'react';
import { NavLink } from 'react-router-dom';
var _ = require('lodash');

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)

import { do_get_cached, do_post, MoneyInput, myParseInt, ScrollToHereWithRouter } from '../utils.jsx';
import { 
	getProductBrand,
	getProductImage,
	isMoneyQuantity,
	shouldShowCashChargeWarning,
	shouldShowRequiredRemaining
} from '../logic.jsx';

import { BasketUpsells } from '../components/BasketUpsells.jsx'; 
import { CashHandlingFeeNotice } from '../components/CashHandlingFeeNotice.jsx'; 
import { PriorityWidget } from '../components/PriorityWidget.jsx';

export class ViewBasket extends React.Component {
	constructor(props) {
		super(props);
		this.state = {'changes':{}, 'upsellProducts':[]};

		this.fetchUpsells();
	}

	fetchUpsells() {
        do_get_cached(window.wpco_api_prefix + "Product/GetUpsellProducts/", {}, ret=>{
            this.setState({'upsellProducts': ret.products});
        });
    }
	
	componentDidUpdate(prevProps, prevState) {
		if(this.props.pledgeBasketToken != prevProps.pledgeBasketToken) {
			var changes = this.state.changes;
			_.each(this.state.changes, (v,k)=>{
				var ci = _.find(this.props.pledgeBasketToken.pledgeItems, (ci)=>(ci.giftListItemId==k));
				//remove changes that match the new pBT
				if(!ci || (ci.quantity == v)) {
					delete changes[k];
				}
			});
			this.setState({'changes':changes});
		}
	}
	
	getItemRequired(item) {
		if(!shouldShowRequiredRemaining(item)) return '';
		return item.required;
	}
	
	getItemRemaining(item) {
		if(!shouldShowRequiredRemaining(item)) return '';
		return item.remaining;
	}
	
	render() {
		var date = dayjs(this.props.list.giftList.occasionDate, 'DD/MM/YYYY');
		date = date.format("D MMM YYYY");

		var items = [];
		var total = 0;
		if(this.props.pledgeBasketToken) {
			items = this.props.pledgeBasketToken.pledgeItems;
			total = this.props.pledgeBasketToken.pledgeValue;
		}
		
		var listItemsById = {};
		(((this.props.list || {}).giftList || {}).items || []).forEach(i=>{
			listItemsById[i.itemId] = i;
		});

		// Look up any upsell items that are in the basket
		items.forEach(i=>{
			if(i.isUpsellProduct) {
				listItemsById[i.giftListItemId] = this.state.upsellProducts.filter(p=>p.id==i.productId)[0];
			}
		});

		// remove any pledge items that are somehow not on the list (???)
		items = items.filter(item=>listItemsById[item.giftListItemId]);

		total = 0;
		items.forEach(item=>{
			total += item.lineTotal;
		});

		var checkoutEnabled = items.length>0;

		var genQuantityInput = (item)=>{
			// PledgeItem.ItemId is now giftListItemId?
			var value = this.state.changes[item.giftListItemId]!==undefined ? this.state.changes[item.giftListItemId] : item.quantity;
			if(isMoneyQuantity(listItemsById[item.giftListItemId])) {
				return <MoneyInput 
					value={ value } 
					onChange={ this.handleQuantityChange.bind(this, item, listItemsById[item.giftListItemId]) } />;
			} else {
				var maxqty = myParseInt(
					item.isUpsellProduct ? 
					10 
					: listItemsById[item.giftListItemId].remaining
				);
				maxqty = Math.min(maxqty, 180); //arbitrary maximum
				return <select className="quantity" 
					value={ value } 
					onChange={ this.handleQuantityChange.bind(this, item, listItemsById[item.giftListItemId]) }>
						{ _.map(_.range(1, maxqty+1), (i)=>(<option key={i} value={i}>{i}</option>)) }
				</select>;
			}
			
			//return <input type="number" className="quantity" value={ this.state.changes[item.PledgeItem.ItemId]!==undefined ? this.state.changes[item.PledgeItem.ItemId].quantity : item.BasketQuantity } onChange={ this.handleQuantityChange.bind(this, item.PledgeItem.ItemId) } />
		};

		return <React.Fragment>
			<ScrollToHereWithRouter />
			<div className="container basket-header">
				<div className="three columns">&nbsp;</div>
				<div className="nine columns">
					<h2 className="underlined"><span>Your</span> basket</h2>
					<p className="small">{ this.props.list.giftList.salutation }<br />{ date }</p>
				</div>
			</div>
			
			<div className="container">
				<div className="twelve columns">
					<table className="result-list view-list basket-list">
						<thead>
							<tr><th width="17%">Item</th><th width="8.5%"></th><th width="17%">Name</th><th width="16.5%">Category</th><th width="7.5%" className="center">Price</th><th width="9.6%" className="center">Required&nbsp;</th><th width="7.7%" className="center">Remaining&nbsp;</th><th width="9.5%" className="center">Quantity</th><th className="center">Remove</th></tr>
						</thead>
						<tbody>
							{ _.map(items, (item, k) => (
								<React.Fragment key={k + "," + item.itemId}>
								<tr className={ listItemsById[item.giftListItemId].isGroupGift ? "first-row" : "" }>
									{ false ? //   item.PledgeItem.ItemId=="CUSTOMVOUCHER"   FIXME - what is this?
										<td className="thumbnail" rowSpan={ listItemsById[item.giftListItemId].isGroupGift ? 2 : 1 }><img src="/assets/images/thumb-gift.jpg" /></td>
									:
										<td className="thumbnail" rowSpan={ listItemsById[item.giftListItemId].isGroupGift ? 2 : 1 }><img src={ getProductImage(listItemsById[item.giftListItemId], 150) } /></td>
									}
									
									<td className="priority" rowSpan={ listItemsById[item.giftListItemId].isGroupGift ? 2 : 1 }>{ listItemsById[item.giftListItemId].priority ? <PriorityWidget active={true} view="guest" /> : '' }</td>
									{ false ? //  item.PledgeItem.ItemId=="CUSTOMVOUCHER" FIXME - what is this?
										<td>
											<h3>Gift Voucher</h3><p dangerouslySetInnerHTML={{__html:item.PledgeItem.Description}} />
											{ shouldShowCashChargeWarning(item.PledgeItem) && <CashHandlingFeeNotice isGuest={ true } cashChargePercentage={ item.PledgeItem.CashChargePercentage } /> }
										</td>
									:	
										<td>
											<h3>{ listItemsById[item.giftListItemId].coupleDescription || listItemsById[item.giftListItemId].description }</h3>{ getProductBrand(listItemsById[item.giftListItemId]) }
											{ shouldShowCashChargeWarning(listItemsById[item.giftListItemId]) && <CashHandlingFeeNotice isGuest={ true } cashChargePercentage={ listItemsById[item.giftListItemId].cashChargePercentage } /> }
										</td>
									}
									<td className="category">{ listItemsById[item.giftListItemId].categoryDescription }</td>
									
									{ isMoneyQuantity(listItemsById[item.giftListItemId]) ? 
										<td className="center price"></td>
									:
										<td className="center price">{ listItemsById[item.giftListItemId].formattedPrice }</td>
									}
									
									<td className="center qty-required">{ this.getItemRequired(listItemsById[item.giftListItemId]) }</td>
									<td className="center qty-remaining">{ this.getItemRemaining(listItemsById[item.giftListItemId]) }</td>
									
									<td className="center quantity">{ genQuantityInput(item) }</td>
									<td className="center remove"><a href="#" onClick={ this.handleRemove.bind(this, item.giftListItemId) }><picture><source type="image/svg+xml" srcSet="/assets/images/icon-delete.svg" /><img src="/assets/images/icon-delete.png" /></picture></a></td>
								</tr>
								{ listItemsById[item.giftListItemId].isGroupGift && <tr className="second-row is-group-gift">
									<td colSpan="2">Group Gift <div className="help">Contribute towards the cost of this present</div></td>
									<td colSpan="6">{ listItemsById[item.giftListItemId].pledgeSummary }</td>
								</tr> }
								</React.Fragment>
							)) }
							{ items.length==0 && 
								<tr>
									<td colSpan="9" className="is-empty">Your basket is currently empty.</td>
								</tr>
							}
							<tr className="spacer"><td colSpan="7" className="no-border"></td><td></td><td></td></tr>
							<tr className="summary">
								<td colSpan="7" className="no-border"></td>
								<td className="summary-heading">Total</td>
								<td className="summary-value">&pound;{ total.toFixed(2) }</td>
							</tr>
						</tbody>
					</table>
					{ !this.props.list.giftList.hideGuestUpsells && <BasketUpsells 
						list={ this.props.list } 
						upsellProducts={ this.state.upsellProducts } 
						onUpdateBasketToken={ this.props.onUpdateBasketToken }
						pledgeItems={ items }
						/>}
					<div className="twelve columns container right actions">
						{ !_.isEmpty(this.state.changes) && <a className="button blue" href="#" onClick={this.handleUpdateQuantities.bind(this)}>Update quantities</a> }
						<NavLink to="../" className="button blue wide">Back to List</NavLink>
						<a href="#" className={"button blue wide" + (!checkoutEnabled?" disabled":"")} onClick={this.handleCheckout.bind(this)}>Checkout</a>
					</div>
				</div>
			</div>
			
		</React.Fragment>;
	}
	
	handleRemove(prod, ev) {
		ev.preventDefault();
		if(this.props.onRemove) this.props.onRemove(prod);
	}
	
	handleBack(ev) {
		ev.preventDefault();
		if(this.props.onBack) this.props.onBack();
	}
	
	handleQuantityChange(item, listItem, ev) {
		var ch = this.state.changes;
		var q = ev.target.value;
		if(listItem.isGroupGift) {
			var max = parseFloat(listItem.remaining);//parseFloat(prod.valueContributed) + parseFloat(prod.valueRemaining);
			if(_.isFinite(parseFloat(q)) && parseFloat(q)>max) {
				q = max;
			}
			q = parseFloat(parseFloat(q).toFixed(2)); //round to 2dp
		} else {
			q = _.isFinite(parseInt(q)) ? parseInt(q) : q;
		}
		if(parseInt(q)===item.quantity) {
			delete ch[item.giftListItemId];
		} else {
			ch[item.giftListItemId] = q;
		}
		console.log('changes now', ch);
		this.setState({'changes':ch});
	}
	
	handleUpdateQuantities(ev) {
		ev.preventDefault();
		
		if(this.props.onUpdateQuantities) this.props.onUpdateQuantities(this.state.changes);
	}
	
	handleCheckout(ev) {
		ev.preventDefault();
		if(!this.props.pledgeBasketToken || !(this.props.pledgeBasketToken.pledgeItems || []).length) {
			alert('Please add some items to your basket first.');
			return;
		}
		do_post("/api/checkout/", {
			// don't pass entire giftlist, it can be huge - let the endpoint re-request it
			//'giftList':this.props.list.giftList,
			
			'giftListId':this.props.list.giftList.giftListId,
			'password':this.props.password,

			'pledgeBasketToken':this.props.pledgeBasketToken,
			//'pledgeBasketSessionToken':cookies.get('pledgeBasketSessionToken'),
			//'pledgeBasketReferenceToken':cookies.get('wppBRT')
		}, (ret)=>{
			console.log(ret);
			if(ret.redir_url) {
				window.location.href = ret.redir_url;
			}
		});
	}

}
