import React from 'react';

export class DragScroller extends React.Component {
    constructor(props) {
        super(props);
        this.divRef = React.createRef();
    }

    render() {
        return <div className={ this.props.className } ref={ this.divRef }>
            { this.props.children }
        </div>;
    }

    componentDidMount() {
        var el = this.divRef.current;

        var state = {
            down: false,
            startX: null,
            startLeft: null,
            velX: null,
        };

        var moveListener = (ev)=>{
            if(ev.buttons==0) {
                state.down = false;
                el.removeEventListener('mousemove', moveListener);
                return;
            }
            state.velX = ev.movementX;
            el.scrollLeft = state.startLeft - (ev.pageX - state.startX);
            //console.log(ev.pageX, state.startX);
        };

        el.addEventListener('click', (ev)=>{
            ev.preventDefault();
        });

        el.addEventListener('mousedown', (ev)=>{
            ev.preventDefault();
            state.down = true;
            state.startX = ev.pageX;
            state.startLeft = el.scrollLeft;
            state.velX = 0;
            el.style.userSelect = 'none';
            el.addEventListener('mousemove', moveListener);
        });

        el.addEventListener('mouseup', (ev)=>{
            state.down = false;
            el.removeEventListener('mousemove', moveListener);

            var totalX = ev.pageX - state.startX;

            if(Math.abs(totalX) < 5) {
                var a = ev.target.closest('a');

                if(a && a.getAttribute("href") && a.getAttribute("href")!="#") {
                    var mev = new MouseEvent('click');
                    a.dispatchEvent(mev);
                }
            }

            var decay = ()=>{
                el.scrollLeft -= state.velX;
                state.velX *= 0.85;
                if(Math.abs(state.velX)>1) {
                    setTimeout(decay, 20);
                }
            };

            if(Math.abs(totalX) < 5) decay();
        });
    }
}
