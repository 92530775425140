import React from 'react';
import { Link } from 'react-router-dom';
var _ = require('lodash');
var jwt = require('jwt-simple');
var queryString = require('query-string');

import { cookies, do_get, do_post, getGiftListSLA, ScrollToTop } from '../utils.jsx';
import { 
	getProductBrand,
	getProductImage,
	isAgeVerificationRequired,
	shouldShowBrand,
} from '../logic.jsx';

import { Button } from '../components/Button.jsx';
import { EditDeliveryAddressForm } from '../components/EditDeliveryAddressForm.jsx';
import { MyForm } from '../components/Forms.jsx';
import { HelpTooltip } from '../components/HelpTooltip.jsx';
import { Popup } from '../components/Popup.jsx';
import { Availability } from '../components/Availability.jsx';

export class AccountPlaceOrder extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			'fetchOrderListRequestNumber': 0,
			'list': null,
			'orderBasketToken': null, // should this go in the session?
			'quantitiesToAdd': {},
			'checkedItems': {},
			'checkedAll': false,
			'collapsedGroups': {},
			'tab':'branded',
			'placedOrderBasketToken': null,
			'filterPurchased': true,
			'filterUnpurchased': true,
		};

		window.place_order = this.testPlaceOrder.bind(this);
	}

	componentDidMount() { 
		this.fetchDeliveryAddress();

		var qs = queryString.parse(this.props.location.search);
		if(qs.success=="1" && qs.token) {
			// If this is the success page, the previous oBT was passed as a GET param.
			var tok = jwt.decode(qs.token, 'asdfasdf', true);
			var placedOrderBasketToken = JSON.parse(tok.orderBasketToken);
			this.finishOrder(placedOrderBasketToken);

			// we need the order list for the confirmation, but avoid updating
			// the session incase it's actually the old key (since the backend
			// takes a while to finalise)
			this.fetchOrderList(true);
		} else {
			this.fetchOrderList();
		}
	}
	
	fetchOrderList(dontStoreSession) {
  	var req = this.state.fetchOrderListRequestNumber+1;
		this.setState({'fetchOrderListRequestNumber':req}, ()=>{
			do_get(window.wpco_api_prefix + 'Manage/PlaceOrder',
				{}, 
				(ret)=> {
					//ignore update if another has already started
					if(this.state.fetchOrderListRequestNumber>req) return;

					if(ret.loginRequired) {
						window.location.href = '/login/?next=' + encodeURI('/my-list/');
					} else if(ret.responseType!=1) { //TODO
						alert(ret.message || "Unknown error");
					} else {

						if(window.location.hash=="#test-derange") {
							ret.giftList.orderItems.forEach(i=>{
								i.deRange = true;
								i.orderByDate = "2024-01-12T00:00:00";
							});
						}

						this.setState({
							'list':ret, 
							'fetchOrderListRequestNumber':req
						});

						if(dontStoreSession) {
							return;
						}
						
						// store session key
						cookies.set('wpoBSK', ret.orderBasketSessionKey, {path:'/', maxAge:14*86400, secure:true});
						
						this.updateOrderBasketToken(ret.orderBasketToken);
					}
				}
			);
		});
	}

	fetchDeliveryAddress() {
		do_get(window.wpco_api_prefix + 'Manage/GiftListSettingsWpc', {}, 
			(ret)=> {
				if(ret.loginRequired) {
					window.location.href = '/login/?next=' + encodeURI('/my-list/');
				} else if(ret.responseType!=1) {
					alert(ret.message || "Unknown error.");
				} else {
					//this.setState({'listSettings':ret.giftListSettings, 'editingDetails':true});
					var addresses = ret.giftListSettings.addresses.filter(a=>a.type=="delivery" && a.address1 && a.town && a.country);
					addresses.sort((a,b)=> 
						( a.isPrimaryAddress > b.isPrimaryAddress) ?
							-1
							: ( ( a.isPrimaryAddress < b.isPrimaryAddress) ?
								1
								: 0
							)
					);
					this.setState({'deliveryAddress': (addresses[0] || {})});
				}
			}
		);
	}

	finishOrder(token) {
		this.setState({
			'placedOrderBasketToken': token || this.state.orderBasketToken,
			'orderBasketToken': null,
			'quantitiesToAdd': {},
			'checkedItems': {},
			'checkedAll': false,
			'collapsedGroups': {},
		});
		//cookies.set('wpoBT', '', {path:'/', maxAge:14*86400, secure:true});
		cookies.set('wpoBSK', '', {path:'/', maxAge:14*86400, secure:true});
	}

	render() {
		if(!this.state.list) return <div key="loading-spinner" className="loading-spinner"></div>;

		var popup = null;
		if(this.state.showingBasket) {
			popup = <Popup 
				onClose={()=>{this.setState({'showingBasket':false})}}
				className="wide">
				<Basket 
					giftList={ this.state.list.giftList } 
					balances={ this.state.list.balances } 
					deliveryAddress={ this.state.deliveryAddress }
					orderBasketToken={ this.state.orderBasketToken } 
					onRemove={ this.handleBasketRemove.bind(this) }
					onQuantityChange={ this.handleBasketQuantityChange.bind(this) }
					onPlaceOrder={ this.handlePlaceOrder.bind(this) }
					/>
			</Popup>;
		}

		var isPlaced = !!this.state.placedOrderBasketToken;
		var basket = this.state.placedOrderBasketToken || this.state.orderBasketToken;
		var basket_items = (basket && basket.items) ? basket.items : [];

		// annotate with basket entries
		var items = this.state.list.giftList.orderItems.map(oi=>{
			var b = basket_items.filter(bi=> bi.giftListItemId==oi.itemId)[0];
			return Object.assign({}, oi, b);
		});

		items = this.filterItemsByPurchasedness(items);

		var groupings = {};
		this.filterItemsByTab(items).forEach(prod=>{
			var k = prod.websiteCategoryPath || {
				branded: "Uncategorised",
				vouchers: "Vouchers",
				funds: "Funds",
				custom: "Custom products"
			}[this.state.tab];
			if(!groupings[k]) {
				groupings[k] = [];
			}
			groupings[k].push(prod);
		});

		var balances = this.state.list.balances;

		var amountToPay = basket?.balanceToPay;
		var remaining = basket?.balanceRemaining;

		var TabLink = (props)=>{
			return <a href="#" 
					className={this.state.tab==props.name ? "in" : ""}
					onClick={ (ev)=>{ this.setState({'tab':props.name}); ev.preventDefault(); } }
					>{ props.children }</a>
		};

		var SpendHelpTooltip = ()=>(
			<HelpTooltip iconLabel="i" className="pototals__tooltip">You can spend all your guest pledges on any of our Branded Products or Subscriptions. This means that you can use any guest pledges placed against your Cash Funds or Custom Products towards Branded Products.You cannot use guest pledges placed against Branded Products towards Cash Funds or Custom Products.</HelpTooltip>
		);

		var sla = getGiftListSLA();
		var canFulfilCustomProducts = !!sla.fulfilCustomProduct;

		return <div>
			<div className="container poheader">
				{ isPlaced ?
					<React.Fragment>
						<ScrollToTop />
						<h2>Thank you for your order</h2>
						<p>Our purchasing team will now start placing orders with our suppliers for you. <br />You can track your order from the <Link to="/my-list/my-orders/">My Orders</Link> section of your Dashboard and we&lsquo;ll email you with a monthly update.</p>
					</React.Fragment>
					: <h2>Place order</h2>
				}
				<div className="poheader__infobox">Our current lead times are 8 to 12 weeks, with many presents arriving before this. To find out more, please contact your Wedding List Advisor</div>

				<ol>
					<li className={isPlaced ? "disabled": ""}>
						<h4>Place order</h4>
						<p>Once we have received your confirmed selections, we will place your order with our suppliers. You can place as many orders as you like.</p>
					</li>
					<li>
						<h4>Track Order Status</h4>
						<p>You can keep track of your orders from your dashboard. We will also email you a monthly update. We expect all your orders to have arrived in our warehouse within 8 to 12 weeks.</p>
					</li>
					<li>
						<h4>Arrange Delivery</h4>
						<p>Once your presents have arrived in our warehouse, you can arrange your delivery on a day and time that suits you.</p>
					</li>
				</ol>
			</div>

			{ isPlaced && <div className="bg-soft">
				<div className="container posummary">
					<div>
						<div className="posummary__totals">
							<p>Order number: &nbsp;&nbsp; <strong>{ basket.headerId }</strong></p>
							<p>Order total: &nbsp;&nbsp; <strong>&pound;{ basket.total?.toFixed(2) }</strong></p>
						</div>

						<p><Link className="button" to="/my-list/my-orders/">My orders</Link></p>

						<p className="posummary__order-link"><a href="/my-list/place-order/">Order more gifts</a></p>
					</div>

					<OrderSummary 
						giftList={ this.state.list.giftList } 
						deliveryAddress={ this.state.deliveryAddress }
						orderBasketToken={ this.state.placedOrderBasketToken } 
						/>
				</div>
			</div> }
			
			{ !isPlaced && <div className="bg-soft">
				{ popup }

				<div className="container flex-row potabs">
					<div><TabLink name="branded">Branded products</TabLink></div>
					<div><TabLink name="vouchers">Vouchers</TabLink></div>
					<div><TabLink name="custom">Custom products</TabLink></div>
					<div><TabLink name="funds">Cash funds</TabLink></div>
				</div>
			
				<div className="container">
					<div className="pocat-filterbar">
						<label><input type="checkbox" className="green-checkbox" checked={ this.state.checkedAll } onChange={ this.handleCheckAll.bind(this) } /> Select all</label>
						<div className="pocat-filterbar__dropdown" tabIndex="-1">Filter
							<ul>
								<li><label><input type="checkbox" className="green-checkbox" checked={ this.state.filterPurchased } onChange={ (ev)=>{ this.setState({filterPurchased:ev.target.checked}); } } /> Purchased</label></li>
								<li><label><input type="checkbox" className="green-checkbox" checked={ this.state.filterUnpurchased } onChange={ (ev)=>{ this.setState({filterUnpurchased:ev.target.checked}); } }/> Unpurchased</label></li>
							</ul>
						</div>
						{ Object.entries(this.state.checkedItems).length>0 && (this.state.tab == "branded" || (this.state.tab == "custom" && canFulfilCustomProducts)) && <Button type="button" onClick={ this.handleAddChecked.bind(this) }>Add selected to basket</Button> }
					</div>
					<div className="flex-row mobile-reverse">
						<div className={"pocat-list pocat-list__" + this.state.tab}>
							{ Object.entries(groupings).map(([name, prods])=>
								<ListEntryGrouping 
									key={name}
									name={name} 
									products={prods} 
									quantities={ this.state.quantitiesToAdd }
									collapsed={ this.state.collapsedGroups[name] }
									checkedItems={ this.state.checkedItems }
									onAddToOrder={ this.handleAddToOrder.bind(this) }
									onCheck={ this.handleCheck.bind(this) }
									onQuantityChange={ this.handleQuantityChange.bind(this) }
									onToggle={ ()=>{ 
										this.setState({'collapsedGroups': Object.assign(
											this.state.collapsedGroups,
											{[name]: !this.state.collapsedGroups[name]}
											)}); 
										} }
									/>
							) }
							
							{ this.state.tab=="branded" && Object.keys(groupings).length==0 &&
								<p className="pocat-list__empty">There are no items available to order. &nbsp;<a href="/browse/">Add gifts to your list</a></p>
							}
							{ this.state.tab=="vouchers" && Object.keys(groupings).length==0 &&
								<p className="pocat-list__empty">There are no items available to order. &nbsp;<a href="/browse/">Add gifts to your list</a></p>
							}
							{ this.state.tab=="funds" && Object.keys(groupings).length==0 &&
								<p className="pocat-list__empty">There are no funds available to withdraw.</p>
							}
							{ this.state.tab=="custom" && Object.keys(groupings).length==0 &&
								<p className="pocat-list__empty">There are no items available to order. &nbsp;<a href="/browse/">Add gifts to your list</a></p>
							}
						</div>
						<div className="pototals">
							<div className="pototals__box">
								{ this.state.tab == "branded" && <React.Fragment>
									<p>You currently have <span className="pototals__big">&pound;{ balances.availableForProductOrder?.toFixed(2) }</span> to spend on branded products
									
									<HelpTooltip iconLabel="i" className="pototals__tooltip">This balance is the total sum of your guest pledges. It includes all pledges towards Branded Products, Vouchers, Funds and Custom Products.<br /><br />This total balance can be spent on any Branded Product.</HelpTooltip>
									</p>
								</React.Fragment> }
								{ this.state.tab == "vouchers" && <React.Fragment>
									<p>You currently have <span className="pototals__big">&pound;{ balances.voucherAndExperiences?.toFixed(2) }</span> to spend on vouchers
									
									<HelpTooltip iconLabel="i" className="pototals__tooltip">This balance is the total sum of guest pledges towards Vouchers.<br /><br />This total balance can be spent on Vouchers or any Branded Product.</HelpTooltip>
									</p>
								</React.Fragment> }
								{ this.state.tab == "funds" && <React.Fragment>
									<p>You currently have <span className="pototals__big">&pound;{ (balances.charity + balances.cash)?.toFixed(2) }</span> to withdraw in cash funds
									
									<HelpTooltip iconLabel="i" className="pototals__tooltip">This balance is the total sum of guest pledges towards a Cash Fund.<br /><br />You can withdraw this balance by emailing office@weddingpresentco.com or you can spend it on any Branded Products.</HelpTooltip>
									</p>
									<p><a href="/browse/">Add more gifts to your list</a></p>
								</React.Fragment> }
								{ this.state.tab == "custom" && canFulfilCustomProducts && <React.Fragment>
									<p>You currently have <span className="pototals__big">&pound;{ (balances.customProduct)?.toFixed(2) }</span> to spend on custom products 
									
									<HelpTooltip iconLabel="i" className="pototals__tooltip">This balance is the total sum of guest pledges towards Custom Products.<br /><br />You can use this balance on any Custom or Branded Product. It cannot be withdrawn as Cash.</HelpTooltip>
									</p>
								</React.Fragment> }
								{ this.state.tab == "custom" && !canFulfilCustomProducts && <React.Fragment>
									<p>You currently have <span className="pototals__big">&pound;{ (balances.customProductFund||0)?.toFixed(2) }</span> to spend on custom products 
									
									<HelpTooltip iconLabel="i" className="pototals__tooltip">This balance is the total sum of guest pledges towards Custom Products.<br /><br />You can withdraw this balance by emailing office@weddingpresentco.com or you can spend it on any Branded Products.</HelpTooltip>
									</p>
									<p><a href="/browse/">Add more gifts to your list</a></p>
								</React.Fragment> }
								{ (this.state.tab == "branded" || this.state.tab == "vouchers" || (this.state.tab == "custom" && canFulfilCustomProducts)) && <React.Fragment>
									<div className="pototals__grid">
										<div>Order total:</div>
										<div>£{ (basket ? basket.total : 0).toFixed(2) }</div>
										{ !!basket && basket.discount>0 && <React.Fragment>
											<div>{ basket.discountPercentage?.toFixed(0) }% discount:</div>
											<div>-£{ basket.discount?.toFixed(2) }</div>
										</React.Fragment> }
										{ amountToPay > 0 &&
											<React.Fragment>
												<div>Extra to pay:</div>
												<div>£{ (amountToPay)?.toFixed(2) }</div>
											</React.Fragment> }
										{ remaining > 0 &&
											<React.Fragment>
												<div>Balance remaining:</div>
												<div>£{ remaining?.toFixed(2) }</div>
											</React.Fragment> }
									</div>
									<div className="flex-row ai-center">
										<a href="#" className="button" onClick={ this.handleShowBasket.bind(this) }>View basket</a>
										<p className="text-right"><a href="/browse/">Add more gifts to your list</a></p>
									</div>
									<br />
								</React.Fragment> }
							</div>
						</div>
					</div>
				</div>
			</div> }
		</div>;
	}


	filterItemsByPurchasedness(items) {
		return items.filter(prod=>{
			if(this.state.filterPurchased && prod.qtyPledged>0) return true;
			if(this.state.filterUnpurchased && prod.qtyPledged==0) return true;
			return false;
		});
	}

	filterItemsByTab(items) {
		return items.filter(prod=>{
			if(this.state.tab == "branded" && (
				prod.isVoucher 
				|| prod.isFundProductType 
				|| prod.isCustomProduct
			)) {
				return false;
			} else if(this.state.tab == "vouchers" && !prod.isVoucher) {
				return false;
			} else if(this.state.tab == "funds" && !prod.isFundProductType ) {
				return false;
			} else if(this.state.tab == "custom" && !prod.isCustomProduct ) {
				return false;
			}
			return true;
		});
	}

	handleShowBasket(ev) {
		ev.preventDefault();
		this.setState({'showingBasket':true});
	}

	updateOrderBasketToken(encoded) {
		return new Promise((resolve, reject)=>{
			//cookies.set('wpoBT', encoded, {path:'/', maxAge:14*86400, secure:true});

			var tok = jwt.decode(encoded, 'asdfasdf', true);
			var orderBasketToken = JSON.parse(tok.orderBasketToken);
			this.setState({
				'orderBasketToken': orderBasketToken,
				//'placedOrderBasketToken': orderBasketToken, // for testing
			}, resolve);
		});
	}

	testPlaceOrder() {
		this.setState({
			'placedOrderBasketToken': this.state.orderBasketToken,
		});
	}

	handleAddToOrder(prod) {
		var qty = this.state.quantitiesToAdd[prod.itemId]===undefined ? 1 : this.state.quantitiesToAdd[prod.itemId];

		//if(prod.isGroupGift) {
			// for group gifts, you can only add (or remove) the whole thing
		//	qty = parseInt(qty)===0 ? 0 : 1;
		//}
	
		var req = {
			giftListId: this.state.list.giftList.giftListId,
			items: [
				{
					giftListItemId: prod.itemId,
					quantity: qty,
					remove: !parseInt(qty),
				}
			]
		};
		
		var ret = new Promise((resolve, reject)=>{
			do_post(window.wpco_api_prefix + 'Manage/UpdateOrderBasket',
				req,
				(ret)=> {
					this.updateOrderBasketToken(ret.orderBasketToken);

					// unset from quantitiesToAdd
					this.setState({
						quantitiesToAdd: Object.assign(this.state.quantitiesToAdd, {
							[prod.itemId]: undefined
						})
					});
					
					resolve();
				}
			);
		});

		return ret;
	}

	handleCheck(prod, v) {
		var checkedItems = this.state.checkedItems;
		if(v) {
			checkedItems[prod.itemId] = v;
		} else {
			delete checkedItems[prod.itemId];
		}
		this.setState({
			'checkedItems': checkedItems,
			'checkedAll': false
		});
	}

	handleCheckAll(ev) {
		if(this.state.checkedAll) {
			this.setState({
				'checkedItems':{},
				'checkedAll':false
			});
		} else {
			var all = this.filterItemsByTab(this.state.list.giftList.orderItems).filter(p=>!p.isDiscontinued).map(p=>p.itemId);
			this.setState({
				'checkedItems': Object.fromEntries(all.map(i=>[i,i])),
				'checkedAll': true
			});
		}
	}

	handleAddChecked(ev) {
		return new Promise((resolve, reject)=>{
			var checkedItems = this.state.checkedItems;

			var req = {
				giftListId: this.state.list.giftList.giftListId,
				items: this.filterItemsByTab(this.state.list.giftList.orderItems).filter(p=>checkedItems[p.itemId]).map(p=>(
					{
						giftListItemId: p.itemId,
						quantity: this.state.quantitiesToAdd[p.itemId] || 1,
						remove: false,
					}
				)),
			};
			
			var ret = new Promise((resolve, reject)=>{
				do_post(window.wpco_api_prefix + 'Manage/UpdateOrderBasket',
					req,
					(ret)=> {
						this.updateOrderBasketToken(ret.orderBasketToken);

						var quantitiesToAdd = Object.fromEntries(
							Object.entries(this.state.quantitiesToAdd)
								  .filter((k,v)=>!checkedItems[k])
						);
						

						// unset from quantitiesToAdd
						this.setState({
							quantitiesToAdd: quantitiesToAdd,
							checkedItems: {},
							checkedAll: false,
						});
						
						resolve();
					}
				);
			});
		});
	}

	handleQuantityChange(prod, qty) {
		this.setState({
			quantitiesToAdd: Object.assign(this.state.quantitiesToAdd, {
				[prod.itemId]: qty
			})
		});
		
		
	}

	handleBasketRemove(prod) {
		var req = {
			giftListId: this.state.list.giftList.giftListId,
			items: [
				{
					giftListItemId: prod.giftListItemId,
					remove: true
				}
			]
		};
		
		do_post(window.wpco_api_prefix + 'Manage/UpdateOrderBasket',
			req,
			(ret)=> {
				this.updateOrderBasketToken(ret.orderBasketToken);
			}
		);

		// prematurely remove the item from the local oBT so it disappears immediately
		this.state.orderBasketToken.items = this.state.orderBasketToken.items.filter(
			i=>i.giftListItemId!=prod.giftListItemId
		);
		this.setState({'orderBasketToken': this.state.orderBasketToken});
	}

	handleBasketQuantityChange(prod, qty) {
		var req = {
			giftListId: this.state.list.giftList.giftListId,
			items: [
				{
					giftListItemId: prod.itemId,
					quantity: qty,
				}
			]
		};
		
		do_post(window.wpco_api_prefix + 'Manage/UpdateOrderBasket',
			req,
			(ret)=> {
				this.updateOrderBasketToken(ret.orderBasketToken);
			}
		);

		// mark the item as loading
		this.state.orderBasketToken.items = this.state.orderBasketToken.items.map(
			i=>
				i.giftListItemId==prod.itemId ?
				Object.assign({}, i, {loading:true})
				: i
		);
		this.setState({'orderBasketToken': this.state.orderBasketToken});
		
	}

	handlePlaceOrder(address) {
		var basket = this.state.orderBasketToken;

		if(address && (!address.address1 || !address.town || !address.country)) {
			create_alert('Please enter a valid delivery address.');
			return;
		}

		try {
			var stack = "";
			try {
				stack = Error().stack
			} catch {}
			if(window.rmon_report) {
				rmon_report({
					'type':'debug', 
					'url':"" + window.location.toString(), 
					'msg':"" + JSON.stringify(basket), 
					'stack':stack
				});
			}
		} catch {}

		var updateAddress = () => new Promise((resolve, reject)=>{
			// If no address, succeed immediately
			if(!address) return resolve();
			// else save the address
			do_post(window.wpco_api_prefix + 'Manage/UpdateGiftListAddress',
				{address: Object.assign(
					{},
					address,
					{
						// update existing address if there is one
						"id": this.state.deliveryAddress ? this.state.deliveryAddress.id : 0,
						"type": "delivery",
						//"isPrimaryAddress": false,
					}
				)},
				(ret)=> {
					if(ret.responseType!=1) {
						create_alert(ret.message || 'There was an error updating your delivery address.');
						reject();
					} else {
						resolve();
					}
				}
			);
		});
		
		if(basket.balanceToPay==0) {
			return updateAddress().then(()=>{
				return new Promise((resolve, reject)=>{
					do_get(window.wpco_api_prefix + 'Manage/PlaceCoupleOrder',
						{},
						(ret)=> {
							if(ret.responseType!=1) {
								create_alert(ret.message || 'There was an unknown error placing your order.');
							}
							this.updateOrderBasketToken(ret.orderBasketToken).then(()=>{
								this.finishOrder();
							});
						}
					);
				});
			});
		} else {
			return updateAddress().then(()=>{
				return new Promise((resolve, reject)=>{
					do_get(window.wpco_api_prefix + 'Manage/GenerateCheckoutSession',
						{},
						(ret)=> {
							this.updateOrderBasketToken(ret.orderBasketToken).then(()=>{
								// store the checkoutSessionId as a cookie
								cookies.set('wpoCS', this.state.orderBasketToken.checkoutSessionId, {path:'/', maxAge:14*86400, secure:true});
							
								// store the orderBasketToken in the session
								do_post('/api/order-checkout/', {
									'checkoutSessionId': this.state.orderBasketToken.checkoutSessionId,
									'orderBasketToken': ret.orderBasketToken,
								}, ()=>{
									if(this.state.orderBasketToken.checkoutSessionUrl) {
										window.location.href = this.state.orderBasketToken.checkoutSessionUrl;
									}
								});
							
							});
						}
					);
				});
			});
		}
	}

}

class RemoveIcon extends React.Component {
	render() {
		return <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
			<line y1="-1" x2="10.986" y2="-1" transform="matrix(0.685365 -0.7282 0.685365 0.7282 2.46899 10)" stroke="#41414C" strokeWidth="2"/>
			<line y1="-1" x2="10.986" y2="-1" transform="matrix(-0.685365 -0.7282 -0.685365 0.7282 8.52734 10)" stroke="#41414C" strokeWidth="2"/>
		</svg>;
	}
}

class QuantitySelect extends React.Component {
	render() {
		var quantities = Array.from(Array(this.props.maxQty).keys()).map(i=>i+1);
		if(this.props.includeZero) quantities.splice(0, 0, 0);
		return <select onChange={ this.handleChange.bind(this) } value={this.props.value}>
			{ quantities.map(i=> <option key={i}>{ i }</option>) }
		</select>;
	}

	handleChange(ev) {
		if(this.props.onChange) this.props.onChange(ev.target.value);
	}
}

class ListEntryGrouping extends React.Component {
	render() {
		return <div className={ "pocat " + (this.props.collapsed?"pocat__collapsed":"") }>
			<div className="pocat__title">{ this.props.name }
				<a href="#" onClick={ this.handleToggle.bind(this) } >
					<svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M1 1L5 5L9 1" stroke="#47513A" strokeWidth="2"/>
					</svg>
				</a>
			</div>
			<div className="pocat__headings">
				<div>Item</div>
				<div className="pocat__heading-quantity">Qty</div>
				<div className="text-right pr-1">Price</div>
				<div className="text-center">Pledged</div>
				<div className="text-center">Ordered</div>
			</div>
			{ this.props.products.map(p=>
				<ListEntry 
					key={ p.itemId }
					product={ p } 
					checked={ !!this.props.checkedItems[p.itemId] }
					quantity={ this.props.quantities[p.itemId] }
					onAddToOrder={ this.props.onAddToOrder } 
					onCheck={ this.props.onCheck }
					onQuantityChange={ this.props.onQuantityChange }
					/>
			) }
		</div>;
	}

	handleToggle(ev) {
		ev.preventDefault();
		if(this.props.onToggle) this.props.onToggle();
	}
}

class ListEntry extends React.Component {
	render() {
		var prod = this.props.product;

		var selectedQuantity = prod.quantity;
		if(this.props.quantity!==undefined) {
			selectedQuantity = this.props.quantity;
		}

		var showQuantity = null;
		if(/*prod.isGroupGift && */this.props.onQuantityChange) {
			//showQuantity = "Group Gift";
		} else if(prod.isFundProductType) {
			showQuantity = 1;
		} else if(prod.isCustomProduct) {
			showQuantity = prod.qtyRequired;
		} else if(!this.props.onQuantityChange) {
			showQuantity = prod.quantity;
		}

		var sla = getGiftListSLA();
		var canFulfilCustomProducts = !!sla.fulfilCustomProduct;

		var showCheck = !!this.props.onCheck;
		var checkDisabled = 
			!!prod.isDiscontinued
			|| prod.outOfStock;
		var showRemove = !!this.props.onRemove;
		var showQuantityOnOrder = !this.props.hideQuantityOnOrder;
		var showLineTotal = this.props.showLineTotal;
		var isAddable =
			(!prod.isCustomProduct || prod.qtyPledged >= prod.qtyRequired) &&
			!prod.isFundProductType &&
			!!this.props.onAddToOrder &&
			!prod.isDiscontinued &&
			!prod.outOfStock &&
			(!prod.isCustomProduct || canFulfilCustomProducts);

		var isAgeRestricted = isAgeVerificationRequired(prod);

		var maxQty = 30; //prod.isGroupGift ? 1 : 30;

		return <div className={"pocat__product " + (prod.isDiscontinued?" pocat__discontinued":"") + (prod.outOfStock?" pocat__outofstock":"")}>
			{ showCheck ? <div className="pocat__checkbox"><input type="checkbox" className="green-checkbox" checked={ this.props.checked } onChange={ this.handleCheck.bind(this) } disabled={ checkDisabled || null } /></div> : null }
			<div><div className="pocat__thumbnail" style={ {backgroundImage:"url('" + getProductImage(prod, 150) + "')"} }></div></div>
			<div className="pocat__description">
				{ prod.coupleDescription || prod.description }<br />
				{ shouldShowBrand(prod) && <a href={"/browse/by-brand/" + prod.brandSeoName + "/"} className="pocat__description-brand">{ getProductBrand(prod) }</a> }
				</div>
			<div className="pocat__quantity">
				{ showQuantity!==null ? 
					showQuantity
					: <QuantitySelect 
						onChange={ this.handleQuantityChange.bind(this) } 
						maxQty={ maxQty }
						value={ selectedQuantity }
						includeZero={ !!prod.quantity && !showRemove }
						/> 
				}
			</div>
			<div className="pocat__price pr-1 text-right">{ prod.isFundProductType ? 
				("£"+ (prod.qtyPledged || 0).toFixed(0))
				: ("£"+ (prod.orderSalePrice || 0).toFixed(2)) }</div>
			{ showLineTotal ? 
				<div className="text-right pocat__line-total">{ prod.loading ? <div className="loading-spinner"></div> : ("£" + (prod.lineTotal || 0).toFixed(2)) }</div>
				: <div className="pocat__stat pocat__pledged text-center">
						{ prod.isGroupGift ? 
							( prod.remaining > 0 ? 0 : 1 )
							: prod.qtyPledged }</div>
			}
			{ showRemove ? 
				<div className="text-center pocat__remove"><a href="#" onClick={ this.handleRemove.bind(this) }><RemoveIcon /></a></div>
				: <React.Fragment>
					{ showQuantityOnOrder && <div className="pocat__stat text-center pocat__on-order">{ prod.qtyOnOrder }</div>}
					{ isAddable && <div className="pocat__actions">
						{ (!!prod.quantity && prod.quantity==selectedQuantity) ?
							<button type="button" disabled={true}>Added to order</button>
							: <Button type="button"
								onClick={ this.handleAddToOrder.bind(this) }
								>
								{ (!prod.quantity || (selectedQuantity==prod.quantity)) ?
									"Add to order"
									: "Update quantity"
								}
								</Button>
						}
						{ prod.isUpsellProduct && <div className="pocat__top-up-gift"><HelpTooltip label="Guest Addition" className="pocat__top-up-gift-notice">This present was kindly added as a 'Guest Addition' by your guest. The choice is yours; you can order this item or use the pledged amount towards any of our branded products.</HelpTooltip></div> }
					</div> }
				</React.Fragment>
			}
			{ ( prod.isGroupGift || prod.isDirectDelivery || isAgeRestricted || prod.isDiscontinued ) &&
				<div className="pocat__status-line">
					{ prod.isGroupGift && <div className="pocat__group-gift-status">
						<div>Group Gift</div>
						<div>Contributed: <strong>&pound;{ (prod.qtyPledged || 0).toFixed(2) }</strong></div>
						<div>Remaining: <strong>&pound;{ (prod.remaining || 0).toFixed(2) }</strong></div>
					</div> }
					{ isAgeRestricted && <div className="age-restriction-notice"><HelpTooltip label="Age restricted" iconLabel="18+" className="age-restriction-notice">This product is age restricted and is only available to persons aged 18 years or over. By adding this product to your list, you declare that you are over 18 years old. A valid ID will be required for delivery once the product has been ordered.</HelpTooltip></div> }
					{ prod.isDirectDelivery && <div className="pocat__direct-delivery"><HelpTooltip label="Item delivered directly" iconLabel="i" className="age-restriction-notice">This product will be delivered to you directly by the Supplier.</HelpTooltip></div> }
				</div>
			}
			<Availability product={prod} productId={prod.id} />
		</div>;
	}

	handleAddToOrder(ev) {
		if(this.props.onAddToOrder) return this.props.onAddToOrder(this.props.product);
	}

	handleRemove(ev) {
		ev.preventDefault();
		this.props.onRemove(this.props.product);
	}

	handleCheck(ev) {
		if(this.props.onCheck) this.props.onCheck(this.props.product, ev.target.checked);
	}

	handleQuantityChange(qty) {
		if(this.props.onQuantityChange) this.props.onQuantityChange(this.props.product, qty);
	}
}

class Basket extends React.Component {
	constructor(props) {
		super(props);
		// this.state = {'ageRestrictionConfirmed':false};
		this.ageCheckboxRef = React.createRef();
		this.addressCheckboxRef = React.createRef();

		var addrValid = props.deliveryAddress && props.deliveryAddress.address1 && props.deliveryAddress.town;
		
		this.state = {
			'editingDeliveryAddress': !addrValid,
			'deliveryAddressFormErrors': {},
		};
	}
	render() {
		var basket = this.props.orderBasketToken;
		var balances = this.props.balances;
		var amountToPay = basket?.balanceToPay;
		var remaining = basket?.balanceRemaining;

		console.log(balances);
		console.log(basket);
		
		var products = basket.items.map(bi=>{
			var p = this.props.giftList.orderItems.filter(oi=> oi.itemId==bi.giftListItemId)[0];
			return Object.assign({}, p, bi);
		});

		var isAgeRestricted = products.filter(bi=>( bi.boolAttributes || []).filter(a=>(a.name=="AgeVerificationNeeded" && a.value)).length).length>0;
		var isDirectDelivery = products.filter(bi=>bi.isDirectDelivery).length>0;
		var isVoucher = products.filter(bi=>bi.isVoucher).length>0;

		return <div className="pobasket">
			<h2>Basket</h2>
			<div className="pobasket__infobox">Our current lead times are 8 to 12 weeks, with many presents arriving before this. To find out more, please contact your Wedding List Advisor</div>
			<div className="flex-row">
				<div className="pocat is-basket">
					<div className="pocat__headings">
						<div>Item</div>
						<div className="pocat__heading-quantity">Qty</div>
						<div className="text-right pr-1">Price</div>
						<div className="text-right">Subtotal</div>
						<div className="text-center">Remove</div>
					</div>
					{ products.map(p=>
						<ListEntry 
							key={ p.giftListItemId }
							product={ p } 
							onRemove={ this.handleRemove.bind(this) } 
							onQuantityChange={ this.handleQuantityChange.bind(this) }
							showLineTotal={ true }
							/>
					) }
					{ products.length==0 && <div className="pocat__empty">
						There are no items in your basket.
					</div> }
				</div>
				<div className="pototals">
					<div className="pototals__box">
						<h3>Your Summary</h3>
						<div className="pototals__grid">
							<div>Order total:</div>
							<div>£{ (basket ? basket.total : 0)?.toFixed(2) }</div>

							{ !!basket && basket.discount>0 && <React.Fragment>
								<div>{ basket.discountPercentage?.toFixed(0) }% discount:</div>
								<div>-£{ basket.discount?.toFixed(2) }</div>
							</React.Fragment> }
							
							<div>Balance on account:</div>
							<div>£{ balances.total?.toFixed(2) }</div>

							{ remaining > 0 &&
								<React.Fragment>
									<div>Balance remaining:</div>
									<div>£{ remaining?.toFixed(2) }</div>
								</React.Fragment> }

							{ !!(isVoucher && amountToPay) && <React.Fragment>
								<div className="pototals__message">
									<strong>Please Note:</strong> Branded Product balance cannot be used to purchase vouchers. As you have a voucher in your basket but do not have enough voucher balance, you can pay the extra now.
								</div><div style={{display: "none"}}></div>
								</React.Fragment> }

							{ amountToPay > 0 &&
								<React.Fragment>
									<div>Extra to pay:</div>
									<div>£{ amountToPay?.toFixed(2) }</div>
								</React.Fragment> }

						</div>
						{ isAgeRestricted && <div>
							<label>
								<input type="checkbox" className="green-checkbox" required ref={ this.ageCheckboxRef } />
								Your basket contains age-restricted items that are only available to those over 18 years of age. Please tick this box to confirm that you are over the age of 18 and that you acknowledge a photographic ID will be required by our couriers when delivering this item.
							</label>
						</div> }
						{ isDirectDelivery ?
							<MyForm onSubmit={this.handleUpdateDeliverySubmit.bind(this)}>
								<div className="pototals__delivery">
									<h3>Delivery address</h3>

									{ this.state.editingDeliveryAddress ?
										<EditDeliveryAddressForm 
											deliveryAddress={ this.props.deliveryAddress }
											errors={ this.state.deliveryAddressFormErrors } />
										: <React.Fragment>
											<p>{ this.deliveryAddressRows().map((r, i)=>
													( <React.Fragment key={i}>{ r }<br /></React.Fragment> )
												) }
												<a href="#" onClick={ (ev)=>{ this.setState({"editingDeliveryAddress": true}); ev.preventDefault(); } }>Edit</a>
											</p>
											<label>
												<input type="checkbox" className="green-checkbox" required ref={ this.addressCheckboxRef } />
												Confirm delivery address
											</label>
										</React.Fragment>
									}
								</div> 
								<div className="flex-row ai-center">
									<Button type="submit" disabled={ products.length==0 }>
										Place your order
									</Button>
									<p><a href="/browse/">Order more gifts</a></p>
								</div>
							</MyForm>
							: <div className="flex-row ai-center">
								<Button type="button" onClick={ this.handlePlaceOrder.bind(this) } disabled={ products.length==0 }>
									Place your order
								</Button>
								<p><a href="/browse/">Order more gifts</a></p>
							</div>
						}
					</div>
				</div>
			</div>
		</div>;
	}

	handleRemove(prod) {
		if(this.props.onRemove) this.props.onRemove(prod);
	}

	handleQuantityChange(prod, qty) {
		if(this.props.onQuantityChange) this.props.onQuantityChange(prod, qty);
	}

	handlePlaceOrder(ev) {
		if(this.ageCheckboxRef.current && !this.ageCheckboxRef.current.reportValidity()) return;
		if(this.addressCheckboxRef.current && !this.addressCheckboxRef.current.reportValidity()) return;
		if(this.props.onPlaceOrder) return this.props.onPlaceOrder();
	}

	handleUpdateDeliverySubmit(data, errors) {
		this.setState({'deliveryAddressFormErrors': errors});
		var hasErrors = _.find(errors, (v)=>(v!=null));
		if(hasErrors) return;
		
		if(this.ageCheckboxRef.current && !this.ageCheckboxRef.current.reportValidity()) return;
		if(this.addressCheckboxRef.current && !this.addressCheckboxRef.current.reportValidity()) return;

		// if we weren't editing, don't send an address down
		if(!this.state.editingDeliveryAddress) data = null;

		if(this.props.onPlaceOrder) return this.props.onPlaceOrder(data);
	}

	deliveryAddressRows() {
		//var addr = {"id":179068,"giftListId":118157,"address1":"46 Mayfield Road","address2":"","address3":"","town":"loondon","county":"","postcode":"sw139jj","country":"United Kingdom","isPrimaryAddress":true,"firstName":"","surname":"","phoneNumber":"","type":"delivery"};
		var addr = this.props.deliveryAddress || {};

		var rows = [
			addr.address1 || "",
			addr.address2 || "",
			addr.address3 || "",
			addr.town || "",
			addr.county || "",
			addr.postcode || "",
			addr.country || "",
		];
		rows = rows.filter(i=>i.trim());
		return rows;
	}
}

class OrderSummary extends React.Component {
	render() { 
		var basket = this.props.orderBasketToken;

		var products = basket.items.map(bi=>{
			var p = this.props.giftList.orderItems.filter(oi=> oi.itemId==bi.giftListItemId)[0];
			return Object.assign({}, p, bi);
		});

		return <div>
			<div className="flex-row">
				<div className="pocat is-summary">
					<div className="pocat__headings">
						<div>Item</div>
						<div>Qty</div>
						<div className="text-right">Price</div>
						<div className="text-right">Subtotal</div>
					</div>
					{ products.map(p=>
						<ListEntry 
							key={ p.giftListItemId }
							product={ p } 
							hideQuantityOnOrder={ true }
							showLineTotal={ true }
							/>
					) }
					{ products.length==0 && <div className="pocat__empty">
						There are no products on this order.
					</div> }
				</div>
				<div className="pototals">
					<div className="pototals__box">
						<div className="pototals__delivery">
							<h3>Delivery address</h3>
							<p>{ this.deliveryAddressRows().map((r, i)=>
									( <React.Fragment key={i}>{ r }<br /></React.Fragment> )
								) }
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>;
	}

	deliveryAddressRows() {
		//var addr = {"id":179068,"giftListId":118157,"address1":"46 Mayfield Road","address2":"","address3":"","town":"loondon","county":"","postcode":"sw139jj","country":"United Kingdom","isPrimaryAddress":true,"firstName":"","surname":"","phoneNumber":"","type":"delivery"};
		var addr = this.props.deliveryAddress || {};

		var rows = [
			addr.address1 || "",
			addr.address2 || "",
			addr.address3 || "",
			addr.town || "",
			addr.county || "",
			addr.postcode || "",
			addr.country || "",
		];
		rows = rows.filter(i=>i.trim());
		return rows;
	}
}
