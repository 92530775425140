import React from 'react';
var _ = require('lodash');

import { do_get, ScrollToTop } from '../utils.jsx';

import { DashboardMenu } from '../components/DashboardMenu.jsx';
import { SectionExpander } from '../components/SectionExpander.jsx';

export class AccountHelp extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.fetchPage();
	}
	
	fetchPage() {
		do_get('/api/page/', {'url':'account-help/'}, (ret)=>{
			console.log(ret.page);
			this.setState({'page':ret.page});
		});
	}
	
	render() {
		if(!this.state.page) return <div key="loading-spinner" className="loading-spinner"></div>;
		
		return <div className="container">
			<ScrollToTop />
			<DashboardMenu />
			<div className="nine columns container">
				<div className="nine columns">
					<h2 className="underlined">Help &amp; <span>support</span></h2>
				</div>
				
				<div className="four columns" dangerouslySetInnerHTML={{__html: this.state.page.main[0].text }} />
				<div className="five columns" dangerouslySetInnerHTML={{__html: this.state.page.main[1].text }} />
				<div className="nine columns">
					<br /><br />
					{ _.map(this.state.page.main.slice(2), (b,k)=>(this.renderBlock(b,k))) }
				</div>
			</div>
		</div>;
	}
	
	
	
	renderBlock(block, k) {
		if(block.type=="faq_block") {
			return <SectionExpander key={k} title={block.title} text={block.text} />;
		}
	}
}
