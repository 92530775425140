import React from 'react';

import { AddToListProcess } from './AddToListProcess.jsx';
import { DragScroller } from '../components/DragScroller.jsx';
import { Popup } from '../components/Popup.jsx';
import { Product } from '../components/Product.jsx';
import { do_post_cached, parse_hash } from '../utils.jsx';

function extractUrlParams(s) {
    var i = s.indexOf("?");
    if(i!=-1) return s.substring(i+1);
    return s;
}

export class SimilarProducts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            'products': null,
            
            'adding': null,
            'addingQuantity': null,
            'added': {},
        };
    }

    componentDidMount() {
        this.fetchSimilarProducts(this.props.link);
    }

    fetchSimilarProducts(link) {
        var search = link;
        var i = -1;
        if(i = search.indexOf("?"), i >- 1) search = search.substring(i);
        if(i = search.indexOf("#"), i >- 1) search = search.substring(0, i);
        var usp = new URLSearchParams(search);
        
        var args = parse_hash(link);
        var filters = [{'filterName': 'KnownLongLeadTime', 'text': 'No' }];
        Object.keys(args).forEach(k=>{
            if(k=="cat") {
                filters.push({
                    'filterName':'Category', 
                    'text':args[k][0],
                });
            } else if(k=="prices") {
                var bits = args[k][0].split(",");
                filters.push({
                    'filterName':'Price', 
                    'from':parseInt(bits[0]),
                    'to':parseInt(bits[1])
                });
            }
        });
        
        do_post_cached(window.wpco_api_prefix + 'Product/Search', {
                'q': usp.get('q'),
                'searchText': usp.get('q'),
                'page': 1,
                'pageSize': 48,
                'sortBy': 'default',
                'filters': filters,
            },
            (ret)=>{
                this.setState({
                    'products': ret.products?.slice(0, 8)
                });
            }
        );
    }

    render() {
        var link = this.props.link;
        if(link.indexOf("#")>-1) {
            link += "&kll=false";
        } else {
            link += "#kll=false";
        }

        return <React.Fragment>
            <AddToListProcess 
                adding={ this.state.adding } 
                addingQuantity={ this.state.addingQuantity }
                onStopAdding={ ()=>{ this.setState({'adding': null}); } }
                onUpdateAddedQuantities={ (added)=>{ this.setState({'added': added}); } }
                />

            <Popup className="wide centered lighter" onClose={ this.handleClose.bind(this) }>
                <h2>Similar Products</h2>
                <p>Explore similar products we think you might love. Not what you’re looking for? Our expert team are on hand to provide personalised gift recommendations. Please don’t hesitate to get in touch.</p>
                <DragScroller className="similar-scroller">
                { this.state.products===null && <div key="loading-spinner" className="loading-spinner"></div> }
                { (this.state.products||[]).map((p, k)=>
                    <Product 
                        onAddToList={ (qty)=>{ this.setState({'adding': p, 'addingQuantity': qty}); } }
                        added={ this.state.added[p.id] }
                        product={p}
                        key={k}
                        urlPrefix="/browse/by-product/"
                        />
                ) }
                </DragScroller>
                <a href={ link } className="button blue">View full list of suggestions</a>
            </Popup>

        </React.Fragment>;
    }

    handleClose() {
        if(this.props.onClose) this.props.onClose();
    }
}
