import React from 'react';
var _ = require('lodash');

export class ListFilterByDropdown extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			filterByOpen:false,
			sortByOpen:false
		};
	}
	
	render() {
		return <React.Fragment>
			{ this.props.filters && <div className={"filter-by-dropdown" + (this.state.filterByOpen?" open":"")} 
				onBlur={this.handleBlur.bind(this, 'filterByOpen')}
				tabIndex="-1"
				>
				<p onClick={()=>{this.setState({filterByOpen:!this.state.filterByOpen})}}>{ this.genFilterLabel() }</p>
				<ul>
					{ _.map(this.props.filters, (f)=>(
						<li key={f[0]}><a href="#" onClick={this.handleFilter.bind(this, f[0])}>{ f[1] }</a></li>
					)) }
				</ul>
			</div> }
			
			{ this.props.sorts && <div className={"filter-by-dropdown" + (this.state.sortByOpen?" open":"")}
				onBlur={this.handleBlur.bind(this, 'sortByOpen')}
				tabIndex="-1"
				>
				<p onClick={()=>{this.setState({sortByOpen:!this.state.sortByOpen})}}>{ this.genSortLabel() }</p>
				<ul>
					{ _.map(this.props.sorts, (f)=>(
						<li key={f[0]}><a href="#" onClick={this.handleSort.bind(this, f[0])}>{ f[1] }</a></li>
					)) }
				</ul>
			</div> }
		</React.Fragment>;
	}
	
	genFilterLabel() {
		var r = _.filter(this.props.filters, (f)=>(f[0]==this.props.currentFilter));
		if(r.length) return r[0][1];
		return "Filter";
	}

	genSortLabel() {
		var r = _.filter(this.props.sorts, (f)=>(f[0]==this.props.currentSort));
		if(r.length) return r[0][1];
		return "Sort";
	}
	
	handleBlur(k, ev) {
		if (!ev.currentTarget.contains(ev.relatedTarget)) {
			var state = {};
			state[k] = false;
			this.setState(state);
		}
	}

	handleFilter(k, ev) {
		ev.preventDefault();
		if(this.props.onFilter) this.props.onFilter(k);
		this.setState({filterByOpen: false});
	}
	
	handleSort(k, ev) {
		ev.preventDefault();
		if(this.props.onSort) this.props.onSort(k);
		this.setState({sortByOpen: false});
	}
}
