var _ = require('lodash');

import { cookies } from './utils.jsx';

/* Business logic type things */


/* item/list functions */

// try normal and capitalised 'k' as keys in obj
function cap(obj, k) {
	if(obj[k]!==undefined) return obj[k];
	k = k.substring(0, 1).toUpperCase() + k.substring(1);
	return obj[k];
}

export function canMakeGroupGift(item) {
	if(isGiftCard(item)) return false;
	if(cap(item, 'isDiscontinued')) return false;
	return cap(item, 'allowConvertToGroupGift');
	//console.log(item);
	//return !isGiftCard(item) && !item.isFund && item.purchased==0;
}

export function canRevertGroupGift(item) {
	return cap(item, 'allowRevertToGroupGift');
}

export function isGiftCard(item) {
	return cap(item, 'id')==1 || cap(item, 'id')==540865; //pre-prod
}

export function isPendingCustomProduct(item) {
	return !!cap(item, 'customProductPending');
}

export function isMoneyQuantity(item) {
	// funds are not money quantities, since they're multiplied by the fund price, except for WPC funds which are always £1 increments?
	return cap(item, 'isGroupGift') || isGiftCard(item) || cap(item, 'isFundProductType');
}

export function shouldShowRequiredRemaining(item) {
	return !isGiftCard(item) && !cap(item, 'isUnlimited') && !cap(item, 'isGroupGift') && !cap(item, 'isFundProductType');
}

export function shouldShowBrand(item) {
	if( isGiftCard(item) || 
		cap(item, 'isCustomProduct') || 
		cap(item, 'isCustomProductFund') || 
		cap(item, 'isFundProductType')) return false;
	var desc = cap(item, 'brandDescription');
	if(desc=="TWS Internal") return false;
	return !!desc;
}

export function shouldShowPrice(item) {
	//if(isGiftCard(item) || item.isGroupGift || item.isCustomProduct || item.isCustomProductFund || item.isFundProductType) return false;
	if(isGiftCard(item) || cap(item, 'isGroupGift') || cap(item, 'isFundProductType')) return false;
	return true;
}

export function shouldShowCashChargeWarning(item) {
	return cap(item, 'hasCashCharge') || cap(item, 'isChargeableCashType');
}

export function isAgeVerificationRequired(item) {
	return _.filter(cap(item, 'boolAttributes') || [], (attr)=>
		attr.name=="IsAgeVerificationRequired" && attr.value
	).length>0;
}

export function shouldAllowEdit(item) {
	/* 
	Don't allow custom funds to be edited, since we can only change the
	CoupleDescription (and not the Description), and the couple may have already
	set a (now immutable) custom Description when they added it. 
	*/
	if(cap(item, 'isFundProductType')) return false;

	return !isGiftCard(item) && !cap(item, 'isGroupGift'); //editing group gifts destroys them !?
}

export function getPurchased(item) {
	if(cap(item, 'isGroupGift')) {
		// 'purchased' is money amount
		return '£'+(cap(item, 'purchased')||0).toFixed(2);
	}

	if(cap(item, 'isFundProductType') || isGiftCard(item)) {
		// show money amount
		return '£'+((cap(item, 'purchased')||0)*(cap(item, 'salePrice')||0)).toFixed(2);
	}

	// show quantity
	return cap(item, 'purchased') || 0;
}

export function getListValue(items) {
	var getItemTotal = (item)=>{
		if(cap(item, 'isUnlimited') || isGiftCard(item) || cap(item, 'required')>9999) {
			return 0; // giftvouchers/funds don't contribute to list value
		}
		if(cap(item, 'isGroupGift')) return cap(item, 'required')==='' ? NaN : cap(item, 'required');
		return cap(item, 'salePrice') * cap(item, 'required');
	}
	return _.sum(_.map(items, getItemTotal));
}

export function getPurchaseValue(items) {
	var getItemPurchaseValue = (item)=>{
		if(cap(item, 'isGroupGift')) return cap(item, 'purchased');
		return cap(item, 'salePrice') * cap(item, 'purchased');
	};

	return _.sum(_.map(items, getItemPurchaseValue));
}


export function	getItemCategory(item) {
	if(isGiftCard(item)) return null;
	var bits = (item.websiteCategoryPath || '').split(/\//g);
	if(bits.length>=2) return bits[1];
	return bits[0];
}


export function sortedFilteredListItems(items, sortOrder, filter, search) {
	var items = [].concat(items);

	if(search) {
		search = search.trim().toLowerCase();

		items = items.filter(i=>(
			(i.title || '').toLowerCase().indexOf(search)>-1
			|| (i.brandDescription || '').toLowerCase().indexOf(search)>-1
		));
	}
	
	if(filter=='purchased') {
		items = _.filter(items, (p)=>(p.purchased==p.required));
	} else if(filter=='unpurchased') {
		items = _.filter(items, (p)=>(p.purchased<p.required));
	}
	
	if(sortOrder=='price asc') {
		items.sort((a,b)=>(a.salePrice-b.salePrice));
	} else if(sortOrder=='price desc') {
		items.sort((a,b)=>(b.salePrice-a.salePrice));
	} else if(sortOrder=='name') {
		items.sort((a,b)=>((a.description||'').localeCompare(b.description||'')));
	} else if(sortOrder=='category') {
		items.sort((a,b)=>{
			var ca = getItemCategory(a);
			var cb = getItemCategory(b);
			ca = ((ca||'')==''?'zzzzz':ca); //force empty string to sort to bottom
			cb = ((cb||'')==''?'zzzzz':cb);
			return ca.localeCompare(cb)
		});
	} else if(sortOrder=='custom') {
		items.sort((a,b)=>(a.customOrder-b.customOrder));
	} else {
		var sortKey = (a)=>((a.isPriority||a.priority)?0:1);
		items.sort((a,b)=>(sortKey(a)-sortKey(b)));
	}
	
	return items;
}

export function getListStatusText(list) {
	if(list.stateId<3) return "Pending";
	if(list.stateId>3) return "Closed";
	return "Open";
}

export function getListTypeText(list) {
	if(list.isFixed) return "Fixed";
	return "Flexible";
}

export function isListTypeChangeable(list) {
	if(window.location.search=="?show_fixed_flexible_popup") return true;

	return list.canChangeListFixedStatus;
}


export function getProductBrand(item) {
	if(!shouldShowBrand(item)) return '';
	return cap(item, 'brandDescription');
}

export function getProductImage(product, width) {
	var images = getProductImages(product, width);
	return images[0];


	var images = cap(product, 'images') || [];
	var index = _.min(images.map(i=>(i.sort||i.Sort||0)));
	images = _.filter(images, i=>((i.sort==index)||(i.Sort==index)));
	
	if(images.length==0) return '';
	
	//images.sort((a,b)=>((b.width||b.Width||0)-(a.width||a.Width||0)));
	
	var ret = '';
	for(var i in images) {
		if(ret && (images[i].width||images[i].Width||0)<width) return ret;
		ret = images[i].url || images[i].Url;
	}
	return ret;
}

export function getProductImages(product, width) {
	var images = cap(product, 'images') || [];

	images = images.map(i=>({
		'url': cap(i, 'url'),
		'width': cap(i, 'width'),
		'sort': cap(i, 'sort'),
	}));

	/*
	images = [
		{url: "https://www.weddingpresentco.com/i/products/G/GuestTowelEgypt-155718-1001.jpg", width:800, sort:0},
		{url: "https://www.weddingpresentco.com/i/products/G/GuestTowelEgypt-155718-1001.jpg", width:600, sort:0},
		{url: "https://www.weddingpresentco.com/i/products/G/GuestTowelEgypt-155718-1001.jpg", width:400, sort:0},
		{url: "https://www.weddingpresentco.com/i/products/G/GuestTowelEgypt-155718-1001.jpg", width:400, sort:2},
		{url: "https://www.weddingpresentco.com/i/products/G/GuestTowelEgypt-155718-1001.jpg", width:800, sort:3},
		{url: "https://www.weddingpresentco.com/i/products/G/GuestTowelEgypt-155718-1001.jpg", width:600, sort:3},
		{url: "https://www.weddingpresentco.com/i/products/G/GuestTowelEgypt-155718-1001.jpg", width:400, sort:3},
	];
	*/

	/*
	Sometimes all of the .sort values are zero, which messes up our attempts to group them by sort/size. Do a first pass to fix any zero sorts, assuming that they are ordered largest->smallest in each group.
	*/

	// if(product.id==137616) {
	// 	console.log('images was', images);
	// }

	var all_same_sort = true;
	_.each(images, img=>{
		if(img.sort!=images[0].sort) all_same_sort = false;
	});
	//console.log('all same sort?', all_same_sort);

	if(all_same_sort) {
		var last_width = 100000;
		var cur_sort = 1;
		_.each(images, img=>{
			if(img.width>=last_width) cur_sort++;
			//if(img.sort==0 || all_same_sort) 
			img.sort = cur_sort;
			last_width = img.width;
		});
	}

	images.sort((a,b)=>(b.width-a.width)); 
	images.sort((a,b)=>(a.sort-b.sort));

	// if(product.id==137616) {
	// 	console.log('images is', images);
	// }

	//filter out tiny images if larger ones exist (due to API data problems)
	var large_images = images.filter(i=>i.width>=350);
	if(large_images.length) images = large_images;

	var ret = [];
	var prev_img = null;
	var got_sort = null;
	_.each(images, img=>{
		//if we haven't got one of this sort yet, and we're past the smallest suitable size or the sort has changed, add.
		if(prev_img && prev_img.sort!=got_sort && (img.width<width || prev_img.sort!=img.sort)) {
			ret.push(prev_img.url);
			got_sort = prev_img.sort;
		}

		prev_img = img;
	});

	if(prev_img && prev_img.sort!=got_sort) {
		ret.push(prev_img.url);
	}

	if(ret.length==0) {
		ret.push('/assets/images/placeholder.png');
	}

	return ret;
}

export function getExpectedDate(prod) {
	if(prod.qtyOrdered > 0 && prod.qtyInWarehouse==prod.qtyOrdered) return "Received";
	if(prod.qtyOrdered > 0 && prod.qtyDelivered==prod.qtyOrdered) return "Delivered";
	return prod.expectedDate || "-";
}

export function getProductSeoName(prod) {
	if(prod.productSeoName=='products') {
		//doesn't have a sensible productSeoName (custom product?) so make one up
		return "product-" + prod.id;
	}
	return prod.productSeoName;
}

export function getProductPageUrl(prod) {
	return "/browse/by-product/" + getProductSeoName(prod) + '/';
}

export function getPledgeBasketQuantity(pledgeBasketToken, giftList) {
	// as shown in header basket widget
	var listItemsById = {};
	((giftList || {}).items || []).forEach(i=>{
		listItemsById[i.itemId] = i;
	});

	var quantity = 0;
	if(pledgeBasketToken && pledgeBasketToken.pledgeItems) {
		quantity = _.sum(_.map(pledgeBasketToken.pledgeItems, i=>{
			if(listItemsById[i.giftListItemId] && isMoneyQuantity(listItemsById[i.giftListItemId])) {
				// just return a single item for group gifts/funds
				return 1;
			} else {
				return i.quantity;
			}
		}));
	}

	// don't count the CGM if present
	if(pledgeBasketToken.HasCGM) {
		quantity -= 1;
	}

	return quantity;
}

export const isIslandsOrHighlandsPostcode = postcode => {
	const islandsOrHighlandsPostcodeTests = [
	  "^GY[0-9]{1,2}[\\s]*([\\d][A-Za-z]{2})$",
	  "^IM[0-9]{1,2}[\\s]*([\\d][A-Za-z]{2})$",
	  "^JE[0-9]{1,2}[\\s]*([\\d][A-Za-z]{2})$",
	  "^HS[1-9][\\s]*([\\d][A-Za-z]{2})$",
	  "^IV([1-9]|[1-4][0-9]|5[0-6])[\\s]*([\\d][A-Za-z]{2})$",
	  "^BT[0-9]{1,2}[\\s]*([\\d][A-Za-z]{2})$",
	  "^KA(27|28)[\\s]*([\\d][A-Za-z]{2})$",
	  "^KW([1-4]|1[5-7])[\\s]*([\\d][A-Za-z]{2})$",
	  "^PA(20|34|(3[7-9])|4[1-8]|(6[0-9]|7[0-8]))[\\s]*([\\d][A-Za-z]{2})$",
	  "^PH((19|2[0-6])|(3[0-9]|4[0-4])|49|50)[\\s]*([\\d][A-Za-z]{2})$",
	  "^TR2[1-5][\\s]*([\\d][A-Za-z]{2})$",
	  "^ZE[1-3][\\s]*([\\d][A-Za-z]{2})$"
	];
  
	for (const t in islandsOrHighlandsPostcodeTests) {
	  	const regex = new RegExp(islandsOrHighlandsPostcodeTests[t]);
	  	if (regex.test(postcode)) return true;
	}
  
	return false;
};

export const isInternalDeliveryAddress = postcode => {
	// Internal means abnormal, requiring external internal processing.
	// External means normal UK, so can go straight to an external courier.

	if (isIslandsOrHighlandsPostcode(postcode)) return true;
  
	const ukPostcodeRegex = new RegExp(
	  "^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$"
	);
  
	const isUkPostcode = ukPostcodeRegex.test(postcode);
  
	return !isUkPostcode;
};

export function canFulfilCustomProducts() {
	// Whether the user's SLA allows them to place orders for custom products via the Place Order mechanism.
	return true;
}

export function getPriceTiers(prices) {
	// devise some price tiers given a list of prices
	
	if(!prices.length) return [];

	const threshold = _.mean(prices)/5 + _.max(prices)/10;
	const increments = [100, 50, 25, 10, 5, 1];
	const increment = increments.filter(p=>p<=threshold)[0];
	if(!increment) return [];

	return [
		[0, increment],
		[increment, increment*2],
		[increment*2, increment*5],
		[increment*5, increment*10],
		[increment*10, null],
	];
}
