import React from 'react';
var _ = require('lodash');
var jwt = require('jwt-simple');

import { cookies, do_get, do_post } from '../utils.jsx';
import { countries } from '../countries.js';

import { DashboardMenu } from '../components/DashboardMenu.jsx';
import { FormInput, MyForm } from '../components/Forms.jsx';
import { Popup } from '../components/Popup.jsx';


export class AccountAdmin extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.fetchDetails();

		var country_choices = _.map(countries, (c)=>[c.ISO2, c.Name]);
		var rem = _.remove(country_choices, (c)=>(c[0]=='GB'));
		this.country_choices = _.concat(rem, country_choices);
		this.country_names = _.fromPairs(_.map(countries, (c)=>[c.ISO2, c.Name]));
	}
	
	render() {
		if(!this.state.listSettings) return <div key="loading-spinner" className="loading-spinner"></div>;
		
		var det = this.state.listSettings;
		
		var vprops = {'errors':this.state.errors || {}, 'values':{} || {}, 'context':{'focused':false}};
		
		if(this.state.editing=='details' && det.people[this.state.editingId]) {
			vprops.values = det.people[this.state.editingId];
			vprops.values.firstName = vprops.values.firstname;
			vprops.values.lastName = vprops.values.lastname;
		} else if(this.state.editing=='married_names') {
			vprops.values.afterTitle = det.afterTitle;
		} else if(this.state.editing=='address' && (this.state.editingId)) {
			vprops.values = _.find(det.addresses, (a)=>a.id==this.state.editingId);
		}


		var addr = det.addresses[0];

		return <div className="container">
			<DashboardMenu />
			<div className="nine columns">
				<h2 className="underlined">Admin</h2>
				
				{ this.state.editing == 'details' && 
					<Popup onClose={()=>{this.setState({'editing':null})}}>
						<h2>Edit your details</h2>

						<MyForm onSubmit={this.handleEditSubmit.bind(this)}>
						<FormInput label="First name" name="firstName" required={true} {...vprops} />
						<FormInput label="Last name" name="lastName" required={true} {...vprops} />
						<FormInput label="Home telephone" name="phoneHome" validate="phone" required={true} {...vprops} />
						<FormInput label="Work telephone" name="phoneWork" validate="phone" required={false} {...vprops} />
						<FormInput label="Mobile telephone" name="phoneMobile" validate="phone" required={false} {...vprops} />
						<FormInput label="Email" name="email" required={true} validate="email" {...vprops} />
						<div className="form-row"><button type="submit" className="right">Update</button></div>
						</MyForm>
					</Popup>
				}

				{ det.people[0] && <div className="section">
					<a className="edit" href="#" onClick={this.handleEditDetails.bind(this, 'details', 0) }>Edit details</a>
					<h3>Your details</h3>
					<p><strong>{ det.people[0].fullName }</strong><br />
					<strong>Home:</strong> { det.people[0].phoneHome }<br />
					<strong>Work:</strong> { det.people[0].phoneWork }<br />
					<strong>Mobile:</strong> { det.people[0].phoneMobile }<br />
					<strong>Email:</strong> { det.people[0].email }</p>
				</div> }

				{ det.people[1] && <div className="section">
					<a className="edit" href="#" onClick={this.handleEditDetails.bind(this, 'details', 1) }>Edit details</a>
					<h3>Your partner's details</h3>
					<p><strong>{ det.people[1].fullName }</strong><br />
					<strong>Home:</strong> { det.people[1].phoneHome }<br />
					<strong>Work:</strong> { det.people[1].phoneWork }<br />
					<strong>Mobile:</strong> { det.people[1].phoneMobile }<br />
					<strong>Email:</strong> { det.people[1].email }</p>
			</div> }

				{ this.state.editing == 'married_names' && 
					<Popup onClose={()=>{this.setState({'editing':null})}}>
						<h2>Edit your married names</h2>

						<MyForm onSubmit={this.handleEditSubmit.bind(this)}>
						<FormInput label="Married names" name="afterTitle" required={true} {...vprops} />
						<div className="form-row"><button type="submit" className="right">Update</button></div>
						</MyForm>
					</Popup>
				}

				<div className="section">
					<a className="edit" href="#" onClick={this.handleEditDetails.bind(this, 'married_names', 1) }>Edit details</a>
					<h3>Your married names</h3>
					<p><strong>{ det.afterTitle }</strong><br /></p>
				</div>


				{ this.state.editing == 'address' && 
					<Popup onClose={()=>{this.setState({'editing':null})}}>
						<h2>Edit your { vprops.values.type } address</h2>

						<MyForm onSubmit={this.handleEditSubmit.bind(this)}>
						<FormInput label="Address" name="address1" required={true} {...vprops} />
						<FormInput label="" name="address2" required={false} {...vprops} />
						<FormInput label="Town" name="town" required={true} {...vprops} />
						<FormInput label="County" name="county" required={false} {...vprops} />
						<FormInput label="Postcode" name="postcode" required={true} {...vprops} />
						<FormInput label="Country" name="country" type="select" choices={this.country_choices} required={true} {...vprops} />
						<FormInput label="Telephone" name="phoneNumber" required={false} {...vprops} validate="phone" />
						<div className="form-row"><button type="submit" className="right">Update</button></div>
						</MyForm>
					</Popup>
				}
								
				{ _.map(det.addresses, (addr, k)=>
					<div className="section" key={k}>
						<a className="edit" href="#" onClick={this.handleEditDetails.bind(this, 'address', addr.id) }>Edit details</a>
						<h3>Your { addr.type } address</h3>
						<p>{ addr.address1 }<br />
						{ addr.address2 }{ addr.address2 && <br /> }
						{ addr.address3 }{ addr.address3 && <br /> }
						{ addr.town }{ addr.town && <br /> }
						{ addr.county }{ addr.county && <br /> }
						{ addr.postcode }{ addr.postcode && <br /> }
						{ addr.country && this.country_names[addr.country] }{ addr.country && <br /> }
						<strong>Telephone:</strong> { addr.phoneNumber }</p>
					</div>
				) }
				
				{ this.state.editing == 'password' && 
					<Popup onClose={()=>{this.setState({'editing':null})}}>
						<h2>Change your password</h2>
						
						<p>We will email you a link to reset your password.</p>
						
						{ this.state.resetMessage ? 
							<div className="alert alert-success">{ this.state.resetMessage }</div> 
						:
							<MyForm onSubmit={this.handleEditSubmit.bind(this)}>
							<div className="form-row"><button type="submit" className="right">Request password reset</button></div>
							</MyForm>
						}
					</Popup>
				}				
				
				<div className="section">
					<a className="edit" href="#" onClick={this.handleEditDetails.bind(this, 'password', 0) }>Edit details</a>
					<h3>Password</h3>
				</div>
			</div>
		</div>;
	}
	
	handleEditDetails(key, id, ev) {
		ev.preventDefault();
		this.setState({'editing':key, 'editingId':id, 'data':_.clone(this.state.details)});
	}
	
	fetchDetails() {
		do_get(window.wpco_api_prefix + 'Manage/GiftListSettingsWpc', {}, 
			(ret)=> {
				if(ret.loginRequired) {
					window.location.href = '/login/?next=' + encodeURI('/my-list/');
				} else if(ret.responseType!=1) {
					alert(ret.message || "Unknown error.");
				} else {
					this.setState({'listSettings':ret.giftListSettings, 'editingDetails':true});
				}
			}
		);
	}
	
	handleEditSubmit(fields, errors) {
		var hasErrors = _.find(errors, (v)=>(v!=null));
		if(hasErrors) {
			this.setState({'data':fields, 'errors':errors});
			return;
		}
		
		this.setState({'data':fields, 'errors':errors});
		
		if(this.state.editing=="password") {
			var tok = jwt.decode(cookies.get('wpaT'), 'asdfasdf', true) || {};
			var email = tok['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'];
			if(!email) {
				alert("Unable to retrieve email.");
			} else {
				do_post(window.wpco_api_prefix + 'Account/ForgotPassword',
					{
						'email':email
					}, (ret)=> {
						this.setState({'resetMessage':ret.message});
					}
				);
			}
		} else if(this.state.editing=="address") {
			var addr = _.find(this.state.listSettings.addresses, a=>a.id==this.state.editingId);
			fields = _.assign({}, addr, fields);
			
			do_post(window.wpco_api_prefix + 'Manage/UpdateGiftListAddress',
				{'address':fields},
				(ret)=>{
					if(ret.loginRequired) {
						window.location.href = '/login/?next=' + encodeURI('/my-list/');
					} else if(ret.responseType!=1) {
						alert(ret.message || "Unknown error.");
					} else {
						this.setState({'editing':null});
						this.fetchDetails();
					}
				}
			);
		} else if(this.state.editing=="married_names") {
			do_post(window.wpco_api_prefix + 'Manage/UpdateGiftListSettingsWpc',
				fields,
				(ret)=>{
					if(ret.loginRequired) {
						window.location.href = '/login/?next=' + encodeURI('/my-list/');
					} else if(ret.responseType!=1) {
						alert(ret.message || "Unknown error.");
					} else {
						this.setState({'editing':null});
						this.fetchDetails();
					}
				}
			);
		} else if(this.state.editing=="details") {
			var data = null;
			data = _.assign({}, fields, {personId:this.state.listSettings.people[this.state.editingId].personId});

			do_post(window.wpco_api_prefix + 'Manage/UpdateGiftListPerson',
				data,
				(ret)=>{
					if(ret.loginRequired) {
						window.location.href = '/login/?next=' + encodeURI('/my-list/');
					} else if(ret.responseType!=1) {
						alert(ret.message || "Unknown error.");
					} else {
						this.setState({'editing':null});
						this.fetchDetails();
					}
				}
			);
		}
	}
}
