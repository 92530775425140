import React from 'react';
import { Redirect } from 'react-router-dom';

import { DashboardMenu } from '../components/DashboardMenu.jsx';

export class Account extends React.Component {
	render() {
		return <div className="container">
			<DashboardMenu />
			<Redirect to="/my-list/account/financial/"/>
		</div>;
	}
}
