import React from 'react';
var _ = require('lodash');

import { getResizedImg } from '../utils.jsx';

export class ImageUploader extends React.Component {
	constructor(props) {
		super(props);
		this.state = {'files':props.imageData || [], 'image':null, 'imageName':null};
	}
	
	render() {
		return <span className="image-uploader">
			<span className="inner">
				<input type="file" accept="image/*" ref="image" onChange={this.handleUpdateImage.bind(this)} />
				<span>Choose files or drag here</span>
			</span>
			{ _.map(this.state.files, (v, k)=><React.Fragment key={k}>
				<span className="file" onClick={this.handleRemoveFile.bind(this, v)}>{ v.name }</span>
				<input type="hidden" name="_image_name" value={ v.name } />
				<input type="hidden" name="_image_content" value={ v.content } />
			</React.Fragment>) }
			{ this.state.image && <img src={ this.state.image } onLoad={ this.handleLoaded.bind(this) } /> }
		</span>
	}
	
	handleRemoveFile(v) {
		this.setState({'files':_.filter(this.state.files, f=>f!=v)});
	}
	
	handleUpdateImage(ev) {
		if(ev.target.files[0]) {
			this.setState({
				'image':URL.createObjectURL(ev.target.files[0]),
				'imageName':ev.target.files[0].name
				
			});
		}
		ev.target.value = null;
	}
	
	handleLoaded(ev) {
		var resized = getResizedImg(ev.target, 1000, this.state.imageName);
		this.setState({
			'files':[].concat(this.state.files, [{
				'name':this.state.imageName,
				'content':resized
			}]),
			'image':null,
			'imageName':null
		});
	}
}
