import React from 'react';
var _ = require('lodash');

export class Paginator extends React.Component {
	render() {
		var pageRange = _.range(1, this.props.pageCount+1);
		pageRange = pageRange.slice(Math.max(Math.min(this.props.page-3, this.props.pageCount-7), 0)).slice(0, 7);
		
		return <div className="paginator">
			{ this.props.pageCount>1 ? <a href="#" onClick={ this.handlePrevious.bind(this) } className="button previous">Previous</a> : "" }
			{ this.props.pageCount>1 ? <a href="#" onClick={ this.handleNext.bind(this) } className="button next">Next</a> : "" }
			<div className="pages">
				{ _.map(pageRange, (i)=>(
					<a	key={i} 
						href="#" 
						onClick={ this.handlePage.bind(this, i) } 
						className={"button" + (i==this.props.page?" active":"")}>{ i }</a>
				) ) }
			</div>
		</div>;
	}
	
	handlePrevious(ev) {
		ev.preventDefault();
		if(this.props.onPrevious) this.props.onPrevious();
	}

	handleNext(ev) {
		ev.preventDefault();
		if(this.props.onNext) this.props.onNext();
	}

	handlePage(i, ev) {
		ev.preventDefault();
		if(this.props.onPage) this.props.onPage(i);
	}
	
}

