import React from 'react';

import { LoginForm } from './LoginForm.jsx';
import { Popup } from './Popup.jsx';

import { do_get, do_post, factfinder_event, get_url_param, track_add_to_list } from '../utils.jsx';

export class AddToListProcess extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            'showAddParamsPopup': false,
            'showAddLoginPopup': false,

            //'adding': null,
            'addOptions': null,


            'productInfo': null,

            'added': {},
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.adding && prevProps.adding != this.props.adding) {
            // not sure this should get triggered by a prop change?
            console.log('add a product!!!');
            this.handleStartAddToList(this.props.adding, this.props.addingQuantity || 1);
        }
    }

    renderAddLoginPopup() {
        var adding_qty = _.keys(JSON.parse(window.localStorage.tempBasket||"[]")).length;
        return <Popup onClose={null}>
            <h2>You&rsquo;ve added {adding_qty} item{adding_qty!=1?'s':''} to your list.</h2>
            <p>To save it please log in or register with us.</p>

            <div className="three columns">
                <p>Follow our 4 easy steps to register and continue creating your dream wedding list.</p>
                <p className="actions"><a href="/create-a-list/" className="button blue">Register</a></p>
            </div>
            <div className="three columns">
                <LoginForm />
            </div>
        </Popup>;
    }

    render() {
        var adding = this.props.adding;

        return <React.Fragment>

            { !!(adding && this.state.showAddLoginPopup) && this.renderAddLoginPopup() }

            { (adding && this.state.showAddParamsPopup) ? <Popup onClose={this.props.onStopAdding}>
				<h2>Item options</h2>

				<form>
				{ adding.colourCount ? <div className="form-row">
					<label>Colour:</label>
					<select value={ this.state.addOptions.colour } onChange={ this.handleChangeAddOption.bind(this, 'colour') }>
						{ _.map(this.state.productInfo.colours, (opt, k)=>(<option key={k}>{opt}</option>))}
					</select>
				</div> : '' }
				
				{ adding.isFundProductType && <div className="form-row">
					<label>Personalise the name of this fund (optional):</label>
					<input type="text" value={ this.state.addOptions.fundDescription } onChange={ this.handleChangeAddOption.bind(this, 'fundDescription') } />
				</div> }
				
				<div className="form-row"><button type="submit" className="right" onClick={this.handleAddToList.bind(this, this.state.adding)}>Add to list</button></div>
				</form>
				
            </Popup>: '' }

        </React.Fragment>;
    }

	handleStopAddToList() {
		this.setState({'showAddParamsPopup':false});
	}

    handleChangeAddOption(name, ev) {
		var opts = this.state.addOptions;
		opts[name] = ev.target.value;
		this.setState({'addOptions':opts});
	}

    handleStartAddToList(prod, qty) {
		if(prod.isFundProductType) {
			this.setState({'addingQuantity':qty, 'adding':prod, 'addOptions':{
				'fundDescription':prod.title
			}}, ()=>{
				this.setState({'showAddParamsPopup':true});
			});
		} else {
			var promise = new Promise((resolve, reject)=>{
				this.setState({'addingQuantity':qty, 'adding':prod}, ()=>{
					var promise = this.handleAddToList(prod);
					if(promise) promise.then(resolve, reject);
					else resolve();
				});
			});
			return promise;
		}
	}

    handleAddToList(prod, ev) {
		if(ev) ev.preventDefault();
		
		this.setState({'showAddParamsPopup':false});

		var atl_method = get_url_param("scan")=="1" ? "scanner" : "web";

		if(prod.isFundProductType) {
			var desc = (this.state.addOptions.fundDescription||'').trim().substring(0, 100);

			if(!desc) return;

			return new Promise((resolve, reject)=>{
				do_get('/api/product-image/' + prod.id + '/', {}, ret=>{
					do_post(window.wpco_api_prefix + 'GiftListItem/AddCustomFund',
						{
							'fundDescription':desc,
							'baseSixtyFourImage':ret.base64,
							'imageName':'fund.jpg',
							'contributionOptions': [1],
							'isCharityFund': prod.isCharity,
						},
						(ret)=>{
							if(ret.loginRequired) {
								//force a login
								this.setState({'showAddLoginPopup':true});
								resolve();
							} else {
								var added = _.assign({}, this.props.added || this.state.added);
								//new item will have some new id so just use original one for notification
								added[prod.id] = 1;

								track_add_to_list({
									atl_method: atl_method,
									productId: prod.id,
									productBucket: prod.id==598971 ? 'charity' : 'cash', 
									productBrand: prod.title,
									productName: prod.title,
									productQuantity: 1,
									productPrice: 0
								});

								factfinder_event("Cart", {
									"id": prod.id,
									"count": 1,
									"price": 0,
								});

								this.setState({'added':added});
                                if(this.props.onUpdateAddedQuantities) this.props.onUpdateAddedQuantities(added);
                                if(this.props.onStopAdding) this.props.onStopAdding();
								resolve();
							}
						}
					);
				});
			});
		}

		var qty = this.props.addingQuantity || 1; //this.state.quantities[prod.presentID]||1;
		var delta = qty - ((this.props.added || this.state.added)[prod.id]||0);

		//don't attempt to update a 'true' product as it may break (eg, group gifts can't be updated)
		if((this.props.added || this.state.added)[prod.id]===true && qty!=0) return;

		//if(this.state.added[prod.id]) qty += this.state.added[prod.id];

		return new Promise((resolve, reject)=>{
			do_post(window.wpco_api_prefix + 'GiftListItem/AddUpdateGiftListItem',
				{
					'productId':prod.id,
					'quantity':qty
				}, 
				(ret)=>{
					if(ret.loginRequired) {
						window.localStorage.tempBasket = JSON.stringify([{'id':prod.id, 'quantity':qty}]);
                        this.setState({'showAddLoginPopup':true});
						resolve();
					} else if(ret.responseType!=1) {
						alert(ret.message);
						reject();
					} else {
						var item = _.find(ret.itemsOnList, (i)=>i.key==prod.id);
						var added = _.assign({}, this.props.added || this.state.added);
						if(item) {
							added[prod.id] = item.value;
						} else {
							delete added[prod.id];
						}

						if(delta!=0) {
							track_add_to_list({
								atl_method: atl_method,
								productId: prod.id,
								productBucket: 'product', //cash, charity
								productBrand: prod.brandDescription,
								productName: prod.title,
								productQuantity: delta,
								productPrice: prod.salePrice
							});

							factfinder_event("Cart", {
								"id": prod.id,
								"count": delta,
								"price": prod.salePrice,
							});
						}

						this.setState({'added':added});
                        if(this.props.onUpdateAddedQuantities) this.props.onUpdateAddedQuantities(added);
                        if(this.props.onStopAdding) this.props.onStopAdding();
						resolve();
					}
					
					// if(!ret.loggedIn) {
					// 	window.localStorage.tempBasket = JSON.stringify(_.concat(JSON.parse(window.localStorage.tempBasket || "[]"), [toAdd]));
					// 	var showLogin = JSON.parse(window.localStorage.tempBasket).length>2;
					// 	this.setState({'added':_.assign({}, this.state.added, {[prod.presentID]:qty}), 'showAddParamsPopup':false, 'showAddLoginPopup':showLogin});
					// 	console.log('setting added to',_.assign({}, this.state.added, {[prod.presentID]:qty}));
					// } else if(ret.errorText && ret.errorText != "You already have this on your list\r") {
					// 	this.setState({'error':ret.errorText});
					// } else {
					// 	this.setState({'added':_.assign({}, this.state.added, {[prod.presentID]:qty}), 'showAddParamsPopup':false});
					// 	this.loadList(); //refresh bg list to get bgPresentIDs
					// }
				}
			);
		});
	}
}