import React from 'react';

export class ExpandingDiv extends React.Component {
	constructor(props) {
		super(props);
		this.state = {'maxHeight':props.open?'none':0};
		this.divRef = React.createRef();
	}
	
	componentDidMount() {
		this.setState({'scrollHeight':this.divRef.current.scrollHeight});
		//console.log('scrollHeight is', this.divRef.current.scrollHeight);
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		var childrenChanged = prevProps.children.length != this.props.children.length;
		
		if((!prevProps.open || childrenChanged) && this.props.open) {
			this.setState({'maxHeight':this.divRef.current.scrollHeight}, ()=>{
				setTimeout(()=>{
					if(this.state.maxHeight!=0) {
						this.setState({'maxHeight':'none'});
					}
				}, Math.max(this.props.delay*1.5, 10));
			});
		} else if(prevProps.open && !this.props.open) {
			this.setState({'maxHeight':this.divRef.current.scrollHeight}, ()=>{
				setTimeout(()=>{
					this.setState({'maxHeight':0});
				}, 10);
			});
		}
	}
	
	render() {
		var styles = {};//'max-height':0};
		styles.maxHeight = this.state.maxHeight;
		
		return <div className={this.props.className} style={styles} ref={this.divRef}>{ this.props.children }</div>;
	}
}
