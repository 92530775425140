import React from 'react';
var $ = require('jquery');
var _ = require('lodash');

import { FormInput, MyForm } from '../components/Forms.jsx';

import { cookies, do_post, factfinder_event, get_url_param} from '../utils.jsx';


export class LoginForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {'error':null, 'errors':{}};
		this.emailRef = React.createRef();
		this.passwordRef = React.createRef();
	}
	
	render() {
		return <div>
		
			<MyForm className="login" onSubmit={this.handleSubmit.bind(this)}>
				{ this.state.error ? <div className="alert alert-error">{ this.state.error }</div> : '' }
				<div className={"form-row" + (this.state.errors.email?' error':'')}><label>Email address<span>*</span></label><input type="email" ref={this.emailRef} defaultValue="" required /></div>
				<div className={"form-row" + (this.state.errors.password?' error':'')}><label>Password<span>*</span></label><input type="password" ref={this.passwordRef} defaultValue="" required /></div>
				<p><em>Please note that passwords are case-sensitive.</em></p><p>Forgotten your password? <a href="/forgot/">click here</a></p>
				<div className="form-row"><button type="submit" className="right">Sign In</button></div>
			</MyForm>
		</div>;
	}
	
	handleSubmit(fields, errors) {
		var email = this.emailRef.current.value;
		var password = this.passwordRef.current.value;
		if(!email) {
			this.setState({'errors':_.assign({}, this.state.errors, {'email':true})});
		} else if(!password) {
			this.setState({'errors':_.assign({}, this.state.errors, {'email':false, 'password':true})});
		} else {
			var default_next_url = '/my-list/';
			if(get_url_param("scan")=="1") default_next_url += "?scan=1";

			return new Promise((resolve)=>{
				this.setState({'error':'', 'errors':{}}, ()=>{
					do_post(window.wpco_api_prefix + 'Account/Login', {'userName':email, 'password':password}, (ret)=>{
						if(ret.responseType!=1) {
							this.setState({'error':ret.message || "Unknown error."});
							resolve();
						} else {
							cookies.set('wpaT', ret.authToken, {path:'/', maxAge:14*86400, secure:true});
							cookies.set('wpgLS', JSON.stringify(ret.giftListSLA), {path:'/', maxAge:14*86400, secure:true});
							factfinder_event("Login", {}, ret.authToken).finally(()=>{
								window.location.href = get_url_param('next') || default_next_url;
							});
							// don't resolve, let spinner spin until mavigation
						}
					});
				});
			});
		}
	}
}
