import React from 'react';
var _ = require('lodash');
import { NavLink } from 'react-router-dom';

import { do_get, BackLink } from '../utils.jsx';
import {
	getProductPageUrl
} from '../logic.jsx';

import { Link } from 'react-router-dom';
import QrScanner from 'qr-scanner';


export class ShowroomScanner extends React.Component {
	constructor(props) {
		super(props);
		this.state = {showInfo: false};
		this.videoElem = React.createRef();
	}

	componentDidMount() {
		this.qrScanner = new QrScanner(
			this.videoElem.current,
			result => {
				this.qrScanner.stop();

				do_get(window.wpco_api_prefix + 'Product/get/ean/' + result.data, {},
					(ret) => {
						if(ret.responseType!=1) {
							// error, restart?
							this.qrScanner.start();
						} else {
							setTimeout(()=>{
								//window.location.assign(getProductPageUrl(ret.product) + "?scan=1");
								this.props.history.push(getProductPageUrl(ret.product) + "?scan=1");
							}, 100);
						}
					}
				);
			},
			{ 
				highlightScanRegion: true
			/* your options or returnDetailedScanResult: true if you're not specifying any other options */ },
		);
		this.qrScanner.start();
	}

	componentWillUnmount() {
		this.qrScanner.stop();
	}

	doTest() {
		this.props.history.push("/browse/by-product/product-yves-delorme-bath-sheet-39541/?scan=1");
		//<NavLink to="/browse/by-product/product-yves-delorme-bath-sheet-39541/?scan=1">yeah</NavLink>
		// onClick={ this.doTest.bind(this) }
	}	
	
	render() {
		return <div className="scanner">
			<h2>Scan code</h2>
			<BackLink className="scanner__backbutton"><svg xmlns="http://www.w3.org/2000/svg" width="12" viewBox="0 0 1.881 2.849"><path fill="none" stroke="#ffffff" strokeMiterlimit="10" strokeWidth=".26458" d="M1.794.1.207 1.476l1.587 1.27"/></svg></BackLink>
			<a href="#" className="scanner__infobutton" onClick={ (ev)=>{ ev.preventDefault(); this.setState({'showInfo': true}) } }>i</a>
			{ this.state.showInfo && <div className="scanner__infobox">
				<h3>How to scan</h3>
				<a href="#" className="scanner__infobox-close" onClick={ (ev)=>{ ev.preventDefault(); this.setState({'showInfo': false}); } }><svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L5.5 5.5L10 1" stroke="#C9A184" strokeWidth="2.5"/><path d="M10 10.5L5.5 6L1 10.5" stroke="#C9A184" strokeWidth="2.5"/></svg></a>
				<p>Please note that this function will only work in our showrooms. Scan the QR code on each product to find out more details or instantly add to your list.</p>
				<p><a href="#" className="scanner__infobox-button" onClick={ (ev)=>{ ev.preventDefault(); this.setState({'showInfo': false}); } }>Start scanning</a></p>
			</div> }
			<p>Fit the code within the frame on the screen</p>
			<div className="scanner__frame">
				<div className="scanner__frame-tl"></div><div className="scanner__frame-tr"></div><div className="scanner__frame-bl"></div><div className="scanner__frame-br"></div>
				<video ref={ this.videoElem }></video>
			</div>
			<p>A successful scan will show the results automatically</p>
		</div>;
	}
}



