import React from 'react';
import ReactDOM from 'react-dom';
var $ = require('jquery');

export class Popup extends React.Component {
	constructor(props) {
		super(props);

		//this.catalogTopMenu = document.getElementById('catalog-top-menu');
		//$(this.catalogTopMenu).empty();
	}
	
	componentWillMount() {
		var popupContainer = $('#popup-container');
		if(popupContainer.length==0) {
			popupContainer = $('<div id="popup-container"></div>').appendTo($('div.frontend'));
			popupContainer.on('click', (ev)=>{
				if(ev.target==popupContainer[0]) {
					if(this.props.onClose) this.props.onClose();
				}
			});
			this.minHeight = $(document).height();
			popupContainer.css('min-height', this.minHeight); 
		}
		popupContainer.attr('class', this.props.className || '');
		this.popupContainer = popupContainer[0];
		this.setState({'top':$(window).scrollTop()});
	}
	
	componentWillUnmount() {
		$(this.popupContainer).css('display', 'none');
	}
	
	componentDidMount() {
		$(this.popupContainer).css('display', 'block');
		setTimeout(this.resizeBg.bind(this), 50);
	}
	
	componentDidUpdate(prevProps, prevState) {
		setTimeout(this.resizeBg.bind(this), 50);
	}
	
	handleClose(ev) {
		ev.preventDefault();
		if(this.props.onClose) this.props.onClose();
	}
	
	render() {
		var style = {'top':this.state.top};
		return <React.Fragment>
			{ReactDOM.createPortal(<div className={"popup row " + (this.props.className||'')} style={style}>
				{ !!this.props.onClose && <a href="#" className="close" onClick={ this.handleClose.bind(this) }></a> }
				{ this.props.children }
			</div>, this.popupContainer)}
		</React.Fragment>;
	}
	
	resizeBg() {
		var mh = $(document).height();
		if(mh!=this.minHeight) {
			this.minHeight = mh;
			$(this.popupContainer).css('min-height', this.minHeight); 
		}
	}
}
