import React from 'react';

import { HelpTooltip } from '../components/HelpTooltip.jsx';

export class AgeRestrictionNotice extends React.Component {
    constructor(props) {
        super(props);
    }

	render() {
        return <div className="age-restriction-notice"><HelpTooltip label="Age restricted" iconLabel="18+" className="age-restriction-notice">This product is age restricted and is only available to persons aged 18 years or over. By adding this product to your list, you declare that you are over 18 years old. A valid ID will be required for delivery once the product has been ordered.</HelpTooltip></div>;
	}
}
