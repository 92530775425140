import React from 'react';

export class PriorityWidget extends React.Component {
	render() {
		return <div className={"couples-choice hoverable " + (this.props.active?" in":"")} onClick={this.handleClick.bind(this)}>
			<h4>Couple’s choice</h4>
			<p>{ this.props.view == "guest" ? "Our favourite presents." : "Show guests which presents you like the most." }</p>
		</div>;
	}
	
	handleClick(ev) {
		if(this.props.onClick) this.props.onClick(ev);
	}
}
