import React from 'react';

export class Discontinued extends React.Component {
	render() {
		return <div className="discontinued-widget">
            <div className="help hoverable">If this item has been ordered, we have automatically credited your account and your balance will reflect this. If this item is yet to be ordered, the pledge value remains in your balance. You can spend any outstanding balance on other items from our suppliers.</div>
			<p>Discontinued</p>
		</div>;
	}
	
}
