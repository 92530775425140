import React from 'react';
var $ = require('jquery');
var _ = require('lodash');

import { validate_email, validate_password, validate_phone } from '../utils.jsx';

export class FormStep extends React.Component {
	constructor(props) {
		super(props);
		this.state = {submitting:false};
		this.headRef = React.createRef();
		this.bodyRef = React.createRef();
		this.stepRef = React.createRef();
	}
	
	render() {
		var options = this.props.options || {};
		return <form className={ this.state.submitting?"submitting":"" }>
			<div className={"form-step " + (this.props.open?" open":"") + (this.props.active?"":" inactive")} ref={this.stepRef} data-step={this.props.name}>
				<div className="head" ref={this.headRef} onClick={ this.handleOpen.bind(this) }><h3>{ this.props.title }</h3></div>
				<div className="body" ref={this.bodyRef}>
					{ this.props.children }
					<div className="form-row"><button type="submit" className="right" onClick={this.handleNext.bind(this)}>{ options.button_text || 'Next' } </button></div>
				</div>
			</div>
		</form>;
	}
	
	componentDidMount() {
		this.adjustHeight(this.props.open, true);
	}
	
	adjustHeight(open, firstMount) {
		if(!open) {
			var shrink = ()=>{
				var head = $(this.headRef.current);
				var h = head.height() + parseInt(head.css("border-top-width")) + parseInt(head.css("border-bottom-width"));
				$(this.stepRef.current).css('max-height', h);
			};
			if(firstMount) shrink();
			else setTimeout(()=>{
				if(this.stepRef.current) $(this.stepRef.current).css('max-height', this.stepRef.current.scrollHeight);
				setTimeout(shrink, 20);
			}, 20);
		} else {
			if(this.stepRef.current) $(this.stepRef.current).css('max-height', this.stepRef.current.scrollHeight);
			setTimeout(()=>{
				$(this.stepRef.current).css('max-height', '');
				$(this.stepRef.current).find('input').first().focus();
			}, 500);
			//$(this.refs.step).find('input').first().focus();
		}
	}
	
	componentWillReceiveProps(nextProps) {
		if((!this.props.open && nextProps.open) || (this.props.open && !nextProps.open)) {
			this.adjustHeight(nextProps.open);
		}
// 		if(!_.isEmpty(nextProps.errors)) {
// 			_.each(nextProps.errors, (v,k)=>{
// 				var el = $(this.refs.body).find('input[name="' + k + '"],select[name="' + k + '"]');
// 				this._showError(el, k, v, false);
// 			});
// 		}
	}
	
	handleOpen() {
		if(this.props.onRequestOpen) this.props.onRequestOpen(!this.props.open);
	}
	
// 	_showError(input, name, error, focus) {
// 		if(error) {
// 			if(focus) input.focus();
// 			//if(_.isEmpty(errors)) input.focus();
// 			//errors[name] = error;
// 			input.parents('.form-row').addClass('error');
// 			var msg = input.parents('.form-row').find('.error-detail');
// 			if(!msg.length) {
// 				msg = $('<div class="error-detail" />');//.insertAfter(input);
// 				if(input.parents('label').length) {
// 					msg.insertAfter(input.parents('label'));
// 				} else {
// 					msg.insertAfter(input);
// 				}
// 			}
// 			msg.text(error);
// 		} else {
// 			input.parents('.form-row').removeClass('error');
// 		}
// 	}
	
	handleNext(ev) {
		ev.preventDefault();
		if(this.state.submitting) return false;
		
		var data = {};
		var errors = {};
		
		$(this.bodyRef.current).find('.form-input').each((k,v)=>{
			var v = $(v);
			var name = v.attr('name');
			var val = v.val();
			var error = null;
			
			if(_.endsWith(name, ":day")) {
				var n = name.substring(0, name.length-4);
				data[n] = "0000-00-" + _.padStart(""+val, 2, "0");
			} else if(_.endsWith(name, ":month")) {
				var n = name.substring(0, name.length-6);
				data[n] = data[n].substring(0, 5) + _.padStart(""+val, 2, "0") + data[n].substring(7);
			} else if(_.endsWith(name, ":year")) {
				name = name.substring(0, name.length-5);
				data[name] = _.padStart(""+val, 4, "0") + data[name].substring(4);
				if(isNaN(Date.parse(data[name]))) {
					delete data[name];
					error = "Please enter a valid date.";
				}
				val = data[name];
			} else if(_.endsWith(name, "[]")) {
				if(v.attr('type')!='checkbox' || v.is(':checked')) {
					name = name.substring(0, name.length-2);
					data[name] = _.concat(data[name]||[], [val]);
				}
			} else if(v.attr('type')=='checkbox') {
				val = data[name] = v.is(':checked');
			} else if(v.attr('type')=='radio') {
				data[name] = v.is(':checked') ? val : data[name];
			} else {
				data[name] = val;
			}
			
			if(error) { 
				//pass
			} if(v.attr('data-required')=="true" && !val) {
				error = "This field is required.";
			} else if(v.attr('data-validate')=="email" && val!='' && !validate_email(val)) {
				error = "Please enter a valid email.";
			} else if(v.attr('data-validate')=="phone" && val!='' && !validate_phone(val)) {
				error = "Please enter a valid phone number.";
			} else if(v.attr('data-validate')=="password" && !validate_password(val)) {
				error = "Your password must be at least 8 characters in length, containing at least 1 number."
			} else if(v.attr('data-same') && val!=$(this.bodyRef.current).find('*[name=\''+ v.attr('data-same') + '\']').val()) {
				error = "Fields do not match.";
			} else if(v.attr('data-validate')=="future" && val && Date.parse(val)<(new Date()).getTime()) {
				error = "Date must not be in the past.";
			}
			
			errors[name] = error;
		});
		
		if(this.props.onNext) {
			var promise = this.props.onNext(data, errors);
			if(promise) {
				this.setState({submitting:true});
				promise.finally(()=>{
					this.setState({submitting: false});
				});
			}
		}
	}
}

export class FormInput extends React.Component {
	constructor(props) {
		super(props);
		this.focusInputRef = React.createRef();
	}
	
	componentWillReceiveProps(nextProps) {
		if(nextProps.errors && nextProps.errors[nextProps.name] && this.props.errors[nextProps.name]!=nextProps.errors[nextProps.name] && (!nextProps.context || !nextProps.context.focused)) {
			if(this.focusInputRef.current) this.focusInputRef.current.focus();
			if(nextProps.context) nextProps.context.focused = true; //cheekily modify prop?
		}
	}
	
	doDateInput(value, error) {
		var options = options || {};
		var months = _.map(['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'], (v,k)=>(<option key={k} value={k+1}>{ v }</option>));
		var years = _.map(_.range(new Date().getFullYear(), new Date().getFullYear()+10), (v)=>(<option key={v} value={v}>{ v }</option>));
		
		value = !isNaN(Date.parse(value)) ? new Date(value) : null;

		var default_year = '';
		if(value) {
			default_year = value.getFullYear();
		} else {
			default_year = (new Date()).getFullYear() + 1;
		}
		
		return <div className={"form-row date " + (error?'error ':'') + (this.props.className||"")}><label>{ this.props.label }{ this.props.required ? <span>*</span> : '' }</label><input type="tel" name={ this.props.name + ":day" } className="form-input" ref={this.focusInputRef} defaultValue={ value ? value.getDate() : "" } /><select name={ this.props.name + ":month" } className="form-input" defaultValue={ value ? value.getMonth() : "" }>{ months }</select><select name={ this.props.name + ":year" } className="form-input" data-validate={ this.props.validate } defaultValue={ default_year }>{ years }</select>{ error }</div>;
	}
	
	doCheckbox(value, error) {
		return <div className={"form-row checkbox " + (error?'error ':'') + (this.props.className||"")}><label><input type="checkbox" name={ this.props.name } value="1" ref={this.focusInputRef} className="form-input" onChange={this.handleCheckboxToggle.bind(this)} data-required={ this.props.required ? "true" : "false" } defaultChecked={value} /> { this.props.label }{ this.props.required ? <span>*</span> : '' }</label>{ error }</div>;
	}

	doYesNoRadio(value, error) {
		return <React.Fragment>
			<div className={"form-row checkbox " + (error?'error ':'') + (this.props.className||"")}><label><input type="radio" name={ this.props.name } value="1" ref={this.focusInputRef} className="form-input" onChange={this.handleCheckboxToggle.bind(this)} defaultChecked={value==='1'} required /> Yes</label>{ error }</div>
			<div className={"form-row checkbox " + (error?'error ':'') + (this.props.className||"")}><label><input type="radio" name={ this.props.name } value="0" className="form-input" onChange={this.handleCheckboxToggle.bind(this)} defaultChecked={value==='0'} required /> No</label>{ error }</div>
		</React.Fragment>;
	}
	
	doTextArea(value, error) {
		return <div className={"form-row " + (error?'error ':'') + (this.props.className||"")}><label>{ this.props.label }{ this.props.required ? <span>*</span> : '' }</label><textarea className="form-input" name={ this.props.name } ref={this.focusInputRef} data-required={ this.props.required ? "true" : "false" } data-validate={ this.props.validate } data-same={ this.props.same } defaultValue={ value } />{ error }{ this.props.help_text ? <div className="help-small">{ this.props.help_text }</div> : "" }</div>;
	}

	doSelect(value, error) {
		var opts = _.map(this.props.choices||[], (c, i)=>(
			<option key={i} value={c[0]}>{ c[1] }</option>
			));
		return <div className={"form-row " + (error?'error ':'') + (this.props.className||"")}><label>{ this.props.label }{ this.props.required ? <span>*</span> : '' }</label><select className="form-input" name={ this.props.name } ref={this.focusInputRef} data-required={ this.props.required ? "true" : "false" } data-validate={ this.props.validate } data-same={ this.props.same } defaultValue={ value }>{ opts }</select>{ error }{ this.props.help_text ? <div className="help-small">{ this.props.help_text }</div> : "" }</div>;
	}
	
	doMoneyInput(value, error) {
		return <div className={"form-row " + (error?'error ':'') + (this.props.className||"")}>{ this.props.label && <label>{ this.props.label }{ this.props.required ? <span>*</span> : '' }</label> }<div className="money-input"><input type="text" className="form-input" name={ this.props.name } ref={this.focusInputRef} data-required={ this.props.required ? "true" : "false" } data-validate={ this.props.validate } data-same={ this.props.same } placeholder={ this.props.placeholder || "" } defaultValue={ value } /></div>{ error }{ this.props.help_text ? <div className="help-small">{ this.props.help_text }</div> : "" }</div>;
	}

	render() {
		var value = this.props.values ? this.props.values[this.props.name] : null;
		var error = this.props.errors && this.props.errors[this.props.name] ? <div className="error-detail">{ this.props.errors[this.props.name] }</div> : null;
		
		var type = this.props.type;
		if(type=="date") return this.doDateInput(value, error);
		if(type=="checkbox") return this.doCheckbox(value, error);
		if(type=="checkbox-extra") return this.doCheckboxExtra(value, error);
		if(type=="yesno") return this.doYesNoRadio(value, error);
		if(type=="textarea") return this.doTextArea(value, error);
		if(type=="select") return this.doSelect(value, error);
		if(type=="money") return this.doMoneyInput(value, error);
		if(!type && this.props.validate=="email") type = "email";
		if(!type && this.props.validate=="phone") type = "tel";
		
		return <div className={"form-row " + (error?'error ':'') + (this.props.className||"")}>{ this.props.label && <label>{ this.props.label }{ this.props.required ? <span>*</span> : '' }</label> }<input type={type||"text"} className="form-input" name={ this.props.name } ref={this.focusInputRef} data-required={ this.props.required ? "true" : "false" } data-validate={ this.props.validate } data-same={ this.props.same } placeholder={ this.props.placeholder || "" } defaultValue={ value } />{ error }{ this.props.help_text ? <div className="help-small">{ this.props.help_text }</div> : "" }</div>;
	}
	
	handleCheckboxToggle() {
		var checked = {};
		var inp = this.focusInputRef.current;
		if(inp.value=="0") { //inverse checkboxes or radios
			checked[this.props.name] = !$(inp).is(':checked');
		} else if(inp.value=="1") {
			checked[this.props.name] = $(inp).is(':checked');
		} else {
			if(!checked[this.props.name]) checked[this.props.name] = {};
			checked[this.props.name][inp.value] = $(inp).is(':checked');
		}
		if(this.props.onChange) this.props.onChange(checked);
	}
	
}

export class MyForm extends React.Component {
	constructor(props) {
		super(props);
		var data = null;
		this.state = {'data':data || {}, 'changes':data || {}, 'errors':{}, 'submitting':false};
		this.bodyRef = React.createRef();
	}
	
	render() {
		return <form method="post" ref={this.bodyRef} onSubmit={this.handleSubmit.bind(this)} className={ (this.props.className||'') + (this.state.submitting?' submitting':'') }>
			{ this.props.children || [] }
		</form>;
	}
	
	handleSubmit(ev) {
		ev.preventDefault();
		if(this.state.submitting) return false;
		
		var data = {};
		var errors = {};
		
		$(this.bodyRef.current).find('.form-input').each((k,v)=>{
			var v = $(v);
			var name = v.attr('name');
			var val = v.val();
			var error = null;
			
			if(_.endsWith(name, ":day")) {
				var n = name.substring(0, name.length-4);
				data[n] = "0000-00-" + _.padStart(""+val, 2, "0");
			} else if(_.endsWith(name, ":month")) {
				var n = name.substring(0, name.length-6);
				data[n] = data[n].substring(0, 5) + _.padStart(""+val, 2, "0") + data[n].substring(7);
			} else if(_.endsWith(name, ":year")) {
				name = name.substring(0, name.length-5);
				data[name] = _.padStart(""+val, 4, "0") + data[name].substring(4);
				if(isNaN(Date.parse(data[name]))) {
					delete data[name];
					if(v.attr('data-required')=="true") error = "Please enter a valid date.";
				}
				val = data[name];
			} else if(_.endsWith(name, "[]")) {
				if(v.attr('type')!='checkbox' || v.is(':checked')) {
					name = name.substring(0, name.length-2);
					data[name] = _.concat(data[name]||[], [val]);
				}
			} else if(v.attr('type')=='checkbox') {
				data[name] = v.is(':checked');
			} else {
				data[name] = val;
			}

			console.log('checking', v, v.attr('data-validate'), val);

			if(error) { 
				//pass
			} if(v.attr('data-required')=="true" && !val) {
				error = "This field is required.";
			} else if(v.attr('data-validate')=="email" && val!='' && !validate_email(val)) {
				error = "Please enter a valid email.";
			} else if(v.attr('data-validate')=="password" && !validate_password(val)) {
				error = "Your password must be at least 8 characters in length, containing at least 1 number."
			} else if(v.attr('data-validate')=="number" && val!='' && !_.isFinite(+val)) {
				error = "Please enter a valid number.";
			} else if(v.attr('data-validate')=="phone" && val!='' && !validate_phone(val)) {
				error = "Please enter a valid phone number.";
			} else if(v.attr('data-same') && val!=$(this.bodyRef.current).find('*[name=\''+ v.attr('data-same') + '\']').val()) {
				error = "Fields do not match.";
			} else if(v.attr('data-validate')=="future" && val && Date.parse(val)<(new Date()).getTime()) {
				error = "Date must not be in the past.";
			}
			
			errors[name] = error;
		});
		
		if(this.props.onSubmit) {
			var promise = this.props.onSubmit(data, errors);
			if(promise) {
				this.setState({submitting:true});
				promise.finally(()=>{
					this.setState({submitting: false});
				});
			}
		}
	}
}
