import React from 'react';
import QueryString from 'query-string';
var _ = require('lodash');

import { FormInput, MyForm } from '../components/Forms.jsx';
import { ListNotOpenPopup } from '../components/ListNotOpenPopup.jsx';
import { Popup } from '../components/Popup.jsx';
import { do_get, do_post, ScrollToHere, ScrollToHereWithRouter } from '../utils.jsx';

export class ListFinder extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			'results':null, 
			'sort':'a-z', 
			'searchCount':0,
			'errors':{}
		};
		this.queryRef = React.createRef();
		this.queryFirstNameRef = React.createRef();
		this.queryYearRef = React.createRef();
		this.queryMonthRef = React.createRef();
	}
	
	componentDidMount() {
		this.lookup();
	}
	
	componentDidUpdate(prevProps) {
		if(this.props.location.search!=prevProps.location.search) {
			this.lookup();
		}
	}
	
	render() {
		var genSort = (n, label)=>{
			if(this.state.sort==n) {
				return label;
			} else {
				return <a href="#" onClick={ (ev)=>{ev.preventDefault();this.setState({'sort':n})} }>{ label }</a>
			}
		};
		
		var results = this.state.results;
		if(results!==null) {
			if(this.state.sort=='a-z') results = _.sortBy(results, (r)=>(r.listTitle));
			else if(this.state.sort=='z-a') results = _.reverse(_.sortBy(results, (r)=>(r.listTitle)));
			else if(this.state.sort=='date') results = _.reverse(_.sortBy(results, (r)=>(new Date(r.occasionDate))));
		}
		
		var nprops = {'errors':this.state.popupErrors || {}, 'values':this.state.popupData || {}, 'context':{'focused':false}};
		var params = QueryString.parse(this.props.location.search);
		
		var years = [];
		for(var i=0;i<5;i++) {
			years.push((new Date()).getFullYear() + i);
		}
		var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

		return <div>
			{ this.state.showPopup=="closed" &&
				<Popup onClose={()=>{this.setState({'showPopup':null})}}>
					<h2>{ this.state.wedding.listTitle }</h2>
					<h3>This wedding list is now closed.</h3>
					<p>If you would still like to buy them a present, please contact The Wedding Present Co. team on <a href="tel:+4402076226300">+44 (0)20 7622 6300</a> or email <a href="mailto:office@weddingpresentco.com">office@weddingpresentco.com</a>.</p>
				</Popup>
			}
			{ this.state.showPopup=="pending" &&
				<ListNotOpenPopup 
					listTitle={this.state.wedding.listTitle}
					weddingDate={this.state.wedding.weddingDate}
					giftListId={this.state.wedding.giftListId}
					onClose={()=>{this.setState({'showPopup':null})}} />
			}
		
			<h1 className="underlined"><span>Find</span> a List</h1>
			
			<MyForm className="find-list" onSubmit={ this.handleSearch.bind(this) }>
				<p>To search for a gift list, please enter the last name of either partner.</p>
				<div className={"form-row" + (this.state.errors.Name ? ' error':'') }><input type="search" className="search" ref={ this.queryRef } autoFocus autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" defaultValue={ this.props.query || params.q } placeholder="Surname" data-testid="listfinder-query" /></div>
				<div className={"form-row" + (this.state.errors.FirstName ? ' error':'') }><input type="search" className="search" ref={ this.queryFirstNameRef } autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" defaultValue={ this.props.queryFirstName || params.fn } placeholder="First name (optional)" data-testid="listfinder-query-firstname" /></div>
				<div className="form-row flex">
					<select ref={ this.queryYearRef }><option className="placeholder" value="">Year (optional)</option>
					{ years.map(y=>(
						<option value={y} key={y}>{ y }</option>
					)) }</select>

					<select ref={ this.queryMonthRef }><option className="placeholder" value="">Month (optional)</option>
					{ months.map((m, k)=>(
						<option value={k+1} key={k+1}>{ m }</option>
					)) }</select>
				</div>

				<div className="form-row"><button className="right">{ results===null && !this.props.query ? "Search" : "Search again" }</button></div>
			</MyForm>
					
			{ results!==null ? <React.Fragment><ScrollToHere searchCount={this.state.searchCount} smooth={true} toTop={true} />
			<table className="result-list">
				<thead>
					<tr><td colSpan="3"><strong>Sort by:</strong> { genSort('a-z', 'A-Z') } | { genSort('z-a', 'Z-A') } | { genSort('date', 'Wedding date') }</td></tr>
					<tr><th>Couple</th><th width="34.3%">Wedding date</th><th width="10%">View</th></tr>
				</thead>
				<tbody>
					{ results.length ? _.map(results, (row)=>(
						<tr key={row.giftListId} onClick={ this.handleRowClick.bind(this, row) }><td className="is-clickable">{ row.listTitle }</td><td>{ row.formattedOccasionDate }</td><td><a href={row.giftListId+'/'} onClick={ this.handleRowClick.bind(this, row) }>{ row.canView ? 
							"View" : "Notify me" }</a></td></tr>
					)) : <tr><td colSpan="3">
						No matches found.<br /><br />
						If you’re having trouble finding a wedding list, please call us on <a href="tel:+441488662100">01488662100</a> or <a href="mailto:office@weddingpresentco.com">email us</a>.
					</td></tr>
					}
				</tbody>
			</table></React.Fragment> : '' }
		</div>;
	}
	
	
	lookup() {
		var params = QueryString.parse(this.props.location.search);
		if(params.q) {
			do_post(window.wpco_api_prefix + 'GiftList/Find', {
				'name':params.q,
				'firstName':params.fn || undefined,
				'year':params.y || undefined,
				'month':params.m || undefined,
			}, (ret)=>{
				this.setState({'results':ret.giftLists, 'searchCount':this.state.searchCount+1, 'errors':{}});
			}, (err)=>{
				this.setState({'errors':err});
			});
		} else if(params.fn) {
			this.setState({'errors':{'Name':'Please enter a surname.'}});
		} else if(this.state.results) {
			this.setState({'results':null});
		} 
	}
	
	handleSearch() {
		this.props.history.push('?' + QueryString.stringify({
			'q':this.queryRef.current.value,
			'fn':this.queryFirstNameRef.current.value || undefined,
			'y':this.queryYearRef.current.value || undefined,
			'm':this.queryMonthRef.current.value || undefined,
		}));
	}
	
	handleRowClick(row, ev) {
		ev.preventDefault();
		if(!row.canView) {
			this.setState({'showPopup':'pending', 'wedding':row, 'notifySuccess':false});
		} else {
			window.location.href = '/find-list/' + row.giftListId + '/';
		}
	}
	
	handlePopupSubmit(fields, errors) {
		var hasErrors = _.find(errors, (v)=>(v!=null));
		this.setState({'popupData':fields, 'popupErrors':errors});
		if(hasErrors) {
			return;
		}

		do_post(window.wpco_api_prefix + 'Pledge/AddToGiftListLiveNotification', {'giftListId':this.state.wedding.giftListId, 'name':'', email:fields['email']}, (ret)=>{
			if(ret.responseType!=1) {
				alert(ret.message || "Unknown error.");
			} else {
				this.setState({'notifySuccess':true, 'notifyResponse':ret});
			}
		});
	}
}
