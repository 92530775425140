import React from 'react';
var _ = require('lodash');
import { Link } from 'react-router-dom';

import { getProductImage } from '../logic.jsx';
import { MoneyInput, ScrollToTop, do_get, do_post, getGiftListSLA } from '../utils.jsx';

import { Button } from '../components/Button.jsx';
import { FormInput, MyForm } from '../components/Forms.jsx';
import { Popup } from '../components/Popup.jsx';

export class AccountCustomProduct extends React.Component {
	constructor(props) {
		super(props);
        this.state = {
            'addedProduct': null,
            'product': null,
            'error': false,
            'limit': null,
            'remaining': null,
        };
	}

    componentDidMount() {
        var sla = getGiftListSLA();
        var limit = (
            sla.isCustomProductLimited ? sla.customProductLimit : true
        );

        do_get(window.wpco_api_prefix + 'giftlist/customproduct/count', {}, ret=>{
            this.setState({
                limit: limit,
                remaining: limit===true ? true : Math.max(limit - ret.count, 0)
            });
        }, err=>{
            // an error response here is probably an auth failure?
            window.location.href = '/login/?next=' + encodeURI('/my-list/custom-product/');
        });
    }
	
	render() {
        if(this.state.remaining===null) {
            return <div></div>;
        }

        var step = this.props.match.params.step;

		return <div className="container">
			<div className="twelve columns cprod">
                { this.state.addedProduct!==null && <AddedProductPopup product={ this.state.addedProduct } /> }
                { (step=="confirm" && this.state.product)
                    ? <ConfirmProductForm product={ this.state.product } onAdd={ this.handleConfirmAdd.bind(this) } />
                    : <AddProductForm onAdd={ this.handleAddProduct.bind(this) } onSend={ this.handleSendUrl.bind(this) } limit={ this.state.limit } remaining={ this.state.remaining } error={ step=="error" } />
                }
			</div>
		</div>;
	}

    handleAddProduct(url) {
        return new Promise((resolve, reject)=>{
            do_get(window.wpco_api_prefix + 'Manage/CustomProduct', {
                'url':url,
            }, ret=>{
                if(ret.loginRequired) {
                    window.location.href = '/login/?next=' + encodeURI('/my-list/custom-product/');
                } else if(ret.responseType!=1) {
                    //create_alert(ret.message || "Unknown error.");
                    // Ignore actual error message as it is TWS specific

                    //this.setState({'error':true});
                    this.props.history.push("/my-list/custom-product/error/");

                    reject();
                } else if(!ret.product) {
                    create_alert("Sorry, no product returned.");
                    reject();
                } else {
                    this.setState({'product': ret.product}, ()=>{
                        this.props.history.push("/my-list/custom-product/confirm/");
                    });
                    resolve();
                }
            });
        });
    }

    handleConfirmAdd(data) {
        return new Promise((resolve, reject)=>{
            do_post(window.wpco_api_prefix + 'Manage/CustomProduct', {
                "description": data.description,
                "salePrice":   data.salePrice,
                "currencySymbol": "£",
                "quantity":    data.quantity,
                "brand":       data.brand,
                "colour":      data.colour,
                "image":       this.state.product.image,
                "supplierReference": this.state.product.supplierReference,
                "url":         this.state.product.url,
                "giftListId":  this.state.product.giftListId,
            }, ret=>{
                if(ret.loginRequired) {
                    window.location.href = '/login/?next=' + encodeURI('/my-list/custom-product/');
                } else if(ret.responseType!=1) {
                    create_alert(ret.message || "Unknown error.");
                    reject();
                } else {
                    this.setState({'addedProduct': ret.newProducts[0]});
                    resolve();
                }
            }).catch(reject);
        });
    }

    handleSendUrl(v) {
        return new Promise((resolve, reject)=>{
            do_post(window.wpco_api_prefix + 'Util/CustomProductEmail', {
                url: v
            }, ret=>{
                resolve();
            }).catch(reject);
        });
    }
}

class AddProductForm extends React.Component {
    constructor(props) {
        super(props);
        this.urlRef = React.createRef();
        this.sendUrlRef = React.createRef();
        this.state = {
            message: null,
            submitting: false,
        };
    }

    render() {
        if(this.props.remaining!==true && this.props.remaining<=0) {
            return <NoProductsLeft />;
        }

		return <div className="">
            <ScrollToTop />
            <h2>Add a gift from another website</h2>
            { this.props.remaining !== true && <div className="cprod__remaining">You have { this.props.remaining } Custom Product{ this.props.remaining>1 ? "s" : "" } available</div> }

            { !this.props.error ? <React.Fragment>
                <p>We understand that sometimes there may be a few extra special or unusual items that you would like to add to your wedding list, which we may be unable to source from our Brand Partners.</p>
                <p>We are therefore delighted to offer you ‘custom products’, which you can add to your wedding list.</p>
                <p>If we're able to source one of your chosen custom products from our Brand Partners, we'll add this to your list as a Branded Product, meaning you'll be able to add another custom product in its place, if you'd like.</p>
                <p>Please paste the full URL of the product you would like to add to your list:</p>
                <div className="cprod__form">
                    <input type="text" className="cprod__beige-input" placeholder="https://" ref={this.urlRef} />
                    <div className="cprod__form-actions">
                        <Button type="button" className="cprod__button" onClick={ this.handleAdd.bind(this) }>Next</Button>
                    </div>
                    { !!this.state.submitting && <div className="cprod__submitting-message">
                        This may take up to 15 seconds...
                    </div> }
                </div>
            </React.Fragment> : <React.Fragment>
                <div className="cprod__error-panel">

                <p>Unfortunately, we have not been able to get the information needed for this custom product from the URL provided.</p>
                <p>Please double check that the product you're trying to add is from a UK-based retailer and priced in Pounds Stirling.</p>
                <p>If you're still having trouble, click the send button below to send the link to your Wedding List Advisor, who will be able to help you.</p>

                <div className="cprod__form">
                    { !!this.state.message ?
                         <div className="cprod__form-message">
                            { this.state.message }
                        </div>
                        : <React.Fragment>
                           <input type="email" className="cprod__beige-input" placeholder="https://" ref={this.sendUrlRef} />
                            <div className="cprod__form-actions">
                                <Button type="button" className="cprod__button" onClick={ this.handleSend.bind(this) }>Send</Button>
                            </div>
                        </React.Fragment>
                    }
                </div>

                </div>
            </React.Fragment> }
            { this.props.limit !==true && !!this.props.limit && <p>You can choose up to { this.props.limit } items from any other UK retailer.</p> }
            <p>Please allow up to 3 working days for us to confirm and add custom products to your list. Custom products must be from a UK-based retailer and priced in Pounds Sterling.</p>
            <p>Other conditions also apply to custom products, as per our T&Cs. Find out more <a href="/terms/terms-couples/" target="_blank">here</a>.</p>
		</div>;
	}

    handleAdd(ev) {
        ev.preventDefault();
        var v = this.urlRef.current.value;
        if(this.props.onAdd && v) {
            this.setState({'submitting': true});
            return this.props.onAdd(v).finally(r=>{
                this.setState({'submitting': false});
            });
        }
    }

    handleSend(ev) {
        ev.preventDefault();
        var v = this.sendUrlRef.current.value;
        if(this.props.onSend && v) return this.props.onSend(v).then(r=>{
            this.setState({'message': 'We have received your request and will add your product as soon as possible.'});
        });
    }
}

class ConfirmProductForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            product: Object.assign(
                {}, 
                props.product,
                props.product?.salePrice
                    ? { salePrice: props.product.salePrice.toFixed(2) }
                    : {}
            ),
            errors: {},
        };
    }
    render() {
        if(!this.props.product) return null;

        var imgStyles = {
            backgroundImage: 'url(' + this.props.product.image + ')',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            width: '100%',
            height: '300px'
        };

        var props = {
			values: this.state.product,
			errors: this.state.errors,
			context: {'focused':false}, 
		};

		return <div>
            <ScrollToTop />
            <h2>Confirm your choice</h2>
            <p>Please double-check that the details below are correct. You can edit them if necessary.</p>
            <p>If your gift is available in multiple colours, please confirm your choice in the colour box below.</p>

            <MyForm onSubmit={this.handleSubmit.bind(this)}>
								
            <div className="cprod__form">

            <FormInput label="Description (140 characters)" name="description" type="textarea" className="cprod__boxed-input-group wide" required={false} {...props} />

            <FormInput label="Price" name="salePrice" type="money" className="cprod__boxed-input-group" required={true} validate="number" {...props} />

            <FormInput label="Quantity" name="quantity" className="cprod__boxed-input-group" required={true} validate="number" {...props} />

            <FormInput label="Brand" name="brand" className="cprod__boxed-input-group" required={true} {...props} />

            <FormInput label="Colour" name="colour" className="cprod__boxed-input-group" required={true} {...props} />

            <div className="cprod__form-image" style={imgStyles}>&nbsp;</div>

            <label className="cprod__form-check-label">
                <input type="checkbox" className="green-checkbox" required ref={ this.ageCheckboxRef } />
                I understand that the details of my Custom Products may change or be updated to a Branded Product should The Wedding Present Company be able to source this from a Brand Partner.
            </label>

            <div className="cprod__form-actions">
                <button type="submit" className="cprod__button">Add to list</button>
            </div>

            </div>

            </MyForm>

		</div>;
	}

    handlePriceChange(ev, value) {
        this.setState({'salePrice': value});
    }

    handleSubmit(data, errors) {
		this.setState({'errors': errors});
		var hasErrors = _.find(errors, (v)=>(v!=null));
		if(hasErrors) return;

        if(this.props.onAdd) return this.props.onAdd(data);
   }
}

class AddedProductPopup extends React.Component {
    render() {
        return <Popup className="cprod__popup">
            <h3>Thank you<br />Your custom product has been added to your list</h3>
            <div className="cprod__popup-row">
                <div>
                    <img src={ getProductImage(this.props.product, 200) } />
                </div>
                <div>
                    <p>{ this.props.product.title }</p>
                    <div className="cprod__popup-status">Added to list</div>

                    <a href="/my-list/" className="cprod__button">View your list</a>
                    <a href="/my-list/custom-product/" className="cprod__button">Add another custom product</a>
                </div>
            </div>
        </Popup>;
    }
}

class NoProductsLeft extends React.Component {
    render() {
        return <div>
            <h2>Sorry, You've reached the limit<br />for custom products</h2>
            <div className="cprod__remaining">0 Custom products available to add</div>
            <div className="cprod__form cprod__centered">
                <p className="cprod__centered">Remember, you can add an unlimited number of gifts from any of our Branded Suppliers. We have thousands of products for you to choose from, from over 400 brilliant suppliers.</p>
                <a href="/browse/by-brand/" className="cprod__button cprod__centered">Browse our suppliers</a>
            </div>
        </div>;

    }
}