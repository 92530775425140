import React from 'react';
import { Link, withRouter } from 'react-router-dom';
var $ = require('jquery');
var _ = require('lodash');

import { do_post, encodeID } from '../utils.jsx';

export class Menu extends React.Component {
	constructor(props) {
		super(props);
		
		this.state = {};
	}
	
	/*
	componentDidMount() {
		if(this.state.funds.length==0) this.fetchFunds();
	}
	
	componentDidUpdate() {
		if(this.state.funds.length==0) this.fetchFunds();
	}
	
	fetchFunds() {
		if(this.state.startedFetch) return;
		var funds_cat = _.find(this.props.categories, (cat)=>(cat.parentID==0 && (cat.name=="Funds" || cat.name=="TEST Funds")));
		if(funds_cat) {
			this.setState({'startedFetch':true}, ()=>{
				do_post(window.wpco_api_prefix + '/api/get-products.a4d', 
					{
						categoryID:funds_cat.categoryID,
					}, 
					(ret)=>{					
						this.setState({'funds':ret.products, 'fundsCatId':funds_cat.categoryID});
					}
				);
			});
		}
	}*/
	
	render() {
		var items = [];
		
		//var brands = _.sortBy(_.filter(this.props.brands || [], (b)=>(b.displayOrder>9990)), (b)=>(-b.displayOrder)).slice(0, 4);
		//var funds_cat = _.find(this.props.categories, (cat)=>(cat.parentID==0 && (cat.name=="Funds" || cat.name=="TEST Funds")));
		//var by_room = _.filter(this.props.categories, (cat)=>(cat.parentID==0 && cat.type=="Room"));
		//var cats = _.filter(this.props.categories, (cat)=>(cat.parentID==0 && cat.type=="Browse" && cat!=funds_cat)).slice(0, 9);
		//var inspiration = _.filter(this.props.categories, (cat)=>(cat.parentID==0 && cat.type=="Collection")).slice(0, 9);
		//var funds = _.filter(this.props.categories, (cat)=>(funds_cat && cat.parentID==funds_cat.categoryID));
		items.push(<li key={2}>
			<h5><DropdownLink to="/browse/by-category/">Category</DropdownLink></h5>
			<ul>
				{ _.map(this.props.categories.slice(0, 8), (cat)=>(
					<li key={cat.title}><DropdownLink to={ cat.url }>{ cat.title }</DropdownLink></li>
					)) }
				<li><DropdownLink to="/browse/by-category/" className="see-all">&ndash; See All Categories</DropdownLink></li>
			</ul>
		</li>);
		
		items.push(<li key={1}>
			<h5><DropdownLink to="/browse/inspiration/">Inspiration</DropdownLink></h5>
			<ul>
				{ _.map(this.props.inspirations.slice(0, 8), (cat)=>(
					<li key={cat.title}><DropdownLink to={ cat.url }>{ cat.title }</DropdownLink></li>
					)) }
				<li><DropdownLink to="/browse/inspiration/" className="see-all">&ndash; See All Inspiration</DropdownLink></li>
			</ul>
		</li>);

		items.push(<li key={3}>
			<h5><DropdownLink to="/browse/by-brand/">Brands</DropdownLink></h5>
			<ul>
				{ _.map(this.props.brands.slice(0, 8), (cat)=>(
					<li key={cat.title}><DropdownLink to={ cat.url }>{ cat.title }</DropdownLink></li>
					)) }
				<li><DropdownLink to="/browse/by-brand/" className="see-all">&ndash; See All Brands</DropdownLink></li>
			</ul>
		</li>);
		
		items.push(<li key={4}>
			<h5>Funds</h5>
			<ul>
				{ _.map(this.props.funds.slice(0, 8), (cat)=>(
					<li key={cat.title}><DropdownLink to={ cat.url }>{ cat.title }</DropdownLink></li>
					)) }
			</ul>
		</li>);
	
		return items;
	}
}

class DropdownLink extends React.Component {
	render() {
		return <a ref="link" className={"no-hover " + (this.props.className||"")} href={this.props.to} onClick={this.handleClick.bind(this)}>{ this.props.children }</a>;
	}
	
	componentDidMount() {
		//react's onTouchStart gets bound as passive, so need to do it ourselves
		this.refs.link.addEventListener('touchstart', this.handleClick.bind(this), {passive: false});
	}
	
	handleClick(ev) {
		//by catching touchstart/click ourselves, we can stop iOS adding :hover to the <a>
		ev.preventDefault();
		$('#catalog-top-menu').addClass('dehover');
		$('#catalog-top-menu').closest('li').addClass('dehover');
		var is_hovering = $('.hovering').length>0;
		$('.hovering').removeClass('hovering');
		$(document.body).removeClass('menu-open');
//		if(!is_hovering) {
			setTimeout(()=>{ 
				$('#catalog-top-menu').removeClass('dehover'); 
				$('#catalog-top-menu').closest('li').removeClass('dehover');
			}, 300);
//		}
		this.props.history.push(this.props.to);
			
		//iOS's :hover emulation is incredibly annoying - once it has triggered there's no way to get the element to unhover without the user actively clicking away.
	}
}
DropdownLink = withRouter(DropdownLink);

