import React from 'react';

import { Link, Route } from 'react-router-dom';

export var ListItemLink = ({ to, exact, label, children, link }) => (
	<Route path={to} exact={exact===undefined?true:exact} children={({ match }) => (
		<li className={match ? 'active' : ''}>
			{ (match && link===false) ? label :
			<Link to={to}>{ label }</Link> }
			{ children }
		</li>
	)}/>
);

